import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

const ListTree = ({ data, onEdit, onDelete, level = 0 }) => {
  return (
    <List>
      {data?.map((item) => (
        <React.Fragment key={item.id}>
          <ListItem style={{ borderBottom: '1px solid #ccc', paddingLeft: level * 20 }}>
            <ListItemText
              primary={item.level_name}
              // secondary={`Status: ${item.status}`}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => onEdit(item)}>
                <Edit />
              </IconButton>
              <IconButton edge="end" onClick={() => onDelete(item)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {/* Render nested items if any */}
          {item.items && item.items.length > 0 && (
            <ListTree
              data={item.items}
              onEdit={onEdit}
              onDelete={onDelete}
              level={level + 1} // Increase level for indentation
            />
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default ListTree;
