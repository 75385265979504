import React, { useState, useCallback, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import CustomInput from "../../../../components/shared/customInput";
import Button from "../../../../components/shared/customBtn";
import { createProviderSetting } from "../../../../api/misc";
import { useSelector } from "react-redux";
import classes from "./addProviders.module.scss";
import "./addProvidersBuiltin.scss";

// const ProviderSchema = Yup.object().shape({
//   name: Yup.string().required("Please enter provider name"),
//   provider_type_id: Yup.string().required("Please select provider type"),
//   image: Yup.mixed().required("Please upload a logo"),
// });

const ProviderSetting = ({
  editProvider,
  handleCancel,
  setProviderState,
  setEditProvider,
  allstatusVal,
  setCountProvider,
  countProvider,
}) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const fileInputRef = useRef(null); // Ref for file input
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null); // Original selected image

  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{
            payment_slip_prefix: editProvider?.payment_slip_prefix || "",
            payment_slip_prefix_starts:
              editProvider?.payment_slip_prefix_starts || "",
          }}
          // validationSchema={ProviderSchema}
          onSubmit={async (values) => {
            setLoading(true);
            try {
              let res = await createProviderSetting(editProvider?.id, values, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });

              if (res?.status === 200) {
                toast.success(res?.data?.message);
                setTimeout(() => {
                  setEditProvider(res.data.data);
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              toast.error(
                error.response?.data?.message || "An error occurred."
              );
            } finally {
              setLoading(false);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2} xs={12} md={4} lg={4}>
                  <Grid item xs={12} md={12}>
                    <CustomInput
                      label="Payment Slip Prefix"
                      type="text"
                      name="payment_slip_prefix"
                      value={values.payment_slip_prefix}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // placeholder="Enter provider payment_slip_prefix"
                      touched={touched.payment_slip_prefix}
                      errors={errors.payment_slip_prefix}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CustomInput
                      label="Payment Starting No"
                      type="number"
                      name="payment_slip_prefix_starts"
                      value={values.payment_slip_prefix_starts}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // placeholder="Enter provider payment_slip_prefix_starts"
                      touched={touched.payment_slip_prefix_starts}
                      errors={errors.payment_slip_prefix_starts}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addProviderBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? (
                    <Loader />
                  ) : (
                    <> {editProvider ? "Save" : "Save"}</>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ProviderSetting;
