import React, { useState, useEffect, useRef } from "react";
import style from "../../../assets/styles/partials/partial-listing.module.scss";
import classes from "./addloan.module.scss";
import {
  getAllOffer,
  createOffer,
  updateOfferStatus,
  deleteOffer,
  updateMainStatus,
} from "../../../api/misc";
import DataTable from "../../../components/shared/customTable";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "../../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../../components/shared/deleteModal";
import FormatDateTime from "../../../components/shared/FormatMain/formatDate";
import BasicModal from "../../../components/shared/basicmodal";
import CustomInput from "../../../components/shared/customInput";
import Loader from "../../../components/shared/loader";
import Visibility from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddComments from "../../../components/shared/customComments";
import useGetAxios from "../../../api/hooks/useGetAxios";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useSelector } from "react-redux";
import LoanComparer from "../creditComparer/loanComparer";

const OfferSchema = Yup.object().shape({
  dt_created: Yup.string().required("Please select date"),
  delivery_channel: Yup.string().required("Please select channel"),
  // document: Yup.string().required("Please select document"),
});
const AddOffer = ({
  clientData,
  handleCancel,
  editExistingclient,
  setEditExistingclient,
  setAllstatusVal,
  allstatusVal,
}) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [count, setCount] = useState(0);
  const [brochuresLink, setOfferLink] = useState();
  const [openPreviewBrochuer, setOpenPreviewOffer] = useState(false);
  const [getStatus, setgetStatus] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openUploadOffer, setOpenuploadOffer] = useState(false);
  const [editStatusOffer, setEditStatusOffer] = useState(null);
  const [showOfferComment, setShowOfferComment] = useState();
  const [offerData, setOfferData] = useState(null);
  console.log("clieeeet", editExistingclient?.status_slug);
  console.log("clieeeetallstatusVal", allstatusVal);
  const handlePreviewModalClose = () => {
    setOpenPreviewOffer(false);
  };

  const {
    response: getAllOfferData,
    loading: getOfferLoading,
    error: getOfferError,
  } = useGetAxios(
    getAllOffer(editExistingclient ? editExistingclient?.id : clientData?.id),
    false,
    count
  );
  const [allOfferData, setAllOfferData] = useState(getAllOfferData?.data);

  useEffect(() => {
    setAllOfferData(getAllOfferData?.data);
  }, [getAllOfferData]);
  useEffect(() => {
    if (editExistingclient?.status_slug == "gdpr-signed") {
      const handleSignedGDPR = async () => {
        const updateSt = {
          status: "indicative-offer-not-sent",
        };
        const resStatReq = await updateMainStatus(
          editExistingclient?.id,
          updateSt,
          {
            headers: {
              lang: lang,
              Authorization: `Bearer ${tokenAD}`,
            },
          }
        );
        if (resStatReq?.status === 200) {
          setEditExistingclient(resStatReq?.data?.data);
          setAllstatusVal("indicative-offer-not-sent");
        } else {
          throw new Error(resStatReq?.data?.message || "An error occurred");
        }
      };
      handleSignedGDPR();
    }
  }, []);

  const handlePreview = (row) => {
    setOpenPreviewOffer(true);
    setOfferLink(row?.file_path);
    setgetStatus(row);
  };

  const handleAddNewOffer = () => {
    setOpenuploadOffer(true);
  };
  const handleEdit = (data) => {
    setOpenuploadOffer(true);
    setEditStatusOffer(data);
  };
  const handleBrochureModalClose = () => {
    setOpenuploadOffer(false);
    setEditStatusOffer(null);
    setLoadingCreate(false);
  };
  const handleImageChange = (event, setFieldValue) => {
    const file = event?.target?.files[0];
    if (file) {
      console.log("document", file);
      // setPreview(URL.createObjectURL(file));
      setFieldValue("document", file);
    }
  };
  const columns = [
    {
      field: "created_at",
      headerName: "Offer Sent Date",
      flex: 1,
      resizable: false,
      renderCell: (params) => (
        <>
          {params?.row?.created_at
            ? FormatDateTime(params?.row?.created_at)
            : ""}
        </>
      ),
    },
    {
      field: "delivery_channel",
      headerName: "Channel",
      resizable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      resizable: false,
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          {params?.row?.file_name ? (
            <span
              className={style.iconStyleEdit}
              onClick={() => handlePreview(params?.row)}
            >
              <Visibility />
            </span>
          ) : (
            <span
              className={style.iconStyleEdit}
              title="File not attached"
              style={{ cursor: "not-allowed" }}
            >
              <Visibility />
            </span>
          )}

          {params?.row?.id ===
            Math.max(
              ...params?.api?.getAllRowIds().map((rowId) => parseInt(rowId))
            ) && (
            <>
              {editExistingclient?.status_slug == "indicative-offer-sent" ||
              editExistingclient?.status_slug ==
                "indicative-offer-waiting-for-the-client" ||
              editExistingclient?.status_slug == "indicative-offer-accepted" ||
              editExistingclient?.status_slug ==
                "indicative-offer-not-accepted-waiting-for-another" ||
              editExistingclient?.status_slug ==
                "indicative-offer-refused-process-closed" ||
              params?.row?.status == "sent" ? null : (
                <span
                  className={style.iconStyleDel}
                  onClick={() => handleDelete(params?.row?.id)}
                >
                  <CloseIcon />
                </span>
              )}
            </>
          )}
          {/* <span
            className={style.iconStyleEdits}
            onClick={() => handleEdit(params?.row)}
          >
            <AddTaskIcon />
          </span> */}
        </>
      ),
    },
  ];
  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here
    try {
      // Replace with your API call, here is a mock example
      const res = await deleteOffer(editExistingclient?.id, deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });
      if (res?.status == 200) {
        const updatedRows = allOfferData?.filter((row) => row?.id !== deleteId);
        setAllOfferData(updatedRows);
        toast.success(res?.data?.message);
        setOpenDelete(false);
        // setEditStatusOffer(null);
        // setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
    setEditStatusOffer(null);
  };

  const dateOnly = offerData?.created_at?.split("T")[0];
  console.log("allOfferData", allOfferData);
  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />
      {editExistingclient?.status_slug == "indicative-offer-sent" ? null : (
        <LoanComparer
          editExistingclient={editExistingclient}
          setOpenuploadOffer={setOpenuploadOffer}
          setShowOfferComment={setShowOfferComment}
          setOfferData={setOfferData}
          allstatusVal={allstatusVal}
          disableClass={true}
          apiCall={true}
        />
      )}

      {allOfferData?.length > 0 && (
        <>
          <div className={classes.commentHeader}>
            <div className={classes.heading}>
              <h3>
                <AssignmentIcon />
                Offers
              </h3>
            </div>
            {/* <div className={classes.addCommentBtn}>
              {editExistingclient?.status == "Indicative Offer Sent" ||
              editExistingclient?.status ==
                "Indicative Offer Waiting For The Client." ||
              editExistingclient?.status == "Indicative Offer Accepted" ||
              editExistingclient?.status ==
                "Indicative Offer Refused, Process Closed" ? null : (
                <Button
                  customClass={classes.filterBtn}
                  blue
                  custborderRadius
                  customWidth="300px"
                  onClick={handleAddNewOffer}
                >
                  Register Offer
                </Button>
              )}
            </div> */}
          </div>
          <div className={classes.reduceHeight}>
            <DataTable
              rows={allOfferData}
              columns={columns}
              loading={getOfferLoading}
              paginationModel={false}
            />
          </div>
          <AddComments
            editExistingclient={editExistingclient}
            clientData={clientData}
            commentType="preoffer"
          />
        </>
      )}
      <div className={classes.addUserBtn}>
        <Button onClick={handleCancel} outlineGrey customWidth="170px">
          Close
        </Button>
      </div>

      <BasicModal
        isOpen={openPreviewBrochuer}
        closeModal={handlePreviewModalClose}
        customWidth="994px"
        customClass="removePadi"
      >
        <iframe
          src={`${brochuresLink}#navpanes=0&scrollbar=0?timestamp=${Date.now()}`}
          width="100%"
          height="1000px"
          style={{ border: "none" }}
          title="PDF Viewer"
        />
      </BasicModal>
      {deleteId && (
        <DeleteModal
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="Offer"
          isLoading={loadingDelete}
        />
      )}
      <BasicModal
        isOpen={openUploadOffer}
        closeModal={handleBrochureModalClose}
        customWidth="994px"
      >
        <div className={classes.defaultForm}>
          <div className={classes.modalHead}>
            <h3>Select Delivery Channel</h3>
            <hr />
          </div>
          {editStatusOffer ? (
            <Formik
              initialValues={{
                status: editStatusOffer ? editStatusOffer?.status : "",
              }}
              onSubmit={async (values, { resetForm }) => {
                setLoadingStatus(true);
                try {
                  const res = await updateOfferStatus(
                    editExistingclient?.id,
                    editStatusOffer?.id,
                    values,
                    {
                      headers: {
                        lang: lang,
                        Authorization: `Bearer ${tokenAD}`,
                      },
                    }
                  );
                  // Handle success response
                  if (res?.status === 200) {
                    setAllOfferData((prevData) =>
                      prevData?.map((item) =>
                        item?.id === editStatusOffer.id
                          ? { ...item, status: values.status }
                          : item
                      )
                    );
                    setLoadingStatus(false);
                    toast.success(res?.data?.message);
                    resetForm({ values: "" });
                    setTimeout(() => {
                      setOpenuploadOffer(false);
                      // setCount(count + 1);
                      setEditStatusOffer(null);
                      // Reset form fields
                    }, 1000);
                  } else {
                    throw new Error(res?.data?.message || "An error occurred");
                  }
                } catch (error) {
                  setLoadingStatus(false);
                  toast.error(
                    error.response.data.message ||
                      "An error occurred during the process."
                  );
                }
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={classes.formMain}>
                    <Grid container spacing={2} xs={12} md={6} lg={6}>
                      <Grid item xs={12} md={12} lg={12}>
                        <div className={classes.inputMain}>
                          <label>Change Status</label>
                          <Field
                            as="select"
                            name="status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.inputField}
                          >
                            <option value="">Select Status</option>
                            {masterData?.applicationOfferStatuses?.map(
                              (item) => (
                                <option key={item?.slug} value={item?.slug}>
                                  {item?.name}
                                </option>
                              )
                            )}
                          </Field>
                          <div className={classes.errorMsg}>
                            <ErrorMessage name="status" />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.addUserBtn}>
                    <Button
                      onClick={handleBrochureModalClose}
                      outlineGrey
                      customWidth="170px"
                    >
                      Close
                    </Button>
                    <Button type="submit" blue customWidth="170px">
                      {loadingStatus ? <Loader /> : <>Save</>}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                dt_created: dateOnly,
                delivery_channel: "",
                offer_type: "1",
                document: "",
                is_url: "0",
              }}
              validationSchema={OfferSchema}
              onSubmit={async (values, { resetForm }) => {
                // console.log("values", values);

                if (values?.delivery_channel == "whatsapp") {
                  toast.error("Function not supported");
                } else {
                  setLoadingCreate(true);
                  let formData = new FormData();
                  if (values?.delivery_channel == "email") {
                    formData.append("dt_created", dateOnly);
                    formData.append(
                      "delivery_channel",
                      values?.delivery_channel
                    );
                    formData.append("offer_type", "1");
                    formData.append("is_url", "1");
                    formData.append(
                      "document",
                      showOfferComment?.data?.file_path || ""
                    );
                  } else {
                    formData.append("dt_created", dateOnly);
                    formData.append(
                      "delivery_channel",
                      values?.delivery_channel
                    );
                    formData.append("offer_type", "1");
                    formData.append("is_url", "1");
                    formData.append(
                      "document",
                      showOfferComment?.data?.file_path || ""
                    );
                  }
                  try {
                    const res = await createOffer(
                      editExistingclient?.id,
                      formData,
                      {
                        headers: {
                          lang: lang,
                          Authorization: `Bearer ${tokenAD}`,
                        },
                      }
                    );
                    // Handle success response
                    if (res?.status === 200) {
                      // Replace with your target path

                      const newUrl = showOfferComment?.data?.full_path; // Creates the full URL based on your current domain
                      window.open(newUrl, "_blank");
                      setAllOfferData((prevArray) => [
                        res?.data?.data,
                        ...prevArray,
                      ]);
                      setLoadingCreate(false);
                      toast.success(res?.data?.message);

                      const updatestat = {
                        status: "indicative-offer-sent",
                      };
                      const resStatReq = await updateMainStatus(
                        editExistingclient?.id,
                        updatestat,
                        {
                          headers: {
                            lang: lang,
                            Authorization: `Bearer ${tokenAD}`,
                          },
                        }
                      );
                      if (resStatReq?.status === 200) {
                        setEditExistingclient(resStatReq?.data?.data);
                        setAllstatusVal("indicative-offer-sent");
                      } else {
                        throw new Error(
                          resStatReq?.data?.message || "An error occurred"
                        );
                      }

                      setTimeout(() => {
                        setOpenuploadOffer(false);
                        // setCount(count + 1);
                        resetForm({ values: "" }); // Reset form fields
                      }, 1000);
                    } else {
                      throw new Error(
                        res?.data?.message || "An error occurred"
                      );
                    }
                  } catch (error) {
                    setLoadingStatus(false);
                    toast.error(
                      error?.response?.data?.message ||
                        "An error occurred during the process."
                    );
                  }
                }
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={classes.formMain}>
                    <Grid container spacing={2} xs={12} md={6} lg={6}>
                      {/* <Grid item xs={12} md={12} lg={12}>
                        <CustomInput
                          label="Date when has been sent to the client"
                          type="date"
                          name="dt_created"
                          value={values.dt_created}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Select Date"
                          touched={touched.dt_created}
                          errors={errors.dt_created}
                        />
                      </Grid> */}
                      <Grid item xs={12} md={12} lg={12}>
                        <div className={classes.inputMain}>
                          <label>Sending Channel</label>
                          <Field
                            as="select"
                            name="delivery_channel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.inputField}
                          >
                            <option value="">Select Channel</option>
                            {masterData?.applicationOfferDeliveryChannels?.map(
                              (item) => (
                                <option key={item?.slug} value={item?.slug}>
                                  {item?.name}
                                </option>
                              )
                            )}
                          </Field>
                          <div className={classes.errorMsg}>
                            <ErrorMessage name="delivery_channel" />
                          </div>
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} md={12} lg={12}>
                        <input
                          id="document"
                          name="document"
                          type="file"
                          accept=".pdf"
                          onChange={(event) =>
                            handleImageChange(event, setFieldValue)
                          }
                        />
                        <small>*just PDF files are allowed</small>
                      </Grid> */}
                    </Grid>
                  </div>
                  <div className={classes.addUserBtn}>
                    <Button
                      onClick={handleBrochureModalClose}
                      outlineGrey
                      customWidth="170px"
                    >
                      Close
                    </Button>
                    <Button type="submit" blue customWidth="170px">
                      {loadingCreate ? <Loader /> : <>Add Offer</>}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </BasicModal>
    </div>
  );
};

export default AddOffer;
