import React, { useState } from "react";
import BasicModal from "../basicmodal/index.js";
import Button from "../customBtn";
import classes from "./deleteModal.module.scss";
import recycle from "../../../assets/images/delete_icon.gif";
import CircularProgress from "@mui/material/CircularProgress";

const DeleteModal = ({
  isOpen,
  onClose,
  handleConfirmDelete,
  name,
  isLoading,
}) => {
  return (
    <>
      <BasicModal
        isOpen={isOpen}
        closeModal={onClose}
        customWidth="580px"
        customClass={classes.deleteStyle}
      >
        <div className={classes.modalContent}>
          <img src={recycle} className={classes.recycle} alt="recyclebin" />
          <h1 className={classes.heading}>
            Are you sure you want to Delete this {name}?
          </h1>
          <div className={classes.delBtn}>
            <Button
              onClick={onClose}
              outlineGrey
              customWidth="350px"
              fontsize="1rem"
              blue
            >
              No
            </Button>
            <Button
              onClick={handleConfirmDelete}
              customWidth="350px"
              fontsize="1rem"
              blue
            >
              {isLoading ? (
                <CircularProgress
                  color="inherit"
                  sx={{
                    width: "27px !important",
                    height: "27px !important",
                  }}
                />
              ) : (
                <>Yes, Delete</>
              )}
            </Button>
          </div>
        </div>
      </BasicModal>
    </>
  );
};

export default DeleteModal;
