import React, { useState, useEffect } from "react";
import classes from "./customFilter.module.scss";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { getAllCities } from "../../../api/misc";
import "./customFilterBuiltin.scss";
import { useTranslation } from "react-i18next";

const CustomFilter = ({
  mainHeading,

  // for career
  careerValue,
  setCareerValue,
  careerOptions,
  // optional attribute
  careerInitialValue,
  careerItemValue,
  careerItemName,

  // for status
  statusValue,
  setStatusValue,
  statusOptions,
  // optional attribute
  statusInitialValue,
  statusItemValue,
  statusItemName,

  // for notificationstatus
  notificationStatusValue,
  setNotificationStatusValue,

  // for paymentstatus
  paymentStatusValue,
  setPaymentStatusValue,

  // for paymentstatus
  deliveryStatusValue,
  setDeliveryStatusValue,

  // for month
  monthValue,
  setMonthValue,
  monthOptions,
  // optional attribute
  monthInitialValue,
  monthItemValue,
  monthItemName,

  // for bank
  bankValue,
  setBankValue,
  bankOptions,
  // optional attribute
  bankInitialValue,
  bankItemValue,
  bankItemName,

  // for slip
  slipValue,
  setSlipValue,
  slipOptions,
  // optional attribute
  slipInitialValue,
  slipItemValue,
  slipItemName,

  // for category
  categoryValue,
  setCategoryValue,
  categoryOptions,
  // optional attribute
  categoryInitialValue,
  categoryItemValue,
  categoryItemName,

  // for provider
  providerValue,
  setProviderValue,
  providerOptions,
  // optional attribute
  providerInitialValue,
  providerItemValue,
  providerItemName,

  // for type
  typeValue,
  setTypeValue,
  typeOptions,
  // optional attribute
  typeInitialValue,
  typeItemValue,
  typeItemName,

  // for provider
  countyValue,
  setCountyValue,
  countyOptions,
  // optional attribute
  countyInitialValue,
  countyItemValue,
  countyItemName,

  // for provider
  cityValue,
  setCityValue,
  // optional attribute
  cityInitialValue,

  // for search name
  inputValue,
  setInputValue,
  searchName,
  searchNamePlaceholder,

  setLoading,
}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const updateFilterData = (field, value) => {
    // Step 1: Retrieve the current filterData from localStorage, or use an empty object if not found
    const filterData = JSON.parse(localStorage.getItem("FilterData")) || {};

    // Step 2: Update only the specified field
    filterData[field] = { value };

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("FilterData", JSON.stringify(filterData));
  };
  const [loadingCities, setLoadingCities] = useState(false);
  const [cities, setCities] = useState([]);
  const fetchCities = async (selectedCountyCode) => {
    if (selectedCountyCode) {
      setLoadingCities(true);
      try {
        const response = await getAllCities(selectedCountyCode, {
          headers: {
            lang: lang,
            Authorization: `Bearer ${tokenAD}`,
          },
        });
        setCities(response.data.data.cities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
      setLoadingCities(false);
    }
  };
  console.log("cities", cities);
  const handleChangeCareer = (event) => {
    const selectedVal = event.target.value;
    setCareerValue(selectedVal);
    updateFilterData("career", selectedVal);
  };

  const handleChangeStatus = (event) => {
    const selectedVal = event.target.value;
    setStatusValue(selectedVal);
    updateFilterData("statuses", selectedVal);
  };
  const handleChangePaymentStatus = (event) => {
    const selectedVal = event.target.value;
    setPaymentStatusValue(selectedVal);
    updateFilterData("paymentStatus", selectedVal);
  };
  const handleChangeDeliveryStatus = (event) => {
    const selectedVal = event.target.value;
    setDeliveryStatusValue(selectedVal);
    updateFilterData("deliveryStatus", selectedVal);
  };

  const handleChangeBank = (event) => {
    const selectedVal = event.target.value;
    setBankValue(selectedVal);
    updateFilterData("bank", selectedVal);
  };

  const handleChangeNotificationStatus = (event) => {
    const selectedVal = event.target.value;
    setNotificationStatusValue(selectedVal);
    updateFilterData("notificationStatus", selectedVal);
  };
  const handleChangeMonth = (event) => {
    const selectedVal = event.target.value;
    setMonthValue(selectedVal);
    updateFilterData("month", selectedVal);
  };

  const handleChangeCategory = (event) => {
    const selectedVal = event.target.value;
    setCategoryValue(selectedVal);
    updateFilterData("category", selectedVal);
  };

  const handleChangeProvider = (event) => {
    const selectedVal = event.target.value;
    setProviderValue(selectedVal);
    updateFilterData("provider", selectedVal);
  };

  const handleChangeCounty = (event) => {
    const selectedVal = event.target.value;
    setCityValue("");
    setCountyValue(selectedVal);
    fetchCities(selectedVal);
    updateFilterData("county", selectedVal);
  };

  const handleChangeCity = (event) => {
    const selectedVal = event.target.value;
    setCityValue(selectedVal);

    updateFilterData("city", selectedVal);
  };

  const handleChangeType = (event) => {
    const selectedVal = event.target.value;
    setTypeValue(selectedVal);
    updateFilterData("type", selectedVal);
  };

  const handleInputChange = (event) => {
    const selectedVal = event.target.value;
    setInputValue(selectedVal);
    updateFilterData("searchName", selectedVal);
  };

  const handleChangeClearFilter = () => {
    setLoading(true);
    if (setCareerValue) setCareerValue("0");
    if (setStatusValue) setStatusValue("0");
    if (setCategoryValue) setCategoryValue("0");
    if (setProviderValue) setProviderValue("0");
    if (setCountyValue) setCountyValue("0");
    if (setCityValue) setCityValue("0");
    if (setTypeValue) setTypeValue("0");
    if (setPaymentStatusValue) setPaymentStatusValue("0");
    if (setDeliveryStatusValue) setDeliveryStatusValue("0");

    if (setBankValue) setBankValue("0");

    if (setMonthValue) setMonthValue("0");
    if (setNotificationStatusValue) setNotificationStatusValue("0");

    if (setInputValue) setInputValue("");

    // Clear all filters from localStorage
    localStorage.removeItem("FilterData");
    localStorage.removeItem("dashboradStatus");
    localStorage.removeItem("paginationPage");
    localStorage.removeItem("dashboradEditData");
    localStorage.removeItem("createApplication");
    setLoading(false);
  };
  useEffect(() => {
    if (countyValue && countyValue != "0") {
      fetchCities(countyValue); // Fetch cities for the default county_code
    }
  }, [countyValue]);
  return (
    <>
      <div className={classes.filter}>
        <h3>{t("filter.heading")}</h3>
        <div className={`${classes.filterRow} customStyleInput`}>
          {setCareerValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>Career</InputLabel>
                <Select
                  value={careerValue}
                  onChange={handleChangeCareer}
                  label="Career"
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  {careerInitialValue && (
                    <MenuItem
                      key={careerInitialValue ? careerInitialValue : "0"}
                      value={careerInitialValue ? careerInitialValue : "0"}
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>All</span>
                      </div>
                    </MenuItem>
                  )}
                  {careerOptions?.map((option) => (
                    <MenuItem
                      key={
                        careerItemValue
                          ? `${option[careerItemValue]}`
                          : option?.id
                      }
                      value={
                        careerItemValue
                          ? `${option[careerItemValue]}`
                          : option?.id
                      }
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>
                          {careerItemName
                            ? `${option[careerItemName]}`
                            : option?.level_name}
                        </span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {setMonthValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>Month</InputLabel>
                <Select
                  value={monthValue}
                  onChange={handleChangeMonth}
                  label="Month"
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  {monthInitialValue && (
                    <MenuItem
                      key={monthInitialValue ? monthInitialValue : "0"}
                      value={monthInitialValue ? monthInitialValue : "0"}
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>All</span>
                      </div>
                    </MenuItem>
                  )}
                  {monthOptions?.map((option) => (
                    <MenuItem
                      key={
                        statusItemValue
                          ? `${option[statusItemValue]}`
                          : option?.slug
                      }
                      value={
                        statusItemValue
                          ? `${option[statusItemValue]}`
                          : option?.slug
                      }
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>
                          {statusItemName
                            ? `${option[statusItemName]}`
                            : option?.name}
                        </span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {setBankValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>Bank</InputLabel>
                <Select
                  value={bankValue}
                  onChange={handleChangeBank}
                  label="Bank"
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  {bankInitialValue && (
                    <MenuItem
                      key={bankInitialValue ? bankInitialValue : "0"}
                      value={bankInitialValue ? bankInitialValue : "0"}
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>All</span>
                      </div>
                    </MenuItem>
                  )}
                  {bankOptions?.map((option) => (
                    <MenuItem
                      key={
                        bankItemValue ? `${option[bankItemValue]}` : option?.id
                      }
                      value={
                        bankItemValue ? `${option[bankItemValue]}` : option?.id
                      }
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>
                          {bankItemName
                            ? `${option[bankItemName]}`
                            : option?.name}
                        </span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {setPaymentStatusValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>Payment Status</InputLabel>
                <Select
                  value={paymentStatusValue}
                  onChange={handleChangePaymentStatus}
                  label="Payment Status"
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <MenuItem key="0" value="0">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>All</span>
                    </div>
                  </MenuItem>
                  <MenuItem key="paid" value="paid">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>Paid</span>
                    </div>
                  </MenuItem>
                  <MenuItem key="not-paid" value="not-paid">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>Not Paid</span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          {setDeliveryStatusValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>Delivery Status</InputLabel>
                <Select
                  value={deliveryStatusValue}
                  onChange={handleChangeDeliveryStatus}
                  label="Delivery Status"
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <MenuItem key="0" value="0">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>All</span>
                    </div>
                  </MenuItem>
                  <MenuItem key="sent" value="sent">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>Sent</span>
                    </div>
                  </MenuItem>
                  <MenuItem key="not-sent" value="not-sent">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>Not Sent</span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          {setStatusValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>{t("loan.status")}</InputLabel>
                <Select
                  value={statusValue}
                  onChange={handleChangeStatus}
                  label={t("loan.status")}
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  {statusInitialValue && (
                    <MenuItem
                      key={statusInitialValue ? statusInitialValue : "0"}
                      value={statusInitialValue ? statusInitialValue : "0"}
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>{t("filter.all")}</span>
                      </div>
                    </MenuItem>
                  )}
                  {statusOptions?.map((option) => (
                    <MenuItem
                      key={
                        statusItemValue
                          ? `${option[statusItemValue]}`
                          : option?.slug
                      }
                      value={
                        statusItemValue
                          ? `${option[statusItemValue]}`
                          : option?.slug
                      }
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>
                          {statusItemName
                            ? `${option[statusItemName]}`
                            : option?.name}
                        </span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {setCategoryValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>{t("filter.category")}</InputLabel>
                <Select
                  value={categoryValue}
                  onChange={handleChangeCategory}
                  label={t("filter.category")}
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <MenuItem
                    key={categoryInitialValue ? categoryInitialValue : "0"}
                    value={categoryInitialValue ? categoryInitialValue : "0"}
                  >
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>{t("filter.all")}</span>
                    </div>
                  </MenuItem>
                  {categoryOptions?.map((option) => (
                    <MenuItem
                      key={
                        categoryItemValue
                          ? `${option[categoryItemValue]}`
                          : option?.id
                      }
                      value={
                        categoryItemValue
                          ? `${option[categoryItemValue]}`
                          : option?.id
                      }
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>
                          {categoryItemName
                            ? `${option[categoryItemName]}`
                            : option?.category_name}
                        </span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {setProviderValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>{t("loan.provider")}</InputLabel>
                <Select
                  value={providerValue}
                  onChange={handleChangeProvider}
                  label={t("loan.provider")}
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <MenuItem
                    key={providerInitialValue ? providerInitialValue : "0"}
                    value={providerInitialValue ? providerInitialValue : "0"}
                  >
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>{t("filter.all")}</span>
                    </div>
                  </MenuItem>
                  {providerOptions?.map((option) => (
                    <MenuItem
                      key={
                        providerItemValue
                          ? `${option[providerItemValue]}`
                          : option?.id
                      }
                      value={
                        providerItemValue
                          ? `${option[providerItemValue]}`
                          : option?.id
                      }
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>
                          {providerItemName
                            ? `${option[providerItemName]}`
                            : option?.name}
                        </span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {setCountyValue && (
            <>
              <div className={classes.filterCol}>
                <FormControl variant="outlined">
                  <InputLabel>County</InputLabel>
                  <Select
                    value={countyValue}
                    onChange={handleChangeCounty}
                    label="county"
                    displayEmpty
                    sx={{
                      "& .MuiSelect-select": {
                        display: "flex",
                        justifyContent: "space-between",
                      },
                    }}
                  >
                    <MenuItem
                      key={countyInitialValue ? countyInitialValue : "0"}
                      value={countyInitialValue ? countyInitialValue : "0"}
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>All</span>
                      </div>
                    </MenuItem>
                    {countyOptions?.map((option) => (
                      <MenuItem
                        key={
                          countyItemValue
                            ? `${option[countyItemValue]}`
                            : option?.code
                        }
                        value={
                          countyItemValue
                            ? `${option[countyItemValue]}`
                            : option?.code
                        }
                      >
                        <div className={classes.dropdownItem}>
                          <span className={classes.value}>
                            {countyItemName
                              ? `${option[countyItemName]}`
                              : option?.county}
                          </span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {loadingCities ? (
                "loading"
              ) : (
                <div className={classes.filterCol}>
                  <FormControl variant="outlined">
                    <InputLabel>City</InputLabel>
                    <Select
                      value={cityValue}
                      onChange={handleChangeCity}
                      label="city"
                      disabled={
                        countyValue && countyValue != "0" ? false : true
                      }
                      displayEmpty
                      sx={{
                        "& .MuiSelect-select": {
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <MenuItem
                        key={cityInitialValue ? cityInitialValue : "0"}
                        value={cityInitialValue ? cityInitialValue : "0"}
                      >
                        <div className={classes.dropdownItem}>
                          <span className={classes.value}>All</span>
                        </div>
                      </MenuItem>
                      {cities?.map((option) => (
                        <MenuItem key={option?.siruta} value={option?.siruta}>
                          <div className={classes.dropdownItem}>
                            <span className={classes.value}>
                              {option?.city}
                            </span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </>
          )}
          {setTypeValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>Type</InputLabel>
                <Select
                  value={typeValue}
                  onChange={handleChangeType}
                  label="Type"
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <MenuItem
                    key={typeInitialValue ? typeInitialValue : "0"}
                    value={typeInitialValue ? typeInitialValue : "0"}
                  >
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>All</span>
                    </div>
                  </MenuItem>
                  {typeOptions?.map((option) => (
                    <MenuItem
                      key={
                        typeItemValue ? `${option[typeItemValue]}` : option?.id
                      }
                      value={
                        typeItemValue ? `${option[typeItemValue]}` : option?.id
                      }
                    >
                      <div className={classes.dropdownItem}>
                        <span className={classes.value}>
                          {typeItemName
                            ? `${option[typeItemName]}`
                            : option?.supplier_name}
                        </span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {setNotificationStatusValue && (
            <div className={classes.filterCol}>
              <FormControl variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={notificationStatusValue}
                  onChange={handleChangeNotificationStatus}
                  label="Status"
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <MenuItem key="0" value="0">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>All</span>
                    </div>
                  </MenuItem>
                  <MenuItem key="read" value="read">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>Read</span>
                    </div>
                  </MenuItem>
                  <MenuItem key="unread" value="unread">
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>Unread</span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          {searchName && (
            <div className={`${classes.filterCol} inputstyle`}>
              <TextField
                label={
                  searchNamePlaceholder
                    ? searchNamePlaceholder
                    : t("filter.name")
                }
                value={inputValue}
                onChange={handleInputChange}
                placeholder={
                  searchNamePlaceholder
                    ? searchNamePlaceholder
                    : t("filter.name")
                }
                type="text"
                variant="outlined"
              />
            </div>
          )}
          <div className={classes.filterCol}>
            <span
              className={classes.clearFilter}
              onClick={handleChangeClearFilter}
              title="Clear Filter"
            >
              <CancelIcon />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomFilter;
