import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/partials/partial-listing.module.scss";
import style from "./addloan.module.scss";
import { getAllContract, createLoanContractList } from "../../../api/misc";
import DataTable from "../../../components/shared/customTable";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Button from "../../../components/shared/customBtn";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import FormatDateTime from "../../../components/shared/FormatMain/formatDate";
import BasicModal from "../../../components/shared/basicmodal";
import CustomInput from "../../../components/shared/customInput";
import Loader from "../../../components/shared/loader";
import useGetAxios from "../../../api/hooks/useGetAxios";
import Visibility from "@mui/icons-material/Visibility";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useRef } from "react";
import { ErrorMessage } from "formik";

const BrochureSchema = Yup.object().shape({
  signing_date: Yup.string().required("Please select date"),
  contract: Yup.string().required("Please select document"),
});

const AddContract = ({ editExistingclient, handleCancel }) => {
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [loadingCreate, setLoadingCreate] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [count, setCount] = useState(0);
  const [brochuresLink, setBrochuresLink] = useState();
  const [openUploadBrochuer, setOpenuploadBrochuer] = useState(false);
  const [openPreviewBrochuer, setOpenPreviewBrochuer] = useState(false);
  const fileInputRef = useRef(null);

  const handlePreviewModalClose = () => {
    setOpenPreviewBrochuer(false);
  };
  const {
    response: getAllBrochureData,
    loading: getBrochureLoading,
    error: getBrochureError,
  } = useGetAxios(getAllContract(editExistingclient?.id), false, count);

  const handlePreview = (row) => {
    setOpenPreviewBrochuer(true);
    setBrochuresLink(row?.media?.full_path);
  };
  const lastRecord = getAllBrochureData?.data?.length
    ? getAllBrochureData.data[getAllBrochureData.data.length - 1]
    : null;
  const updateLastRecord = lastRecord?.signing_date?.split(" ")[0];
  console.log(updateLastRecord);
  const columns = [
    {
      field: "signing_date",
      headerName: "Date Time",
      flex: 1,
      resizable: false,
      renderCell: (params) => (
        <>
          {params?.row?.signing_date
            ? FormatDateTime(params?.row?.signing_date)
            : ""}
        </>
      ),
    },
    {
      field: "created_by",
      headerName: "Uploaded by",
      resizable: false,
      flex: 1,
      renderCell: (params) => <>{params?.row?.created_by?.name}</>,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handlePreview(params.row)}
          >
            <Visibility sx={{ color: "black" }} />
          </span>
        </>
      ),
    },
  ];
  const handleUploadClick = (values, setFieldValue) => {
    if (!values.signing_date) {
      toast.error("Please select a signing date before uploading.");
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.click(); // Open file picker
    }
  };
  const handleImageChange = async (
    event,
    values,
    setFieldValue,
    handleSubmit
  ) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type !== "application/pdf") {
        toast.error("Only PDF files are allowed.");
        return;
      }

      setFieldValue("contract", file);

      // Automatically submit the form after selecting the file
      setTimeout(() => {
        handleSubmit(); // Call form submission
      }, 500);
    }
  };
  console.log("brochuresLink", brochuresLink);
  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />

      <Grid container spacing={2} xs={12} md={6} lg={6}>
        <Grid item xs={12} md={12} lg={12}>
          <Formik
            initialValues={{
              signing_date: updateLastRecord || "",
              contract: "",
            }}
            enableReinitialize
            validationSchema={BrochureSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log("values", values);
              setLoadingCreate(true);

              const formData = new FormData();
              formData.append("application_id", editExistingclient?.id);
              formData.append("signing_date", values.signing_date);
              formData.append("contract", values.contract);

              try {
                const res = await createLoanContractList(formData, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });

                if (res?.status === 200) {
                  setLoadingCreate(false);
                  toast.success(res?.data?.message);

                  setTimeout(() => {
                    setCount(count + 1);
                    // resetForm();
                    if (fileInputRef.current) {
                      fileInputRef.current.value = ""; // Clear file input
                    }
                  }, 1000);
                } else {
                  throw new Error(res?.data?.message || "An error occurred");
                }
              } catch (error) {
                setLoadingCreate(false);
                toast.error(
                  error.response?.data?.message ||
                    "An error occurred during the process."
                );
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form>
                <div className={classes.formMain}>
                  <Grid container spacing={2} xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={8} lg={8}>
                      <CustomInput
                        label="Set signing date"
                        type="date"
                        name="signing_date"
                        value={values.signing_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Select signing date"
                        touched={touched.signing_date}
                        errors={errors.signing_date}
                      />
                    </Grid>

                    {/* Hidden File Input */}
                    <input
                      ref={fileInputRef}
                      id="contract"
                      name="contract"
                      type="file"
                      accept=".pdf"
                      style={{ display: "none" }} // Hide file input
                      onChange={(event) =>
                        handleImageChange(
                          event,
                          values,
                          setFieldValue,
                          handleSubmit
                        )
                      }
                    />
                    <Grid item xs={12} md={6} lg={6}>
                      <p className={style.docStyle}>
                        <FilePresentIcon /> Contract Document
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className={style.addUserBtn}>
                        <Button
                          type="button"
                          blue
                          customWidth="170px"
                          onClick={() =>
                            handleUploadClick(values, setFieldValue)
                          }
                        >
                          {loadingCreate ? <Loader /> : <>Upload</>}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className={style.reduceHeight}>
            <DataTable
              rows={getAllBrochureData?.data}
              columns={columns}
              loading={getBrochureLoading}
              paginationModel={false}
            />
          </div>
        </Grid>
      </Grid>

      {/* <div className={style.addUserBtn}>
        <Button onClick={handleCancel} outlineGrey customWidth="170px">
          Close
        </Button>
      </div> */}
      <BasicModal
        isOpen={openPreviewBrochuer}
        closeModal={handlePreviewModalClose}
        customWidth="1400px"
        customClass="removePadi"
      >
        <iframe
          src={`${brochuresLink}#navpanes=0&scrollbar=0?timestamp=${Date.now()}`}
          width="100%"
          height="1000px"
          style={{ border: "none" }}
          title="PDF Viewer"
        />
        {/* <img src={brochuresLink} alt="" /> */}
      </BasicModal>
    </div>
  );
};

export default AddContract;
