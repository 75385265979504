import { LOGOUT } from "../constants";
const initialState = {
  masterData: null,
};

const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MASTERDATA":
      return {
        ...state,
        masterData: action.payload,
      };

    case LOGOUT: // Reset state to initial values on logout
      return initialState;
    default:
      return state;
  }
};

export default masterDataReducer;
