import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./Login.module.scss";
import { Link } from "react-router-dom";
import { userTwoFactor } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import loginBanner from "../../../assets/images/loginBanner2.png";
import logo from "../../../assets/images/logo.png";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { useTranslation } from "react-i18next";
import CustomLanguage from "../../../components/shared/customLanguage";
import Loader from "../../../components/shared/loader";
import axios from "axios";
import { creteRecordGeoLocation } from "../../../api/misc";

const OTPSchema = Yup.object().shape({
  code: Yup.string().required("Field is required"),
});
const TwoFactorAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classes.mainLogin}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{ code: "" }}
          validationSchema={OTPSchema}
          onSubmit={async (values) => {
            setLoading(true);
            const lang = localStorage.getItem("lang");
            const userData = JSON.parse(localStorage.getItem("userData"));
            const dataSend = {
              email: userData.data.email,
              code: values.code,
            };
            try {
              const res = await userTwoFactor(dataSend, {
                headers: {
                  lang: lang ? lang : "en",
                },
              });

              if (res?.status == 200) {
                toast.success(res?.data?.message);
                dispatch({
                  type: LOGIN,
                  payload: res?.data,
                });

                localStorage.setItem(
                  "authToken",
                  JSON.stringify(res?.data?.data?.token)
                );
                localStorage.setItem("userData", JSON.stringify(res?.data));
                localStorage.setItem("lang", lang ? lang : "en");
                const routeGo =
                  (res?.data?.data?.menu[0]?.collapsable &&
                    res?.data?.data?.menu[0]?.dropdown) ||
                  (!res?.data?.data?.menu[0]?.collapsable &&
                    res?.data?.data?.menu[0]?.dropdown)
                    ? res?.data?.data?.menu[0]?.items[0]?.link
                    : res?.data?.data?.menu[0]?.link;
                setTimeout(async () => {
                  setLoading(false);
                  navigate(routeGo ? `/${routeGo}` : "/help");

                  if (res?.data.data.geo_location) {
                    const agentData = await axios.get(
                      "https://ipinfo.io/json?token=bf12de50cc381f"
                    );
                    await creteRecordGeoLocation(
                      {
                        user_agent: agentData.data,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${res?.data?.data?.token}`,
                          lang: "en",
                        },
                      }
                    );
                  }
                }, 1000);
              }
            } catch (error) {
              setLoading(false);
              const showErr = error?.response?.data?.message;
              toast.error(showErr);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <img src={logo} alt="logo" />
                <h1>{t("loginPage.login")}</h1>
              </div>
              <CustomInput
                label="OTP CODE"
                type="text"
                name="code"
                value={values.code}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter OTP"
                touched={touched.code}
                errors={errors.code}
              />

              <Button type="submit" blue disabled={!(isValid && dirty)}>
                {loading ? <Loader /> : <>Continue</>}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rightBanner}>
        <img src={loginBanner} alt="login banner" height="100%" />
      </div>
    </div>
  );
};

export default TwoFactorAuth;
