import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Switch,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  TextareaAutosize,
} from "@mui/material";
import { updateAppSetting } from "../../../api/misc";
import { ToastContainer, toast } from "react-toastify";
import classes from "./appSettings.module.scss";
import Loader from "../../../components/shared/loader";
import FormatText from "../../../components/shared/FormatMain/formattext";

const DynamicForm = ({
  appSettings,
  setCount,
  count,
  setAllAppsSettingData,
}) => {
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [loading, setLoading] = useState(false);
  const initialValues = appSettings?.reduce((acc, section) => {
    section.list.forEach((item) => {
      if (item.type === "checkbox") {
        acc[item.name] = item.value || [];
      } else if (item.type === "switch") {
        acc[item.name] = item.value === "on";
      } else {
        acc[item.name] = item.value || "";
      }
    });
    return acc;
  }, {});

  // const validationSchema = Yup.object();

  const handleApiCall = async (category, type, key, value) => {
    const payload = { category, type, key, value };
    setLoading(true);
    try {
      const res = await updateAppSetting(payload, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });
      if (res?.status === 200) {
        // setCount(count + 1);
        setLoading(false);
        setTimeout(() => {
          toast.success(res?.data?.message);
        }, 500);
        setAllAppsSettingData(res.data.data);
      }
    } catch (error) {
      setLoading(false);
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <ToastContainer />
      {loading && (
        <div className={classes.loaderCenter}>
          <Loader />
        </div>
      )}
      <Formik enableReinitialize initialValues={initialValues}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className={classes.genList}>
              {appSettings?.map((section) => (
                <div key={section.slug} className={classes.list}>
                  <div className={classes.genHeading}>
                    <h5>{section.name}</h5>
                  </div>
                  {section.list.map((item) => (
                    <div key={item.name} className={classes.dynamicMain}>
                      <label className={classes.labelName}>
                        {FormatText(item.name)}
                      </label>
                      {item.type === "input" && (
                        <Field
                          name={item.name}
                          type="text"
                          className={classes.dynamicInput}
                          label={item.name}
                          onBlur={(e) =>
                            handleApiCall(
                              section.slug,
                              item?.type,
                              item.name,
                              e.target.value
                            )
                          }
                        />
                      )}

                      {item.type === "switch" && (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values[item.name]}
                              className={classes.dynamicSwitch}
                              onChange={(e) => {
                                const newValue = e.target.checked;
                                setFieldValue(item.name, newValue);
                                handleApiCall(
                                  section.slug,
                                  item?.type,
                                  item.name,
                                  newValue ? "on" : "off"
                                );
                              }}
                            />
                          }
                          labelPlacement="start"
                        />
                      )}

                      {item.type === "checkbox" &&
                        item.list.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            className={classes.dynamicCheckbox}
                            control={
                              <input
                                type="checkbox"
                                name={item?.name}
                                value={option?.value}
                                checked={values[item?.name]?.includes(
                                  option?.value
                                )}
                                onChange={(e) => {
                                  const newValue = e.target.checked
                                    ? [...values[item.name], option.value]
                                    : values[item.name].filter(
                                        (val) => val !== option.value
                                      );
                                  setFieldValue(item.name, newValue);
                                  handleApiCall(
                                    section.slug,
                                    item?.type,
                                    item.name,
                                    newValue
                                  );
                                }}
                              />
                            }
                            label={option.text}
                          />
                        ))}

                      {item.type === "radio" && (
                        <div
                          role="group"
                          aria-labelledby={`${item.name}-label`}
                          className={classes.dynamicRadio}
                        >
                          {item.list.map((option) => (
                            <label key={option.value}>
                              <input
                                type="radio"
                                name={item.name}
                                value={option.value}
                                checked={values[item.name] === option.value}
                                onChange={(e) => {
                                  setFieldValue(item.name, e.target.value);
                                  handleApiCall(
                                    section.slug,
                                    item.type,
                                    item.name,
                                    e.target.value
                                  );
                                }}
                              />
                              {option.text}
                            </label>
                          ))}
                        </div>
                      )}

                      {item.type === "dropdown" && (
                        <Field
                          name={item.name}
                          as="select"
                          className={classes.dynamicDropdown}
                          onChange={async (e) => {
                            setFieldValue(item.name, e.target.value);
                            handleApiCall(
                              section.slug,
                              item?.type,
                              item.name,
                              e.target.value
                            );
                          }}
                        >
                          {item.list.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </Field>
                      )}

                      {item.type === "textarea" && (
                        <Field
                          name={item.name}
                          as={TextareaAutosize}
                          className={classes.dynamicTextarea}
                          minRows={5}
                          placeholder={item.name}
                          onBlur={(e) =>
                            handleApiCall(
                              section.slug,
                              item?.type,
                              item.name,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DynamicForm;
