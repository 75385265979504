import React from "react";
import classes from "./addProducts.module.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./addProductsBuiltin.scss";
import AddProvider from "./addProductMain";
import AddBrochures from "./addBrochure";
import AddClientDocuments from "./clientDocuments";
import AddProductSetting from "./productSetting";

const ProductTabs = ({
  editProduct,
  handleCancel,
  setProductState,
  setEditProduct,
  setCountProvider,
  countProvider,
}) => {
  localStorage.setItem(
    "breadcrumbName",
    editProduct ? "Edit Product" : "Add Product"
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  return (
    <>
      {editProduct ? <h2>#{editProduct.product_name}</h2> : null}
      <div className={classes.MainProvider}>
        <div className={classes.ProviderTabContainer}>
          <Box className={`${classes.tabsHead} ProviderTabBuiltin`}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Generic Data" {...a11yProps(0)} />
              <Tab
                label="Brochures"
                {...a11yProps(1)}
                disabled={editProduct ? false : true}
              />
              <Tab
                label="Client Documents"
                {...a11yProps(2)}
                disabled={editProduct ? false : true}
              />
              {/* <Tab
                label="Commission"
                {...a11yProps(3)}
                disabled={editProduct ? false : true}
              /> */}
              <Tab
                label="Settings"
                {...a11yProps(3)}
                disabled={editProduct ? false : true}
              />
              <Tab
                label="History"
                {...a11yProps(4)}
                disabled={editProduct ? false : true}
              />
            </Tabs>
          </Box>
        </div>
        <div className={classes.ProfileTabs}>
          <CustomTabPanel value={value} index={0}>
            <AddProvider
              handleCancel={handleCancel}
              editProduct={editProduct}
              setProductState={setProductState}
              setEditProduct={setEditProduct}
              setCountProvider={setCountProvider}
              countProvider={countProvider}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AddBrochures
              editProduct={editProduct}
              setEditProduct={setEditProduct}
              handleCancel={handleCancel}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <AddClientDocuments
              editProduct={editProduct}
              setEditProduct={setEditProduct}
              handleCancel={handleCancel}
            />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={3}>
            Commission Page
          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={3}>
            <AddProductSetting
              editProduct={editProduct}
              setEditProduct={setEditProduct}
              handleCancel={handleCancel}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            {/* <History /> */}
            History Page
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default ProductTabs;
