import React, { useState, useCallback } from "react";
import classes from "./addProviders.module.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./addProvidersBuiltin.scss";
import AddProvider from "./addProviderMain";
// import AddCareer from "./careerLevel";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import AddCommission from "./commission";
import ProviderHistory from "./providerHistory";
import ProviderSetting from "./providerSetting";
const ProviderTabs = ({
  editProvider,
  handleCancel,
  setProviderState,
  setEditProvider,
  setCountProvider,
  countProvider,
}) => {
  localStorage.setItem(
    "breadcrumbName",
    editProvider ? "Edit Provider" : "Add Provider"
  );
  const [value, setValue] = React.useState(0);
  const [allstatusVal, setAllstatusVal] = useState(
    editProvider ? editProvider?.status : "active"
  );
  // editExistingclient ? editExistingclient?.status_slug : 0
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const handleAllStatusChange = useCallback(async (event) => {
    setAllstatusVal(event.target.value);
  }, []);
  return (
    <>
      {editProvider ? <h2>#{editProvider.name}</h2> : null}
      <div className={classes.MainProvider}>
        <div className={classes.wholeStatus}>
          <FormControl variant="standard" sx={{ minWidth: 150 }}>
            <InputLabel>Status</InputLabel>
            <Select value={allstatusVal} onChange={handleAllStatusChange}>
              <MenuItem value="0" disabled>
                Select Status
              </MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"inactive"}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.ProviderTabContainer}>
          <Box className={`${classes.tabsHead} ProviderTabBuiltin`}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Generic Data" {...a11yProps(0)} />
              <Tab
                label="Commission"
                {...a11yProps(1)}
                disabled={editProvider ? false : true}
              />
              <Tab
                label="Settings"
                {...a11yProps(2)}
                disabled={editProvider ? false : true}
              />
              <Tab
                label="History"
                {...a11yProps(3)}
                disabled={editProvider ? false : true}
              />
            </Tabs>
          </Box>
        </div>
        <div className={classes.ProfileTabs}>
          <CustomTabPanel value={value} index={0}>
            <AddProvider
              handleCancel={handleCancel}
              editProvider={editProvider}
              setProviderState={setProviderState}
              setEditProvider={setEditProvider}
              allstatusVal={allstatusVal}
              setCountProvider={setCountProvider}
              countProvider={countProvider}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AddCommission
              handleCancel={handleCancel}
              editProvider={editProvider}
              setProviderState={setProviderState}
              setEditProvider={setEditProvider}
              allstatusVal={allstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ProviderSetting
              handleCancel={handleCancel}
              editProvider={editProvider}
              setEditProvider={setEditProvider}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <ProviderHistory
              handleCancel={handleCancel}
              editProvider={editProvider}
            />
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default ProviderTabs;
