import React, { useState, useEffect, useRef } from "react";
import classes from "./addloan.module.scss";
import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import Button from "../../../components/shared/customBtn";
import { getLoanDocument, uploadLoanDocument } from "../../../api/misc";
import { Grid, LinearProgress, Box, Typography } from "@mui/material";
import BasicModal from "../../../components/shared/basicmodal";
import FormatDateTime from "../../../components/shared/FormatMain/formatDate";

const AddDocument = ({ clientData, handleCancel, editExistingclient }) => {
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [loanDocument, setLoanDocument] = useState([]);
  const [count, setCount] = useState(0);
  const [clickID, setClickID] = useState("");
  const [documentLink, setDocumentLink] = useState();
  const [openPreviewDocument, setOpenPreviewDocument] = useState(false);

  const fileInputRef = useRef(null);

  const handlePreviewModalClose = () => {
    setOpenPreviewDocument(false);
  };

  const getGenericData = async () => {
    try {
      const headers = {
        headers: {
          lang: lang,
          Authorization: `Bearer ${tokenAD}`,
        },
      };
      const res = await getLoanDocument(editExistingclient?.id, headers);

      if (res?.status === 200) {
        setLoanDocument(res?.data?.data);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
  };
  console.log("loanDocument", loanDocument);
  useEffect(() => {
    getGenericData();
  }, [count]);

  const handlePreview = (path) => {
    setOpenPreviewDocument(true);
    setDocumentLink(path);
  };
  console.log("oyeterr", clickID);
  const handleFileSelect = async (event) => {
    console.log("listdaoooo", clickID);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLoading(true);

      try {
        const docData = new FormData();
        docData.append("application_id", editExistingclient?.id);
        docData.append("provider_type_document_id", clickID);
        docData.append("document", selectedFile);

        const res = await uploadLoanDocument(docData, {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        });

        if (res?.status == 200) {
          toast.success(res?.data?.message);
          setCount(count + 1);
        }
      } catch (error) {
        const showErr =
          error?.response?.data?.message == "Account Blocked"
            ? error?.response?.data?.errors[0]
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message;
        toast.error(showErr);
        setCount(count + 1);
      }
      setLoading(false);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className={classes.defaultUpload}>
        <Grid container spacing={2} xs={12} md={12} lg={8}>
          <Grid item xs={12} md={6} lg={6}>
            <h3>Document list to be provided by the client.</h3>
            <small>Upload all the documents in the designated area</small>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Box sx={{ width: "100%", mt: 2 }}>
              <Typography variant="body1" gutterBottom>
                Complete:{" "}
                {loanDocument
                  ? loanDocument?.percent
                    ? loanDocument?.percent
                    : "0"
                  : "0"}
                %
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  loanDocument
                    ? loanDocument?.percent
                      ? loanDocument?.percent
                      : "0"
                    : "0"
                } // Pass the percentage value here
                sx={{ height: 10, borderRadius: 5 }}
              />
            </Box>
            {loading && <Loader />}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <ul className={classes.docUpload}>
              {loanDocument?.list?.map((item, index) => (
                <li>
                  <div>
                    <p>
                      {index + 1}. {item?.document_name}
                    </p>
                    <small>
                      {item?.file && (
                        <>
                          uploaded on {FormatDateTime(item?.file?.created_at)}{" "}
                          by {item?.file?.created_by}
                        </>
                      )}
                    </small>
                  </div>
                  <div className={classes.uploadLink}>
                    {item?.flag == 1 && (
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => handlePreview(item?.filled_url)}
                      >
                        Template
                      </Button>
                    )}
                    {item?.file && (
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => handlePreview(item?.file?.file_path)}
                      >
                        View
                      </Button>
                    )}

                    <Box>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept=".pdf"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={(event) => handleFileSelect(event)}
                      />
                      {editExistingclient?.status_slug ===
                      "indicative-offer-refused-process-closed" ? (
                        ""
                      ) : (
                        <label>
                          <Button
                            variant="contained"
                            component="span"
                            onClick={() => {
                              fileInputRef.current.click();
                              setClickID(item?.id);
                            }}
                          >
                            Uploads
                          </Button>
                        </label>
                      )}
                    </Box>
                  </div>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
        <div className={classes.addUserBtn}>
          <Button onClick={handleCancel} outlineGrey customWidth="170px">
            Close
          </Button>
        </div>
        <BasicModal
          isOpen={openPreviewDocument}
          closeModal={handlePreviewModalClose}
          customWidth="1400px"
          customClass="removePadi"
        >
          <iframe
            src={`${documentLink}#navpanes=0&scrollbar=0?timestamp=${Date.now()}`}
            width="100%"
            height="1000px"
            style={{ border: "none" }}
            title="PDF Viewer"
          />
        </BasicModal>
      </div>
    </>
  );
};

export default AddDocument;
