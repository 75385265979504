import React from "react";
import { FormControlLabel, Checkbox, Box } from "@mui/material";
import { Formik, Field, Form } from "formik";

const CheckboxGroup = ({ label, name, children, setFieldValue, values }) => {
  const handleMainCheckboxChange = (e) => {
    const checked = e.target.checked;
    // Toggle all child checkboxes when the main checkbox is toggled
    children.forEach((child) => setFieldValue(child.name, checked));
  };

  // Determine main checkbox checked state based on child checkboxes
  const isMainChecked = children.every((child) => values[child.name]);
  const isMainIndeterminate =
    children.some((child) => values[child.name]) && !isMainChecked;

  return (
    <Box sx={{ marginBottom: 2 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isMainChecked}
            indeterminate={isMainIndeterminate}
            onChange={handleMainCheckboxChange}
          />
        }
        label={label}
      />
      <Box sx={{ pl: 4 }}>
        {children.map((child, index) => (
          <FormControlLabel
            key={index}
            control={
              <Field
                type="checkbox"
                as={Checkbox}
                name={child.name}
                checked={values[child.name]}
              />
            }
            label={child.label}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CheckboxGroup;
