// src/hooks/useGetAxios.js
import { useState, useEffect } from "react";
import axios from "../../utilities/index";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useGetAxios = (
  url,
  showToast = true,
  count,
  dispatch = null,
  actionType = null
) => {
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const tokenAD = JSON.parse(localStorage.getItem("authToken"));
      const lang = localStorage.getItem("lang");
      const ipVal = localStorage.getItem("ipInfo");
      const headers = {
        "Content-Type": "application/json",
        ...(tokenAD && { Authorization: `Bearer ${tokenAD}` }),
        ...(lang && { lang: lang }),
        ...(ipVal && { ip: ipVal }),
      };

      try {
        const result = await axios.get(url, { headers });
        setResponse(result.data);

        if (showToast) {
          toast.success(result?.data?.message);
        }

        // Dispatch success action if provided
        if (dispatch) {
          dispatch({
            type: actionType,
            payload: result?.data?.data,
          });
        }
      } catch (err) {
        console.log("mainerr", err);
        setError(err);
        if (err.response.data.message == "ip_restricted") {
          toast.error("Your IP is restricted");
          // navigate("./sign-in");
          // localStorage.removeItem("userData");
          // localStorage.removeItem("authToken");
          // localStorage.removeItem("userRole");
          // localStorage.removeItem("allBranches");
          // localStorage.removeItem("allUsers");
          // localStorage.removeItem("careerLevels");
          // localStorage.removeItem("FilterData");
          // localStorage.removeItem("paginationPage");
          // localStorage.removeItem("dashboradStatus");
          // localStorage.removeItem("dashboradEditData");
          // localStorage.removeItem("createApplication");
        }
        if (err.status === 401) {
          navigate("./sign-in");
          localStorage.removeItem("userData");
          localStorage.removeItem("authToken");
          localStorage.removeItem("userRole");
          localStorage.removeItem("allBranches");
          localStorage.removeItem("allUsers");
          localStorage.removeItem("careerLevels");
          localStorage.removeItem("FilterData");
          localStorage.removeItem("paginationPage");
          localStorage.removeItem("dashboradStatus");
          localStorage.removeItem("dashboradEditData");
          localStorage.removeItem("createApplication");
        }
        if (showToast) {
          toast.error(err.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, count]);

  return { response, loading, error };
};

export default useGetAxios;
