import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/partials/partial-listing.module.scss";
import style from "./products.module.scss";
import { getAllProducts, deleteProduct, cloneProduct } from "../../../api/misc";
import DataTable from "../../../components/shared/customTable";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import Button from "../../../components/shared/customBtn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../assets/images/edit_square.svg";
import DeleteModal from "../../../components/shared/deleteModal";
import CustomFilter from "../../../components/shared/customFilter";
import { useSelector } from "react-redux";
import useGetAxios from "../../../api/hooks/useGetAxios";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Products = ({
  setProductState,
  setEditProduct,
  setCountProvider,
  countProvider,
}) => {
  localStorage.setItem("breadcrumbName", "Settings | Products ");

  const masterData = useSelector((state) => state?.masterData?.masterData);
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [totalRows, setTotalRows] = useState(0); // Total number of rows from API
  const [count, setCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 20,
  }); // Pagination state

  const [providerValue, setProviderValue] = useState(
    FilterData?.provider?.value ? FilterData?.provider?.value : "0"
  );
  const [statusValue, setStatusValue] = useState(
    FilterData?.statuses?.value ? FilterData?.statuses?.value : "0"
  );
  const [inputValue, setInputValue] = useState(
    FilterData?.searchName?.value ? FilterData?.searchName?.value : ""
  );
  const {
    response: getAllProductData,
    loading: getProductLoading,
    error: getProductError,
  } = useGetAxios(
    getAllProducts(
      paginationModel.page,
      paginationModel.pageSize,
      providerValue && providerValue != "0" ? providerValue : "",
      statusValue && statusValue != "0" ? statusValue : "",
      inputValue ? inputValue : ""
    ),
    false,
    count
  );

  const handleEdit = (row) => {
    // Add your edit logic here
    setProductState(false);
    setEditProduct(row);
  };
  const [cloneData, setCloneData] = useState();
  const [loadingCloneId, setLoadingCloneId] = useState(null);
  const handleClone = async (id) => {
    // setLoadingDelete(true);
    // Add your delete logic here
    setLoadingCloneId(id);

    try {
      // Replace with your API call, here is a mock example
      const res = await cloneProduct(id, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        // setOpenDelete(false);
        setCloneData(res.data.data.product);
        setEditProduct(res.data.data.product);
        setProductState(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
        setCountProvider(countProvider + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    // setLoadingDelete(false);
    setLoadingCloneId(null);
  };

  console.log("cloneData", cloneData);
  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteProduct(deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
        setCountProvider(countProvider + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
    setCloneData(null);
  };
  const handleAddNewProduct = () => {
    // Add your delete logic here
    setProductState(false);
  };
  const handleExport = () => {
    console.log("handle export");
    // Add your delete logic here
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 70, resizable: false, flex: 1 },
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 2,
      resizable: false,
    },
    { field: "status", headerName: "Status", resizable: false, flex: 1 },
    {
      field: "provider_name",
      headerName: "Provider",
      resizable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          {loadingCloneId === params.row.id ? (
            <CircularProgress
              sx={{
                width: "20px !important",
                height: "20px !important",
                color: "black !important",
              }}
            />
          ) : (
            <span
              className={classes.iconStyleEdit}
              onClick={() => handleClone(params.row.id)}
              title="Clone"
            >
              <ContentCopyIcon sx={{ color: "black" }} />
            </span>
          )}

          <span
            className={classes.iconStyleEdit}
            onClick={() => handleEdit(params.row)}
          >
            <img src={editSquare} alt="editIcon" />
          </span>
          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </span>
        </>
      ),
    },
  ];

  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };

  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };

  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />
      <CustomFilter
        setLoading={setLoading}
        statusValue={statusValue}
        setStatusValue={setStatusValue}
        statusOptions={masterData?.productStatuses}
        statusInitialValue="0"
        providerValue={providerValue}
        setProviderValue={setProviderValue}
        providerOptions={masterData?.providers}
        providerInitialValue="0"
        inputValue={inputValue}
        setInputValue={setInputValue}
        searchName
      />
      <div className={classes.addRow}>
        <Button
          customClass={classes.filterBtn}
          blue
          custborderRadius
          customWidth="153px"
          onClick={handleAddNewProduct}
        >
          Add New
        </Button>
        <div className={classes.addRowInner}>
          <Button
            customClass={classes.filterBtn}
            outlineGrey
            custborderRadius
            onClick={handleExport}
          >
            Export data
          </Button>
          <div className={classes.paginationControls}>
            <FormControl variant="standard" sx={{ minWidth: 60 }}>
              <InputLabel>Rows</InputLabel>
              <Select
                value={paginationModel.pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <DataTable
        rows={getAllProductData?.data?.data}
        columns={columns}
        loading={getProductLoading}
        totalRows={getAllProductData?.data?.total}
        paginationModel={paginationModel}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
      {deleteId && (
        <DeleteModal
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="Product"
          isLoading={loadingDelete}
        />
      )}
    </div>
  );
};

export default Products;
