import React, { useState, useCallback } from "react";
import classes from "./addloan.module.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import "./addUsersBuiltin.scss";
import AddLoan from "./addLoanMain";
import AddProduct from "./addProductLoan";
import AddDocument from "./addDocuments";
import AddGDPR from "./addGDPR";
import MainStatus from "../../../components/shared/mainStatus";
import AddOffer from "./addOffer";
import AddAML from "./addAML";
import { updateMainStatus } from "../../../api/misc";
import PreScoring from "./preScoring";
import ClientFinancial from "./clientFinancial";
import { useTranslation } from "react-i18next";
import AddContract from "./addContract";

const LoanTabs = ({
  clientData,
  handleCancel,
  setLoanState,
  setClientData,
  editExistingclient,
  setEditExistingclient,
}) => {
  const { t } = useTranslation();
  localStorage.setItem(
    "breadcrumbName",
    editExistingclient ? t("loan.headingEditApp") : t("loan.headingAddApp")
  );

  const [value, setValue] = useState(0);
  const [countStatus, setCountStatus] = useState(0);
  const [allstatusVal, setAllstatusVal] = useState(
    editExistingclient ? editExistingclient?.status_slug : 0
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  console.log("allstatusVal", allstatusVal);
  return (
    <>
      <div className={`${classes.MainUser} ${classes.backgroundBase}`}>
        <div className={`${classes.headCol}`}>
          <h3>
            {editExistingclient &&
              editExistingclient?.id +
                "|" +
                editExistingclient?.client?.first_name +
                editExistingclient?.client?.last_name}
          </h3>
          <MainStatus
            allstatusVal={allstatusVal}
            setAllstatusVal={setAllstatusVal}
            editExistingclient={editExistingclient}
            setEditExistingclient={setEditExistingclient}
            apiCall={updateMainStatus}
            countStatus={countStatus}
          />
        </div>
        <div className={classes.UserTabContainer}>
          <Box className={`${classes.tabsHead} UserTabBuiltin`}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={t("loan.clienData")} {...a11yProps(0)} />
              <Tab
                label="GDPR"
                {...a11yProps(1)}
                disabled={editExistingclient ? false : true}
              />
              <Tab
                label="AML"
                {...a11yProps(2)}
                disabled={editExistingclient ? false : true}
              />
              <Tab
                label={t("loan.clientFinancials")}
                {...a11yProps(3)}
                disabled={
                  editExistingclient?.status == "Client Registered" ||
                  editExistingclient?.status == "Loan Application Created" ||
                  editExistingclient?.status == "GDPR: not generated" ||
                  editExistingclient?.status == "GDPR: Generated" ||
                  editExistingclient?.status == "GDPR Sent" ||
                  editExistingclient?.status == "GDPR: waiting for client" ||
                  // editExistingclient?.status == "GDPR: signed" ||
                  editExistingclient?.status == "GDPR: refused" ||
                  editExistingclient?.status == "GDPR: time out" ||
                  !editExistingclient
                    ? true
                    : false
                }
              />
              <Tab
                label="PreScoring"
                {...a11yProps(4)}
                disabled={
                  editExistingclient?.status == "Client Registered" ||
                  editExistingclient?.status == "Loan Application Created" ||
                  editExistingclient?.status == "GDPR: not generated" ||
                  editExistingclient?.status == "GDPR: Generated" ||
                  editExistingclient?.status == "GDPR Sent" ||
                  editExistingclient?.status == "GDPR: waiting for client" ||
                  // editExistingclient?.status == "GDPR: signed" ||
                  editExistingclient?.status == "GDPR: refused" ||
                  editExistingclient?.status == "GDPR: time out" ||
                  !editExistingclient
                    ? true
                    : false
                }
              />
              <Tab
                label={t("loan.offers")}
                {...a11yProps(5)}
                disabled={
                  editExistingclient?.status == "Client Registered" ||
                  editExistingclient?.status == "Loan Application Created" ||
                  editExistingclient?.status == "GDPR: not generated" ||
                  editExistingclient?.status == "GDPR: Generated" ||
                  editExistingclient?.status == "GDPR Sent" ||
                  editExistingclient?.status == "GDPR: waiting for client" ||
                  // editExistingclient?.status == "GDPR: signed" ||
                  editExistingclient?.status == "GDPR: refused" ||
                  editExistingclient?.status == "GDPR: time out" ||
                  !editExistingclient
                    ? true
                    : false
                }
              />
              <Tab
                label={t("loan.product")}
                {...a11yProps(6)}
                disabled={
                  editExistingclient?.status == "Client Registered" ||
                  editExistingclient?.status == "Loan Application Created" ||
                  editExistingclient?.status == "GDPR: not generated" ||
                  editExistingclient?.status == "GDPR: Generated" ||
                  editExistingclient?.status == "GDPR Sent" ||
                  editExistingclient?.status == "GDPR: waiting for client" ||
                  editExistingclient?.status == "GDPR: signed" ||
                  editExistingclient?.status == "GDPR: refused" ||
                  editExistingclient?.status == "GDPR: time out" ||
                  editExistingclient?.status == "Indicative Offer Not Sent" ||
                  editExistingclient?.status == "Indicative Offer  Sent" ||
                  editExistingclient?.status ==
                    "Indicative Offer  waiting for the client." ||
                  editExistingclient?.status ==
                    "Indicative Offer, not accepted, waiting for another" ||
                  editExistingclient?.status ==
                    "Indicative Offer refused, process  closed" ||
                  !editExistingclient
                    ? true
                    : false
                }
              />
              <Tab
                label={t("loan.documents")}
                {...a11yProps(7)}
                disabled={
                  editExistingclient?.status == "Client Registered" ||
                  editExistingclient?.status == "Loan Application Created" ||
                  editExistingclient?.status == "GDPR: not generated" ||
                  editExistingclient?.status == "GDPR: Generated" ||
                  editExistingclient?.status == "GDPR Sent" ||
                  editExistingclient?.status == "GDPR: waiting for client" ||
                  editExistingclient?.status == "GDPR: signed" ||
                  editExistingclient?.status == "GDPR: refused" ||
                  editExistingclient?.status == "GDPR: time out" ||
                  editExistingclient?.status == "Indicative Offer Not Sent" ||
                  editExistingclient?.status == "Indicative Offer  Sent" ||
                  editExistingclient?.status ==
                    "Indicative Offer  waiting for the client." ||
                  editExistingclient?.status ==
                    "Indicative Offer, not accepted, waiting for another" ||
                  editExistingclient?.status ==
                    "Indicative Offer refused, process  closed" ||
                  !editExistingclient
                    ? true
                    : false
                }
              />
              <Tab
                label="Contract"
                {...a11yProps(8)}
                disabled={
                  editExistingclient?.status == "Client Registered" ||
                  editExistingclient?.status == "Loan Application Created" ||
                  editExistingclient?.status == "GDPR: not generated" ||
                  editExistingclient?.status == "GDPR: Generated" ||
                  editExistingclient?.status == "GDPR Sent" ||
                  editExistingclient?.status == "GDPR: waiting for client" ||
                  editExistingclient?.status == "GDPR: signed" ||
                  editExistingclient?.status == "GDPR: refused" ||
                  editExistingclient?.status == "GDPR: time out" ||
                  editExistingclient?.status == "Indicative Offer Not Sent" ||
                  editExistingclient?.status == "Indicative Offer  Sent" ||
                  editExistingclient?.status ==
                    "Indicative Offer  waiting for the client." ||
                  editExistingclient?.status ==
                    "Indicative Offer, not accepted, waiting for another" ||
                  editExistingclient?.status ==
                    "Indicative Offer refused, process  closed" ||
                  !editExistingclient
                    ? true
                    : false
                }
              />
            </Tabs>
          </Box>
        </div>
        <div className={classes.ProfileTabs}>
          <CustomTabPanel value={value} index={0}>
            <AddLoan
              handleCancel={handleCancel}
              clientData={clientData}
              setLoanState={setLoanState}
              setClientData={setClientData}
              setEditExistingclient={setEditExistingclient}
              editExistingclient={editExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AddGDPR
              handleCancel={handleCancel}
              setClientData={setClientData}
              setLoanState={setLoanState}
              clientData={clientData}
              editExistingclient={editExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
              setCountStatus={setCountStatus}
              countStatus={countStatus}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <AddAML
              handleCancel={handleCancel}
              editExistingclient={editExistingclient}
              setEditExistingclient={setEditExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <ClientFinancial
              handleCancel={handleCancel}
              editExistingclient={editExistingclient}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <PreScoring
              handleCancel={handleCancel}
              editExistingclient={editExistingclient}
              setEditExistingclient={setEditExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <AddOffer
              handleCancel={handleCancel}
              setClientData={setClientData}
              setLoanState={setLoanState}
              clientData={clientData}
              editExistingclient={editExistingclient}
              setEditExistingclient={setEditExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            <AddProduct
              handleCancel={handleCancel}
              setClientData={setClientData}
              setLoanState={setLoanState}
              clientData={clientData}
              editExistingclient={editExistingclient}
              setEditExistingclient={setEditExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={7}>
            <AddDocument
              handleCancel={handleCancel}
              clientData={clientData}
              editExistingclient={editExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={8}>
            <AddContract
              handleCancel={handleCancel}
              clientData={clientData}
              editExistingclient={editExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default LoanTabs;
