import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/partials/partial-listing.module.scss";
import style from "./addClient/addloan.module.scss";
import {
  getAllPaymentSlips,
  getSingleSlip,
  ExportCommission,
  updateDeliveryStatus,
  deleteSlip,
} from "../../api/misc";
import DataTable from "../../components/shared/customTable";
import Button from "../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import FormatDateTimeOnly from "../../components/shared/FormatMain/formatDateOnly";
import CustomFilter from "../../components/shared/customFilter";
import useGetAxios from "../../api/hooks/useGetAxios";
import { useSelector } from "react-redux";
import Loader from "../../components/shared/loader";
import { Grid, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import PageviewIcon from "@mui/icons-material/Pageview";
import BasicModal from "../../components/shared/basicmodal";
import CustomInput from "../../components/shared/customInput";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../components/shared/deleteModal";
import DownloadIcon from "@mui/icons-material/Download";
import FormatText from "../../components/shared/FormatMain/formattext";
import FormatTextDash from "../../components/shared/FormatMain/formattextDash";

const DeliverySchema = Yup.object().shape({
  payment_date: Yup.string().required("Please select date"),
});
const PaymentSchema = Yup.object().shape({
  receipt_date: Yup.string().required("Please select date"),
});

const PaymentSlip = () => {
  localStorage.setItem("breadcrumbName", "Bank Payment Slips");
  // get master data start
  const masterData = useSelector((state) => state?.masterData?.masterData);
  // get master data end
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSlip, setLoadingSlip] = useState(false);
  const [loadingDelivery, setLoadingDelivery] = useState(false);
  const [viewSlip, setViewSlip] = useState([]);
  const [viewDownload, setViewDownload] = useState();
  const [openCommissionModal, setOpenCommissionModal] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [deliveryID, setDeliveryID] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteSlip(deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 20,
  }); // Pagination state
  const [paymentStatusValue, setPaymentStatusValue] = useState(
    FilterData?.paymentStatus?.value ? FilterData?.paymentStatus?.value : "0"
  );
  const [deliveryStatusValue, setDeliveryStatusValue] = useState(
    FilterData?.deliveryStatus?.value ? FilterData?.deliveryStatus?.value : "0"
  );

  const [monthValue, setMonthValue] = useState(
    FilterData?.month?.value ? FilterData?.month?.value : "0"
  );
  const [bankValue, setBankValue] = useState(
    FilterData?.bank?.value ? FilterData?.bank?.value : "0"
  );

  const [inputValue, setInputValue] = useState(
    FilterData?.searchName?.value ? FilterData?.searchName?.value : ""
  );

  // get all loans
  const {
    response: getAllCommissionData,
    loading: getCommissionLoading,
    error: getCommissionError,
  } = useGetAxios(
    getAllPaymentSlips(
      paginationModel.page,
      paginationModel.pageSize,
      monthValue && monthValue != "0" ? monthValue : "",
      bankValue && bankValue != "0" ? bankValue : "",
      paymentStatusValue && paymentStatusValue != "0" ? paymentStatusValue : "",
      deliveryStatusValue && deliveryStatusValue != "0"
        ? deliveryStatusValue
        : "",

      inputValue ? inputValue : ""
    ),
    false,
    count
  );
  const [loadingExport, setLoadingExport] = useState(false);
  const handleExport = async () => {
    console.log("handle export");
    // Add your delete logic here
    setLoadingExport(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await ExportCommission(
        paymentStatusValue && paymentStatusValue != "0"
          ? paymentStatusValue
          : "0",
        monthValue && monthValue != "0" ? monthValue : "0",
        {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        }
      );

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        console.log("res?.data", res?.data?.data?.file_url);
        const fileUrl = res?.data?.data?.file_url;
        setTimeout(() => {
          window.open(fileUrl, "_self");
        }, 500);
        // const link = document.createElement("a");
        // link.href = fileUrl;
        // link.setAttribute("download", "loan-application.xlsx"); // Set default filename
        // link.style.display = "none";

        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingExport(false);
  };
  const handleView = async (row) => {
    setLoadingSlip(true);
    try {
      // Replace with your API call, here is a mock example
      const res = await getSingleSlip(row.id, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setLoadingSlip(false);
        setOpenCommissionModal(true);
        setViewSlip(res?.data?.data?.details);
      }
    } catch (error) {
      setLoadingSlip(false);
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
  };
  const handleDownload = async (row) => {
    setLoadingSlip(true);
    try {
      // Replace with your API call, here is a mock example
      const res = await getSingleSlip(row.id, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setLoadingSlip(false);
        setOpenDownloadModal(true);
        setViewDownload(res?.data?.data);
      }
    } catch (error) {
      setLoadingSlip(false);
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
  };
  const handleCommissionModalClose = () => {
    setOpenCommissionModal(false);
    setOpenDeliveryModal(false);
    setViewSlip([]);
    setViewDownload(null);
    setDeliveryID(null);
    setOpenPaymentModal(false);
    setOpenDownloadModal(false);
    setOpenDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handlChangeDeliveryStatus = (id) => {
    setOpenDeliveryModal(true);
    setDeliveryID(id);
  };
  const handlChangePaymentStatus = (id) => {
    setOpenPaymentModal(true);
    setDeliveryID(id);
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 70, resizable: false, flex: 1 },
    {
      field: "id",
      headerName: "Payment Slip ID",
      resizable: false,
      flex: 1,
    },
    {
      field: "provider_name",
      headerName: "Bank",
      resizable: false,
      flex: 1,
      renderCell: (params) => <>{params?.row?.provider?.name}</>,
    },
    {
      field: "amount",
      headerName: "Amount",
      resizable: false,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Generation Date",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.created_at
            ? FormatDateTimeOnly(params?.row?.created_at)
            : ""}
        </>
      ),
    },
    {
      field: "delivery_status",
      headerName: "Delivary Status",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.delivery_status == "deleted" ? (
            <span
              className={style.iconStyleButtonDel}
              // onClick={() => handlChangeDeliveryStatus(params.row.id)}
            >
              {FormatText(params?.row?.delivery_status)}
            </span>
          ) : params?.row?.delivery_status == "not-sent" ? (
            <span
              className={style.iconStyleButtonNS}
              onClick={() => handlChangeDeliveryStatus(params.row.id)}
            >
              {FormatTextDash(params?.row?.delivery_status)}
            </span>
          ) : (
            <span
              className={style.iconStyleButtonS}
              // onClick={() => handlEdit(params.row)}
            >
              {FormatText(params?.row?.delivery_status)}{" "}
              {params?.row?.receipt_date && `/ ${params?.row?.receipt_date}`}
            </span>
          )}
        </>
      ),
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.payment_status === "not-paid" &&
          params?.row?.delivery_status !== "deleted" ? (
            <span
              className={style.iconStyleButtonPayNS}
              onClick={() => handlChangePaymentStatus(params.row.id)}
            >
              {FormatTextDash(params?.row?.payment_status)}
            </span>
          ) : params?.row?.payment_status === "paid" &&
            params?.row?.delivery_status !== "deleted" ? (
            <span className={style.iconStyleButtonPayS}>
              {FormatText(params?.row?.payment_status)}{" "}
              {params?.row?.payment_date && `/ ${params?.row?.payment_date}`}
            </span>
          ) : null}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          {params?.row?.delivery_status == "deleted" ? null : (
            <span
              className={classes.iconStyleEdits}
              onClick={() => handleDownload(params.row)}
            >
              <SystemUpdateAltIcon />
            </span>
          )}
          {params?.row?.delivery_status == "sent" ||
          params?.row?.delivery_status == "deleted" ? null : (
            <span
              className={classes.iconStyleEdits}
              onClick={() => handleDelete(params.row.id)}
            >
              <DisabledByDefaultIcon />
            </span>
          )}
          <span
            className={classes.iconStyleEdits}
            onClick={() => handleView(params.row)}
          >
            <PageviewIcon />
          </span>
        </>
      ),
    },
  ];
  const columnsSlip = [
    {
      field: "id",
      headerName: "Loan ID",
      resizable: false,
      flex: 1,
    },
    {
      field: "client_name",
      headerName: "Loan Client",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.application?.client?.first_name}{" "}
          {params?.row?.application?.client?.last_name}
        </>
      ),
    },
    {
      field: "loan_amount",
      headerName: "Loan Amount",
      resizable: false,
      flex: 1,
      renderCell: (params) => <>{params?.row?.application?.amount}</>,
    },
    {
      field: "company_commission_perc",
      headerName: "Company Commission",
      resizable: false,
      flex: 1,
    },
    {
      field: "company_commission_value",
      headerName: "Company Commission Value",
      resizable: false,
      flex: 1,
    },
  ];
  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };

  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };
  const getLast12Months = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const today = new Date();
    let monthOptions = [{ slug: "0", name: "All" }];

    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(today.getMonth() - i);

      const monthNumber = (date.getMonth() + 1).toString().padStart(2, "0"); // "01", "02", ...
      const monthName = months[date.getMonth()];

      monthOptions.push({ slug: monthNumber, name: monthName });
    }

    return monthOptions;
  };
  const handleDownloadPDF = () => {
    window.open(viewDownload?.pdf_url, "_blank");
  };
  const handleDownloadExcel = () => {
    window.open(viewDownload?.excel_url, "_blank");
  };
  // Usage
  const monthOptions = getLast12Months();
  console.log(monthOptions);
  const totalCompanyCommissionValue = viewSlip?.reduce(
    (total, row) => total + parseFloat(row.company_commission_value || 0),
    0
  );
  return (
    <div className={`${classes.mainListingPage} ${classes.backgroundBase}`}>
      <ToastContainer />
      <>
        <CustomFilter
          setLoading={setLoading}
          paymentStatusValue={paymentStatusValue}
          setPaymentStatusValue={setPaymentStatusValue}
          deliveryStatusValue={deliveryStatusValue}
          setDeliveryStatusValue={setDeliveryStatusValue}
          monthValue={monthValue}
          setMonthValue={setMonthValue}
          monthOptions={monthOptions}
          bankValue={bankValue}
          setBankValue={setBankValue}
          bankOptions={masterData?.providers}
          bankInitialValue="0"
          // inputValue={inputValue}
          // setInputValue={setInputValue}
          // searchName
          // searchNamePlaceholder="Payment Slip"
        />

        <div className={classes.addRow}>
          <div></div>
          <div className={classes.addRowInner}>
            <Button
              customClass={classes.filterBtn}
              outlineGrey
              custborderRadius
              onClick={handleExport}
            >
              Export data {loadingExport && <Loader />}
            </Button>
            <div className={classes.paginationControls}>
              <FormControl variant="standard" sx={{ minWidth: 60 }}>
                <InputLabel>Rows</InputLabel>
                <Select
                  value={paginationModel.pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <DataTable
          rows={getAllCommissionData?.data?.data}
          columns={columns}
          loading={getCommissionLoading}
          totalRows={getAllCommissionData?.data?.total}
          paginationModel={paginationModel}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <BasicModal
          isOpen={openDeliveryModal}
          closeModal={handleCommissionModalClose}
          customWidth="994px"
        >
          <div className={style.defaultForm}>
            <Formik
              initialValues={{
                payment_date: "",
                receipt_date: null,
                receipt_reference: null,
              }}
              validationSchema={DeliverySchema}
              onSubmit={async (values, { resetForm }) => {
                setLoadingDelivery(true);
                console.log("values", values);
                try {
                  const res = await updateDeliveryStatus(
                    deliveryID,
                    "payment",
                    values,
                    {
                      headers: {
                        lang: lang,
                        Authorization: `Bearer ${tokenAD}`,
                      },
                    }
                  );
                  // Handle success response
                  if (res?.status === 200) {
                    setLoadingDelivery(false);
                    toast.success(res?.data?.message);

                    setTimeout(() => {
                      setOpenCommissionModal(false);
                      setCount(count + 1);
                      resetForm({ values: "" }); // Reset form fields
                    }, 1000);
                  } else {
                    throw new Error(res?.data?.message || "An error occurred");
                  }
                } catch (error) {
                  setLoadingDelivery(false);
                  toast.error(
                    error?.response?.data?.message ||
                      "An error occurred during the process."
                  );
                }
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={style.formMain}>
                    <h3></h3>
                    <p>Mark selected Payment Slip as sent.</p>
                    <Grid container spacing={2} xs={12} md={8} lg={8}>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomInput
                          label="Date of sending*"
                          type="date"
                          name="payment_date"
                          value={values.payment_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Select Date"
                          touched={touched.payment_date}
                          errors={errors.payment_date}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={style.addUserBtn}>
                    <Button
                      onClick={handleCommissionModalClose}
                      outlineGrey
                      customWidth="170px"
                    >
                      Close
                    </Button>
                    <Button type="submit" blue customWidth="170px">
                      {loadingDelivery ? <Loader /> : <>Apply</>}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </BasicModal>
        <BasicModal
          isOpen={openPaymentModal}
          closeModal={handleCommissionModalClose}
          customWidth="994px"
        >
          <div className={style.defaultForm}>
            <Formik
              initialValues={{
                payment_date: null,
                receipt_date: "",
                receipt_reference: "",
              }}
              validationSchema={PaymentSchema}
              onSubmit={async (values, { resetForm }) => {
                setLoadingDelivery(true);
                console.log("values", values);
                try {
                  const res = await updateDeliveryStatus(
                    deliveryID,
                    "receipt",
                    values,
                    {
                      headers: {
                        lang: lang,
                        Authorization: `Bearer ${tokenAD}`,
                      },
                    }
                  );
                  // Handle success response
                  if (res?.status === 200) {
                    setLoadingDelivery(false);
                    toast.success(res?.data?.message);

                    setTimeout(() => {
                      setOpenPaymentModal(false);
                      setCount(count + 1);
                      resetForm({ values: "" }); // Reset form fields
                    }, 1000);
                  } else {
                    throw new Error(res?.data?.message || "An error occurred");
                  }
                } catch (error) {
                  setLoadingDelivery(false);
                  toast.error(
                    error?.response?.data?.message ||
                      "An error occurred during the process."
                  );
                }
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={style.formMain}>
                    <h3></h3>
                    <p>Mark selected Payment Slip as paid.</p>
                    <Grid container spacing={2} xs={12} md={8} lg={8}>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomInput
                          label="Date of receipt*"
                          type="date"
                          name="receipt_date"
                          value={values.receipt_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Select Date"
                          touched={touched.receipt_date}
                          errors={errors.receipt_date}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomInput
                          label="Receipt reference"
                          type="text"
                          name="receipt_reference"
                          value={values.receipt_reference}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="enter reference"
                          touched={touched.receipt_reference}
                          errors={errors.receipt_reference}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={style.addUserBtn}>
                    <Button
                      onClick={handleCommissionModalClose}
                      outlineGrey
                      customWidth="170px"
                    >
                      Close
                    </Button>
                    <Button type="submit" blue customWidth="170px">
                      {loadingDelivery ? <Loader /> : <>Apply</>}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </BasicModal>
        {deleteId && (
          <DeleteModal
            isOpen={openDelete}
            onClose={handleCommissionModalClose}
            handleConfirmDelete={handleConfirmDelete}
            name="Payment Slip"
            isLoading={loadingDelete}
          />
        )}
        <BasicModal
          isOpen={openDownloadModal}
          closeModal={handleCommissionModalClose}
          customWidth="994px"
        >
          <div className={style.defaultForm}>
            <>
              <div className={style.slipStyle}>
                <h3> The payment slip has been generated</h3>
                <h4>No: #{viewDownload?.id}</h4>
                <Button onClick={handleDownloadPDF} black customWidth="220px">
                  <DownloadIcon /> Download PDF
                </Button>
                <Button onClick={handleDownloadExcel} black customWidth="220px">
                  <DownloadIcon /> Download Excel
                </Button>
              </div>
            </>
          </div>
        </BasicModal>
        <BasicModal
          isOpen={openCommissionModal}
          closeModal={handleCommissionModalClose}
          customWidth="994px"
        >
          <div className={style.defaultForm}>
            <>
              <div className={style.reduceHeight}>
                <DataTable rows={viewSlip} columns={columnsSlip} />
              </div>
              <div className={style.totalComi}>
                <p>
                  Total Due Commission:{" "}
                  <span> {totalCompanyCommissionValue} LEI</span>
                </p>
                <p>
                  Total Contracts Brokered: <span> #{viewSlip.length}</span>
                </p>
              </div>
              <div className={style.addUserBtn}>
                <Button
                  onClick={handleCommissionModalClose}
                  outlineGrey
                  customWidth="170px"
                >
                  Close
                </Button>
              </div>
            </>
          </div>
        </BasicModal>
      </>
    </div>
  );
};

export default PaymentSlip;
