import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/partials/partial-listing.module.scss";
import style from "./incomeSource.module.scss";
import { getAllIncomeSource, deleteIncomeSource } from "../../../api/misc";
import DataTable from "../../../components/shared/customTable";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import Button from "../../../components/shared/customBtn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../assets/images/edit_square.svg";
import DeleteModal from "../../../components/shared/deleteModal";
import CustomFilter from "../../../components/shared/customFilter";
import { useSelector } from "react-redux";
import useGetAxios from "../../../api/hooks/useGetAxios";

const IncomeSource = ({ setIncomeSourceState, setEditIncomeSource }) => {
  localStorage.setItem("breadcrumbName", "Settings | Income Sources ");

  const masterData = useSelector((state) => state?.masterData?.masterData);
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [count, setCount] = useState(0);

  const {
    response: getAllAccessLevelData,
    loading: getAccessLevelLoading,
    error: getAccessLevelError,
  } = useGetAxios(getAllIncomeSource(), false, count);

  const handleEdit = (row) => {
    // Add your edit logic here
    setIncomeSourceState(false);
    setEditIncomeSource(row);
  };

  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteIncomeSource(deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handleAddNewAccessLevel = () => {
    // Add your delete logic here
    setIncomeSourceState(false);
  };
  const handleExport = () => {
    console.log("handle export");
    // Add your delete logic here
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 70, resizable: false, flex: 1 },
    {
      field: "name_ro",
      headerName: "Income source (RO)",
      flex: 2,
      resizable: false,
    },
    {
      field: "name_en",
      headerName: "Income source (EN)",
      resizable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handleEdit(params.row)}
          >
            <img src={editSquare} alt="editIcon" />
          </span>

          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </span>
        </>
      ),
    },
  ];

  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />

      <div className={classes.addRow}>
        <Button
          customClass={classes.filterBtn}
          blue
          custborderRadius
          customWidth="153px"
          onClick={handleAddNewAccessLevel}
        >
          Add New
        </Button>
        <div className={classes.addRowInner}>
          {/* <Button
            customClass={classes.filterBtn}
            outlineGrey
            custborderRadius
            onClick={handleExport}
          >
            Export data
          </Button> */}
          {/* <div className={classes.paginationControls}>
            <FormControl variant="standard" sx={{ minWidth: 60 }}>
              <InputLabel>Rows</InputLabel>
              <Select
                value={paginationModel.pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div> */}
        </div>
      </div>
      <DataTable
        rows={getAllAccessLevelData?.data}
        columns={columns}
        loading={getAccessLevelLoading}
        // totalRows={getAllAccessLevelData?.data?.total}
        // paginationModel={paginationModel}
        // onPageChange={handlePageChange}
        // onPageSizeChange={handlePageSizeChange}
      />
      {deleteId && (
        <DeleteModal
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="Income Source"
          isLoading={loadingDelete}
        />
      )}
    </div>
  );
};

export default IncomeSource;
