import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/partials/partial-listing.module.scss";
import style from "./header.module.scss";
import { getAllNotificationDynamic, ExportCommission } from "../../api/misc";
import DataTable from "../shared/customTable";
import Button from "../shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import FormatDateEngOnly from "../shared/FormatMain/formatDateEngOnly";
import CustomFilter from "../shared/customFilter";
import useGetAxios from "../../api/hooks/useGetAxios";
import { useSelector } from "react-redux";
import Loader from "../shared/loader";
import { Grid, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FormatText from "../shared/FormatMain/formattext";
import BasicModal from "../shared/basicmodal";

const AllNotifications = ({ allNotiList }) => {
  localStorage.setItem("breadcrumbName", "All Notifications");
  // get master data start
  const masterData = useSelector((state) => state?.masterData?.masterData);
  // get master data end
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 20,
  }); // Pagination state
  const [notificationStatusValue, setNotificationStatusValue] = useState(
    FilterData?.notificationStatus?.value
      ? FilterData?.notificationStatus?.value
      : "0"
  );

  const [typeValue, setTypeValue] = useState(
    FilterData?.type?.value ? FilterData?.type?.value : "0"
  );

  // get all loans
  const {
    response: getAllNotificationData,
    loading: getNotificationLoading,
    error: getNotificationError,
  } = useGetAxios(
    getAllNotificationDynamic(
      paginationModel.page,
      paginationModel.pageSize,
      notificationStatusValue && notificationStatusValue != "0"
        ? notificationStatusValue
        : "",
      typeValue && typeValue != "0" ? typeValue : ""
    ),
    false,
    count
  );
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [singleNotifications, setSingleNotifications] = useState();
  const handleNotificationModalClose = () => {
    setOpenNotificationModal(false);
    setSingleNotifications(null);
  };
  const handleView = (row) => {
    setOpenNotificationModal(true);
    setSingleNotifications(row);
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 70, resizable: false, flex: 1 },
    {
      field: "subject",
      headerName: "Subject",
      resizable: false,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      resizable: false,
      flex: 1,
      renderCell: (params) => <>{FormatText(params?.row?.type)}</>,
    },
    {
      field: "is_read",
      headerName: "Status",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>{params?.row?.is_read == 0 ? "unread" : "read"}</>
      ),
    },
    {
      field: "created_at",
      headerName: "Date Sent",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.created_at
            ? FormatDateEngOnly(params?.row?.created_at)
            : ""}
        </>
      ),
    },
    {
      field: "updated_at",
      headerName: "Date Read",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.updated_at
            ? FormatDateEngOnly(params?.row?.updated_at)
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handleView(params.row)}
          >
            <VisibilityIcon sx={{ color: "black" }} />
          </span>
        </>
      ),
    },
  ];

  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };

  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNotificationNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < getAllNotificationData?.data.data.length - 1
        ? prevIndex + 1
        : prevIndex
    );
  };
  console.log("currentIndex", currentIndex);
  const handleNotificationPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  return (
    <div className={`${classes.mainListingPage} ${classes.backgroundBase}`}>
      <>
        <BasicModal
          isOpen={openNotificationModal}
          closeModal={handleNotificationModalClose}
          customWidth="994px"
          removePadi
        >
          {getAllNotificationData?.data.data?.length > 0 && (
            <div
              key={getAllNotificationData?.data.data[currentIndex]?.id}
              className={`${style.modalStyle}`}
            >
              <h3>
                {FormatText(
                  getAllNotificationData?.data.data[currentIndex]?.type
                )}
              </h3>
              <p>
                {getAllNotificationData?.data.data[currentIndex]?.description}
              </p>
            </div>
          )}

          <div className={style.addUserBtn}>
            <Button
              onClick={handleNotificationPrev}
              grey
              customWidth="70px"
              disabled={currentIndex === 0}
            >
              {`<`}
            </Button>
            <Button
              onClick={handleNotificationNext}
              grey
              customWidth="70px"
              disabled={
                currentIndex === getAllNotificationData?.data.data?.length - 1
              }
            >
              {`>`}
            </Button>
            <Button
              onClick={handleNotificationModalClose}
              outlineGrey
              customWidth="170px"
            >
              Close
            </Button>
          </div>
        </BasicModal>
        <CustomFilter
          setLoading={setLoading}
          typeValue={typeValue}
          setTypeValue={setTypeValue}
          typeOptions={masterData?.notificationTypes}
          typeItemValue="slug"
          typeItemName="name"
          typeInitialValue="0"
          notificationStatusValue={notificationStatusValue}
          setNotificationStatusValue={setNotificationStatusValue}
        />

        <div className={classes.addRow}>
          <div></div>
          <div className={classes.addRowInner}>
            <div className={classes.paginationControls}>
              <FormControl variant="standard" sx={{ minWidth: 60 }}>
                <InputLabel>Rows</InputLabel>
                <Select
                  value={paginationModel.pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <DataTable
          rows={getAllNotificationData?.data.data}
          columns={columns}
          loading={getNotificationLoading}
          totalRows={getAllNotificationData?.data?.total}
          paginationModel={paginationModel}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    </div>
  );
};

export default AllNotifications;
