import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./signatureBuiltin.scss";
import Button from "../customBtn";

const SignaturePad = ({ onSave, setSignature, signaturePadRef }) => {
  // Function to clear the signature pad

  // Function to save the signature as an image
  // const handleSave = () => {
  //   const dataURL = signaturePadRef.current.toDataURL();
  //   onSave(dataURL); // Pass the dataURL back to the parent component or save it directly
  // };

  return (
    <>
      <div className="signMain">
        <h3> Signature Here </h3>
        <SignatureCanvas
          ref={signaturePadRef}
          penColor="black"
          canvasProps={{
            className: "signatureCanvas",
          }}
        />
      </div>
      <p style={{ textAlign: "center" }}>
        <b>You must need to add signature to submit the form</b>
      </p>
      {/* <div className="signBtn">
        <Button onClick={handleClear} outlineGrey>
          Clear
        </Button>
        <Button onClick={handleSave} black>
          Save Signature
        </Button>
      </div> */}
    </>
  );
};

export default SignaturePad;
