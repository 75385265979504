import React, { useState } from "react";
import Products from "./getAllProduct";
import AddProduct from "./addProduct";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { useDispatch } from "react-redux";
import { getAllMasterData } from "../../../api/misc";

const MainProduct = () => {
  const dispatch = useDispatch();
  const [productState, setProductState] = useState(true);
  const [editProduct, setEditProduct] = useState();
  const handleCancel = () => {
    setProductState(true);
    setEditProduct(null);
  };
  const [countProvider, setCountProvider] = useState(0);
  const { response: masterDataProvider } = useGetAxios(
    getAllMasterData(),
    false,
    countProvider,
    dispatch,
    "MASTERDATA"
  );
  return (
    <>
      {productState ? (
        <Products
          setProductState={setProductState}
          setEditProduct={setEditProduct}
          setCountProvider={setCountProvider}
          countProvider={countProvider}
        />
      ) : (
        <AddProduct
          editProduct={editProduct}
          handleCancel={handleCancel}
          setProductState={setProductState}
          setEditProduct={setEditProduct}
          setCountProvider={setCountProvider}
          countProvider={countProvider}
        />
      )}
    </>
  );
};

export default MainProduct;
