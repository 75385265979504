import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/partials/partial-listing.module.scss";
import style from "./addClient/addloan.module.scss";
import {
  getAllAgentCommission,
  ExportAgentCommission,
  updateCommissionStatus,
} from "../../api/misc";
import DataTable from "../../components/shared/customTable";
import Button from "../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import FormatDateTime from "../../components/shared/FormatMain/formatDate";
import CustomFilter from "../../components/shared/customFilter";
import useGetAxios from "../../api/hooks/useGetAxios";
import { useSelector } from "react-redux";
import Loader from "../../components/shared/loader";
import BasicModal from "../../components/shared/basicmodal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import CustomInput from "../../components/shared/customInput";

const CommissionSchema = Yup.object().shape({
  payment_date: Yup.string().required("Please select date"),
});

const AgentCommission = () => {
  localStorage.setItem("breadcrumbName", "Agent Commission");
  // get master data start
  const masterData = useSelector((state) => state?.masterData?.masterData);
  // get master data end
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 20,
  }); // Pagination state
  const [paymentStatusValue, setPaymentStatusValue] = useState(
    FilterData?.paymentStatus?.value ? FilterData?.paymentStatus?.value : "0"
  );

  const [monthValue, setMonthValue] = useState(
    FilterData?.month?.value ? FilterData?.month?.value : "0"
  );

  const [inputValue, setInputValue] = useState(
    FilterData?.searchName?.value ? FilterData?.searchName?.value : ""
  );

  // get all loans
  const {
    response: getAllCommissionData,
    loading: getCommissionLoading,
    error: getCommissionError,
  } = useGetAxios(
    getAllAgentCommission(
      "payment",
      paginationModel.page,
      paginationModel.pageSize,
      paymentStatusValue && paymentStatusValue != "0" ? paymentStatusValue : "",
      monthValue && monthValue != "0" ? monthValue : "",
      inputValue ? inputValue : ""
    ),
    false,
    count
  );
  const [loadingExport, setLoadingExport] = useState(false);
  const handleExport = async () => {
    console.log("handle export");
    // Add your delete logic here
    setLoadingExport(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await ExportAgentCommission(
        paymentStatusValue && paymentStatusValue != "0"
          ? paymentStatusValue
          : "0",
        monthValue && monthValue != "0" ? monthValue : "0",
        {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        }
      );

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        console.log("res?.data", res?.data?.data?.file_url);
        const fileUrl = res?.data?.data?.file_url;
        setTimeout(() => {
          window.open(fileUrl, "_self");
        }, 500);
        // const link = document.createElement("a");
        // link.href = fileUrl;
        // link.setAttribute("download", "loan-application.xlsx"); // Set default filename
        // link.style.display = "none";

        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingExport(false);
  };
  const [selectedIds, setSelectedIds] = useState([]);
  console.log("selectedIds", selectedIds);
  // Select all "Not Paid" rows
  const handleSelectAll = () => {
    const notPaidRows = getAllCommissionData?.data?.data
      ?.filter((row) => row.payment_status === "Not Paid")
      ?.map((row) => row.id);

    const allSelected = notPaidRows.every((id) => selectedIds.includes(id));

    setSelectedIds(allSelected ? [] : notPaidRows);
  };

  const handleCheckboxChange = (id, isChecked) => {
    setSelectedIds((prev) =>
      isChecked ? [...prev, id] : prev.filter((selectedId) => selectedId !== id)
    );
  };
  const columns = [
    {
      field: "checkbox",
      resizable: false,
      headerName: getAllCommissionData?.data?.data?.some(
        (row) => row?.payment_status === "Not Paid"
      ) ? (
        <input
          type="checkbox"
          onChange={(e) => handleSelectAll(e)}
          checked={getAllCommissionData?.data?.data
            ?.filter((row) => row?.payment_status === "Not Paid")
            ?.every((row) => selectedIds?.includes(row?.id))}
        />
      ) : (
        <input type="checkbox" disabled />
      ), // Hide header checkbox if no "Not Paid" rows exist
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params?.row?.payment_status === "Not Paid" ? (
          <input
            type="checkbox"
            checked={selectedIds?.includes(params.row.id)}
            onChange={(e) =>
              handleCheckboxChange(params?.row?.id, e.target.checked)
            }
          />
        ) : null, // No checkbox for paid items
    },
    {
      field: "id",
      headerName: "Loan ID",
      resizable: false,
      flex: 1,
    },
    {
      field: "client_name",
      headerName: "Loan Client",
      resizable: false,
      flex: 1,
    },
    {
      field: "provider_name",
      headerName: "Bank",
      resizable: false,
      flex: 1,
    },
    {
      field: "commission_origin",
      headerName: "Commission origin",
      resizable: false,
      flex: 1,
    },
    {
      field: "loan_amount",
      headerName: "Loan Amount",
      resizable: false,
      flex: 1,
    },
    {
      field: "commission_type",
      headerName: "Commission Type",
      resizable: false,
      flex: 1,
    },
    {
      field: "company_commission_perc",
      headerName: "Company Commission",
      resizable: false,
      flex: 1,
    },
    {
      field: "company_commission_value",
      headerName: "Company Commission Value",
      resizable: false,
      flex: 1,
    },
    {
      field: "agent_commission_perc",
      headerName: "Agent Commission %",
      resizable: false,
      flex: 1,
    },
    {
      field: "agent_commission_value",
      headerName: "Commission Value (lei)",
      resizable: false,
      flex: 1,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === "Paid" ? "#629c44" : "#e93578",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      resizable: false,
      flex: 1,
    },
  ];

  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };

  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };
  const getLast12Months = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const today = new Date();
    let monthOptions = [{ slug: "0", name: "All" }];

    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(today.getMonth() - i);

      const monthNumber = (date.getMonth() + 1).toString().padStart(2, "0"); // "01", "02", ...
      const monthName = months[date.getMonth()];

      monthOptions.push({ slug: monthNumber, name: monthName });
    }

    return monthOptions;
  };

  // Usage
  const monthOptions = getLast12Months();

  const [loadingCommission, setLoadingCommission] = useState(false);
  const [openCommissionModal, setOpenCommissionModal] = useState(false);
  const handleOpenCommission = () => {
    setOpenCommissionModal(true);
  };
  const handleCommissionModalClose = () => {
    setOpenCommissionModal(false);
  };
  return (
    <div className={`${classes.mainListingPage} ${classes.backgroundBase}`}>
      <ToastContainer />
      <>
        <CustomFilter
          setLoading={setLoading}
          paymentStatusValue={paymentStatusValue}
          setPaymentStatusValue={setPaymentStatusValue}
          monthValue={monthValue}
          setMonthValue={setMonthValue}
          monthOptions={monthOptions}
          inputValue={inputValue}
          setInputValue={setInputValue}
          searchName
        />

        <div className={classes.addRow}>
          <div></div>
          <div className={classes.addRowInner}>
            <Button
              customClass={classes.filterBtn}
              outlineGrey
              custborderRadius
              onClick={handleExport}
            >
              Export data {loadingExport && <Loader />}
            </Button>
            <div className={classes.paginationControls}>
              <FormControl variant="standard" sx={{ minWidth: 60 }}>
                <InputLabel>Rows</InputLabel>
                <Select
                  value={paginationModel.pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className={style.reduceHeight}>
          <DataTable
            rows={getAllCommissionData?.data?.data}
            columns={columns}
            loading={getCommissionLoading}
            totalRows={getAllCommissionData?.data?.total}
            paginationModel={paginationModel}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
        {selectedIds.length > 0 && (
          <Button onClick={handleOpenCommission} grey customWidth="270px">
            Mark all selected as PAID
          </Button>
        )}

        <BasicModal
          isOpen={openCommissionModal}
          closeModal={handleCommissionModalClose}
          customWidth="994px"
        >
          <div className={style.defaultForm}>
            <Formik
              initialValues={{
                payment_date: "",
                payment_reference: "",
                commission_ids: selectedIds,
              }}
              validationSchema={CommissionSchema}
              onSubmit={async (values, { resetForm }) => {
                setLoadingCommission(true);
                console.log("values", values);
                try {
                  const res = await updateCommissionStatus(values, {
                    headers: {
                      lang: lang,
                      Authorization: `Bearer ${tokenAD}`,
                    },
                  });
                  // Handle success response
                  if (res?.status === 200) {
                    setLoadingCommission(false);
                    toast.success(res?.data?.message);

                    setTimeout(() => {
                      setOpenCommissionModal(false);
                      setCount(count + 1);
                      resetForm({ values: "" }); // Reset form fields
                    }, 1000);
                  } else {
                    throw new Error(res?.data?.message || "An error occurred");
                  }
                } catch (error) {
                  setLoadingCommission(false);
                  toast.error(
                    error?.response?.data?.message ||
                      "An error occurred during the process."
                  );
                }
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={style.formMain}>
                    <p>Mark selected Loan Application as paid.</p>
                    <Grid container spacing={2} xs={12} md={8} lg={8}>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomInput
                          label="Payment data*"
                          type="date"
                          name="payment_date"
                          value={values.payment_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Select Date"
                          touched={touched.payment_date}
                          errors={errors.payment_date}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomInput
                          label="Payment reference"
                          type="text"
                          name="payment_reference"
                          value={values.payment_reference}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="enter reference"
                          touched={touched.payment_reference}
                          errors={errors.payment_reference}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={style.addUserBtn}>
                    <Button
                      onClick={handleCommissionModalClose}
                      outlineGrey
                      customWidth="170px"
                    >
                      Close
                    </Button>
                    <Button type="submit" blue customWidth="170px">
                      {loadingCommission ? <Loader /> : <>Apply</>}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </BasicModal>
      </>
    </div>
  );
};

export default AgentCommission;
