import React, { useState, useEffect } from "react";
import classes from "./appSettings.module.scss";
import Grid from "@mui/material/Grid";
import DynamicForm from "./dynamicForm";
import { useSelector } from "react-redux";
import { getAllAppsSetting } from "../../../api/misc";
import useGetAxios from "../../../api/hooks/useGetAxios";
import Loader from "../../../components/shared/loader";
import { toast } from "react-toastify";

const AppSettings = () => {
  localStorage.setItem("breadcrumbName", "Settings | Apps Settings");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allAppsSettingData, setAllAppsSettingData] = useState([]);
  useEffect(() => {
    const getdataSettings = async () => {
      setLoading(true);
      try {
        const res = await getAllAppsSetting({
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        });
        if (res?.status === 200) {
          setLoading(false);
          setAllAppsSettingData(res.data.data.appSettings);
        }
      } catch (error) {
        setLoading(false);
        const showErr = error?.response?.data?.message;
      }
    };
    getdataSettings();
  }, [count]);

  const appSettings = [
    {
      slug: "notification",
      name: "Notification",
      list: [
        {
          name: "sendgrid_api_key",
          type: "input",
          value: "faraz",
          list: [],
        },
        {
          name: "sendgrid_from",
          type: "input",
          value: "",
          list: [],
        },
      ],
    },
    {
      slug: "security",
      name: "Security",
      list: [
        {
          name: "application_geolocation",
          type: "switch",
          value: "on",
          list: [],
        },
        {
          name: "suspicious_login",
          type: "switch",
          value: "off",
          list: [],
        },
        {
          name: "suspicious_logout",
          type: "switch",
          value: "on",
          list: [],
        },
        {
          name: "suspicious_forgot",
          type: "switch",
          value: "",
          list: [],
        },
      ],
    },
    {
      slug: "currency",
      name: "Currency",
      list: [
        {
          name: "allowed_currencies",
          type: "checkbox",
          value: ["USD", "LEI"],
          list: [
            {
              value: "LEI",
              text: "LEI",
            },
            {
              value: "EUR",
              text: "EUR",
            },
            {
              value: "USD",
              text: "USD",
            },
          ],
        },
      ],
    },
    {
      slug: "location",
      name: "Location",
      list: [
        {
          name: "demographic_location",
          type: "radio",
          value: "walachia",
          list: [
            {
              value: "walachia",
              text: "Walachia",
            },
            {
              value: "transylvania",
              text: "Transylvania",
            },
            {
              value: "moldavia",
              text: "Moldavia",
            },
          ],
        },
        {
          name: "demographic_locationasdas",
          type: "radio",
          value: "walachia2",
          list: [
            {
              value: "walachia2",
              text: "Walachia2",
            },
            {
              value: "transylvania2",
              text: "Transylvania2",
            },
            {
              value: "moldavia2",
              text: "Moldavia2",
            },
          ],
        },
      ],
    },
    {
      slug: "country",
      name: "Country",
      list: [
        {
          name: "demographic_country",
          type: "dropdown",
          value: "AR",
          list: [
            {
              value: "AB",
              text: "ALBA",
            },
            {
              value: "AG",
              text: "ARGES",
            },
            {
              value: "AR",
              text: "ARAD",
            },
            {
              value: "B",
              text: "BUCURESTI",
            },
            {
              value: "BC",
              text: "BACAU",
            },
            {
              value: "BH",
              text: "BIHOR",
            },
            {
              value: "BN",
              text: "BISTRITA-NASAUD",
            },
            {
              value: "BR",
              text: "BRAILA",
            },
            {
              value: "BT",
              text: "BOTOSANI",
            },
            {
              value: "BV",
              text: "BRASOV",
            },
          ],
        },
      ],
    },
    {
      slug: "about_us",
      name: "About Us",
      list: [
        {
          name: "about_us_description",
          type: "textarea",
          value: " there are many text here",
          list: [],
        },
      ],
    },
  ];

  return (
    <>
      <div className={classes.mainGenSetting}>
        <h3>Apps Settings</h3>
        {loading && <Loader />}

        {/* {loading ? (
          <Loader />
        ) : ( */}
        <DynamicForm
          appSettings={allAppsSettingData}
          // appSettings={appSettings}
          setCount={setCount}
          count={count}
          setAllAppsSettingData={setAllAppsSettingData}
        />
        {/* )} */}
      </div>
    </>
  );
};

export default AppSettings;
