import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/partials/partial-listing.module.scss";
import style from "./users.module.scss";
import { getAllUsers, deleteUser, resendUser } from "../../../api/misc";
import DataTable from "../../../components/shared/customTable";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import Button from "../../../components/shared/customBtn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../assets/images/edit_square.svg";
import DeleteModal from "../../../components/shared/deleteModal";
import FormatDateTime from "../../../components/shared/FormatMain/formatDate";
import FormatText from "../../../components/shared/FormatMain/formattext";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomFilter from "../../../components/shared/customFilter";
import { useSelector } from "react-redux";
import useGetAxios from "../../../api/hooks/useGetAxios";
import Loader from "../../../components/shared/loader";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";

const Users = ({ setUserState, setEditUser }) => {
  localStorage.setItem("breadcrumbName", "Settings | Users ");

  const masterData = useSelector((state) => state?.masterData?.masterData);
  console.log("masterDatamasterData", masterData);
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [totalRows, setTotalRows] = useState(0); // Total number of rows from API
  const [count, setCount] = useState(0);
  const [loadingResend, setLoadingResend] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 20,
  }); // Pagination state

  const [careerValue, setCareerValue] = useState(
    FilterData?.career?.value ? FilterData?.career?.value : "0"
  );
  const [statusValue, setStatusValue] = useState(
    FilterData?.statuses?.value ? FilterData?.statuses?.value : "0"
  );
  const [inputValue, setInputValue] = useState(
    FilterData?.searchName?.value ? FilterData?.searchName?.value : ""
  );

  const {
    response: getAllUserData,
    loading: getUserLoading,
    error: getUserError,
  } = useGetAxios(
    getAllUsers(
      paginationModel.page,
      paginationModel.pageSize,
      careerValue && careerValue != "0" ? careerValue : "",
      statusValue && statusValue != "0" ? statusValue : "",
      inputValue ? inputValue : ""
    ),
    false,
    count
  );

  // fectching careers

  const handleEdit = (row) => {
    // Add your edit logic here
    setUserState(false);
    setEditUser(row);
  };

  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteUser(deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleResend = async (id) => {
    setLoadingResend(id);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await resendUser(id, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        toast.success(res?.data?.data[0]);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingResend(null);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handleAddNewUSer = () => {
    // Add your delete logic here
    setUserState(false);
  };
  const handleExport = () => {
    console.log("handle export");
    // Add your delete logic here
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 70, resizable: false, flex: 1 },
    {
      field: "full_name",
      headerName: "User",
      flex: 1,
      resizable: false,
      // renderCell: (params) => (
      //   <>
      //     {params?.row?.first_name} {params?.row?.last_name}
      //   </>
      // ),
    },
    { field: "city_name", headerName: "City", resizable: false, flex: 1 },
    {
      field: "career_level_name",
      headerName: "Career Level",
      resizable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      resizable: false,
      flex: 1,
      renderCell: (params) => <>{FormatText(params?.row?.status)}</>,
    },
    {
      field: "last_login",
      headerName: "Last Login",
      resizable: false,
      flex: 2,
      renderCell: (params) => (
        <>
          {params?.row?.last_login
            ? FormatDateTime(params?.row?.last_login?.created_at)
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      flex: 1.5,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handleEdit(params.row)}
          >
            <img src={editSquare} alt="editIcon" />
          </span>

          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </span>
          {params.row.status == "pending_activation" && (
            <span
              className={classes.iconStyleResends}
              onClick={() => handleResend(params.row.id)}
              title="Resend"
            >
              {loadingResend === params.row.id ? (
                <CircularProgress
                  color="inherit"
                  sx={{
                    width: "22px !important",
                    height: "22px !important",
                  }}
                />
              ) : (
                <SendToMobileIcon />
              )}
            </span>
          )}
        </>
      ),
    },
  ];

  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };

  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };

  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />
      <CustomFilter
        setLoading={setLoading}
        careerValue={careerValue}
        setCareerValue={setCareerValue}
        careerOptions={masterData?.careerLevels}
        careerInitialValue="0"
        statusValue={statusValue}
        setStatusValue={setStatusValue}
        statusOptions={masterData?.userStatuses}
        statusInitialValue="0"
        inputValue={inputValue}
        setInputValue={setInputValue}
        searchName
      />
      <div className={classes.addRow}>
        <Button
          customClass={classes.filterBtn}
          blue
          custborderRadius
          customWidth="153px"
          onClick={handleAddNewUSer}
        >
          Add New
        </Button>
        <div className={classes.addRowInner}>
          <Button
            customClass={classes.filterBtn}
            outlineGrey
            custborderRadius
            onClick={handleExport}
          >
            Export data
          </Button>
          <div className={classes.paginationControls}>
            <FormControl variant="standard" sx={{ minWidth: 60 }}>
              <InputLabel>Rows</InputLabel>
              <Select
                value={paginationModel.pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <DataTable
        rows={getAllUserData?.data?.data}
        columns={columns}
        loading={getUserLoading}
        totalRows={getAllUserData?.data?.total}
        paginationModel={paginationModel}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
      {deleteId && (
        <DeleteModal
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="User"
          isLoading={loadingDelete}
        />
      )}
    </div>
  );
};

export default Users;
