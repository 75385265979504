import React, { useState, useEffect } from "react";
import classes from "../../../../assets/styles/partials/partial-listing.module.scss";
import style from "./addProducts.module.scss";
import {
  getAllBrochures,
  deleteProductDocumentList,
  createProductDocumentList,
} from "../../../../api/misc";
import DataTable from "../../../../components/shared/customTable";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Button from "../../../../components/shared/customBtn";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../../assets/images/edit_square.svg";
import DeleteModal from "../../../../components/shared/deleteModal";
import FormatDateTime from "../../../../components/shared/FormatMain/formatDate";
import BasicModal from "../../../../components/shared/basicmodal";
import CustomInput from "../../../../components/shared/customInput";
import Loader from "../../../../components/shared/loader";
import useGetAxios from "../../../../api/hooks/useGetAxios";

const BrochureSchema = Yup.object().shape({
  brochure_name: Yup.string().required("Please enter brochure name"),
  document: Yup.string().required("Please select document"),
});

const AddBrochures = ({ editProduct, handleCancel }) => {
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [loadingCreate, setLoadingCreate] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [count, setCount] = useState(0);
  const [brochuresLink, setBrochuresLink] = useState();
  const [openUploadBrochuer, setOpenuploadBrochuer] = useState(false);
  const [openPreviewBrochuer, setOpenPreviewBrochuer] = useState(false);

  const handleBrochureModalClose = () => {
    setOpenuploadBrochuer(false);
  };
  const handlePreviewModalClose = () => {
    setOpenPreviewBrochuer(false);
  };
  const {
    response: getAllBrochureData,
    loading: getBrochureLoading,
    error: getBrochureError,
  } = useGetAxios(getAllBrochures(editProduct?.id), false, count);

  const handlePreview = (row) => {
    setOpenPreviewBrochuer(true);
    setBrochuresLink(row?.file_path);
  };

  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteProductDocumentList(editProduct?.id, deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handleAddNewProduct = () => {
    setOpenuploadBrochuer(true);
  };

  const columns = [
    {
      field: "brochure_name",
      headerName: "Document Name",
      flex: 1,
      resizable: false,
    },
    {
      field: "created_at",
      headerName: "Uploaded on",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.created_at
            ? FormatDateTime(params?.row?.created_at)
            : ""}
        </>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handlePreview(params.row)}
          >
            Preview
          </span>
          |
          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
            {/* <DeleteIcon /> */}
            Delete
          </span>
        </>
      ),
    },
  ];
  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      // setPreview(URL.createObjectURL(file));
      setFieldValue("document", file);
    }
  };
  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />

      <div className={classes.addRow}>
        <Button
          customClass={classes.filterBtn}
          blue
          custborderRadius
          customWidth="153px"
          onClick={handleAddNewProduct}
        >
          Upload
        </Button>
        <div className={classes.addRowInner}></div>
      </div>
      <DataTable
        rows={getAllBrochureData?.data}
        columns={columns}
        loading={getBrochureLoading}
        paginationModel={false}
      />
      <div className={style.addProductBtn}>
        <Button onClick={handleCancel} outlineGrey customWidth="170px">
          Close
        </Button>
      </div>
      {deleteId && (
        <DeleteModal
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="Brochure"
          isLoading={loadingDelete}
        />
      )}
      <BasicModal
        isOpen={openPreviewBrochuer}
        closeModal={handlePreviewModalClose}
        customWidth="1400px"
        customClass="removePadi"
      >
        <iframe
          src={`${brochuresLink}#navpanes=0&scrollbar=0?timestamp=${Date.now()}`}
          width="100%"
          height="1000px"
          style={{ border: "none" }}
          title="PDF Viewer"
        />
        {/* <img src={brochuresLink} alt="" /> */}
      </BasicModal>
      <BasicModal
        isOpen={openUploadBrochuer}
        closeModal={handleBrochureModalClose}
        customWidth="994px"
      >
        <div className={classes.defaultForm}>
          <div className={classes.modalHead}>
            <h3>Upload a product Brochure</h3>
            <hr />
          </div>

          <Formik
            initialValues={{ brochure_name: "", document: "" }}
            validationSchema={BrochureSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log("values", values);
              setLoadingCreate(true);
              const formData = new FormData();
              formData.append("brochure_name", values.brochure_name);
              formData.append("document", values.document);

              try {
                const res = await createProductDocumentList(
                  editProduct?.id,
                  formData,
                  {
                    headers: {
                      lang: lang,
                      Authorization: `Bearer ${tokenAD}`,
                    },
                  }
                );
                // Handle success response
                if (res?.status === 200) {
                  setLoadingCreate(false);
                  toast.success(res?.data?.message);

                  setTimeout(() => {
                    setOpenuploadBrochuer(false);
                    setCount(count + 1);
                    resetForm({ values: "" }); // Reset form fields
                  }, 1000);
                } else {
                  throw new Error(res?.data?.message || "An error occurred");
                }
              } catch (error) {
                setLoading(false);
                toast.error(
                  error.response.data.message ||
                    "An error occurred during the process."
                );
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formMain}>
                  <Grid container spacing={2} xs={12} md={6} lg={6}>
                    <Grid item xs={12} md={12} lg={12}>
                      <CustomInput
                        label="Brochure name"
                        type="text"
                        name="brochure_name"
                        value={values.brochure_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Type brochure name"
                        touched={touched.brochure_name}
                        errors={errors.brochure_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <input
                        id="document"
                        name="document"
                        type="file"
                        accept=".pdf"
                        onChange={(event) =>
                          handleImageChange(event, setFieldValue)
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={style.addProductBtn}>
                  <Button
                    onClick={handleBrochureModalClose}
                    outlineGrey
                    customWidth="170px"
                  >
                    Close
                  </Button>
                  <Button type="submit" blue customWidth="170px">
                    {loadingCreate ? <Loader /> : <>Add Brochure</>}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </BasicModal>
    </div>
  );
};

export default AddBrochures;
