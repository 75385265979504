import React, { useState, useEffect } from "react";
import classes from "../../../../assets/styles/partials/partial-listing.module.scss";
import style from "./addProviders.module.scss";
import { getAllUserHistory } from "../../../../api/misc";
import DataTable from "../../../../components/shared/customTable";
import Button from "../../../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatDateTime from "../../../../components/shared/FormatMain/formatDate";
import useGetAxios from "../../../../api/hooks/useGetAxios";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";

const AccessLevelHistory = ({ editAccessLevel, handleCancel }) => {
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [count, setCount] = useState(0);

  const {
    response: getAllHistoryData,
    loading: getHistoryLoading,
    error: getHistoryError,
  } = useGetAxios(
    getAllUserHistory("access_level", editAccessLevel?.id),
    false,
    count
  );

  const columns = [
    {
      field: "updated_at",
      headerName: "Date Time",
      flex: 1,
      resizable: false,
      renderCell: (params) => (
        <>
          {params?.row?.updated_at
            ? FormatDateTime(params?.row?.updated_at)
            : ""}
        </>
      ),
    },
    {
      field: "what",
      headerName: "What",
      flex: 1,
      resizable: false,
    },
    {
      field: "old_values",
      headerName: "Old Value (s)",
      flex: 1,
      resizable: false,
    },
    {
      field: "new_values",
      headerName: "New Value",
      flex: 1,
      resizable: false,
    },
    {
      field: "who",
      headerName: "Who",
      flex: 1,
      resizable: false,
    },
  ];

  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />
      <h4>
        This is the Access level audit log. The most recent events are showed
        first.
      </h4>
      <DataTable
        rows={getAllHistoryData?.data?.data}
        columns={columns}
        loading={getHistoryLoading}
        paginationModel={false}
      />
      <div className={style.addProviderBtn}>
        <Button onClick={handleCancel} outlineGrey customWidth="170px">
          Close
        </Button>
      </div>
    </div>
  );
};

export default AccessLevelHistory;
