import React from "react";
import classes from "./addUsers.module.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./addUsersBuiltin.scss";
import AddUser from "./addUserMain";
import AddCareer from "./careerLevel";
import AddSecurity from "./addSecurity";
import AddDocuments from "./addDocument";
import UserHistory from "./userHistory";

const UserTabs = ({ editUser, handleCancel, setUserState, setEditUser }) => {
  localStorage.setItem("breadcrumbName", editUser ? "Edit User" : "Add User");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  return (
    <>
      {editUser ? <h2>#{editUser.full_name}</h2> : null}
      <div className={classes.MainUser}>
        <div className={classes.UserTabContainer}>
          <Box className={`${classes.tabsHead} UserTabBuiltin`}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="User Data" {...a11yProps(0)} />
              {/* <Tab
                label="Contract"
                {...a11yProps(1)}
                disabled={editUser ? false : true}
              /> */}
              <Tab
                label="Documents"
                {...a11yProps(1)}
                disabled={editUser ? false : true}
              />
              <Tab
                label="Career Level"
                {...a11yProps(2)}
                disabled={editUser ? false : true}
              />
              <Tab
                label="Security"
                {...a11yProps(3)}
                disabled={editUser ? false : true}
              />
              {/* <Tab
                label="Notifications"
                {...a11yProps(5)}
                disabled={editUser ? false : true}
              /> */}
              <Tab
                label="History"
                {...a11yProps(4)}
                disabled={editUser ? false : true}
              />
            </Tabs>
          </Box>
        </div>
        <div className={classes.ProfileTabs}>
          <CustomTabPanel value={value} index={0}>
            <AddUser
              handleCancel={handleCancel}
              editUser={editUser}
              setUserState={setUserState}
              setEditUser={setEditUser}
            />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={1}>
            <ChangePassword />
            tab 2
          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={1}>
            <AddDocuments handleCancel={handleCancel} editUser={editUser} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <AddCareer
              handleCancel={handleCancel}
              setEditUser={setEditUser}
              setUserState={setUserState}
              editUser={editUser}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <AddSecurity
              handleCancel={handleCancel}
              editUser={editUser}
              setEditUser={setEditUser}
            />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={5}>
            <ChangePassword />
            tab 5
          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={4}>
            <UserHistory editUser={editUser} handleCancel={handleCancel} />
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default UserTabs;
