import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
const Unauthorized = () => {
  return (
    <div className="mainSectionUn">
      <h1>Unauthorized Access</h1>
      <p>You do not have permission to view this page.</p>
      <Link to="sign-in">Go to Sign in page</Link>
    </div>
  );
};

export default Unauthorized;
