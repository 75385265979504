import React, { useEffect, useState } from "react";
import Loans from "./getAllLoans";
import AddLoan from "./addLoan/index";
const MainLoan = () => {
  const [loanState, setLoanState] = useState(true);
  const [clientData, setClientData] = useState();
  const [editExistingclient, setEditExistingclient] = useState();
  const handleCancel = () => {
    setLoanState(true);
    setClientData(null);
    setEditExistingclient(null);
    localStorage.removeItem("dashboradEditData");
    localStorage.removeItem("createApplication");
  };
  const DashboardEditData = JSON.parse(
    localStorage.getItem("dashboradEditData")
  );
  const AddClientApplication = JSON.parse(
    localStorage.getItem("createApplication")
  );
  useEffect(() => {
    if (DashboardEditData) {
      setLoanState(false);
      setEditExistingclient(DashboardEditData);
    }
    if (AddClientApplication) {
      setLoanState(false);
      setClientData(AddClientApplication);
    }
  }, []);
  return (
    <>
      {loanState ? (
        <Loans
          setLoanState={setLoanState}
          setClientData={setClientData}
          setEditExistingclient={setEditExistingclient}
        />
      ) : (
        <AddLoan
          clientData={clientData}
          handleCancel={handleCancel}
          setLoanState={setLoanState}
          setClientData={setClientData}
          setEditExistingclient={setEditExistingclient}
          editExistingclient={editExistingclient}
        />
      )}
    </>
  );
};

export default MainLoan;
