import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/partials/partial-listing.module.scss";
import style from "./addClient/addloan.module.scss";
import {
  getAllAgentCommission,
  ExportAgentCommission,
  updateCommissionStatus,
  generatePaymentSlip,
} from "../../api/misc";
import DataTable from "../../components/shared/customTable";
import Button from "../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import FormatDateTime from "../../components/shared/FormatMain/formatDate";
import CustomFilter from "../../components/shared/customFilter";
import useGetAxios from "../../api/hooks/useGetAxios";
import { useSelector } from "react-redux";
import Loader from "../../components/shared/loader";
import BasicModal from "../../components/shared/basicmodal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import CustomInput from "../../components/shared/customInput";
import DownloadIcon from "@mui/icons-material/Download";

const CommissionSchema = Yup.object().shape({
  payment_date: Yup.string().required("Please select date"),
});

const BankReconciliation = () => {
  localStorage.setItem("breadcrumbName", "Bank Reconciliation");
  // get master data start
  const masterData = useSelector((state) => state?.masterData?.masterData);
  // get master data end
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedProviderID, setSelectedProviderID] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingCommission, setLoadingCommission] = useState(false);
  const [loadingSlip, setLoadingSlip] = useState(false);
  const [openCommissionModal, setOpenCommissionModal] = useState(false);
  const [slipData, setSlipData] = useState();
  const [selectSlipData, setSelectSlipData] = useState([]);
  console.log("selectSlipData", selectSlipData);
  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 20,
  }); // Pagination state
  const [paymentStatusValue, setPaymentStatusValue] = useState(
    FilterData?.paymentStatus?.value ? FilterData?.paymentStatus?.value : "0"
  );

  const [monthValue, setMonthValue] = useState(
    FilterData?.month?.value ? FilterData?.month?.value : "0"
  );
  const [bankValue, setBankValue] = useState(
    FilterData?.bank?.value ? FilterData?.bank?.value : "0"
  );
  const [slipValue, setSlipValue] = useState(
    FilterData?.slip?.value ? FilterData?.slip?.value : "0"
  );

  const [inputValue, setInputValue] = useState(
    FilterData?.searchName?.value ? FilterData?.searchName?.value : ""
  );

  // get all loans
  const {
    response: getAllCommissionData,
    loading: getCommissionLoading,
    error: getCommissionError,
  } = useGetAxios(
    getAllAgentCommission(
      "reconciliation",
      paginationModel.page,
      paginationModel.pageSize,
      paymentStatusValue && paymentStatusValue != "0" ? paymentStatusValue : "",
      monthValue && monthValue != "0" ? monthValue : "",
      bankValue && bankValue != "0" ? bankValue : "",
      inputValue ? inputValue : ""
    ),
    false,
    count
  );
  const newData = [
    ...(getAllCommissionData?.data?.data || []),
    // {
    //   id: 3,
    //   application_id: 4,
    //   provider_id: 5,
    //   provider_name: "BRM",
    //   client_name: "Adeel Ahmad 2",
    //   agent_name: "Adeel Ahmad 2",
    //   commission_origin: "Own",
    //   loan_amount: "1655000.00",
    //   commission_type: "Full",
    //   company_commission_perc: "15.00%",
    //   company_commission_value: "90000.00",
    //   agent_commission_perc: "20.00%",
    //   agent_commission_value: "120000.00",
    //   payment_reference: "556",
    //   payment_status: "Not-Paid",
    //   payment_date: "2025-02-14",
    //   status_changed_by: 1,
    //   status_changed_at: "2025-02-14 10:15:22",
    // },
  ];
  const handleExport = async () => {
    // Add your delete logic here
    setLoadingExport(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await ExportAgentCommission(
        paymentStatusValue && paymentStatusValue != "0"
          ? paymentStatusValue
          : "0",
        monthValue && monthValue != "0" ? monthValue : "0",
        {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        }
      );

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        console.log("res?.data", res?.data?.data?.file_url);
        const fileUrl = res?.data?.data?.file_url;
        setTimeout(() => {
          window.open(fileUrl, "_self");
        }, 500);
        // const link = document.createElement("a");
        // link.href = fileUrl;
        // link.setAttribute("download", "loan-application.xlsx"); // Set default filename
        // link.style.display = "none";

        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingExport(false);
  };
  const handleDownloadPDF = () => {
    window.open(slipData?.pdf_url, "_blank");
  };
  const handleDownloadExcel = () => {
    window.open(slipData?.excel_url, "_blank");
  };
  // ✅ Handle individual checkbox selection
  const handleCheckboxChange = (row, isChecked) => {
    if (isChecked) {
      if (selectedProvider && selectedProvider !== row.provider_name) {
        alert(
          `You can only select rows from the same provider: ${selectedProvider}. Deselect them first to select another provider.`
        );
        return;
      }
      setSelectedProvider(row.provider_name);
      setSelectedProviderID(row.provider_id);
      setSelectedIds((prev) => [...prev, row.id]);
      setSelectSlipData((prev) => [...prev, row]);
    } else {
      const updatedData = selectSlipData.filter((data) => data.id !== row.id);
      const updatedSelectedIds = selectedIds.filter((id) => id !== row.id);
      setSelectedIds(updatedSelectedIds);
      setSelectSlipData(updatedData);
      // setSelectedProviderID(null);
      if (updatedSelectedIds.length === 0) setSelectedProvider(null);
    }
  };

  // ✅ Handle select all for the same provider
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const firstProvider = newData[0]?.provider_name;
      const firstProviderID = newData[0]?.provider_id;
      const sameProviderRows = newData.filter(
        (row) => row.provider_name === firstProvider
      );
      setSelectedIds(sameProviderRows.map((row) => row.id));
      setSelectedProvider(firstProvider);
      setSelectedProviderID(firstProviderID);
      setSelectSlipData(sameProviderRows);
    } else {
      setSelectedIds([]);
      setSelectedProvider(null);
      setSelectedProviderID(null);
      setSelectSlipData([]);
    }
  };
  const columns = [
    {
      field: "checkbox",
      resizable: false,
      headerName: (
        <input
          type="checkbox"
          onChange={(e) => handleSelectAll(e)}
          checked={
            newData?.length > 0 &&
            newData?.every((row) => selectedIds?.includes(row?.id))
          }
        />
      ),
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={selectedIds?.includes(params.row.id)}
          onChange={(e) => handleCheckboxChange(params?.row, e.target.checked)}
        />
      ),
    },
    {
      field: "id",
      headerName: "Loan ID",
      resizable: false,
      flex: 1,
      minWidth: 70,
    },
    {
      field: "client_name",
      headerName: "Loan Client",
      resizable: false,
      flex: 1,
    },
    {
      field: "provider_name",
      headerName: "Bank",
      resizable: false,
      flex: 1,
    },
    {
      field: "commission_origin",
      headerName: "Commission origin",
      resizable: false,
      flex: 1,
    },
    {
      field: "loan_amount",
      headerName: "Loan Amount",
      resizable: false,
      flex: 1,
    },
    {
      field: "commission_type",
      headerName: "Commission Type",
      resizable: false,
      flex: 1,
    },
    {
      field: "company_commission_perc",
      headerName: "Company Commission",
      resizable: false,
      flex: 1,
    },
    {
      field: "company_commission_value",
      headerName: "Company Commission Value",
      resizable: false,
      flex: 1,
    },
    {
      field: "agent_commission_perc",
      headerName: "Agent Commission %",
      resizable: false,
      flex: 1,
    },
    {
      field: "agent_commission_value",
      headerName: "Commission Value (lei)",
      resizable: false,
      flex: 1,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === "Paid" ? "#629c44" : "#e93578",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      resizable: false,
      flex: 1,
    },
  ];
  const columnsSlip = [
    {
      field: "id",
      headerName: "Loan ID",
      resizable: false,
      flex: 1,
    },
    {
      field: "client_name",
      headerName: "Loan Client",
      resizable: false,
      flex: 1,
    },
    {
      field: "loan_amount",
      headerName: "Loan Amount",
      resizable: false,
      flex: 1,
    },
    {
      field: "company_commission_perc",
      headerName: "Company Commission",
      resizable: false,
      flex: 1,
    },
    {
      field: "company_commission_value",
      headerName: "Company Commission Value",
      resizable: false,
      flex: 1,
    },
  ];
  const totalCompanyCommissionValue = selectSlipData.reduce(
    (total, row) => total + parseFloat(row.company_commission_value || 0),
    0
  );
  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };

  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };
  const getLast12Months = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const today = new Date();
    let monthOptions = [{ slug: "0", name: "All" }];

    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(today.getMonth() - i);

      const monthNumber = (date.getMonth() + 1).toString().padStart(2, "0"); // "01", "02", ...
      const monthName = months[date.getMonth()];

      monthOptions.push({ slug: monthNumber, name: monthName });
    }

    return monthOptions;
  };

  // Usage
  const monthOptions = getLast12Months();

  const handleOpenCommission = async () => {
    // Add your delete logic here
    setOpenCommissionModal(true);
  };
  const handleCommissionModalClose = () => {
    setOpenCommissionModal(false);
    setOpenSlipDownload(false);
    // setSlipData(null);
    // setCount(count + 1);
  };
  const [openSlipDownload, setOpenSlipDownload] = useState(false);
  const handleSlipFile = async () => {
    setLoadingSlip(true);
    try {
      // Replace with your API call, here is a mock example
      const slipDataSend = {
        provider_id: selectedProviderID,
        commission_ids: selectedIds,
      };
      const res = await generatePaymentSlip(slipDataSend, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenSlipDownload(true);
        setLoadingSlip(false);
        setCount(count + 1);
        toast.success(res?.data?.message);
        setSlipData(res?.data?.data);
        setSelectSlipData([]);
        setSelectedProvider(null);
        setSelectedProviderID(null);
        setSelectedIds([]);
      }
    } catch (error) {
      setLoadingSlip(false);
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
  };

  return (
    <div className={`${classes.mainListingPage} ${classes.backgroundBase}`}>
      <ToastContainer />
      <>
        <CustomFilter
          setLoading={setLoading}
          paymentStatusValue={paymentStatusValue}
          setPaymentStatusValue={setPaymentStatusValue}
          monthValue={monthValue}
          setMonthValue={setMonthValue}
          monthOptions={monthOptions}
          bankValue={bankValue}
          setBankValue={setBankValue}
          bankOptions={masterData?.providers}
          bankInitialValue="0"
          // slipValue={slipValue}
          // setSlipValue={setSlipValue}
          // slipOptions={slipOptions}
          // inputValue={inputValue}
          // setInputValue={setInputValue}
          // searchName
          // searchNamePlaceholder="Payment Slip"
        />

        <div className={classes.addRow}>
          <div></div>
          <div className={classes.addRowInner}>
            <Button
              customClass={classes.filterBtn}
              outlineGrey
              custborderRadius
              onClick={handleExport}
            >
              Export data {loadingExport && <Loader />}
            </Button>
            <div className={classes.paginationControls}>
              <FormControl variant="standard" sx={{ minWidth: 60 }}>
                <InputLabel>Rows</InputLabel>
                <Select
                  value={paginationModel.pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className={style.reduceHeight}>
          <DataTable
            rows={newData}
            columns={columns}
            loading={getCommissionLoading}
            totalRows={getAllCommissionData?.data?.total}
            paginationModel={paginationModel}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
        {selectedIds.length > 0 && (
          <Button onClick={handleOpenCommission} grey customWidth="270px">
            Generate Payment Slip
          </Button>
        )}

        <BasicModal
          isOpen={openCommissionModal}
          closeModal={handleCommissionModalClose}
          customWidth="994px"
        >
          <div className={style.defaultForm}>
            {openSlipDownload ? (
              <>
                <div className={style.slipStyle}>
                  <h3> The payment slip has been generated</h3>
                  <h4>No: #{slipData?.id}</h4>
                  <Button onClick={handleDownloadPDF} black customWidth="220px">
                    <DownloadIcon /> Download PDF
                  </Button>
                  <Button
                    onClick={handleDownloadExcel}
                    black
                    customWidth="220px"
                  >
                    <DownloadIcon /> Download Excel
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={style.reduceHeight}>
                  <DataTable rows={selectSlipData} columns={columnsSlip} />
                </div>
                <div className={style.totalComi}>
                  <p>
                    Total Due Commission:{" "}
                    <span> {totalCompanyCommissionValue} LEI</span>
                  </p>
                  <p>
                    Total Contracts Brokered:{" "}
                    <span> #{selectSlipData.length}</span>
                  </p>
                </div>
                <div className={style.addUserBtn}>
                  <Button
                    onClick={handleCommissionModalClose}
                    outlineGrey
                    customWidth="170px"
                  >
                    Close
                  </Button>
                  <Button onClick={handleSlipFile} blue customWidth="170px">
                    {loadingSlip ? <Loader /> : "Apply"}
                  </Button>
                </div>
              </>
            )}
          </div>
        </BasicModal>
      </>
    </div>
  );
};

export default BankReconciliation;
