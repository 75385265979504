import React, { useState, useEffect, memo, useCallback } from "react";
import { Formik, Form } from "formik";
import classes from "./profile.module.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../components/shared/customInput";
import Button from "../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import { Typography } from "@mui/material";
import {
  getAllCities,
  getUserProfileInfo,
  updateUserProfile,
} from "../../api/misc";
import { IconButton, Box, Avatar } from "@mui/material";
import editSquare from "../../assets/images/edit_square.svg";
import { useSelector } from "react-redux";
import useGetAxios from "../../api/hooks/useGetAxios";

const phoneRegExp = /^\+\d{1,3}\d{9}$/;
const ClientSchema = Yup.object().shape({
  address: Yup.string().required("Please enter address"),
  county: Yup.string().required("Please select county"),
  city: Yup.string().required("Please select city"),
  contact_email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    ),
  contact_phone: Yup.string()
    .matches(
      phoneRegExp,
      "Not valid. Expected format: +00123456789 or +000{9 numbers}"
    )
    .required("Phone number is required"),
});

const Profile = memo(() => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const [count, setCount] = useState(0);
  const {
    response: getProfileInfoDataTotal,
    loading: getProfileInfoLoading,
    error: getProfileInfoError,
  } = useGetAxios(getUserProfileInfo(), false, count);
  const getProfileInfoData = getProfileInfoDataTotal?.data?.profile;
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [image, setImage] = useState();
  const [imageFile, setImageFile] = useState();

  // Handle file input change
  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl); // Set image preview
      setFieldValue("picture", file);
    }
  };

  const fetchCities = async (selectedCountyCode) => {
    if (selectedCountyCode) {
      setLoadingCities(true);
      try {
        const response = await getAllCities(selectedCountyCode, {
          headers: {
            lang: lang,
            Authorization: `Bearer ${tokenAD}`,
          },
        });
        setCities(response.data.data.cities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
      setLoadingCities(false);
    }
  };

  console.log("getProfileInfoData", getProfileInfoData);
  useEffect(() => {
    setImage(
      getProfileInfoDataTotal?.data?.profile?.profile_picture?.full_path
    );
  }, [getProfileInfoData]);
  // for edit
  useEffect(() => {
    if (getProfileInfoData?.data?.county) {
      fetchCities(getProfileInfoData?.data?.county); // Fetch cities for the default county
    }
  }, [getProfileInfoData?.data?.county]);
  console.log("image", image);
  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          enableReinitialize
          initialValues={{
            address: getProfileInfoData?.address || "",
            contact_email: getProfileInfoData?.contact_email || "",
            contact_phone: getProfileInfoData?.contact_phone || "",
            county: getProfileInfoData?.data?.county || "",
            city: getProfileInfoData?.data?.city || "",
            picture: "",
          }}
          validationSchema={ClientSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            const formData = new FormData();
            formData.append("address", values.address);
            formData.append("contact_email", values.contact_email);
            formData.append("contact_phone", values.contact_phone);
            formData.append("county", values.county);
            formData.append("city", values.city);
            formData.append("picture", values.picture);
            try {
              const res = await updateUserProfile(formData, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });
              // Handle success response
              if (res?.status === 200) {
                localStorage.setItem("userNameImage", JSON.stringify(image));
                // setCount(count + 1);
                // toast.success(res?.data?.message);
                setLoading(false);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2} lg={2}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      sx={{ position: "relative", width: 150, height: 150 }}
                    >
                      {/* Image Preview */}
                      <Avatar
                        src={image}
                        alt="Uploaded"
                        sx={{
                          width: 150,
                          height: 150,
                          border: "2px solid #ccc",
                          position: "relative",
                        }}
                      >
                        {!image && "No Image"}
                      </Avatar>

                      {/* IconButton for Upload */}
                      <IconButton
                        component="label"
                        sx={{
                          position: "absolute",
                          bottom: -10,
                          right: -10,
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                        }}
                      >
                        <img src={editSquare} alt="editIcon" />
                        <input
                          type="file"
                          name="picture"
                          accept="image/*"
                          hidden
                          onChange={(event) =>
                            handleImageChange(event, setFieldValue)
                          } // Handle image selection
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid container xs={12} md={10} lg={10} spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <ul className={classes.userInfo}>
                        <li>
                          <div className={classes.headlist}>Full Name:</div>
                          <div className={classes.datalist}>
                            {getProfileInfoData?.name}
                          </div>
                        </li>
                        <li>
                          <div className={classes.headlist}>
                            Current Career Level:
                          </div>
                          <div className={classes.datalist}>
                            {getProfileInfoData?.career?.level_name}
                          </div>
                        </li>
                        <li>
                          <div className={classes.headlist}>Reporting To:</div>
                          <div className={classes.datalist}>
                            {getProfileInfoData?.boss?.name}
                          </div>
                        </li>
                        <li>
                          <div className={classes.headlist}>
                            Working Branch:
                          </div>
                          <div className={classes.datalist}>
                            {getProfileInfoData?.branch?.branch_name}
                          </div>
                        </li>
                      </ul>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <h2>Address</h2>
                      <hr />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className={`${classes.inputMain}`}>
                        <label>County*</label>
                        <div className={classes.inputFieldSelect}>
                          <Field
                            as="select"
                            name="county"
                            onBlur={handleBlur}
                            touched={touched.county}
                            errors={errors.county}
                            onChange={async (event) => {
                              const selectedCountyCode = event.target.value;
                              setFieldValue("county", selectedCountyCode);
                              setFieldValue("city", ""); // Reset city when county changes
                              fetchCities(selectedCountyCode);
                            }}
                            placeholder="Select County"
                            className={`${classes.inputField}`}
                          >
                            <option value="">Select County</option>

                            {masterData?.counties?.map((county) => (
                              <option value={`${county?.code}`}>
                                {county?.county}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="county" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      {loadingCities ? (
                        <p>Loading cities...</p>
                      ) : (
                        <div className={`${classes.inputMain}`}>
                          <label>City*</label>
                          <div className={classes.inputFieldSelect}>
                            <Field
                              as="select"
                              name="city"
                              onBlur={handleBlur}
                              touched={touched.city}
                              errors={errors.city}
                              onChange={handleChange}
                              disabled={!values.county || cities?.length === 0}
                              placeholder="Select City"
                              className={`${classes.inputField}`}
                            >
                              <option value="">Select Citys</option>

                              {cities?.map((city) => (
                                <option value={city?.siruta}>
                                  {city?.city}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className={classes.errorMsg}>
                            <ErrorMessage name="city" />
                          </div>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <CustomInput
                        label="Address"
                        type="text"
                        name="address"
                        value={values.address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter address"
                        touched={touched.address}
                        errors={errors.address}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <h2>Contact</h2>
                      <hr />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <CustomInput
                        label="Email Address*"
                        type="text"
                        name="contact_email"
                        value={values.contact_email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter email"
                        touched={touched.contact_email}
                        errors={errors.contact_email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <CustomInput
                        label="Phone*"
                        type="text"
                        name="contact_phone"
                        value={values.contact_phone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter phone no"
                        touched={touched.contact_phone}
                        errors={errors.contact_phone}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addUserBtn}>
                <Button type="submit" blue customWidth="170px">
                  {loading ? <Loader /> : <>Save</>}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
});

export default Profile;
