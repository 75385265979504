import React, { useState, useEffect, useRef } from "react";
import classes from "./userSign.module.scss";
import { Formik, Field, Form, ErrorMessage } from "formik";
import SignaturePad from "../../components/shared/signaturePad";
import Button from "../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import CheckboxGroup from "../../components/shared/customCheckbox/nestedCheckbox";
import {
  signGDPRbyID,
  generateGDPR,
  deleteProductDocumentList,
  createProductDocumentList,
  signGDPR,
} from "../../api/misc";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/shared/loader";

const SignGDPR = () => {
  const signaturePadRef = useRef(null);
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const { id } = useParams();
  const [signature, setSignature] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gdprData, setGDPRData] = useState("");
  const [gdprFile, setGDPRFile] = useState("");
  const [count, setCount] = useState(0);
  const [userAgent, setUserAgent] = useState();
  // const handleSignatureSave = (dataURL) => {
  //   setSignature(dataURL); // Save the dataURL (base64 image) for further use
  // };
  console.log("signature", signature);
  const handleClear = () => {
    signaturePadRef.current.clear();
    setSignature(null);
  };
  // Fetch user data from API
  const fetchData = async () => {
    // setLoading(true); // Show loading
    try {
      // Replace with your API call, here is a mock example
      const response = await signGDPRbyID(id);
      // Assuming the API returns { data: [...], total: number }
      setTimeout(() => {
        setGDPRData(response.data.data);
      }, 1500);
    } catch (error) {
      // toast.error(showErr);
    }
    // setLoading(false); // Hide loading
  };

  // Trigger data fetch when pagination model changes
  useEffect(() => {
    fetchData();
  }, [count]);
  useEffect(() => {
    setGDPRFile(`${gdprData.file_path}?v=${Date.now()}`);
  }, [count, gdprData]);
  return (
    <div className={classes.userSign}>
      <ToastContainer />
      <div className={classes.userSignContainer}>
        {gdprData?.status === "signed" && (
          <p style={{ padding: "20px 40px" }}>
            The document has been accepted and signed on:{" "}
            {gdprData?.signed_date}
            <br />
            Country: {gdprData?.user_agent?.country}, City:{" "}
            {gdprData?.user_agent?.city}, Latitude:{" "}
            {gdprData?.user_agent?.latitude}, Longitude:{" "}
            {gdprData?.user_agent?.longitude} <br />
            IP: {gdprData?.user_agent?.ip}, <br />
            User Agent: {gdprData?.user_agent?.userAgent}
          </p>
        )}
        <iframe
          src={`${gdprFile}#navpanes=0&scrollbar=0`}
          width="100%"
          height="1000px"
          style={{ border: "none" }}
          title="PDF Viewer"
        />
        {gdprData?.status === "signed" ? (
          <></>
        ) : (
          <div className={classes.nestedForm}>
            <Formik
              initialValues={{
                is_validated: false,
                communication_phone: false,
                communication_email: false,
                mk_phone: false,
                mk_email: false,
                communication_main: false,
                mk_main: false,
              }}
              // validationSchema={PDFSchema}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                if (signaturePadRef.current?.isEmpty()) {
                  setLoading(false);
                  toast.error(
                    "You must need to add signature to submit the form."
                  );
                  return;
                }
                try {
                  // Replace with your API call, here is a mock example
                  const agentData = await axios.get(
                    "https://ipinfo.io/json?token=bf12de50cc381f",
                    {
                      headers: {
                        Authorization: `Bearer ${tokenAD}`,
                        lang: lang,
                      },
                    }
                  );

                  if (agentData?.status == 200) {
                    const signdata = {
                      is_validated: values.is_validated,
                      communication_phone: values.communication_phone,
                      communication_email: values.communication_email,
                      mk_phone: values.mk_phone,
                      mk_email: values.mk_email,
                      signature: signaturePadRef.current.toDataURL() || "",
                      user_agent: agentData?.data,
                    };

                    try {
                      const res = await signGDPR(id, signdata);
                      // gdprID, signdata,
                      // Handle success response
                      if (res?.status === 200) {
                        setLoading(false);
                        setTimeout(() => {
                          toast.success(res?.data?.message);
                          // setGDPRData(res.data.data);
                          // setSignature(null);
                          setCount(count + 1);
                        }, 1500);
                      } else {
                        throw new Error(
                          res?.data?.message || "An error occurred"
                        );
                      }
                    } catch (error) {
                      setLoading(false);
                      toast.error(
                        error.response.data.message ||
                          "An error occurred during the process."
                      );
                    }
                  }
                } catch (error) {
                  setLoading(false);
                  const showErr = error?.response?.data?.message;
                  toast.error(showErr);
                }
              }}
            >
              {({
                values,
                setFieldValue,
                handleChange,
                handleBlur,
                errors,
                touched,
              }) => (
                <Form>
                  <div className={classes.pdfcheckbox}>
                    <Field
                      type="checkbox"
                      name="is_validated"
                      checked={values.is_validated}
                      onChange={handleChange}
                      errors={errors.is_validated}
                      touched={touched.businessCategory}
                      onBlur={handleBlur}
                    />
                    I'm agree with the GDPR Terms and Conditions
                  </div>
                  <div className={classes.errorMsg}>
                    <ErrorMessage name="is_validated" />
                  </div>

                  <CheckboxGroup
                    label="Agreed Marketing communication channel"
                    name="communication_main"
                    setFieldValue={setFieldValue}
                    values={values}
                    children={[
                      {
                        name: "communication_email",
                        label: `Email (${gdprData?.client_email}) `,
                      },
                      {
                        name: "communication_phone",
                        label: `Phone / Whatsapp / SMS (${gdprData?.client_phone}) `,
                      },
                    ]}
                  />
                  <CheckboxGroup
                    label="Agreed Loan Information communication channel"
                    name="mk_main"
                    setFieldValue={setFieldValue}
                    values={values}
                    children={[
                      {
                        name: "mk_email",
                        label: `Email (${gdprData?.client_email}) `,
                      },
                      {
                        name: "mk_phone",
                        label: `Phone / Whatsapp / SMS (${gdprData?.client_phone}) `,
                      },
                    ]}
                  />
                  <SignaturePad
                    // onSave={handleSignatureSave}
                    setSignature={setSignature}
                    signaturePadRef={signaturePadRef}
                  />
                  <div className={classes.pdfBtn}>
                    <Button onClick={handleClear} outlineGrey>
                      Clear
                    </Button>
                    <Button
                      // disabled={signature ? false : true}
                      type="submit"
                      blue
                    >
                      {loading ? <Loader /> : " Sign and Send"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignGDPR;
