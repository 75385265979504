import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./incomeSource.module.scss";
// import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import { AddIncomeSource, UpdateIncomeSource } from "../../../api/misc";
import { useSelector } from "react-redux";

const IncomeSourceSchema = Yup.object().shape({
  name_en: Yup.string().required("Please enter income source (Ro)"),
  name_ro: Yup.string().required("Please enter income source (En)"),
});

const AddEditIncomeSource = ({
  editIncomeSource,
  handleCancel,
  setEditIncomeSource,
  setIncomeSourceState,
}) => {
  localStorage.setItem(
    "breadcrumbName",
    editIncomeSource ? "Edit Income Source" : "Add Income Source"
  );
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);

  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={
            editIncomeSource
              ? {
                  name_en: editIncomeSource?.name_en,
                  name_ro: editIncomeSource?.name_ro,
                }
              : {
                  name_en: "",
                  name_ro: "",
                }
          }
          validationSchema={IncomeSourceSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);

            try {
              let res;

              // Check if editing or creating a new user
              if (editIncomeSource) {
                res = await UpdateIncomeSource(editIncomeSource?.id, values, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              } else {
                res = await AddIncomeSource(values, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              }

              // Handle success response
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);

                setTimeout(() => {
                  // setUserState(true); // Update user state
                  resetForm({ values: "" }); // Reset form fields
                  setIncomeSourceState(true);
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <h3>Salaries</h3>
                <Grid container spacing={2} xs={12} md={6} lg={6}>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Income Source (Ro)"
                      type="text"
                      name="name_ro"
                      value={values?.name_ro}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Income Source (Ro)"
                      touched={touched.name_ro}
                      errors={errors.name_ro}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Income Source (En)"
                      type="text"
                      name="name_en"
                      value={values?.name_en}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Income Source (En)"
                      touched={touched.name_en}
                      errors={errors.name_en}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addUserBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>{editIncomeSource ? "Save" : "Add"}</>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddEditIncomeSource;
