import React, { useState } from "react";
import classes from "./settingSidebar.module.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SettingSiderbar = () => {
  const userCompleteData = JSON.parse(localStorage.getItem("userData"));
  const location = useLocation();
  const lastSegment = location.pathname.split("/").filter(Boolean).pop();
  const [activeLink, setActiveLink] = useState(lastSegment);

  const handleLinkClick = (link) => {
    setActiveLink(link); // Update the active link when a link is clicked
    localStorage.removeItem("FilterData");
    localStorage.removeItem("paginationPage");
    localStorage.removeItem("dashboradEditData");
    localStorage.removeItem("dashboradStatus");
    localStorage.removeItem("createApplication");
  };

  return (
    <div className={classes.mainSidebar}>
      <ul>
        {userCompleteData?.data?.menu?.map(
          (itemMenu) =>
            itemMenu?.slug === "settings" &&
            itemMenu?.items?.map((itemMenuInner) => (
              <li>
                <Link
                  to={itemMenuInner?.link}
                  className={
                    activeLink === itemMenuInner.slug ? classes.active : ""
                  }
                  onClick={() => handleLinkClick(itemMenuInner.slug)}
                >
                  {itemMenuInner.title}
                </Link>
              </li>
            ))
        )}
        {/* <li>
          <Link
            to="settings/general-settings"
            className={activeLink === "general-settings" ? classes.active : ""}
            onClick={() => handleLinkClick("general-settings")}
          >
            General settings
          </Link>
        </li>
        <li>
          <Link
            to="settings/appearance"
            className={activeLink === "appearance" ? classes.active : ""}
            onClick={() => handleLinkClick("appearance")}
          >
            Appearance
          </Link>
        </li>
        <li>
          <Link
            to="settings/privacy"
            className={activeLink === "privacy" ? classes.active : ""}
            onClick={() => handleLinkClick("privacy")}
          >
            Privacy & Safety
          </Link>
        </li>
        <li>
          <Link
            to="settings/notifications"
            className={activeLink === "notifications" ? classes.active : ""}
            onClick={() => handleLinkClick("notifications")}
          >
            Notifications
          </Link>
        </li>
        <li>
          <Link
            to="settings/providers"
            className={activeLink === "providers" ? classes.active : ""}
            onClick={() => handleLinkClick("providers")}
          >
            Providers
          </Link>
        </li>
        <li>
          <Link
            to="settings/products"
            className={activeLink === "products" ? classes.active : ""}
            onClick={() => handleLinkClick("products")}
          >
            Products
          </Link>
        </li>
        <li>
          <Link
            to="settings/users"
            className={activeLink === "users" ? classes.active : ""}
            onClick={() => handleLinkClick("users")}
          >
            Users
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default SettingSiderbar;
