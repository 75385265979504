import React, { useState } from "react";
import IncomeSource from "./getAllBranches";
import AddEditIncomeSource from "./AddEditBranches";
const MainAccessLevel = () => {
  const [incomeSourceState, setIncomeSourceState] = useState(true);
  const [editIncomeSource, setEditIncomeSource] = useState();
  const handleCancel = () => {
    setIncomeSourceState(true);
    setEditIncomeSource(null);
  };
  return (
    <>
      {incomeSourceState ? (
        <IncomeSource
          setIncomeSourceState={setIncomeSourceState}
          setEditIncomeSource={setEditIncomeSource}
        />
      ) : (
        <AddEditIncomeSource
          editIncomeSource={editIncomeSource}
          handleCancel={handleCancel}
          setEditIncomeSource={setEditIncomeSource}
          setIncomeSourceState={setIncomeSourceState}
        />
      )}
    </>
  );
};

export default MainAccessLevel;
