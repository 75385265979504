import React, { useState, useCallback, useRef } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImageHelper"; // Helper to crop the image
import { v4 as uuidv4 } from "uuid";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import CustomInput from "../../../../components/shared/customInput";
import Button from "../../../../components/shared/customBtn";
import { createProvider, editSingleProvider } from "../../../../api/misc";
import { useSelector } from "react-redux";
import classes from "./addProviders.module.scss";
import "./addProvidersBuiltin.scss";

const ProviderSchema = Yup.object().shape({
  name: Yup.string().required("Please enter provider name"),
  provider_type_id: Yup.string().required("Please select provider type"),
  image: Yup.mixed().required("Please upload a logo"),
});

const AddProvider = ({
  editProvider,
  handleCancel,
  setProviderState,
  setEditProvider,
  allstatusVal,
  setCountProvider,
  countProvider,
}) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const fileInputRef = useRef(null); // Ref for file input
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null); // Original selected image
  const [croppedImage, setCroppedImage] = useState(
    editProvider ? editProvider?.provider_image?.full_path : null
  ); // Cropped image result
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const handleImageUpload = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(reader.result); // Set the base64 image
        setShowCropper(true); // Show the cropper modal
        setFieldValue("image", file); // Update Formik's value
      };
      reader.readAsDataURL(file);
    }

    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCropComplete = useCallback(async () => {
    try {
      const croppedResult = await getCroppedImg(logo, cropArea, 500, 200);
      setCroppedImage(croppedResult);
      setShowCropper(false); // Close the cropper modal
    } catch (error) {
      console.error("Error cropping the image:", error);
    }
  }, [cropArea, logo]);
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={
            editProvider
              ? {
                  name: editProvider?.name,
                  provider_type_id: editProvider?.provider_type_id,
                  image: "",
                }
              : {
                  name: "",
                  provider_type_id: "",
                  image: "",
                }
          }
          validationSchema={ProviderSchema}
          onSubmit={async (values) => {
            setLoading(true);
            const sendData = {
              ...values,
              image: croppedImage,
              status: allstatusVal, // Include the cropped logo in the payload
            };
            console.log("sendData", sendData);
            try {
              let res;
              if (editProvider) {
                res = await editSingleProvider(editProvider?.id, sendData, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              } else {
                res = await createProvider(sendData, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              }

              if (res?.status === 200) {
                toast.success(res?.data?.message);
                setTimeout(() => {
                  setEditProvider(res.data.data);
                  setCountProvider(countProvider + 1);
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              toast.error(
                error.response?.data?.message || "An error occurred."
              );
            } finally {
              setLoading(false);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      label="Provider Name"
                      type="text"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter provider name"
                      touched={touched.name}
                      errors={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={`${classes.inputMain}`}>
                      <label>Provider Type</label>
                      <div className={classes.inputFieldSelect}>
                        <Field
                          as="select"
                          name="provider_type_id"
                          onBlur={handleBlur}
                          touched={touched.provider_type_id}
                          errors={errors.provider_type_id}
                          onChange={async (event) => {
                            const selectedProviderType = event.target.value;
                            setFieldValue(
                              "provider_type_id",
                              selectedProviderType
                            );
                          }}
                          placeholder="Provider Type"
                          className={`${classes.inputField}`}
                        >
                          <option value="">Select Type</option>
                          {masterData?.providerTypes.map((providerType) => (
                            <option
                              key={providerType?.id}
                              value={`${providerType?.id}`}
                            >
                              {providerType?.supplier_name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className={classes.errorMsg}>
                        <ErrorMessage name="provider_type_id" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>Upload Logo</div>
                    <br />
                    <Button
                      blue
                      fontsize="0.813rem"
                      customWidth="122px"
                      onClick={handleBrowseClick}
                    >
                      Upload
                    </Button>
                    <small>Logo (500 x 200 px)</small>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(event) =>
                        handleImageUpload(event, setFieldValue)
                      }
                    />
                    {croppedImage && (
                      <div>
                        <img
                          src={croppedImage}
                          alt="Cropped Logo"
                          style={{
                            width: "250px",
                            height: "100px",
                            marginTop: "10px",
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>
                    )}
                    <div className={classes.errorMsg}>
                      <ErrorMessage name="image" />
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* Cropper Modal */}
              {showCropper && (
                <div className={classes.cropperContainer}>
                  <div className={classes.cropBox}>
                    <Cropper
                      image={logo}
                      crop={crop}
                      zoom={zoom}
                      aspect={500 / 200}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={(_, cropAreaPixels) =>
                        setCropArea(cropAreaPixels)
                      }
                    />
                  </div>
                  <div className={classes.cropBtn}>
                    <Button
                      onClick={handleCropComplete}
                      blue
                      customWidth="150px"
                    >
                      Crop
                    </Button>
                    <Button
                      onClick={() => setShowCropper(false)}
                      outlineGrey
                      customWidth="150px"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              )}
              {showCropper ? null : (
                <div className={classes.addProviderBtn}>
                  <Button
                    onClick={handleCancel}
                    outlineGrey
                    customWidth="170px"
                  >
                    Close
                  </Button>
                  <Button type="submit" blue customWidth="170px">
                    {loading ? (
                      <Loader />
                    ) : (
                      <> {editProvider ? "Save" : "Save"}</>
                    )}
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddProvider;
