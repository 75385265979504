import React, { useState, useCallback } from "react";
import classes from "./addloan.module.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./addUsersBuiltin.scss";
import AddLoan from "./addLoanMain";
import AddGDPR from "./addGDPR";

import AddFamilyMember from "./addFamily";
import ClientFinancial from "./clientFinancial";

const LoanTabs = ({
  clientData,
  handleCancel,
  setLoanState,
  setClientData,
  editExistingclient,
  setEditExistingclient,
}) => {
  localStorage.setItem(
    "breadcrumbName",
    editExistingclient ? "Edit Client" : "Client"
  );
  const [value, setValue] = useState(0);

  const [allstatusVal, setAllstatusVal] = useState(
    editExistingclient ? editExistingclient?.status_slug : 0
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <>
      <div className={`${classes.MainUser} ${classes.backgroundBase}`}>
        {/* <MainStatus
          allstatusVal={allstatusVal}
          setAllstatusVal={setAllstatusVal}
          editExistingclient={editExistingclient}
          setEditExistingclient={setEditExistingclient}
          apiCall={updateMainStatus}
        /> */}
        <div className={classes.UserTabContainer}>
          <Box className={`${classes.tabsHead} UserTabBuiltin`}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Client Data" {...a11yProps(0)} />
              <Tab
                label="Client Financials"
                {...a11yProps(1)}
                disabled={editExistingclient ? false : true}
              />
              <Tab
                label="Family members"
                {...a11yProps(2)}
                disabled={editExistingclient ? false : true}
              />
              <Tab
                label="GDPR"
                {...a11yProps(3)}
                disabled={editExistingclient ? false : true}
              />
              {/*
              <Tab
                label="Offers"
                {...a11yProps(3)}
                disabled={editExistingclient ? false : true}
              />
              <Tab
                label="Product"
                {...a11yProps(4)}
                disabled={editExistingclient ? false : true}
              />
              <Tab
                label="Documents"
                {...a11yProps(5)}
                disabled={editExistingclient ? false : true}
              />
              <Tab
                label="Contract"
                {...a11yProps(6)}
                disabled={editExistingclient ? false : true}
              />*/}
            </Tabs>
          </Box>
        </div>
        <div className={classes.ProfileTabs}>
          <CustomTabPanel value={value} index={0}>
            <AddLoan
              handleCancel={handleCancel}
              clientData={clientData}
              setLoanState={setLoanState}
              setClientData={setClientData}
              setEditExistingclient={setEditExistingclient}
              editExistingclient={editExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ClientFinancial
              handleCancel={handleCancel}
              editExistingclient={editExistingclient}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <AddFamilyMember
              handleCancel={handleCancel}
              setClientData={setClientData}
              setLoanState={setLoanState}
              clientData={clientData}
              editExistingclient={editExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <AddGDPR
              handleCancel={handleCancel}
              setClientData={setClientData}
              setLoanState={setLoanState}
              clientData={clientData}
              editExistingclient={editExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={3}>
            <AddOffer
              handleCancel={handleCancel}
              setClientData={setClientData}
              setLoanState={setLoanState}
              clientData={clientData}
              editExistingclient={editExistingclient}
              setEditExistingclient={setEditExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <AddProduct
              handleCancel={handleCancel}
              setClientData={setClientData}
              setLoanState={setLoanState}
              clientData={clientData}
              editExistingclient={editExistingclient}
              setEditExistingclient={setEditExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <AddDocument
              handleCancel={handleCancel}
              clientData={clientData}
              editExistingclient={editExistingclient}
              allstatusVal={allstatusVal}
              setAllstatusVal={setAllstatusVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            tab 5
          </CustomTabPanel> */}
        </div>
      </div>
    </>
  );
};

export default LoanTabs;
