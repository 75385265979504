const formatDateEngOnly = (dateString) => {
  const date = new Date(dateString);

  // Array of month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract day, month, and year
  const day = date.getDate();
  const month = months[date.getMonth()]; // Get month name
  const year = date.getFullYear();

  // Format as "22 Feb, 2022"
  return `${day} ${month}, ${year}`;
};

export default formatDateEngOnly;
