import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./addUsers.module.scss";
import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../../components/shared/customInput";
import Button from "../../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import {
  getAllUsersName,
  getAllBranches,
  addCareerLevel,
  editSingleUser,
} from "../../../../api/misc";
import { useSelector } from "react-redux";

const CareerSchema = Yup.object().shape({
  career_id: Yup.string().required("Please Select Career Level"),
  // boss_id: Yup.string().required("Please Select User"),
  branch_id: Yup.string().required("Please Select Branch"),
});
const AddCareer = ({ editUser, handleCancel, setUserState, setEditUser }) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);

  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={
            editUser
              ? {
                  career_id: editUser?.career_level?.career_id,
                  boss_id: editUser?.career_level?.boss_id,
                  branch_id: editUser?.career_level?.branch_id,
                }
              : {
                  career_id: "",
                  boss_id: "",
                  branch_id: "",
                }
          }
          validationSchema={CareerSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);

            console.log("values", values);

            try {
              let res = await addCareerLevel(editUser?.id, values, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });
              // Handle success response
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);

                setTimeout(() => {
                  // setUserState(true); // Update user state
                  // resetForm({ values: "" }); // Reset form fields
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6} spacing={2}>
                    <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
                      <div className={`${classes.inputMain}`}>
                        <label>Current Carrer Level*</label>
                        <div className={classes.inputFieldSelect}>
                          <Field
                            as="select"
                            name="career_id"
                            onBlur={handleBlur}
                            touched={touched.career_id}
                            errors={errors.career_id}
                            onChange={handleChange}
                            placeholder="Select Career Level"
                            className={`${classes.inputField}`}
                          >
                            <option value="">Select Career Level</option>

                            {masterData?.careerLevels?.map((item) => (
                              <option value={`${item.id}`}>
                                {item.level_name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="career_id" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
                      <div className={`${classes.inputMain}`}>
                        <label>Reporting To</label>
                        <div className={classes.inputFieldSelect}>
                          <Field
                            as="select"
                            name="boss_id"
                            // onBlur={handleBlur}
                            // touched={touched.boss_id}
                            // errors={errors.boss_id}
                            onChange={handleChange}
                            placeholder="Select User"
                            className={`${classes.inputField}`}
                          >
                            <option value="">Select User</option>

                            {masterData?.users?.map((item) => (
                              <>
                                {item?.id === editUser?.id ? (
                                  ""
                                ) : (
                                  <option value={`${item.id}`}>
                                    {item.name}
                                  </option>
                                )}
                              </>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="boss_id" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
                      <div className={`${classes.inputMain}`}>
                        <label>Working Branch*</label>
                        <div className={classes.inputFieldSelect}>
                          <Field
                            as="select"
                            name="branch_id"
                            onBlur={handleBlur}
                            touched={touched.branch_id}
                            errors={errors.branch_id}
                            onChange={handleChange}
                            placeholder="Select Branch"
                            className={`${classes.inputField}`}
                          >
                            <option value="">Select Branch</option>

                            {masterData?.branches?.map((item) => (
                              <option value={`${item.id}`}>
                                {item.branch_name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="branch_id" />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addUserBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? <Loader /> : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddCareer;
