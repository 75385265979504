import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import classes from "./VerifyAccount.module.scss";
import { userVerifyAccount, checkResetPassLink } from "../../../api/auth";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import resetBanner from "../../../assets/images/loginBanner2.jpg";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { useTranslation } from "react-i18next";
import CustomLanguage from "../../../components/shared/customLanguage";
import Loader from "../../../components/shared/loader";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(12, "Password cannot exceed 12 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (e.g, !, @, #)"
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm New Password is required"),
});
const ChangePassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);
  const [errMesg, setErrMesg] = useState();
  const lang = localStorage.getItem("lang");

  // Parse query parameters from the location.search
  const queryParams = new URLSearchParams(location.search);

  // Extract token and email
  const token = queryParams.get("token");
  const email = queryParams.get("email");
  useEffect(() => {
    const checkLink = async () => {
      setLoadingCheck(true);
      try {
        // Replace with your API call, here is a mock example
        const linkData = {
          token: token,
          email: email,
        };
        const res = await checkResetPassLink(linkData, {
          headers: {
            lang: lang,
          },
        });

        if (res?.status == 200) {
          setLoadingCheck(false);
          toast.success(res?.data?.message);
        }
      } catch (error) {
        const showErr = error?.response?.data?.message;
        setLoadingCheck(false);
        if (showErr == "invalid_reset_link") {
          setErrMesg(showErr);
          // toast.error("Reset link is invalid");
        } else if (showErr == "link_expired") {
          setErrMesg(showErr);
          // toast.error(t("expireLink"));
        } else {
          toast.error(showErr);
        }
      }
    };
    checkLink();
  }, []);
  // Redirect to login page if token or email is missing
  if (!token || !email) {
    return <Navigate to="/login" />;
  } else {
    return (
      <div className={classes.mainChangePassword}>
        <ToastContainer />
        <div className={classes.defaultForm}>
          {loadingCheck ? (
            <div className={classes.errStyle}>
              {" "}
              <Loader />
            </div>
          ) : (
            <>
              {errMesg == "invalid_reset_link" ? (
                <h3 className={classes.errStyle}>Reset link is invalid</h3>
              ) : errMesg == "link_expired" ? (
                <h3 className={classes.errStyle}>{t("expireLink")}</h3>
              ) : (
                <Formik
                  initialValues={{ password: "", password_confirmation: "" }}
                  validationSchema={changePasswordSchema}
                  onSubmit={async (values) => {
                    setLoading(true);

                    const valueData = {
                      password: values.password,
                      password_confirmation: values.password_confirmation,
                      token: token,
                      email: email,
                    };
                    try {
                      const res = await userVerifyAccount(valueData, {
                        headers: {
                          lang: lang,
                        },
                      });
                      if (res?.status == 200) {
                        toast.success(res?.data?.message);
                        setTimeout(() => {
                          setLoading(false);
                          navigate("/sign-in");
                        }, 2000);
                      }
                    } catch (error) {
                      setLoading(false);
                      const showErr = error?.response?.data?.message;
                      if (showErr == "invalid_reset_link") {
                        toast.error("Reset link is invalid");
                      } else if (showErr == "link_expired") {
                        toast.error(t("expireLink"));
                      } else {
                        toast.error(showErr);
                      }
                    }
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                    dirty,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className={classes.formHeader}>
                        {/* <img src={logo} alt="logo" /> */}
                        <h1>{t("verifyPage.heading")}</h1>
                        <p>{t("verifyPage.description")}</p>
                      </div>
                      <CustomLanguage />
                      <CustomInput
                        label={t("verifyPage.passLabel")}
                        type={showNewPass ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={t("verifyPage.passLabel")}
                        touched={touched.password}
                        errors={errors.password}
                        eyeIcon
                        setPasswordChange={setShowNewPass}
                        passwordChange={showNewPass}
                      />
                      <CustomInput
                        label={t("verifyPage.newPassLabel")}
                        type={showConPass ? "text" : "password"}
                        name="password_confirmation"
                        value={values.password_confirmation}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={t("verifyPage.newPassLabel")}
                        touched={touched.password_confirmation}
                        errors={errors.password_confirmation}
                        eyeIcon
                        setPasswordChange={setShowConPass}
                        passwordChange={showConPass}
                      />

                      <Button type="submit" blue disabled={!(isValid && dirty)}>
                        {loading ? <Loader /> : <>{t("verifyPage.resetBtn")}</>}
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </>
          )}
        </div>
        <div className={classes.rightBanner}>
          <img src={resetBanner} alt="reset banner" height="100%" />
        </div>
      </div>
    );
  }
};

export default ChangePassword;
