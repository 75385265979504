import React from "react";
import classes from "./permissions.module.scss";

const Permissions = () => {
  return (
    <>
      <div className={classes.mainUser}>
        <h1>Permissions</h1>
      </div>
    </>
  );
};

export default Permissions;
