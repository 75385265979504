import React, { useState, useEffect, memo, useCallback } from "react";
import { Formik, Form } from "formik";
import classes from "./addloan.module.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { Field, ErrorMessage, FieldArray } from "formik";
import { GetClientFinancial, CreateClientFinancial } from "../../../api/misc";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SavingsIcon from "@mui/icons-material/Savings";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { useTranslation } from "react-i18next";

const ComparerSchema = Yup.object().shape({
  incomes: Yup.array().of(
    Yup.object({
      income_source_id: Yup.string().required("Required"),
      contract_start_date: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
    })
  ),
  // expenses: Yup.array().of(
  //   Yup.object({
  //     expense_type_id: Yup.string().required("Required"),
  //     amount: Yup.string().required("Required"),
  //   })
  // ),
});

const ClientFinancial = ({ handleCancel, editExistingclient }) => {
  const { t } = useTranslation();
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);

  const {
    response: getAllClientFinancialData,
    loading: getClientFinancialLoading,
    error: getClientFinancialError,
  } = useGetAxios(
    GetClientFinancial(editExistingclient?.client_id),
    false,
    count
  );

  useEffect(() => {
    // Recalculate totals when values change
    if (getAllClientFinancialData?.data) {
      calculateTotals(
        getAllClientFinancialData?.data?.incomes || [],
        getAllClientFinancialData?.data?.expenses || []
      );
    }
  }, [getAllClientFinancialData]);

  const calculateTotals = (incomes, expenses) => {
    const incomeTotal = incomes
      ?.filter((item) => !item.deleted && item.amount)
      ?.reduce((sum, income) => sum + parseFloat(income.amount || 0), 0);
    const expenseTotal = expenses
      ?.filter((item) => !item.deleted && item.amount)
      ?.reduce((sum, expense) => sum + parseFloat(expense.amount || 0), 0);
    setTotalIncome(incomeTotal || 0);
    setTotalExpense(expenseTotal || 0);
  };
  return (
    <>
      <ToastContainer />

      <div className={classes.defaultForm}>
        <Formik
          enableReinitialize
          initialValues={{
            incomes: getAllClientFinancialData?.data?.incomes || [
              {
                income_source_id: "",
                contract_start_date: "",
                amount: "",
              },
            ],
            expenses: getAllClientFinancialData?.data?.expenses || [
              { expense_type_id: "", amount: "" },
            ],
          }}
          validationSchema={ComparerSchema}
          onSubmit={async (values, { resetForm }) => {
            console.log("valuessss", values);
            setLoading(true);
            const data = {
              incomes: values.incomes,
              expenses:
                values.expenses &&
                values.expenses.some(
                  (expense) => expense.expense_type_id || expense.amount
                )
                  ? values.expenses.filter(
                      (expense) => expense.expense_type_id || expense.amount
                    )
                  : [],
            };
            try {
              let res = await CreateClientFinancial(
                editExistingclient?.client_id,
                data,
                {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                }
              );
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);
                setCount(count + 1);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }

              // Handle success response
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div
                  className={`${classes.formMain} ${classes.prescoringStyle}`}
                >
                  <div className={classes.incomeMain}>
                    <div className={classes.head}>
                      <h3>1. {t("loan.monthlyIncome")} (Lei)</h3>

                      <h2>
                        {t("loan.totalIncomes")}: <span>{totalIncome}</span>
                      </h2>
                    </div>
                    {getClientFinancialLoading ? (
                      <Loader />
                    ) : (
                      <FieldArray
                        name="incomes"
                        render={(arrayHelpers) => {
                          // Ensure at least one empty row exists
                          if (values?.incomes?.length === 0) {
                            arrayHelpers.push({
                              income_source_id: "",
                              contract_start_date: "",
                              amount: "",
                            });
                          }

                          return (
                            <ul>
                              {values.incomes
                                ?.filter((item) => !item.deleted)
                                ?.map((income, index) => (
                                  <li key={index}>
                                    <div className={`${classes.inputMain}`}>
                                      <label>{t("loan.incomeSource")}</label>
                                      <div className={classes.inputFieldSelect}>
                                        <Field
                                          as="select"
                                          name={`incomes.${index}.income_source_id`}
                                          onBlur={handleBlur}
                                          className={`${classes.inputField}`}
                                        >
                                          <option value="">
                                            Select income source
                                          </option>
                                          {masterData?.incomeSources?.map(
                                            (item) => (
                                              <option
                                                key={item?.id}
                                                value={item?.id}
                                              >
                                                {item?.name}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        <div className={classes.errorMsg}>
                                          <ErrorMessage
                                            name={`incomes.${index}.income_source_id`}
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <CustomInput
                                        label={t("loan.ContractStartDate")}
                                        type="date"
                                        name={`incomes.${index}.contract_start_date`}
                                        value={income.contract_start_date}
                                        onChange={handleChange}
                                      />
                                      <div className={classes.errorMsg}>
                                        <ErrorMessage
                                          name={`incomes.${index}.contract_start_date`}
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <CustomInput
                                        label={t("loan.incomeAmount")}
                                        type="text"
                                        name={`incomes.${index}.amount`}
                                        placeholder="Income Amount"
                                        value={income.amount}
                                        onChange={handleChange}
                                      />
                                      <div className={classes.errorMsg}>
                                        <ErrorMessage
                                          name={`incomes.${index}.amount`}
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                    <div className={classes.actionbtn}>
                                      <CloseIcon
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                      {index === values.incomes.length - 1 && (
                                        <AddCircleOutlineIcon
                                          onClick={() =>
                                            arrayHelpers.push({
                                              income_source_id: "",
                                              contract_start_date: "",
                                              amount: "",
                                            })
                                          }
                                        />
                                      )}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          );
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.incomeMain}>
                    <div className={classes.head}>
                      <h3>2.{t("loan.monthlyExpensesOtherLoan")} (Lei)</h3>

                      <h2>
                        {t("loan.totalExpenses")}: <span>{totalExpense}</span>
                      </h2>
                    </div>
                    {getClientFinancialLoading ? (
                      <Loader />
                    ) : (
                      <FieldArray
                        name="expenses"
                        render={(arrayHelpers) => {
                          // Ensure at least one empty row exists
                          if (values?.expenses?.length === 0) {
                            arrayHelpers.push({
                              expense_type_id: "",
                              amount: "",
                            });
                          }

                          return (
                            <ul>
                              {values.expenses
                                ?.filter((item) => !item.deleted)
                                ?.map((income, index) => (
                                  <li key={index}>
                                    <div className={`${classes.inputMain}`}>
                                      <label>{t("loan.expenseType")}</label>
                                      <div className={classes.inputFieldSelect}>
                                        <Field
                                          as="select"
                                          name={`expenses.${index}.expense_type_id`}
                                          onBlur={handleBlur}
                                          className={`${classes.inputField}`}
                                        >
                                          <option value="">
                                            Select Expense Type
                                          </option>
                                          {masterData?.productSetting?.expenses?.map(
                                            (item) => (
                                              <option
                                                key={item?.id}
                                                value={item?.id}
                                              >
                                                {item?.name}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        {/* <div className={classes.errorMsg}>
                                          <ErrorMessage
                                            name={`expenses.${index}.expense_type_id`}
                                            component="div"
                                          />
                                        </div> */}
                                      </div>
                                    </div>

                                    <div>
                                      <CustomInput
                                        label={t("loan.expenseAmount")}
                                        type="text"
                                        name={`expenses.${index}.amount`}
                                        placeholder="Expense Amount"
                                        value={income.amount}
                                        onChange={handleChange}
                                      />
                                      {/* <div className={classes.errorMsg}>
                                        <ErrorMessage
                                          name={`expenses.${index}.amount`}
                                          component="div"
                                        />
                                      </div> */}
                                    </div>
                                    <div className={classes.actionbtn}>
                                      <CloseIcon
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                      {index === values.expenses.length - 1 && (
                                        <AddCircleOutlineIcon
                                          onClick={() =>
                                            arrayHelpers.push({
                                              expense_type_id: "",
                                              amount: "",
                                            })
                                          }
                                        />
                                      )}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.addUserBtn}>
                  <Button
                    onClick={handleCancel}
                    outlineGrey
                    customWidth="170px"
                  >
                    {t("loan.close")}
                  </Button>
                  {editExistingclient?.status_slug ===
                  "indicative-offer-refused-process-closed" ? (
                    ""
                  ) : (
                    <Button type="submit" blue customWidth="170px">
                      {loading ? <Loader /> : t("loan.save")}
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ClientFinancial;
