import React, { useState } from "react";
import { Formik, Form } from "formik";
import classes from "./Forgot.module.scss";
import { userForgotPass } from "../../../api/auth";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import resetBanner from "../../../assets/images/loginBanner2.png";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { useTranslation } from "react-i18next";
import CustomLanguage from "../../../components/shared/customLanguage";
import Loader from "../../../components/shared/loader";

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    ),
});
const ResetPassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div className={classes.mainResetPassword}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={resetPasswordSchema}
          onSubmit={async (values) => {
            setLoading(true);
            const lang = localStorage.getItem("lang");
            try {
              const res = await userForgotPass(values, {
                headers: {
                  lang: lang,
                },
              });
              if (res?.status == 200) {
                toast.success(res?.data?.message);
                setTimeout(() => {
                  setLoading(false);
                }, 2000);
              }
            } catch (error) {
              setLoading(false);
              const showErr = error?.response?.data?.message;
              toast.error(showErr);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                {/* <img src={logo} alt="logo" /> */}
                <h1>{t("forgotPage.heading")}</h1>
                <p>{t("forgotPage.description")}</p>
              </div>
              <CustomLanguage />
              <CustomInput
                label={t("loginPage.email")}
                type="text"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t("loginPage.enterEmail")}
                touched={touched.email}
                errors={errors.email}
              />

              <Button type="submit" blue disabled={!(isValid && dirty)}>
                {loading ? <Loader /> : <>{t("forgotPage.forgotBtn")}</>}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rightBanner}>
        <img src={resetBanner} alt="reset banner" height="100%" />
      </div>
    </div>
  );
};

export default ResetPassword;
