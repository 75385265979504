import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./addUsers.module.scss";
import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../../components/shared/customInput";
import Button from "../../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import { getAllCities, createUser, editSingleUser } from "../../../../api/misc";
import { useSelector } from "react-redux";

const phoneRegExp = /^\+\d{1,3}\d{9}$/;
const UserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    ),
  first_name: Yup.string().required("Please enter first_name"),
  last_name: Yup.string().required("Please enter last_name"),
  contact_phone: Yup.string()
    .matches(
      phoneRegExp,
      "Not valid. Expected format: +00123456789 or +000{9 numbers}"
    )
    .required("Phone number is required"),
  personal_id: Yup.string()
    .required("Please enter personal id")
    .min(13, "Personal id must be atleast 13 digit long")
    .max(13, "Personal id must be not greater than 13 digit"),
  county: Yup.string().required("Please select county"),
  city: Yup.string().required("Please select city"),
  address: Yup.string().required("Please enter address"),
  contact_email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    ),
  // is_company: Yup.boolean(),
  // company_name: Yup.string().when("is_company", {
  //   is: true, // When is_company is true
  //   then: Yup.string().required("Company Name is required"), // Make company_name required
  //   otherwise: Yup.string(), // Otherwise it's not required
  // }),
  // company_id: Yup.string().when("is_company", {
  //   is: true, // When is_company is true
  //   then: Yup.string().required("Company ID is required"), // Make company_id required
  //   otherwise: Yup.string(), // Otherwise it's not required
  // }),
});

const AddUser = ({ editUser, handleCancel, setUserState, setEditUser }) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(editUser?.is_company ? true : false); // Checkbox state
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);

  // Handle change event
  const handleChangeCompany = (event) => {
    setChecked(event.target.checked); // Toggle the checked state
  };
  const fetchCities = async (selectedCountyCode) => {
    if (selectedCountyCode) {
      setLoadingCities(true);
      try {
        const response = await getAllCities(selectedCountyCode, {
          headers: {
            lang: lang,
            Authorization: `Bearer ${tokenAD}`,
          },
        });
        setCities(response.data.data.cities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
      setLoadingCities(false);
    }
  };

  useEffect(() => {
    if (editUser?.county_code) {
      fetchCities(editUser?.county_code); // Fetch cities for the default county_code
    }
  }, [editUser?.county_code]);
  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={
            editUser
              ? {
                  first_name: editUser?.first_name,
                  last_name: editUser?.last_name,
                  personal_id: editUser?.personal_id,
                  email: editUser?.email,
                  is_company: editUser?.is_company ? true : false,
                  county: editUser?.county_code,
                  city: editUser?.city_code,
                  address: editUser?.address,
                  contact_email: editUser?.contact_email,
                  contact_phone: editUser?.contact_phone,
                  bank_name: editUser?.data?.bank_name,
                  iban: editUser?.data?.iban,
                  company_name: editUser?.is_company
                    ? editUser?.company_name
                    : "",
                  company_id: editUser?.is_company ? editUser?.company_id : "",
                }
              : {
                  first_name: "",
                  last_name: "",
                  personal_id: "",
                  email: "",
                  is_company: false,
                  county: "",
                  city: "",
                  address: "",
                  contact_email: "",
                  contact_phone: "",
                  company_name: "",
                  company_id: "",
                  bank_name: "",
                  iban: "",
                }
          }
          validationSchema={UserSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            const sendData = {
              first_name: values?.first_name,
              last_name: values?.last_name,
              personal_id: values?.personal_id,
              email: values?.email,
              is_company: values?.is_company,
              county: values?.county,
              city: values?.city,
              address: values?.address,
              contact_email: values?.contact_email,
              contact_phone: values?.contact_phone,
              company_name: values?.is_company ? values?.company_name : "",
              company_id: values?.is_company ? values?.company_id : "",
              bank_name: values?.bank_name,
              iban: values?.iban,
            };

            try {
              let res;

              // Check if editing or creating a new user
              if (editUser) {
                res = await editSingleUser(editUser?.id, sendData, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              } else {
                res = await createUser(values, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              }

              // Handle success response
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);

                setTimeout(() => {
                  // setUserState(true); // Update user state
                  // resetForm({ values: "" }); // Reset form fields
                  setEditUser(res?.data?.data?.user);
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="First Name*"
                      type="text"
                      name="first_name"
                      value={values?.first_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter first name"
                      touched={touched.first_name}
                      errors={errors.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="Last Name*"
                      type="text"
                      name="last_name"
                      value={values?.last_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter last name"
                      touched={touched.last_name}
                      errors={errors.last_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="Personal ID*"
                      type="text"
                      name="personal_id"
                      value={values?.personal_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter personal ID"
                      touched={touched.personal_id}
                      errors={errors.personal_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="Login Email*"
                      type="text"
                      name="email"
                      value={values?.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter email"
                      touched={touched.email}
                      errors={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <span className="" onClick={handleChangeCompany}>
                      <Field
                        type="checkbox"
                        name="is_company"

                        // onChange={handleChangeCompany}
                      />
                    </span>
                    The user represent a company
                  </Grid>
                  {checked && (
                    <>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomInput
                          label="Company ID"
                          type="text"
                          name="company_id"
                          value={values?.company_id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter company ID"
                          touched={touched.company_id}
                          errors={errors.company_id}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomInput
                          label="Company Name"
                          type="text"
                          name="company_name"
                          value={values?.company_name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter company name"
                          touched={touched.company_name}
                          errors={errors.company_name}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={12} lg={12}>
                    <h2>Address</h2>
                    <hr />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={`${classes.inputMain}`}>
                      <label>County*</label>
                      <div className={classes.inputFieldSelect}>
                        <Field
                          as="select"
                          name="county"
                          onBlur={handleBlur}
                          touched={touched.county}
                          errors={errors.county}
                          onChange={async (event) => {
                            const selectedCountyCode = event.target.value;
                            setFieldValue("county", selectedCountyCode);
                            setFieldValue("city", ""); // Reset city when county changes
                            fetchCities(selectedCountyCode);
                          }}
                          placeholder="Select County"
                          className={`${classes.inputField}`}
                        >
                          <option value="">Select County</option>

                          {masterData?.counties?.map((county) => (
                            <option value={`${county?.code}`}>
                              {county?.county}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className={classes.errorMsg}>
                        <ErrorMessage name="county" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {loadingCities ? (
                      <p>Loading cities...</p>
                    ) : (
                      <div className={`${classes.inputMain}`}>
                        <label>City*</label>
                        <div className={classes.inputFieldSelect}>
                          <Field
                            as="select"
                            name="city"
                            onBlur={handleBlur}
                            touched={touched.city}
                            errors={errors.city}
                            onChange={handleChange}
                            disabled={!values?.county || cities?.length === 0}
                            placeholder="Select City"
                            className={`${classes.inputField}`}
                          >
                            <option value="">Select City</option>

                            {cities?.map((city) => (
                              <option value={city.siruta}>{city.city}</option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="city" />
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Address*"
                      type="text"
                      name="address"
                      value={values?.address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter address"
                      touched={touched.address}
                      errors={errors.address}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <h2>Contact</h2>
                    <hr />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="Email Address*"
                      type="text"
                      name="contact_email"
                      value={values?.contact_email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter email"
                      touched={touched.contact_email}
                      errors={errors.contact_email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="Phone*"
                      type="text"
                      name="contact_phone"
                      value={values?.contact_phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter phone no"
                      touched={touched.contact_phone}
                      errors={errors.contact_phone}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <h2>Bank Info</h2>
                    <hr />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="Bank Name"
                      type="text"
                      name="bank_name"
                      value={values?.bank_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter bank name"
                      touched={touched.bank_name}
                      errors={errors.bank_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="IBAN"
                      type="text"
                      name="iban"
                      value={values?.iban}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter IBAN"
                      touched={touched.iban}
                      errors={errors.iban}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addUserBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? <Loader /> : <>{editUser ? "Save" : "Add User"}</>}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddUser;
