import React from "react";

const FormatText = (text) => {
  if (!text) return ""; // Return 'No Status' for empty strings or undefined

  return text
    .split("_") // Split the string by underscores (e.g., 'pending_activation')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(" "); // Join the words back with spaces (e.g., 'Pending Activation')
};

export default FormatText;
