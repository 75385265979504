import React, { useState, useEffect, memo, useCallback } from "react";
import { Formik, Form } from "formik";
import classes from "./comparer.module.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import {
  CreateCreditCompare,
  GetCreditCompareSingle,
  getIndicativeOffer,
} from "../../../api/misc";
import { useSelector } from "react-redux";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import RuleIcon from "@mui/icons-material/Rule";
import DataTable from "../../../components/shared/customTable";
import BasicModal from "../../../components/shared/basicmodal";
import { cleanFilterItem } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import useGetAxios from "../../../api/hooks/useGetAxios";

const ComparerSchema = Yup.object().shape({
  currency: Yup.string().required("Please select currency"),
  loan_purpose: Yup.string().required("Please select loan purpose"),
  interest_rate_id: Yup.string().required("Please select interest rate"),
  duration: Yup.number()
    .required("Property value is required")
    .positive("Must be a positive number"),
  property_value: Yup.number()
    .required("Property value is required")
    .positive("Must be a positive number"),
  down_payment_percentage: Yup.number()
    .required("Down payment percentage is required")
    .min(0, "Must be at least 0%")
    .max(100, "Cannot exceed 100%"),
  down_payment_value: Yup.number()
    .required("Down payment value is required")
    .positive("Must be a positive number"),
});
const CreditComparer = memo(() => {
  localStorage.setItem("breadcrumbName", "Loan Comparer");
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [loadingOffer, setLoadingOffer] = useState(false);
  const [resultData, setResultData] = useState();
  const [openViewComparer, setOpenViewComparer] = useState(false);
  const [viewComparerSingle, setViewComparerSingle] = useState();
  const [count, setCount] = useState(0);

  const handleViewComparerModalClose = () => {
    setOpenViewComparer(false);
    setViewComparerSingle(null);
  };
  const handleEdit = async (row) => {
    // Add your edit logic here
    try {
      let res = await GetCreditCompareSingle(
        "comparer",
        resultData?.id,
        row?.ps_interest_type_id,
        {
          headers: {
            lang: lang,
            Authorization: `Bearer ${tokenAD}`,
          },
        }
      );
      if (res?.status === 200) {
        setLoading(false);
        setViewComparerSingle(res?.data.data);
      } else {
        throw new Error(res?.data?.message || "An error occurred");
      }

      // Handle success response
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data.message || "An error occurred during the process."
      );
    }

    setOpenViewComparer(true);
  };
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id, isChecked) => {
    setSelectedIds((prev) =>
      isChecked ? [...prev, id] : prev.filter((selectedId) => selectedId !== id)
    );
  };
  const columns = [
    {
      field: "checkbox",
      headerName: "Select",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={selectedIds.includes(params.row.id)}
          onChange={(e) =>
            handleCheckboxChange(params.row.id, e.target.checked)
          }
        />
      ),
    },

    {
      field: "bank_name",
      headerName: "Bank",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const logoUrl = params?.row?.logo?.full_path;
        return logoUrl ? (
          <img
            src={logoUrl}
            alt={params?.bank_name}
            style={{ margin: "5px 0" }}
          />
        ) : (
          params?.bank_name
        );
      },
    },
    {
      field: "interest_type",
      headerName: "Interest Type",
      resizable: false,
      flex: 1,
    },
    {
      field: "fixed_installment",
      headerName: "IMI",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value).toLocaleString() : "0";
      },
    },
    {
      field: "variable_installment",
      headerName: "MIAFP",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value).toLocaleString() : "0";
      },
    },
    {
      field: "fixed_rate_perc",
      headerName: "IIR",
      resizable: false,
      flex: 1,
    },
    {
      field: "variable_rate_perc",
      headerName: "IRAFP",
      resizable: false,
      flex: 1,
    },
    {
      field: "admin_fee",
      headerName: "Admin Fee",
      resizable: false,
      flex: 1,
    },
    {
      field: "salary_transfer",
      headerName: "Salary Transfer",
      resizable: false,
      flex: 1,
    },
    {
      field: "life_insurance",
      headerName: "Life Insurance",
      resizable: false,
      flex: 1,
    },
    {
      field: "grade_energy_level",
      headerName: "Only A Energy",
      resizable: false,
      flex: 1,
    },
    {
      field: "first_owned_property",
      headerName: "Only First Owned",
      resizable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Repayment schedule",
      width: 150,
      renderCell: (params) => (
        <span
          className={classes.iconStyleEdit}
          onClick={() => handleEdit(params.row)}
        >
          <RemoveRedEyeIcon />
        </span>
      ),
    },
  ];
  const columnsOverall = [
    {
      field: "installment_no",
      headerName: "Month",
      resizable: false,
      flex: 1,
    },

    {
      field: "principal_amount",
      headerName: "Principal due before paying the installment",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value) : "0";
      },
    },
    {
      field: "total_paid",
      headerName: "Total",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value) : "0";
      },
    },
    {
      field: "interest_payment",
      headerName: "Interest",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value) : "0";
      },
    },
    {
      field: "principal_payment",
      headerName: "Rate of principal",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value) : "0";
      },
    },
    {
      field: "principal_amount_after",
      headerName: "Principal due after paying the installment",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value) : "0";
      },
    },
    {
      field: "administration_fee",
      headerName: "Administration fee",
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value) : "0";
      },
    },
  ];

  const rowsd = resultData?.result?.map((row, index) => ({
    ...row,
    id: row.ps_interest_type_id || index, // Use existing id or generate one
  }));
  const rowsOverall = viewComparerSingle?.result?.overall_detail?.map(
    (row, index) => ({
      ...row,
      id: row.installment_no || index, // Use existing id or generate one
    })
  );
  const handleGetBankInfo = async () => {
    setLoadingOffer(true);
    // Add your delete logic here
    try {
      // Replace with your API call, here is a mock example
      const res = await getIndicativeOffer(
        "comparer",
        resultData?.id,
        selectedIds,
        {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        }
      );
      if (res?.status == 200) {
        // toast.success(res?.data?.message);
        setLoadingOffer(false);
        const fileUrl = res?.data?.data?.full_path;
        setTimeout(() => {
          window.open(fileUrl, "_blank");
        }, 500);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingOffer(false);
  };
  return (
    <>
      <div className={classes.defaultForm}>
        <h3 className={classes.head}>
          <PublishedWithChangesIcon /> Quick Loan Comparer
        </h3>
        <Formik
          enableReinitialize
          initialValues={{
            currency: "",
            loan_purpose: "",
            interest_rate_id: "",
            property_value: "",
            down_payment_percentage: "",
            down_payment_value: "",
            first_property: false,
            energy_efficiecy_level: false,
            salary_account: false, // true->yes, false->no
            life_insurance: false, // true->yes, false->no
            provider_ids: "",
          }}
          validationSchema={ComparerSchema}
          onSubmit={async (values, { resetForm }) => {
            console.log("valuessss", values);
            setLoading(true);

            try {
              let res = await CreateCreditCompare(values, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });
              if (res?.status === 200) {
                setLoading(false);
                // toast.success(res?.data?.message);
                setResultData(res?.data.data);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }

              // Handle success response
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            touched,
            errors,
            handleSubmit,
          }) => {
            // Dynamically calculate down payment value
            const handlePropertyValueChange = (e) => {
              const propertyValue = parseFloat(e.target.value); // Get the new property value
              const downPaymentPercentage = parseFloat(
                values.down_payment_percentage
              );

              setFieldValue("property_value", e.target.value); // Update property_value field

              if (!isNaN(propertyValue) && !isNaN(downPaymentPercentage)) {
                const downPaymentValue =
                  (propertyValue * downPaymentPercentage) / 100;
                setFieldValue(
                  "down_payment_value",
                  downPaymentValue.toFixed(0)
                ); // Update down_payment_value
              } else {
                setFieldValue("down_payment_value", ""); // Reset down_payment_value
              }
            };

            const handleDownPaymentPercentageChange = (e) => {
              const downPaymentPercentage = parseFloat(e.target.value); // Get the new percentage
              const propertyValue = parseFloat(values.property_value);

              setFieldValue("down_payment_percentage", e.target.value); // Update down_payment_percentage field

              if (!isNaN(propertyValue) && !isNaN(downPaymentPercentage)) {
                const downPaymentValue =
                  (propertyValue * downPaymentPercentage) / 100;
                setFieldValue(
                  "down_payment_value",
                  downPaymentValue.toFixed(0)
                ); // Update down_payment_value
              } else {
                setFieldValue("down_payment_value", ""); // Reset down_payment_value
              }
            };

            const handleDownPaymentValueChange = (e) => {
              const downPaymentValue = parseFloat(e.target.value); // Get the new down payment value
              const propertyValue = parseFloat(values.property_value);

              setFieldValue("down_payment_value", e.target.value); // Update down_payment_value field

              if (!isNaN(propertyValue) && !isNaN(downPaymentValue)) {
                const downPaymentPercentage =
                  (downPaymentValue / propertyValue) * 100;
                setFieldValue(
                  "down_payment_percentage",
                  downPaymentPercentage.toFixed(2)
                ); // Update down_payment_percentage
              } else {
                setFieldValue("down_payment_percentage", ""); // Reset down_payment_percentage
              }
            };
            const handleCheckboxChangeNew = (e, arrayName) => {
              const { value, checked } = e.target;
              const parsedValue = isNaN(value) ? value : Number(value);

              // Ensure currentArray is always an array
              const currentArray = Array.isArray(values[arrayName])
                ? values[arrayName]
                : [];

              if (checked) {
                // Add value if checked
                const updatedArray = [...currentArray, parsedValue];
                setFieldValue(arrayName, updatedArray);
              } else {
                // Remove value if unchecked
                const updatedArray = currentArray.filter(
                  (item) => item !== parsedValue
                );
                setFieldValue(arrayName, updatedArray);
              }
            };
            return (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formMain}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <div className={classes.inputMain}>
                        <label>Loan Currency*</label>
                        <Field
                          as="select"
                          name="currency"
                          className={classes.inputField}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e); // Formik's default handler
                            setSelectedCurrency(e.target.value.toUpperCase()); // Update the state
                          }}
                        >
                          <option value="">Select</option>
                          {masterData?.productSetting?.currencies?.map(
                            (item) => (
                              <option key={item.slug} value={item.slug}>
                                {item.name}
                              </option>
                            )
                          )}
                        </Field>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="currency" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className={classes.inputMain}>
                        <label>Loan Purpose*</label>
                        <Field
                          as="select"
                          name="loan_purpose"
                          className={classes.inputField}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          {masterData?.productSetting?.loan_types?.map(
                            (item) => (
                              <option key={item.slug} value={item.slug}>
                                {item.name}
                              </option>
                            )
                          )}
                        </Field>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="loan_purpose" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className={classes.inputMain}>
                        <label>Interest Rate*</label>
                        <Field
                          as="select"
                          name="interest_rate_id"
                          className={classes.inputField}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          {masterData?.productSetting?.interest_rates?.map(
                            (item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            )
                          )}
                        </Field>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="interest_rate_id" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <CustomInput
                        label="Duration (years)"
                        type="text"
                        name="duration"
                        value={values.duration}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="No of years"
                        touched={touched.duration}
                        errors={errors.duration}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <CustomInput
                        label="The value of the property"
                        type="text"
                        name="property_value"
                        value={values.property_value}
                        onBlur={handleBlur}
                        onChange={handlePropertyValueChange} // Updated handler
                        placeholder="Enter value"
                        touched={touched.property_value}
                        errors={errors.property_value}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <CustomInput
                        label="Down Payment (%)"
                        type="text"
                        name="down_payment_percentage"
                        value={values.down_payment_percentage}
                        onBlur={handleBlur}
                        onChange={handleDownPaymentPercentageChange} // Updated handler
                        placeholder="Enter percentage"
                        touched={touched.down_payment_percentage}
                        errors={errors.down_payment_percentage}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <CustomInput
                        label="Down Payment (LEI)"
                        type="text"
                        name="down_payment_value"
                        value={values.down_payment_value}
                        onBlur={handleBlur}
                        onChange={handleDownPaymentValueChange} // Updated handler
                        placeholder="Calculated Value"
                        touched={touched.down_payment_value}
                        errors={errors.down_payment_value}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className={classes.addUserBtn}>
                        <Button type="submit" blue customWidth="170px">
                          {loading ? <Loader /> : <>Generate</>}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <br />
                      <p>Additional criteria:</p>
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            type="checkbox"
                            name="first_property"
                            checked={values.first_property}
                            value={values.first_property}
                            onChange={handleChange}
                          />
                        }
                        label={"First Owned property"}
                      />
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            type="checkbox"
                            name="energy_efficiecy_level"
                            checked={values.energy_efficiecy_level}
                            value={values.energy_efficiecy_level}
                            onChange={handleChange}
                          />
                        }
                        label={"A class Energy Efficency"}
                      />
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            type="checkbox"
                            name="salary_account"
                            checked={values.salary_account}
                            value={values.salary_account}
                            onChange={handleChange}
                          />
                        }
                        label={"Salary account"}
                      />
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            type="checkbox"
                            name="life_insurance"
                            checked={values.life_insurance}
                            value={values.life_insurance}
                            onChange={handleChange}
                          />
                        }
                        label={"Life insurance included"}
                      />
                      <p>Included the following Banks</p>
                      {masterData?.providersHasProduct?.map((item) => (
                        <FormControlLabel
                          key={item.id}
                          control={
                            <Field
                              as={Checkbox}
                              type="checkbox"
                              name="provider_ids" // Same name as Formik state array
                              value={item.id} // The id of the current item will be the checkbox value
                              checked={values?.provider_ids?.includes(item?.id)} // Check if the item is in the selected array
                              onChange={(e) =>
                                handleCheckboxChangeNew(e, "provider_ids")
                              } // Call the dynamic handler
                            />
                          }
                          label={item.name} // Display the name of the item
                        />
                      ))}
                    </Grid>
                  </Grid>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {resultData && (
        <div className={classes.defaultForm}>
          <h3 className={classes.head}>
            <RuleIcon /> Results
          </h3>
          <div>
            Loan amount {`(${resultData?.currency?.toUpperCase()})`}:{" "}
            {resultData?.loan_amount}
          </div>
          <div>LTV %: {resultData?.ltv}</div>
          <div className={classes.reduceHeight}></div>
          <DataTable rows={rowsd} columns={columns} paginationModel={false} />
          <div>
            <h5>Legend:</h5>
            <p>
              <b>IMI:</b> Initial monthly installment
            </p>
            <p>
              <b>MIAFP:</b> Monthly installment after the fixed period
            </p>
            <p>
              <b>IIR:</b> Initial interest rate
            </p>
            <p>
              <b>IRAFP:</b> Interest rate after fixed period
            </p>
          </div>
          <div className={classes.addUserBtn}>
            <Button onClick={handleGetBankInfo} customWidth="250px">
              {loadingOffer ? <Loader /> : <>Export</>}
            </Button>
          </div>
        </div>
      )}

      <BasicModal
        isOpen={openViewComparer}
        closeModal={handleViewComparerModalClose}
        customWidth="994px"
      >
        <DataTable
          rows={rowsOverall}
          columns={columnsOverall}
          paginationModel={false}
        />
      </BasicModal>
    </>
  );
});

export default CreditComparer;
