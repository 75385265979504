import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import classes from "./header.module.scss";
import "./headerBuiltin.scss";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../constants";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import logo from "../../assets/images/logo.png";
import bell from "../../assets/images/bell.svg";
import fork from "../../assets/images/fork.svg";
import boy from "../../assets/images/boy.svg";
import profileIcon from "../../assets/images/profileIcon.svg";
import man from "../../assets/images/man.svg";
import logoutIcon from "../../assets/images/logoutIcon.svg";
import { userLogout } from "../../api/auth";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { ToastContainer, toast } from "react-toastify";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  getUserProfileInfo,
  getAllNotification,
  getAllAppsSetting,
  updateNotification,
} from "../../api/misc";
import useGetAxios from "../../api/hooks/useGetAxios";
import Pusher from "pusher-js";
import FormatDateTime from "../shared/FormatMain/formatDate";
import BasicModal from "../shared/basicmodal";
import Button from "../shared/customBtn";
import FormatText from "../shared/FormatMain/formattext";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import InfoIcon from "@mui/icons-material/Info";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const Header = ({ handleArrow }) => {
  const userDataLocal = JSON.parse(localStorage.getItem("userData")) || []; // Check for auth token
  const userName = userDataLocal?.data?.profile?.name;
  const userID = userDataLocal?.data?.profile?.id;
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [menuDataAPI, setMenuDataAPI] = useState(userDataLocal?.data?.menu);
  const names = ["Foodie Gulberg", "Foodie Johar Town", "Foodie Askari 11"];
  const notificationsOld = [
    { text: "LOREAM IPSUM TEXT HERE", date: "7 August 2024 - 02:29 PM" },
    { text: "LOREAM IPSUM TEXT HERE", date: "7 August 2024 - 02:29 PM" },
    { text: "LOREAM IPSUM TEXT HERE", date: "7 August 2024 - 02:29 PM" },
    { text: "LOREAM IPSUM TEXT HERE", date: "7 August 2024 - 02:29 PM" },
  ];
  const [breadcrumbName, setBreadcrumbName] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      const name = localStorage.getItem("breadcrumbName");
      setBreadcrumbName(name || "");
    }, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);
  const [image, setImage] = useState();
  const {
    response: getProfileInfoDataTotal,
    loading: getProfileInfoLoading,
    error: getProfileInfoError,
  } = useGetAxios(getUserProfileInfo(), false);
  useEffect(() => {
    if (getProfileInfoDataTotal?.data?.profile?.profile_picture?.full_path) {
      setImage(
        getProfileInfoDataTotal?.data?.profile?.profile_picture?.full_path
      );
      const userNameImage =
        getProfileInfoDataTotal?.data?.profile?.profile_picture?.full_path;
      localStorage.setItem("userNameImage", JSON.stringify(userNameImage));
    }
  }, [getProfileInfoDataTotal]);
  useEffect(() => {
    const interval = setInterval(() => {
      const userimage = localStorage.getItem("userNameImage");
      const cleanImage = userimage?.replace(/['"]+/g, "");
      setImage(cleanImage);
    }, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      // Call the logout function with the token in the Authorization header

      const res = await userLogout(userID, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
        },
      });

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        setTimeout(() => {
          localStorage.removeItem("userData");
          localStorage.removeItem("authToken");
          localStorage.removeItem("userRole");
          localStorage.removeItem("allBranches");
          localStorage.removeItem("allUsers");
          localStorage.removeItem("careerLevels");
          localStorage.removeItem("FilterData");
          localStorage.removeItem("paginationPage");
          localStorage.removeItem("breadcrumbName");
          localStorage.removeItem("userNameImage");
          localStorage.removeItem("dashboradStatus");
          localStorage.removeItem("dashboradEditData");
          localStorage.removeItem("createApplication");

          navigate("/sign-in");
        }, 500);

        dispatch({
          type: LOGOUT,
        });
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      // toast.error(showErr);
    }
  };

  const [restaurantName, setRestaurantName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRestaurantName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [showDrop, setShowDrop] = useState(false);
  const [showBellDrop, setShowBellDrop] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  const handleDrop = () => {
    if (showBellDrop) {
      setShowBellDrop(false);
    }
    setShowDrop((prev) => !prev);
  };

  const handleBellDrop = () => {
    if (showDrop) {
      setShowDrop(false);
    }
    setShowBellDrop((prev) => !prev);
  };
  const handleShowAll = () => {
    setShowBellDrop(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleDrop();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  const hanldleProfile = () => {
    navigate("/settings");
    setShowDrop(false);
  };

  const checkPermission = (menuItemPermissions) => {
    // Ensure both permissions are valid arrays
    if (
      Array.isArray(menuItemPermissions) &&
      Array.isArray(userDataLocal?.data?.permissions)
    ) {
      // Check if any menuItemPermissions exist in userDataLocal permissions
      return menuItemPermissions.some((permission) =>
        userDataLocal.data.permissions.includes(permission)
      );
    }
    return false; // Return false if either is invalid or no match is found
  };

  // fetch noti
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [allAppsSettingData, setAllAppsSettingData] = useState([]);
  useEffect(() => {
    const getdataSettings = async () => {
      try {
        const res = await getAllAppsSetting({
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        });
        if (res?.status === 200) {
          setAllAppsSettingData(res.data.data.appSettings);
        }
      } catch (error) {
        setLoading(false);
        const showErr = error?.response?.data?.message;
      }
    };
    getdataSettings();
  }, []);

  const pusherAppKey = allAppsSettingData
    ?.find((item) => item?.slug === "notification")
    ?.list?.find((setting) => setting?.name === "pusher_app_key")?.value;

  const pusherAppCluster = allAppsSettingData
    ?.find((item) => item?.slug === "notification")
    ?.list?.find((setting) => setting?.name === "pusher_app_cluster")?.value;

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getAllNotification({
        headers: {
          lang: lang,
          Authorization: `Bearer ${tokenAD}`,
        },
      });

      if (response?.status === 200) {
        setLoading(false);
        setNotifications(response.data.data.data);
      } else {
        throw new Error(response?.data?.message || "An error occurred");
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data.message || "An error occurred during the process."
      );
    }
  };
  useEffect(() => {
    if (!pusherAppKey || !pusherAppCluster) return; // Ensure values exist

    fetchNotifications(); // Call API to fetch initial notifications

    // Initialize Pusher
    const pusher = new Pusher(pusherAppKey, {
      cluster: pusherAppCluster,
      encrypted: true,
    });

    // Subscribe to the notification channel
    const channel = pusher.subscribe("notification-channel");

    channel.bind(`notification-event-${userID}`, (newNotification) => {
      // toast.success("New Notification recieved");
      setNotifications((prev) => [
        // newNotification?.message,
        { ...newNotification?.message, is_read: 0 },
        ...prev,
      ]); // Add new notification to the list
    });

    // Cleanup on unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [pusherAppKey, pusherAppCluster]);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [singleNotification, setSingleNotification] = useState();
  const handleNotificationModalClose = () => {
    setOpenNotificationModal(false);
    setSingleNotification(null);
    setShowBellDrop(false);
    fetchNotifications();
  };
  const handleOpenNotiModel = async (row) => {
    // Add your edit logic here
    setOpenNotificationModal(true);
    setSingleNotification(row);
    try {
      const response = await updateNotification(row?.id, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (response?.status === 200) {
      } else {
        throw new Error(response?.data?.message || "An error occurred");
      }
    } catch (error) {
      toast.error(
        error.response.data.message || "An error occurred during the process."
      );
    }
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNotificationNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < notifications.length - 1 ? prevIndex + 1 : prevIndex
    );
  };
  const handleNotificationPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };
  return (
    <nav className={classes.headerNav}>
      <ToastContainer />
      <BasicModal
        isOpen={openNotificationModal}
        closeModal={handleNotificationModalClose}
        customWidth="994px"
      >
        {notifications?.length > 0 && (
          <div key={notifications[currentIndex]?.id}>
            <h3>{FormatText(notifications[currentIndex]?.type)}</h3>
            <hr />
            <p>{notifications[currentIndex]?.description}</p>
          </div>
        )}
        <div className={classes.addUserBtn}>
          <Button
            onClick={handleNotificationPrev}
            grey
            customWidth="70px"
            disabled={currentIndex === 0}
          >
            {`<`}
          </Button>
          <Button
            onClick={handleNotificationNext}
            grey
            customWidth="70px"
            disabled={currentIndex === notifications?.length - 1}
          >
            {`>`}
          </Button>
          <Button
            onClick={handleNotificationModalClose}
            outlineGrey
            customWidth="170px"
          >
            Close
          </Button>
        </div>
      </BasicModal>
      {/* <div className={classes.logo}>
        <img src={logo} alt="logo" />
        <div className={classes.arrow} onClick={handleArrow}>
          →
        </div>
      </div> */}
      <h3 className={classes.headText}>{breadcrumbName}</h3>
      <div className={classes.navLinks}>
        <div className={classes.belldrop}>
          {notifications?.filter((notification) => notification?.is_read === 0)
            .length == 0 ? (
            <div className={classes.notification}>
              <Link to="/allNotifications">
                <img src={bell} alt="bell" />
              </Link>
            </div>
          ) : (
            <div className={classes.notification} onClick={handleBellDrop}>
              <span>
                {
                  notifications
                    ?.filter((notification) => notification.is_read === 0) // Filter unread notifications
                    .slice(0, 5).length
                }
                {/* {notifications?.length} */}
              </span>{" "}
              <img src={bell} alt="bell" />
            </div>
          )}
          {showBellDrop && (
            <div className={classes.addBellDropInner} ref={dropdownRef2}>
              <ul>
                {notifications
                  ?.filter((notification) => notification?.is_read === 0) // Filter unread notifications
                  .slice(0, 5) // Get only the first 5 unread notifications
                  .map((notification, index) => (
                    <li
                      key={index}
                      onClick={() => handleOpenNotiModel(notification)}
                    >
                      {notification?.type == "commission_payment" ? (
                        <PriceCheckIcon />
                      ) : notification?.type == "general_info" ? (
                        <InfoIcon />
                      ) : notification?.type == "critical" ? (
                        <WarningAmberIcon />
                      ) : null}
                      {/* <img src={man} alt="" /> */}
                      <div className={classes.textContainer}>
                        <h4>{notification?.description}</h4>
                        <p>{FormatDateTime(notification?.updated_at)}</p>
                      </div>
                    </li>
                  ))}
                {/* {notifications?.map((notification, index) => (
                  <li
                    key={index}
                    onClick={() => handleOpenNotiModel(notification)}
                  >
                    <img src={man} alt="" />
                    <div className={classes.textContainer}>
                      <h4>{notification?.description}</h4>
                      <p>{FormatDateTime(notification?.updated_at)}</p>
                    </div>
                  </li>
                ))} */}
                <li onClick={handleShowAll}>
                  <Link to="/allNotifications">Show All</Link>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className={classes.user}>
          <Link to={`/profile`}>
            <img src={image ? image : boy} alt="" />
          </Link>
          {userName}
        </div>
        {menuDataAPI
          ?.filter((menuItem) => {
            // Check if the dropdown should be shown (if any sub-item is accessible)
            const hasPermission = checkPermission(menuItem?.permission);
            const hasAccessibleSubItem = menuItem?.items?.some((item) =>
              checkPermission(item?.permission)
            );

            // Return true only for menu items that should be rendered
            return (
              (hasPermission || hasAccessibleSubItem) &&
              menuItem?.dropdown &&
              !menuItem?.collapsable
            );
          })
          .map((menuItem, index) => (
            <div className={classes.logout} key={index}>
              <Link to={`/settings/users`}>
                <SettingsSuggestIcon />
              </Link>
            </div>
          ))}
        <div className={classes.logout}>
          <PowerSettingsNewIcon onClick={handleLogout} />
        </div>

        {/* <div className={`${classes.selectDropdownCust} mainBuiltinStyle`}>
          <div className={`${classes.top_drop}`}>
            <div className={classes.drop_container} onClick={handleDrop}>
              <div className={classes.desc1}>
                <h5>Hassan Malik</h5>
                <h6>Product Designer</h6>
              </div>
              <div className={classes.desc2}>
                <img src={boy} alt="Uploaded" />
              </div>
            </div>
            {showDrop && (
              <div className={classes.addNewDropInner} ref={dropdownRef}>
                <ul>
                  <li onClick={hanldleProfile}>
                    {" "}
                    <img src={profileIcon} alt="" /> Profile
                  </li>
                  <li onClick={handleLogout}>
                    {" "}
                    <img src={logoutIcon} alt="" /> Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </nav>
  );
};

export default Header;
