import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./basicModal.scss";
import { Typography } from "@mui/material";

const BasicModal = ({
  isOpen,
  closeModal,
  children,
  customClass,
  customWidth,
  headTitle,
  removePadi,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`modalContainer`}>
        <Box
          className={`modalboxMain ${customClass} ${
            removePadi ? "nopadding" : null
          }`}
          sx={customWidth && { maxWidth: { md: customWidth } }}
        >
          {/* <div className="modalTitle modalTitle-space">{headTitle}</div> */}
          <button className="closeButton" onClick={closeModal}>
            <CloseIcon size={24} />
          </button>

          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default BasicModal;
