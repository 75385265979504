import React, { useState, useEffect } from "react";
import classes from "../../../../assets/styles/partials/partial-listing.module.scss";
import style from "./addUsers.module.scss";
import {
  getAllUserDocument,
  deleteUserDocumentList,
  createUserDocumentList,
  getPreviewDoc,
} from "../../../../api/misc";
import DataTable from "../../../../components/shared/customTable";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Button from "../../../../components/shared/customBtn";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../../assets/images/edit_square.svg";
import DeleteModal from "../../../../components/shared/deleteModal";
import FormatDateTime from "../../../../components/shared/FormatMain/formatDate";
import BasicModal from "../../../../components/shared/basicmodal";
import CustomInput from "../../../../components/shared/customInput";
import Loader from "../../../../components/shared/loader";
import useGetAxios from "../../../../api/hooks/useGetAxios";
import { Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DocumentSchema = Yup.object().shape({
  document_type: Yup.string().required("Please enter document type"),
  document_name: Yup.string().required("Please enter document name"),
  issuing_date: Yup.string().required("Please select issuing date"),
  expiring_date: Yup.string().required("Please select expiring date"),
  document: Yup.string().required("Please select document"),
});

const AddDocuments = ({ editUser, handleCancel }) => {
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [loadingCreate, setLoadingCreate] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [count, setCount] = useState(0);
  const [documentLink, setDocumentLink] = useState();
  const [openUploadDocument, setOpenuploadDocument] = useState(false);
  const [openPreviewDocument, setOpenPreviewDocument] = useState(false);

  const handleBrochureModalClose = () => {
    setOpenuploadDocument(false);
  };
  const handlePreviewModalClose = () => {
    setOpenPreviewDocument(false);
  };
  const {
    response: getAllBrochureData,
    loading: getBrochureLoading,
    error: getBrochureError,
  } = useGetAxios(getAllUserDocument(editUser?.id), false, count);

  const handlePreview = async (row) => {
    setOpenPreviewDocument(true);
    try {
      // Replace with your API call, here is a mock example
      const res = await getPreviewDoc(row.id, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setDocumentLink(res?.data?.data);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
  };
  console.log("ffffff", documentLink);
  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteUserDocumentList(editUser?.id, deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handleAddNewProduct = () => {
    setOpenuploadDocument(true);
  };

  const columns = [
    {
      field: "document_type",
      headerName: "Document Type",
      flex: 1,
      resizable: false,
    },
    {
      field: "document_name",
      headerName: "Document Name",
      flex: 1,
      resizable: false,
    },
    {
      field: "issuing_date",
      headerName: "Issuing Date",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.issuing_date
            ? FormatDateTime(params?.row?.issuing_date)
            : ""}
        </>
      ),
    },
    {
      field: "expiring_date",
      headerName: "Expiring Date",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.expiring_date
            ? FormatDateTime(params?.row?.expiring_date)
            : ""}
        </>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handlePreview(params.row)}
          >
            <VisibilityIcon sx={{ color: "black" }} />
          </span>

          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </span>
        </>
      ),
    },
  ];
  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      // setPreview(URL.createObjectURL(file));
      setFieldValue("document", file);
    }
  };
  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />

      <div className={classes.addRow}>
        <Button
          customClass={classes.filterBtn}
          blue
          custborderRadius
          customWidth="153px"
          onClick={handleAddNewProduct}
        >
          Add New
        </Button>
        <div className={classes.addRowInner}></div>
      </div>
      <DataTable
        rows={getAllBrochureData?.data}
        columns={columns}
        loading={getBrochureLoading}
        paginationModel={false}
      />
      <div className={style.addUserBtn}>
        <Button onClick={handleCancel} outlineGrey customWidth="170px">
          Close
        </Button>
      </div>
      {deleteId && (
        <DeleteModal
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="Document"
          isLoading={loadingDelete}
        />
      )}
      <BasicModal
        isOpen={openPreviewDocument}
        closeModal={handlePreviewModalClose}
        customWidth="1400px"
        customClass="removePadi"
      >
        <iframe
          src={`${documentLink}#navpanes=0&scrollbar=0?timestamp=${Date.now()}`}
          width="100%"
          height="1000px"
          style={{ border: "none" }}
          title="PDF Viewer"
        />
        {/* <img src={documentLink} alt="" width="100%" /> */}
      </BasicModal>
      <BasicModal
        isOpen={openUploadDocument}
        closeModal={handleBrochureModalClose}
        customWidth="994px"
      >
        <div className={classes.defaultForm}>
          <div className={classes.modalHead}>
            <h3>Upload a User Document</h3>
            <hr />
          </div>

          <Formik
            initialValues={{
              document_type: "",
              document_name: "",
              issuing_date: "",
              expiring_date: "",
              document: "",
            }}
            validationSchema={DocumentSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log("values", values);
              setLoadingCreate(true);
              const formData = new FormData();
              formData.append("document_type", values.document_type);
              formData.append("document_name", values.document_name);
              formData.append("issuing_date", values.issuing_date);
              formData.append("expiring_date", values.expiring_date);
              formData.append("document", values.document);

              try {
                const res = await createUserDocumentList(
                  editUser?.id,
                  formData,
                  {
                    headers: {
                      lang: lang,
                      Authorization: `Bearer ${tokenAD}`,
                    },
                  }
                );
                // Handle success response
                if (res?.status === 200) {
                  setLoadingCreate(false);
                  toast.success(res?.data?.message);

                  setTimeout(() => {
                    setOpenuploadDocument(false);
                    setCount(count + 1);
                    resetForm({ values: "" }); // Reset form fields
                  }, 1000);
                } else {
                  throw new Error(res?.data?.message || "An error occurred");
                }
              } catch (error) {
                setLoading(false);
                toast.error(
                  error.response.data.message ||
                    "An error occurred during the process."
                );
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formMain}>
                  <Grid container spacing={2} xs={12} md={6} lg={6}>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className={`${style.inputMain}`}>
                        <label>Document Type</label>
                        <div className={style.inputFieldSelect}>
                          <Field
                            as="select"
                            name="document_type"
                            onBlur={handleBlur}
                            touched={touched.document_type}
                            errors={errors.document_type}
                            onChange={handleChange}
                            placeholder="Select document type"
                            className={`${style.inputField}`}
                          >
                            <option value="">Select document type</option>

                            {masterData?.userDocumentTypes?.map((item) => (
                              <option value={`${item?.slug}`}>
                                {item?.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={style.errorMsg}>
                          <ErrorMessage name="document_type" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <CustomInput
                        label="Document Name"
                        type="text"
                        name="document_name"
                        value={values.document_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter document name"
                        touched={touched.document_name}
                        errors={errors.document_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <CustomInput
                        label="Issuing Date"
                        type="date"
                        name="issuing_date"
                        value={values.issuing_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        touched={touched.issuing_date}
                        errors={errors.issuing_date}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <CustomInput
                        label="Expiration Date"
                        type="date"
                        name="expiring_date"
                        value={values.expiring_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        touched={touched.expiring_date}
                        errors={errors.expiring_date}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <input
                        id="document"
                        name="document"
                        type="file"
                        accept=".jpg,.jpeg,.pdf"
                        onChange={(event) =>
                          handleImageChange(event, setFieldValue)
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={style.addUserBtn}>
                  <Button
                    onClick={handleBrochureModalClose}
                    outlineGrey
                    customWidth="170px"
                  >
                    Close
                  </Button>
                  <Button type="submit" blue customWidth="170px">
                    {loadingCreate ? <Loader /> : <>Add Document</>}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </BasicModal>
    </div>
  );
};

export default AddDocuments;
