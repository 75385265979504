import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import Button from "../../../../components/shared/customBtn";
import DataTable from "../../../../components/shared/customTable";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../../assets/images/edit_square.svg";
import classes from "./addProducts.module.scss";
import BasicModal from "../../../../components/shared/basicmodal";
import Loader from "../../../../components/shared/loader";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import CustomInput from "../../../../components/shared/customInput";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";

const DocumentSchema = Yup.object().shape({
  energetic_level: Yup.string().required("Field Required"),
  down_payment: Yup.string().required("Field Required"),

  // bank_margin: Yup.string().required("Field Required"),
  // interest_rate: Yup.string().required("Field Required"),
});
const AddInterestRate = ({
  singleProductSetting,
  setAllInterestRate,
  allInterestRate,
  showInterest,
}) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const [openUploadDocument, setOpenuploadDocument] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [addType, setAddType] = useState();
  const [editType, setEditType] = useState();

  const handleBrochureModalClose = () => {
    setOpenuploadDocument(false);
    setAddType(null);
    setEditType(null);
  };
  const handleOpenInterestModal = (type) => {
    setOpenuploadDocument(true);
    setAddType(type);
  };
  console.log("addType", addType);
  const handleEdit = (row) => {
    setOpenuploadDocument(true);
    console.log("row", row);
    console.log("rowtpe", row?.type);
    setEditType(row);
    setAddType(row?.type);
  };

  const handleDelete = (id) => {
    setAllInterestRate((prevRates) =>
      prevRates.map((rate) =>
        rate.id === id ? { ...rate, is_deleted: "1" } : rate
      )
    );
  };

  useEffect(() => {
    const filteredRates = singleProductSetting?.ps_interest_types?.filter(
      (rate) => rate.is_deleted == "0"
    );
    setAllInterestRate(filteredRates);
  }, [singleProductSetting]);

  const columns = [
    {
      field: "energetic_level",
      headerName: "Energetic Level",
      flex: 1,
      resizable: false,
    },
    {
      field: "down_payment",
      headerName: "Down Payment(%)",
      resizable: false,
      flex: 1,
    },
    {
      field: "life_insurance",
      headerName: "Life Insurance",
      resizable: false,
      flex: 1,
      renderCell: (params) => (params?.row?.life_insurance == 1 ? "Yes" : "No"),
    },
    {
      field: "salary_transfer",
      headerName: "Salary acc.",
      resizable: false,
      flex: 1,
      renderCell: (params) =>
        params?.row?.salary_transfer == 1 ? "Yes" : "No",
    },
    {
      field: "bank_margin",
      headerName: "Bank Margin",
      resizable: false,
      flex: 1,
    },
    {
      field: "interest_rate",
      headerName: "Interest Rate",
      resizable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handleEdit(params.row)}
          >
            <img src={editSquare} alt="editIcon" />
          </span>

          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </span>
        </>
      ),
    },
  ];

  // Dynamically render tables based on types
  const renderTables = (type) => {
    // Conditionally skip rendering the fixed_period table if showInterest is false
    if (type === "fixed_period" && !showInterest) {
      return null;
    }

    const filteredColumns = columns.filter((column) => {
      if (type === "fixed_period" && column.field === "bank_margin")
        return false;
      if (type === "after_fixed_period" && column.field === "interest_rate")
        return false;
      return true;
    });

    const filteredData = allInterestRate?.filter(
      (item) => item?.type === type && item?.is_deleted == "0"
    );

    return (
      <div className={classes.interestTable} key={type}>
        <div className={classes.head}>
          <h4>
            {type == "fixed_period"
              ? `Interest rate in the fixed period`
              : `Interest rate after the fixed period`}
          </h4>
          <Button
            onClick={() => handleOpenInterestModal(type)}
            blue
            customWidth="150px"
          >
            Add
          </Button>
        </div>
        <div className={classes.table}>
          <DataTable rows={filteredData} columns={filteredColumns} />
        </div>
      </div>
    );
  };

  const validateDuplicate = (values) => {
    return allInterestRate?.some(
      (rate) =>
        rate?.energetic_level == values.energetic_level &&
        rate?.down_payment == values.down_payment &&
        rate?.interest_rate == values.interest_rate &&
        rate?.bank_margin == values.bank_margin &&
        rate?.life_insurance == values.life_insurance &&
        rate?.salary_transfer == values.salary_transfer &&
        rate?.type == addType
      //  &&
      // rate?.is_deleted === "0"
      // &&
      // (!editType || rate?.id !== editType?.id)
    );
  };
  return (
    <>
      {["fixed_period", "after_fixed_period"].map((type) => renderTables(type))}

      <BasicModal
        isOpen={openUploadDocument}
        closeModal={handleBrochureModalClose}
        customWidth="994px"
      >
        <div className={classes.defaultForm}>
          <div className={classes.modalHead}>
            <h3>{editType ? "Edit Interest Rate" : "Add Interest Rate"}</h3>
            <hr />
          </div>

          <Formik
            initialValues={{
              energetic_level: editType ? editType.energetic_level : "",
              down_payment: editType ? editType.down_payment : "",
              life_insurance: editType?.life_insurance == 1 ? true : false,
              salary_transfer: editType?.salary_transfer == 1 ? true : false,
              bank_margin: editType ? editType.bank_margin : null,
              interest_rate: editType ? editType.interest_rate : null,
              is_deleted: "0",
            }}
            validationSchema={DocumentSchema}
            onSubmit={async (values, { resetForm }) => {
              if (validateDuplicate(values)) {
                toast.error("Duplicate entry! Please check the values.");
                return;
              }
              const updatedData = {
                ...values,
                id: editType ? editType.id : Date.now(), // Use existing id or generate a new unique id
                type: editType ? editType.type : addType,
                life_insurance: values.life_insurance ? 1 : 0,
                salary_transfer: values.salary_transfer ? 1 : 0,
              };

              if (editType) {
                // Edit logic: update the existing entry
                setAllInterestRate((prevRates) =>
                  Array.isArray(prevRates)
                    ? prevRates.map((rate) =>
                        rate?.id === editType?.id ? updatedData : rate
                      )
                    : []
                );
              } else {
                // Add logic: append a new entry
                setAllInterestRate((prevRates) =>
                  Array.isArray(prevRates)
                    ? [...prevRates, updatedData]
                    : [updatedData]
                );
              }

              resetForm(); // Reset the form after submission
              handleBrochureModalClose(); // Close the modal
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => {
              const handleBlurDecimal = (e, fieldName) => {
                const value = e.target.value;

                // Ensure the value has two decimal points
                const formattedValue = parseFloat(value).toFixed(2);

                // Update the formik state
                setFieldValue(fieldName, formattedValue);
              };
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={classes.formMain}>
                    <Grid container spacing={2} xs={12} md={12} lg={12}>
                      <Grid item xs={12} md={4} lg={4}>
                        <div className={`${classes.inputMain}`}>
                          <label>Energetic Level</label>
                          <div
                            role="group"
                            aria-labelledby={`energetic_level-label`}
                            className={`${classes.radioBTN}`}
                          >
                            {masterData?.productSetting?.energetic_levels?.map(
                              (item) => (
                                <label>
                                  <input
                                    type="radio"
                                    name="energetic_level"
                                    value={item}
                                    checked={values?.energetic_level?.includes(
                                      item
                                    )}
                                    onChange={handleChange}
                                  />
                                  {item}
                                </label>
                              )
                            )}
                          </div>
                          {/* <div className={classes.inputFieldSelect}>
                            <Field
                              as="select"
                              name="energetic_level"
                              onBlur={handleBlur}
                              touched={touched.energetic_level}
                              errors={errors.energetic_level}
                              onChange={handleChange}
                              className={`${classes.inputField}`}
                            >
                              <option value="">Select energetic level</option>

                              {masterData?.productSetting?.energetic_levels?.map(
                                (item) => (
                                  <option value={`${item}`}>{item}</option>
                                )
                              )}
                            </Field>
                            
                          </div> */}
                          <div className={classes.errorMsg}>
                            <ErrorMessage name="energetic_level" />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <div className={`${classes.inputMain}`}>
                          <label>Down Payment</label>
                          <div
                            role="group"
                            aria-labelledby={`down_payment-label`}
                            className={`${classes.radioBTN}`}
                          >
                            {masterData?.productSetting?.down_payment_slabs?.map(
                              (item) => (
                                <label>
                                  <input
                                    type="radio"
                                    name="down_payment"
                                    value={item}
                                    checked={values?.down_payment?.includes(
                                      item
                                    )}
                                    onChange={handleChange}
                                  />
                                  {item}
                                </label>
                              )
                            )}
                          </div>
                          {/* <div className={classes.inputFieldSelect}>
                            <Field
                              as="select"
                              name="down_payment"
                              onBlur={handleBlur}
                              touched={touched.down_payment}
                              errors={errors.down_payment}
                              onChange={handleChange}
                              className={`${classes.inputField}`}
                            >
                              <option value="">Select down payment</option>

                              {masterData?.productSetting?.down_payment_slabs?.map(
                                (item) => (
                                  <option value={`${item}`}>{item}</option>
                                )
                              )}
                            </Field>
                          </div> */}
                          <div className={classes.errorMsg}>
                            <ErrorMessage name="down_payment" />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        {/* <CustomInput
                        label="Mandatory Life Insurance Included"
                        type="checkbox"
                        name="life_insurance"
                        value={values.life_insurance}
                      /> */}
                        <FormControlLabel
                          control={
                            <Field name="life_insurance">
                              {({ field, form }) => (
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={handleChange}
                                />
                              )}
                            </Field>
                          }
                          label="Mandatory Life Insurance Included"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                          control={
                            <Field name="salary_transfer">
                              {({ field, form }) => (
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={handleChange}
                                />
                              )}
                            </Field>
                          }
                          label="Mandatory Salary transfer into the bank account"
                        />
                      </Grid>
                      {addType == "after_fixed_period" && (
                        <Grid item xs={12} md={6} lg={6}>
                          <CustomInput
                            label="Bank Margin over IRCC(%)"
                            type="number"
                            name="bank_margin"
                            value={values.bank_margin}
                            onBlur={(e) => handleBlurDecimal(e, "bank_margin")}
                            onChange={handleChange}
                            placeholder="%"
                            touched={touched.bank_margin}
                            errors={errors.bank_margin}
                          />
                        </Grid>
                      )}
                      {addType == "fixed_period" && (
                        <Grid item xs={12} md={6} lg={6}>
                          <CustomInput
                            label="Interest Rate(%)"
                            type="number"
                            name="interest_rate"
                            value={values.interest_rate}
                            onBlur={(e) =>
                              handleBlurDecimal(e, "interest_rate")
                            }
                            onChange={handleChange}
                            placeholder="%"
                            touched={touched.interest_rate}
                            errors={errors.interest_rate}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  <div className={classes.addProductBtn}>
                    <Button
                      onClick={handleBrochureModalClose}
                      outlineGrey
                      customWidth="170px"
                    >
                      Close
                    </Button>
                    <Button type="submit" blue customWidth="170px">
                      {loadingCreate ? <Loader /> : <>Add</>}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </BasicModal>
    </>
  );
};

export default AddInterestRate;
