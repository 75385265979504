import React, { useState, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImageHelper"; // Helper to crop the image
import { v4 as uuidv4 } from "uuid";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import CustomInput from "../../../../components/shared/customInput";
import Button from "../../../../components/shared/customBtn";
import {
  getAllCommission,
  createCommission,
  editSingleCommission,
  deleteCommission,
} from "../../../../api/misc";
import { useSelector } from "react-redux";
import classes from "./addProviders.module.scss";
import "./addProvidersBuiltin.scss";
import useGetAxios from "../../../../api/hooks/useGetAxios";
import BasicModal from "../../../../components/shared/basicmodal";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../../assets/images/edit_square.svg";
import DataTable from "../../../../components/shared/customTable";

const CommissionSchema = Yup.object().shape({
  name: Yup.string().required("Please enter Commission name"),
  Commission_type_id: Yup.string().required("Please select Commission type"),
  image: Yup.mixed().required("Please upload a logo"),
});

const AddCommission = ({
  editProvider,
  handleCancel,
  setEditProvider,
  allstatusVal,
}) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [openUploadDocument, setOpenuploadDocument] = useState(false);
  const [statusCommission, setStatusCommission] = useState();
  const [eidtCommission, setEidtCommission] = useState();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStatusCommission("lead");
  }, []);

  const handleBrochureModalClose = () => {
    setOpenuploadDocument(false);
    setEidtCommission(null);
  };

  const handleOpenInterestModal = () => {
    setOpenuploadDocument(true);
    setStatusCommission(statusCommission);
  };

  const handleEdit = (row) => {
    setOpenuploadDocument(true);
    setEidtCommission(row);
  };

  const handleDelete = async (id) => {
    console.log("del", id);
    try {
      let res = await deleteCommission(id, {
        headers: {
          lang: lang,
          Authorization: `Bearer ${tokenAD}`,
        },
      });

      // Handle success response
      if (res?.status == 200) {
        toast.success(res?.data?.message);

        setTimeout(() => {
          setCount(count + 1);
        }, 1000);
      } else {
        throw new Error(res?.data?.message || "An error occurred");
      }
    } catch (error) {
      toast.error(
        error.response.data.message || "An error occurred during the process."
      );
    }
  };

  const {
    response: getCommissionData,
    loading: getCommissionLoading,
    error: getCommissionError,
  } = useGetAxios(getAllCommission(editProvider?.id), false, count);
  // Filter rows based on the `statusCommission` value
  const filteredRows = getCommissionData?.data?.filter(
    (row) => row.status == statusCommission
  );

  // Define columns dynamically based on `statusCommission`
  const columns =
    statusCommission == "lead"
      ? [
          {
            field: "fixed_price",
            headerName: "Fixed Price / Lead (Lei)",
            resizable: false,
            flex: 1,
          },
          {
            field: "start_date",
            headerName: "Start Date",
            resizable: false,
            flex: 1,
          },
          {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params) => (
              <>
                <span
                  className={classes.iconStyleEdit}
                  onClick={() => handleEdit(params.row)}
                >
                  <img src={editSquare} alt="editIcon" />
                </span>
                <span
                  className={classes.iconStyleDel}
                  onClick={() => handleDelete(params.row.id)}
                >
                  <DeleteIcon />
                </span>
              </>
            ),
          },
        ]
      : [
          {
            field: "volume_min",
            headerName: "Volume (Lei)",
            resizable: false,
            flex: 1,
            renderCell: (params) => (
              <>
                {params?.row?.volume_min} - {params?.row?.volume_max}
              </>
            ),
          },
          {
            field: "commission",
            headerName: "Commission %",
            resizable: false,
            flex: 1,
          },
          {
            field: "start_date",
            headerName: "Start Date",
            resizable: false,
            flex: 1,
          },
          {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params) => (
              <>
                <span
                  className={classes.iconStyleEdit}
                  onClick={() => handleEdit(params.row)}
                >
                  <img src={editSquare} alt="editIcon" />
                </span>
                <span
                  className={classes.iconStyleDel}
                  onClick={() => handleDelete(params.row.id)}
                >
                  <DeleteIcon />
                </span>
              </>
            ),
          },
        ];
  console.log("getCommissionData", getCommissionData?.data);
  console.log("eidtCommission", eidtCommission);
  console.log("editProvider", editProvider);
  console.log("statusCommission", statusCommission);
  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <div className={classes.dynamicMain}>
          <label className={classes.labelName}>Commission Type</label>
          <div role="group" aria-labelledby={`commissionType-label`}>
            <label>
              <input
                type="radio"
                name="commission_type"
                value="lead"
                checked={statusCommission == "lead"}
                onChange={(e) => setStatusCommission(e.target.value)}
              />
              Lead
            </label>
            <label>
              <input
                type="radio"
                name="commission_type"
                value="full"
                checked={statusCommission == "full"}
                onChange={(e) => setStatusCommission(e.target.value)}
              />
              Full
            </label>
          </div>
        </div>
        <div className={classes.interestTable}>
          <div className={classes.head}>
            <h4>Commission Structure</h4>
            <Button
              onClick={() => handleOpenInterestModal()}
              blue
              customWidth="70px"
            >
              +
            </Button>
          </div>
          <div className={classes.table}>
            <DataTable
              rows={filteredRows}
              columns={columns}
              loading={getCommissionLoading}
            />
          </div>
        </div>
        <BasicModal
          isOpen={openUploadDocument}
          closeModal={handleBrochureModalClose}
          customWidth="994px"
        >
          <div className={classes.defaultForm}>
            <div className={classes.modalHead}>
              <h3>{eidtCommission ? "Edit Commission" : "Add Commission"}</h3>
              <hr />
            </div>

            <Formik
              initialValues={{
                fixed_price: eidtCommission ? eidtCommission?.fixed_price : "",
                volume_min: eidtCommission ? eidtCommission?.volume_min : "",
                volume_max: eidtCommission ? eidtCommission?.volume_max : "",
                commission: eidtCommission ? eidtCommission?.commission : "",
                start_date: eidtCommission ? eidtCommission?.start_date : "",
              }}
              // validationSchema={DocumentSchema}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);

                const updatedData = {
                  ...values,
                  provider_id: editProvider?.id,
                  status: statusCommission, // lead, full
                  fixed_price: values.fixed_price || null,
                  volume_min: values.volume_min || null,
                  volume_max: values.volume_max || null,
                  commission: values.commission || null,
                  start_date: values.start_date || null,
                };
                try {
                  let res;
                  eidtCommission
                    ? (res = await editSingleCommission(
                        eidtCommission?.id,
                        updatedData,
                        {
                          headers: {
                            lang: lang,
                            Authorization: `Bearer ${tokenAD}`,
                          },
                        }
                      ))
                    : (res = await createCommission(updatedData, {
                        headers: {
                          lang: lang,
                          Authorization: `Bearer ${tokenAD}`,
                        },
                      }));

                  // Handle success response
                  if (res?.status == 200) {
                    setLoading(false);
                    toast.success(res?.data?.message);

                    setTimeout(() => {
                      setOpenuploadDocument(false);
                      setCount(count + 1);
                      setEidtCommission(null);
                      resetForm({ values: "" }); // Reset form fields
                    }, 1000);
                  } else {
                    throw new Error(res?.data?.message || "An error occurred");
                  }
                } catch (error) {
                  setLoading(false);
                  toast.error(
                    error.response.data.message ||
                      "An error occurred during the process."
                  );
                }
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={classes.formMain}>
                    <Grid container spacing={2} xs={12} md={12} lg={12}>
                      {statusCommission == "full" && (
                        <>
                          <Grid item xs={12} md={12} lg={12}>
                            Volume Range (Lei)
                          </Grid>
                          <Grid item xs={12} md={3} lg={3}>
                            <CustomInput
                              label="Start"
                              type="text"
                              name="volume_min"
                              value={values.volume_min}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                              touched={touched.volume_min}
                              errors={errors.volume_min}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} lg={3}>
                            <CustomInput
                              label="End"
                              type="text"
                              name="volume_max"
                              value={values.volume_max}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                              touched={touched.volume_max}
                              errors={errors.volume_max}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <CustomInput
                              label="Commission %"
                              type="text"
                              name="commission"
                              value={values.commission}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder=""
                              touched={touched.commission}
                              errors={errors.commission}
                            />
                          </Grid>
                        </>
                      )}
                      {statusCommission == "lead" && (
                        <Grid item xs={12} md={3} lg={3}>
                          <CustomInput
                            label="Price / Lead (Lei)"
                            type="text"
                            name="fixed_price"
                            value={values.fixed_price}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder=""
                            touched={touched.fixed_price}
                            errors={errors.fixed_price}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={3} lg={3}>
                        <CustomInput
                          label="Start Date"
                          type="date"
                          name="start_date"
                          value={values.start_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder=""
                          touched={touched.start_date}
                          errors={errors.start_date}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.addProductBtn}>
                    <Button
                      onClick={handleBrochureModalClose}
                      outlineGrey
                      customWidth="170px"
                    >
                      Close
                    </Button>
                    <Button type="submit" blue customWidth="170px">
                      {loading ? <Loader /> : <>Save</>}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </BasicModal>
      </div>
    </>
  );
};

export default AddCommission;
