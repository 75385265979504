import React, { useState, useEffect, memo, useCallback } from "react";
import { Formik, Form } from "formik";
import classes from "./addloan.module.scss";
import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import { Typography } from "@mui/material";
import {
  getAllCities,
  createClient,
  createLoan,
  getClientByPersonalId,
  updateClient,
} from "../../../api/misc";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const validateCNP = (p_cnp) => {
  let i = 0,
    year = 0,
    hashResult = 0,
    cnp = [];
  const hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];

  if (p_cnp.length !== 13) return false;

  for (i = 0; i < 13; i++) {
    cnp[i] = parseInt(p_cnp.charAt(i), 10);
    if (isNaN(cnp[i])) return false;
    if (i < 12) hashResult += cnp[i] * hashTable[i];
  }

  hashResult %= 11;
  if (hashResult === 10) hashResult = 1;

  year = cnp[1] * 10 + cnp[2];
  switch (cnp[0]) {
    case 1:
    case 2:
      year += 1900;
      break;
    case 3:
    case 4:
      year += 1800;
      break;
    case 5:
    case 6:
      year += 2000;
      break;
    case 7:
    case 8:
    case 9:
      year += 2000;
      if (year > parseInt(new Date().getFullYear(), 10) - 14) year -= 100;
      break;
    default:
      return false;
  }

  if (year < 1800 || year > 2099) return false;
  return cnp[12] === hashResult;
};

const phoneRegExp = /^\+\d{1,3}\d{9}$/;
const ClientSchema = Yup.object().shape({
  personal_id: Yup.string()
    .required("Please enter personal id")
    .min(13, "Personal id must be atleast 13 digit long")
    .max(13, "Personal id must be not greater than 13 digit")
    .test("is-valid-cnp", "Invalid Personal ID", (value) => validateCNP(value)),
  first_name: Yup.string().required("Please enter first_name"),
  last_name: Yup.string().required("Please enter last_name"),
  county: Yup.string().required("Please select county"),
  city: Yup.string().required("Please select city"),
  marital_status: Yup.string().required("Please select marital status"),
  age: Yup.string().required("Please enter age"),
  sex: Yup.string().required("Please select sex"),

  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    ),
  phone: Yup.string()
    .matches(
      phoneRegExp,
      "Not valid. Expected format: +00123456789 or +000{9 numbers}"
    )
    .required("Phone number is required"),
  // is_company: Yup.boolean(),
  // company_name: Yup.string().when("is_company", {
  //   is: true, // When is_company is true
  //   then: Yup.string().required("Company Name is required"), // Make company_name required
  //   otherwise: Yup.string(), // Otherwise it's not required
  // }),
  // company_id: Yup.string().when("is_company", {
  //   is: true, // When is_company is true
  //   then: Yup.string().required("Company ID is required"), // Make company_id required
  //   otherwise: Yup.string(), // Otherwise it's not required
  // }),
});

const AddLoan = memo(
  ({
    clientData,
    handleCancel,
    setClientData,
    editExistingclient,
    setEditExistingclient,
    allstatusVal,
    setAllstatusVal,
  }) => {
    const { t } = useTranslation();
    const masterData = useSelector((state) => state?.masterData?.masterData);

    const lang = localStorage.getItem("lang");
    const tokenAD = JSON.parse(localStorage.getItem("authToken"));
    const [loading, setLoading] = useState(false);
    const [clientloading, setClientLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [loadingCities, setLoadingCities] = useState(false);
    const [personalId, setPersonalId] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [firstDigit, setFirstDigit] = useState(null);
    const [personAge, setPersonAge] = useState(null);
    useEffect(() => {
      if (clientData?.personal_id || editExistingclient?.client?.personal_id) {
        // Extract the first digit
        const firstDigitValue =
          parseInt(clientData?.personal_id.charAt(0), 10) ||
          parseInt(editExistingclient?.client?.personal_id.charAt(0), 10);
        setFirstDigit(firstDigitValue);

        // Extract birthdate (next 6 digits)
        const birthDatePart =
          clientData?.personal_id.slice(1, 7) ||
          editExistingclient?.client?.personal_id.slice(1, 7); // "931214"
        const birthYear = parseInt(birthDatePart.slice(0, 2), 10);
        const birthMonth = parseInt(birthDatePart.slice(2, 4), 10);
        const birthDay = parseInt(birthDatePart.slice(4, 6), 10);

        // Determine the full year (1900s or 2000s)
        const fullBirthYear =
          birthYear > 22 ? 1900 + birthYear : 2000 + birthYear;

        // Calculate the age
        const today = new Date();
        const birthDate = new Date(fullBirthYear, birthMonth - 1, birthDay);
        let age = today.getFullYear() - birthDate.getFullYear();
        const isBirthdayPassed =
          today.getMonth() > birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() &&
            today.getDate() >= birthDate.getDate());
        if (!isBirthdayPassed) {
          age--;
        }
        setPersonAge(age);
      }
    }, [clientData?.personal_id, editExistingclient?.client?.personal_id]);

    const handleApiCall = async (id) => {
      if (validateCNP(id)) {
        // setPersonalId(id);
        setClientLoading(true);
        try {
          const response = await getClientByPersonalId(id, {
            headers: {
              lang: lang,
              Authorization: `Bearer ${tokenAD}`,
            },
          });

          if (response.status === 200) {
            setClientData(response.data.data);
          }
        } catch (error) {
          if (error.status == 403) {
            toast.error(
              "Client not found; keep the personal ID as is and allow creation of new client"
            );
            setClientLoading(false);
            setClientData({ personal_id: id });
          }
        }
      }
    };
    console.log("clientdata", clientData);
    useEffect(() => {
      if (personalId?.length === 13) {
        handleApiCall(personalId);
        setIsDisabled(false); // Enable inputs if personal_id has 13 digits
      } else {
        setIsDisabled(true); // Disable inputs otherwise
      }
    }, [personalId]);
    useEffect(() => {
      if (clientData?.personal_id?.length === 13 || editExistingclient) {
        setIsDisabled(false); // Enable inputs if personal_id has 13 digits
      } else {
        setIsDisabled(true); // Disable inputs otherwise
      }
    }, [clientData?.personal_id]);

    const fetchCities = async (selectedCountyCode) => {
      if (selectedCountyCode) {
        setLoadingCities(true);
        try {
          const response = await getAllCities(selectedCountyCode, {
            headers: {
              lang: lang,
              Authorization: `Bearer ${tokenAD}`,
            },
          });
          setCities(response.data.data.cities);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
        setLoadingCities(false);
      }
    };

    useEffect(() => {
      if (clientData?.address?.county) {
        fetchCities(clientData?.address?.county); // Fetch cities for the default county
      }
    }, [clientData?.address?.county]);
    // for edit
    useEffect(() => {
      if (editExistingclient?.client?.address?.county) {
        fetchCities(editExistingclient?.client?.address?.county); // Fetch cities for the default county
      }
    }, [editExistingclient?.client?.address?.county]);

    const getPhone = clientData?.contacts
      ?.filter((perm) => perm?.contact_type === "phone") // Filter for emails only
      ?.map((perm) => perm?.value);
    const getEmail = clientData?.contacts
      ?.filter((perm) => perm?.contact_type === "email") // Filter for emails only
      ?.map((perm) => perm?.value);
    // for edit
    const getPhoneEdit = editExistingclient?.client?.contacts
      ?.filter((perm) => perm?.contact_type === "phone") // Filter for emails only
      ?.map((perm) => perm?.value);
    const getEmailEdit = editExistingclient?.client?.contacts
      ?.filter((perm) => perm?.contact_type === "email") // Filter for emails only
      ?.map((perm) => perm?.value);

    console.log("editExistingclient", editExistingclient);
    return (
      <>
        <ToastContainer />

        <div className={classes.defaultForm}>
          <Formik
            enableReinitialize
            initialValues={
              editExistingclient
                ? {
                    personal_id: editExistingclient?.client?.personal_id || "",
                    card_id_sn:
                      editExistingclient?.client?.address?.card_id_sn || "",
                    card_id_no:
                      editExistingclient?.client?.address?.card_id_no || "",
                    first_name: editExistingclient?.client?.first_name || "",
                    last_name: editExistingclient?.client?.last_name || "",
                    email: getEmailEdit ? getEmailEdit[0] : "",
                    phone: getPhoneEdit ? getPhoneEdit[0] : "",
                    county: editExistingclient?.client?.address?.county || "",
                    city: editExistingclient?.client?.address?.city || "",
                    street: editExistingclient?.client?.address?.street || "",
                    no: editExistingclient?.client?.address?.no || "",
                    flat: editExistingclient?.client?.address?.flat || "",
                    floor: editExistingclient?.client?.address?.floor || "",
                    sc: editExistingclient?.client?.address?.sc || "",
                    ap: editExistingclient?.client?.address?.ap || "",
                    zip_code:
                      editExistingclient?.client?.address?.zip_code || "",
                    marital_status:
                      editExistingclient?.client?.marital_status || "",
                    education_level_id:
                      editExistingclient?.client?.education_level_id || "",
                    age:
                      editExistingclient?.client?.age ||
                      (personAge && personAge) ||
                      "",
                    sex:
                      editExistingclient?.client?.sex || firstDigit == 1
                        ? "male"
                        : "female" || "",
                  }
                : {
                    personal_id: clientData ? clientData?.personal_id : "",
                    card_id_sn: clientData?.address?.card_id_sn || "",
                    card_id_no: clientData?.address?.card_id_no || "",
                    first_name: clientData?.first_name || "",
                    last_name: clientData?.last_name || "",
                    email: getEmail ? getEmail[0] : "",
                    phone: getPhone ? getPhone[0] : "",
                    county: clientData?.address?.county || "",
                    city: clientData?.address?.city || "",
                    street: clientData?.address?.street || "",
                    no: clientData?.address?.no || "",
                    flat: clientData?.address?.flat || "",
                    floor: clientData?.address?.floor || "",
                    sc: clientData?.address?.sc || "",
                    ap: clientData?.address?.ap || "",
                    zip_code: clientData?.address?.zip_code || "",
                    marital_status: clientData?.marital_status || "",
                    age: (personAge && personAge) || "",
                    sex: firstDigit == 1 ? "male" : "female" || "",
                    education_level_id: clientData?.education_level_id || "",
                  }
            }
            validationSchema={ClientSchema}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true);

              const clientExist = {
                personal_id: values.personal_id,
              };

              try {
                let res;

                if (editExistingclient) {
                  res = await updateClient(
                    editExistingclient?.client_id,
                    values,
                    {
                      headers: {
                        lang: lang,
                        Authorization: `Bearer ${tokenAD}`,
                      },
                    }
                  );
                  if (res?.status === 200) {
                    setLoading(false);
                    toast.success(res?.data?.message);
                    setTimeout(() => {
                      // setClientData(res?.data?.data);
                    }, 1000);
                  } else {
                    throw new Error(res?.data?.message || "An error occurred");
                  }
                } else if (clientData?.id) {
                  res = await updateClient(clientData?.id, values, {
                    headers: {
                      lang: lang,
                      Authorization: `Bearer ${tokenAD}`,
                    },
                  });
                  if (res?.status === 200) {
                    setLoading(false);
                    toast.success(res?.data?.message);
                    setTimeout(async () => {
                      setClientData(res?.data?.data);
                      const clientExist = {
                        personal_id: res?.data?.data?.personal_id,
                        status: allstatusVal ? allstatusVal : "gdpr-sent",
                        // company_name: "Company Name",
                        // company_address: "sdfs",
                        // company_phone: "23423",
                        // company_vat: "23234",
                        // company_registration_code: "sdfsf",
                      };
                      const resLoanReq = await createLoan(clientExist, {
                        headers: {
                          lang: lang,
                          Authorization: `Bearer ${tokenAD}`,
                        },
                      });
                      if (resLoanReq?.status === 200) {
                        setLoading(false);
                        toast.success(resLoanReq?.data?.message);
                        setEditExistingclient(resLoanReq?.data?.data);
                        setAllstatusVal(resLoanReq?.data?.data?.status_slug);
                      } else {
                        throw new Error(
                          resLoanReq?.data?.message || "An error occurred"
                        );
                      }
                    }, 1000);
                  } else {
                    throw new Error(res?.data?.message || "An error occurred");
                  }
                } else {
                  res = await createClient(values, {
                    headers: {
                      lang: lang,
                      Authorization: `Bearer ${tokenAD}`,
                    },
                  });
                  if (res?.status === 200) {
                    setLoading(false);
                    toast.success(res?.data?.message);
                    setTimeout(async () => {
                      setClientData(res?.data?.data);
                      const clientExist = {
                        personal_id: res?.data?.data?.personal_id,
                        status: allstatusVal ? allstatusVal : "gdpr-sent",
                        company_name: "Company Name",
                        company_address: "sdfs",
                        company_phone: "23423",
                        company_vat: "23234",
                        company_registration_code: "sdfsf",
                      };
                      const resLoanReq = await createLoan(clientExist, {
                        headers: {
                          lang: lang,
                          Authorization: `Bearer ${tokenAD}`,
                        },
                      });
                      if (resLoanReq?.status === 200) {
                        setLoading(false);
                        toast.success(resLoanReq?.data?.message);
                        setEditExistingclient(resLoanReq?.data?.data);
                        setAllstatusVal(resLoanReq?.data?.data?.status_slug);
                      } else {
                        throw new Error(
                          resLoanReq?.data?.message || "An error occurred"
                        );
                      }
                    }, 1000);
                  } else {
                    throw new Error(res?.data?.message || "An error occurred");
                  }
                }

                // Handle success response
              } catch (error) {
                setLoading(false);
                toast.error(
                  error.response.data.message ||
                    "An error occurred during the process."
                );
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formMain}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <CustomInput
                        label="Personal ID (CNP)*"
                        type="text"
                        name="personal_id"
                        value={values.personal_id}
                        onBlur={handleBlur}
                        disabled={editExistingclient}
                        onChange={(e) => {
                          handleChange(e); // Update Formik state
                          setPersonalId(e.target.value); // Update personal ID state
                        }}
                        placeholder="Enter personal id"
                        touched={touched.personal_id}
                        errors={errors.personal_id}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <CustomInput
                        label={t("loan.cardSN")}
                        type="text"
                        name="card_id_sn"
                        value={values.card_id_sn}
                        onBlur={handleBlur}
                        disabled={isDisabled}
                        onChange={handleChange}
                        placeholder="SX"
                        touched={touched.card_id_sn}
                        errors={errors.card_id_sn}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <CustomInput
                        label={t("loan.idCard")}
                        type="text"
                        name="card_id_no"
                        value={values.card_id_no}
                        onBlur={handleBlur}
                        disabled={isDisabled}
                        onChange={handleChange}
                        placeholder="SX"
                        touched={touched.card_id_no}
                        errors={errors.card_id_no}
                      />
                    </Grid>
                    {clientloading ? (
                      <Loader />
                    ) : (
                      <>
                        <Grid item xs={12} md={6} lg={6}>
                          <CustomInput
                            label={t("loan.firstName") + "*"}
                            type="text"
                            name="first_name"
                            value={values.first_name}
                            onBlur={handleBlur}
                            disabled={isDisabled}
                            onChange={handleChange}
                            placeholder="Enter first name"
                            touched={touched.first_name}
                            errors={errors.first_name}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <CustomInput
                            label={t("loan.lastName") + "*"}
                            type="text"
                            name="last_name"
                            disabled={isDisabled}
                            value={values.last_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter last name"
                            touched={touched.last_name}
                            errors={errors.last_name}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <div className={`${classes.inputMain}`}>
                            <label>{t("loan.maritalStatus") + "*"}</label>
                            <div className={classes.inputFieldSelect}>
                              <Field
                                as="select"
                                name="marital_status"
                                onBlur={handleBlur}
                                touched={touched.marital_status}
                                errors={errors.marital_status}
                                disabled={isDisabled}
                                onChange={handleChange}
                                placeholder="Select marital_status"
                                className={`${classes.inputField}`}
                              >
                                <option value="">Select Martial Status</option>

                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="divorced">Divorced</option>
                              </Field>
                            </div>
                            <div className={classes.errorMsg}>
                              <ErrorMessage name="marital_status" />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                          <CustomInput
                            label={t("loan.age") + "*"}
                            type="text"
                            name="age"
                            disabled={isDisabled}
                            value={values.age}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter age"
                            touched={touched.age}
                            errors={errors.age}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <div className={`${classes.inputMain}`}>
                            <label>Sex*</label>
                            <div className={classes.inputFieldSelect}>
                              <Field
                                as="select"
                                name="sex"
                                onBlur={handleBlur}
                                touched={touched.sex}
                                errors={errors.sex}
                                disabled={isDisabled}
                                onChange={handleChange}
                                placeholder="Select sex"
                                className={`${classes.inputField}`}
                              >
                                <option value="">Select sex</option>

                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Field>
                            </div>
                            <div className={classes.errorMsg}>
                              <ErrorMessage name="sex" />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <div className={`${classes.inputMain}`}>
                            <label>{t("loan.educationLevel")}</label>
                            <div className={classes.inputFieldSelect}>
                              <Field
                                as="select"
                                name="education_level_id"
                                onBlur={handleBlur}
                                touched={touched.education_level_id}
                                errors={errors.education_level_id}
                                disabled={isDisabled}
                                onChange={handleChange}
                                placeholder="Select education_level_id"
                                className={`${classes.inputField}`}
                              >
                                <option value="">Select education level</option>

                                {masterData?.educationLevels?.map((item) => (
                                  <option value={`${item?.id}`}>
                                    {item?.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <h2>{t("loan.address")}</h2>
                          <hr />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <div className={`${classes.inputMain}`}>
                            <label>{t("loan.County")}*</label>
                            <div className={classes.inputFieldSelect}>
                              <Field
                                as="select"
                                name="county"
                                onBlur={handleBlur}
                                touched={touched.county}
                                errors={errors.county}
                                disabled={isDisabled}
                                onChange={async (event) => {
                                  const selectedCountyCode = event.target.value;
                                  setFieldValue("county", selectedCountyCode);
                                  setFieldValue("city", ""); // Reset city when county changes
                                  fetchCities(selectedCountyCode);
                                }}
                                placeholder="Select County"
                                className={`${classes.inputField}`}
                              >
                                <option value="">Select County</option>

                                {masterData?.counties?.map((county) => (
                                  <option value={`${county?.code}`}>
                                    {county?.county}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <div className={classes.errorMsg}>
                              <ErrorMessage name="county" />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          {loadingCities ? (
                            <p>Loading cities...</p>
                          ) : (
                            <div className={`${classes.inputMain}`}>
                              <label>{t("loan.City")}*</label>
                              <div className={classes.inputFieldSelect}>
                                <Field
                                  as="select"
                                  name="city"
                                  onBlur={handleBlur}
                                  touched={touched.city}
                                  errors={errors.city}
                                  onChange={handleChange}
                                  disabled={
                                    !values.county ||
                                    cities?.length === 0 ||
                                    isDisabled
                                  }
                                  placeholder="Select City"
                                  className={`${classes.inputField}`}
                                >
                                  <option value="">Select Citys</option>

                                  {cities?.map((city) => (
                                    <option value={city?.siruta}>
                                      {city?.city}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <div className={classes.errorMsg}>
                                <ErrorMessage name="city" />
                              </div>
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <CustomInput
                            label={t("loan.Street")}
                            type="text"
                            name="street"
                            disabled={isDisabled}
                            value={values.street}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter street"
                            touched={touched.street}
                            errors={errors.street}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <CustomInput
                            label={t("loan.zipCode")}
                            type="text"
                            name="zip_code"
                            disabled={isDisabled}
                            value={values.zip_code}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter zip_code"
                            touched={touched.zip_code}
                            errors={errors.zip_code}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <CustomInput
                            label={t("loan.no")}
                            type="text"
                            name="no"
                            disabled={isDisabled}
                            value={values.no}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter no"
                            touched={touched.no}
                            errors={errors.no}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <CustomInput
                            label={t("loan.flat")}
                            type="text"
                            name="flat"
                            disabled={isDisabled}
                            value={values.flat}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter flat"
                            touched={touched.flat}
                            errors={errors.flat}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <CustomInput
                            label={t("loan.floor")}
                            type="text"
                            name="floor"
                            disabled={isDisabled}
                            value={values.floor}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter floor"
                            touched={touched.floor}
                            errors={errors.floor}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <CustomInput
                            label="Sc"
                            type="text"
                            name="sc"
                            disabled={isDisabled}
                            value={values.sc}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter sc"
                            touched={touched.sc}
                            errors={errors.sc}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <CustomInput
                            label="Ap"
                            type="text"
                            name="ap"
                            disabled={isDisabled}
                            value={values.ap}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter ap"
                            touched={touched.ap}
                            errors={errors.ap}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <h2>{t("loan.contact")}</h2>
                          <hr />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <CustomInput
                            label={t("loan.emailAddress") + "*"}
                            type="text"
                            name="email"
                            disabled={isDisabled}
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter email"
                            touched={touched.email}
                            errors={errors.email}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <CustomInput
                            label={t("loan.phone") + "*"}
                            type="text"
                            name="phone"
                            disabled={isDisabled}
                            value={values.phone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter phone no"
                            touched={touched.phone}
                            errors={errors.phone}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
                <div className={classes.addUserBtn}>
                  <Button
                    onClick={handleCancel}
                    outlineGrey
                    customWidth="170px"
                  >
                    Close
                  </Button>
                  {editExistingclient?.status_slug ===
                  "indicative-offer-refused-process-closed" ? (
                    ""
                  ) : (
                    <Button type="submit" blue customWidth="170px">
                      {loading ? <Loader /> : <>Save</>}
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }
);

export default AddLoan;
