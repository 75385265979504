import React, { useState } from "react";
import Expenses from "./getAllExpenses";
import AddEditExpenses from "./AddEditExpenses";
const MainAccessLevel = () => {
  const [expensesState, setExpensesState] = useState(true);
  const [editExpenses, setEditExpenses] = useState();
  const handleCancel = () => {
    setExpensesState(true);
    setEditExpenses(null);
  };
  return (
    <>
      {expensesState ? (
        <Expenses
          setExpensesState={setExpensesState}
          setEditExpenses={setEditExpenses}
        />
      ) : (
        <AddEditExpenses
          editExpenses={editExpenses}
          handleCancel={handleCancel}
          setEditExpenses={setEditExpenses}
          setExpensesState={setExpensesState}
        />
      )}
    </>
  );
};

export default MainAccessLevel;
