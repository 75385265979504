import React, { useState } from "react";
import AccessLevel from "./getAllAccessLevel";
import AccessLevelTabs from "./addAccessLevel";
const MainAccessLevel = () => {
  const [accessLevelState, setAccessLevelState] = useState(true);
  const [editAccessLevel, setEditAccessLevel] = useState();
  const handleCancel = () => {
    setAccessLevelState(true);
    setEditAccessLevel(null);
  };
  return (
    <>
      {accessLevelState ? (
        <AccessLevel
          setAccessLevelState={setAccessLevelState}
          setEditAccessLevel={setEditAccessLevel}
        />
      ) : (
        <AccessLevelTabs
          editAccessLevel={editAccessLevel}
          handleCancel={handleCancel}
          setEditAccessLevel={setEditAccessLevel}
        />
      )}
    </>
  );
};

export default MainAccessLevel;
