import React, { useState, useEffect } from "react";
import classes from "./addProducts.module.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { Checkbox, FormControlLabel, styled } from "@mui/material";
import Button from "../../../../components/shared/customBtn";
import {
  getProductSettingsList,
  updateProductSetting,
} from "../../../../api/misc";
import useGetAxios from "../../../../api/hooks/useGetAxios";
import BasicModal from "../../../../components/shared/basicmodal";
import Visibility from "@mui/icons-material/Visibility";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./addProductsBuiltin.scss";
import CustomInput from "../../../../components/shared/customInput";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "react-select";
import * as Yup from "yup";
import Addcountry from "./addcountry";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddInterestRate from "./addInterestRate";

const validationSchemaProductSetting = Yup.object().shape({
  loan_type: Yup.string().required("Field is required"),
  currency: Yup.string().required("Field is required"),
  ps_interest_rate_id: Yup.string().required("Field is required"),
  // interest_rate_fixed_period: Yup.string().required("Field is required"),
  // interest_rate_after_fixed_period: Yup.string().required("Field is required"),
  // dae: Yup.string().required("Field is required"),
  ps_purchase_ids: Yup.array().min(1, "Field is required"),
  ps_refinancing_ids: Yup.array().min(1, "Field is required"),
  ps_build_recon_ids: Yup.array().min(1, "Field is required"),
  ps_can_req_loan_ids: Yup.array().min(1, "Field is required"),

  income_sources: Yup.array().of(
    Yup.object({
      income_source_id: Yup.string().required("Required"),
      seniority: Yup.string().required("Required"),
      percent: Yup.string()
        .required("Required")
        .matches(/^\d+$/, "Must be a number"),
    })
  ),
  is_expense_debt_to_income: Yup.string().required("Field is required"),
  expense_debt_to_income_ratio: Yup.string().required("Field is required"),

  ps_expenses: Yup.array().min(1, "Field is required"),
  ps_warranties_collaterals_ids: Yup.array().min(1, "Field is required"),
  // new forign
  // foreigners_countries: Yup.array().of(
  //   Yup.object({
  //     country_code: Yup.string().required("Required"),
  //     value: Yup.string().required("Required"),
  //   })
  // ),

  // foreigners_country_ids: Yup.array().min(1, "Field is required"),
  // foreigners_country_values: Yup.array()
  //   .of(Yup.string().required("Value cannot be empty"))
  //   .min(1, "At least one value is required"),
  min_loan_amount: Yup.string().required("Field is required"),
  max_loan_amount: Yup.string().required("Field is required"),
  min_income_amount: Yup.string().required("Field is required"),
  min_age: Yup.string().required("Field is required"),
  max_age: Yup.string().required("Field is required"),
  max_age_male: Yup.string().required("Field is required"),
  max_age_female: Yup.string().required("Field is required"),
  max_ltv: Yup.string().required("Field is required"),

  dti: Yup.string().required("Field is required"),
  max_dti: Yup.string().required("Field is required"),
  dtsi: Yup.string().required("Field is required"),
  min_duration_mount: Yup.string().required("Field is required"),
  max_duration_mount: Yup.string().required("Field is required"),
  early_repayment: Yup.string().required("Field is required"),
  // grade_energy_efficiency_level: Yup.string().required("Field is required"),
  // salary_transfer_required: Yup.string().required("Field is required"),
  aegrm_tax: Yup.string().required("Field is required"),
  assessment_fee: Yup.string().required("Field is required"),
  property_evaluation_fee: Yup.string().required("Field is required"),
  admin_fee_for_credit_account: Yup.string().required("Field is required"),
  wire_transfer_payment_commission: Yup.string().required("Field is required"),
  // margin_initial_interest_rate: Yup.string().required("Field is required"),
  // margin_after_fix_interest_rate: Yup.string().required("Field is required"),
  property_insurance: Yup.string().required("Field is required"),
  property_insurance_cover_val: Yup.string().required("Field is required"),
  mandatory_life_insurance: Yup.string().required("Field is required"),
  is_life_insurance_included: Yup.string().required("Field is required"),
  life_insurance_included_value: Yup.string().required("Field is required"),
  ps_drawdown_option_ids: Yup.array().min(1, "Field is required"),
  max_drawdown_period: Yup.string().required("Field is required"),
  max_drawdown_period_refinancing: Yup.string().required("Field is required"),
  fee_incomplete_drawdown: Yup.string().required("Field is required"),
  fee_drawdown: Yup.string().required("Field is required"),
  drawdown_extension_period: Yup.string().required("Field is required"),
  // debt_to_income_seniority: Yup.string().required("Field is required"),
});

const AddProductSetting = ({ editProduct, handleCancel }) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [showInterest, setShowInterest] = useState(true);
  const [borrowVal, setBorrowVal] = useState(false);
  const [debtValue, setDebtValue] = useState();
  const [allInterestRate, setAllInterestRate] = useState([]);
  console.log("allInterestRate", allInterestRate);

  const {
    response: getProductSettingData,
    loading: getProductDocumentLoading,
    error: getProductDocumentError,
  } = useGetAxios(getProductSettingsList(editProduct?.id), false, count);

  const productSettings = masterData?.productSetting;
  const singleProductSetting = getProductSettingData?.data;
  const [selectedCountries, setSelectedCountries] = useState();
  useEffect(() => {
    const checkFun = async () => {
      if (singleProductSetting?.ps_interest_rate_id === 1) {
        setShowInterest(false);
      }
    };

    checkFun();
  }, [getProductSettingData]);
  const handleRemove = () => {};
  const expenseArrayList = singleProductSetting?.ps_expenses.map(
    (item) => item?.ps_expense_id
  );
  console.log("singleProductSetting", singleProductSetting);
  let initialValues;
  const computeInitialValues = (singleProductSetting) => {
    console.log("expenseWithId1singleProductSetting", singleProductSetting);
    const initialValuesInner = {
      loan_type: singleProductSetting?.loan_type || "",
      currency: singleProductSetting?.currency || "",
      ps_interest_rate_id: singleProductSetting?.ps_interest_rate_id || "",
      // interest_rate_fixed_period:
      //   singleProductSetting?.interest_rate_fixed_period || "",
      // interest_rate_after_fixed_period:
      //   singleProductSetting?.interest_rate_after_fixed_period || "",
      ps_purchase_ids: singleProductSetting?.ps_purchase_ids || [],
      ps_refinancing_ids: singleProductSetting?.ps_refinancing_ids || [],
      ps_build_recon_ids: singleProductSetting?.ps_build_recon_ids || [],
      ps_can_req_loan_ids: singleProductSetting?.ps_can_req_loan_ids || [],
      no_of_co_borrowers: singleProductSetting?.no_of_co_borrowers || "",
      interest_types: singleProductSetting?.ps_interest_types || [],
      income_sources: singleProductSetting?.income_sources || [
        { income_source_id: "", seniority: "", percent: "" }, // Initial blank row
      ],
      is_expense_debt_to_income:
        singleProductSetting?.is_expense_debt_to_income == 1 ? true : false,
      expense_debt_to_income_ratio:
        singleProductSetting?.expense_debt_to_income_ratio || "0",
      ps_expenses: expenseArrayList || [],
      ps_warranties_collaterals_ids:
        singleProductSetting?.ps_warranties_collaterals_ids || [],
      foreigners_countries:
        singleProductSetting?.foreigners_countries ||
        [
          // Initial blank row
        ],
      // foreigners_country_ids:
      //   selectedCountries?.map((country) => country?.id) || [],
      // foreigners_country_values:
      //   selectedCountries?.map((country) => country?.value || "") || [],
      min_loan_amount: singleProductSetting?.min_loan_amount || "0",
      max_loan_amount: singleProductSetting?.max_loan_amount || "0",
      min_income_amount: singleProductSetting?.min_income_amount || "0",
      min_age: singleProductSetting?.min_age || "0",
      max_age: singleProductSetting?.max_age || "0",
      max_age_male: singleProductSetting?.max_age_male || "0",
      max_age_female: singleProductSetting?.max_age_female || "0",
      max_ltv: singleProductSetting?.max_ltv || "0",
      max_dti: singleProductSetting?.max_dti || "0",
      dti: singleProductSetting?.dti || "0",
      dtsi: singleProductSetting?.dtsi || "0",
      min_duration_mount: singleProductSetting?.min_duration_mount || "0",
      max_duration_mount: singleProductSetting?.max_duration_mount || "0",
      early_repayment: singleProductSetting?.early_repayment || 0, // 0-false, 1-true
      // grade_energy_efficiency_level:
      //   singleProductSetting?.grade_energy_efficiency_level || 0, // 0-false, 1-true
      // salary_transfer_required:
      //   singleProductSetting?.salary_transfer_required || 0, // 0-false, 1-true
      aegrm_tax: singleProductSetting?.aegrm_tax || "0",
      assessment_fee: singleProductSetting?.assessment_fee || "0",
      property_evaluation_fee:
        singleProductSetting?.property_evaluation_fee || "0",
      admin_fee_for_credit_account:
        singleProductSetting?.admin_fee_for_credit_account || "0",
      wire_transfer_payment_commission:
        singleProductSetting?.wire_transfer_payment_commission || "0",
      // margin_initial_interest_rate:
      //   singleProductSetting?.margin_initial_interest_rate || "",
      // margin_after_fix_interest_rate:
      //   singleProductSetting?.margin_after_fix_interest_rate || "",
      property_insurance: singleProductSetting?.property_insurance || "",
      property_insurance_cover_val:
        singleProductSetting?.property_insurance_cover_val || "0",
      mandatory_life_insurance:
        singleProductSetting?.mandatory_life_insurance || 0,
      is_life_insurance_included:
        singleProductSetting?.is_life_insurance_included || 0,
      life_insurance_included_value:
        singleProductSetting?.life_insurance_included_value || "0",
      ps_drawdown_option_ids:
        singleProductSetting?.ps_drawdown_option_ids || [],
      max_drawdown_period: singleProductSetting?.max_drawdown_period || "0",
      max_drawdown_period_refinancing:
        singleProductSetting?.max_drawdown_period_refinancing || "0",
      fee_incomplete_drawdown:
        singleProductSetting?.fee_incomplete_drawdown || "0",
      fee_drawdown: singleProductSetting?.fee_drawdown || "0",
      drawdown_extension_period:
        singleProductSetting?.drawdown_extension_period || 0,
    };

    return initialValuesInner;
  };
  initialValues = computeInitialValues(getProductSettingData?.data);

  let checkedGol;
  console.log("showInterest", showInterest);
  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        {getProductDocumentLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize // Ensures the form is reinitialized when ProductSettingsList is fetched
            initialValues={initialValues}
            validationSchema={validationSchemaProductSetting}
            onSubmit={async (values, { resetForm }) => {
              console.log("valuessss", values);
              const checkinterest =
                values?.ps_interest_rate_id == 1
                  ? "0"
                  : values?.interest_rate_fixed_period;
              setLoading(true);
              const dataff = {
                loan_type: values?.loan_type,
                currency: values?.currency,
                ps_interest_rate_id: values?.ps_interest_rate_id,
                // interest_rate_fixed_period: checkinterest,
                // interest_rate_after_fixed_period:
                //   values?.interest_rate_after_fixed_period,
                dae: "0",
                ps_purchase_ids: values?.ps_purchase_ids,
                ps_refinancing_ids: values?.ps_refinancing_ids,
                ps_build_recon_ids: values?.ps_build_recon_ids,
                ps_can_req_loan_ids: values?.ps_can_req_loan_ids,
                no_of_co_borrowers: values?.no_of_co_borrowers,
                interest_types: allInterestRate,
                ps_expenses: values?.ps_expenses,
                income_sources: values?.income_sources,

                is_expense_debt_to_income: values?.is_expense_debt_to_income,
                expense_debt_to_income_ratio:
                  values?.expense_debt_to_income_ratio,
                ps_warranties_collaterals_ids:
                  values?.ps_warranties_collaterals_ids,
                foreigners_countries:
                  values?.foreigners_countries?.length &&
                  !values.foreigners_countries.some(
                    (item) => item.country_code || item.value
                  )
                    ? []
                    : values?.foreigners_countries,
                // foreigners_country_ids: values.foreigners_country_ids,
                // foreigners_country_values: values.foreigners_country_values,
                min_loan_amount: values?.min_loan_amount,
                max_loan_amount: values?.max_loan_amount,
                min_income_amount: values?.min_income_amount,
                min_age: values?.min_age,
                max_age: values?.max_age,
                max_age_male: values?.max_age_male,
                max_age_female: values?.max_age_female,
                max_ltv: values?.max_ltv,

                max_dti: values?.max_dti,
                dti: values?.dti,
                dtsi: values?.dtsi,
                min_duration_mount: values?.min_duration_mount,
                max_duration_mount: values?.max_duration_mount,
                early_repayment: values?.early_repayment, // 0-false, 1-true
                // grade_energy_efficiency_level:
                //   values?.grade_energy_efficiency_level, // 0-false, 1-true
                // salary_transfer_required: values?.salary_transfer_required, // 0-false, 1-true
                aegrm_tax: values?.aegrm_tax,
                assessment_fee: values?.assessment_fee,
                property_evaluation_fee: values?.property_evaluation_fee,
                admin_fee_for_credit_account:
                  values?.admin_fee_for_credit_account,
                wire_transfer_payment_commission:
                  values?.wire_transfer_payment_commission,
                // margin_initial_interest_rate:
                //   values?.margin_initial_interest_rate,
                // margin_after_fix_interest_rate:
                //   values?.margin_after_fix_interest_rate,
                property_insurance: values?.property_insurance,
                property_insurance_cover_val:
                  values?.property_insurance_cover_val,
                mandatory_life_insurance: values?.mandatory_life_insurance,
                is_life_insurance_included: values?.is_life_insurance_included,
                life_insurance_included_value:
                  values?.life_insurance_included_value,

                ps_drawdown_option_ids: values?.ps_drawdown_option_ids,
                max_drawdown_period: values?.max_drawdown_period,
                max_drawdown_period_refinancing:
                  values?.max_drawdown_period_refinancing,
                fee_incomplete_drawdown: values?.fee_incomplete_drawdown,
                fee_drawdown: values?.fee_drawdown,
                drawdown_extension_period: values?.drawdown_extension_period,
              };
              console.log("values", dataff);
              try {
                let res = await updateProductSetting(editProduct?.id, dataff, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });

                if (res?.status === 200) {
                  setLoading(false);
                  toast.success("Successfully Update");
                  setCount(count + 1);
                } else {
                  throw new Error(res?.data?.message || "An error occurred");
                }
              } catch (error) {
                setLoading(false);
                toast.error(
                  error.response.data.message ||
                    "An error occurred during the process."
                );
              }
            }}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              errors,
              touched,
              handleBlur,
            }) => {
              const handleCheckboxChangeNew = (e, arrayName) => {
                const { value, checked } = e.target;
                // Parse the value as a number if it's numeric; otherwise, keep it as is
                const parsedValue = isNaN(value) ? value : Number(value);

                // Get the current array of selected values from Formik state
                const currentArray = values[arrayName] || [];
                if (checked) {
                  // If checked, push the parsed value into the array
                  const updatedArray = [...currentArray, parsedValue];
                  setFieldValue(arrayName, updatedArray); // Update the Formik state with the new array
                } else {
                  // If unchecked, remove the parsed value from the array
                  const updatedArray = currentArray.filter(
                    (item) => item !== parsedValue
                  );
                  setFieldValue(arrayName, updatedArray); // Update the Formik state with the updated array
                }
              };
              const handleSelectAll = (arrayName, items) => {
                // Extract all item IDs from the given items array
                const allIds = items.map((item) => item.id) || [];

                // Check if all checkboxes are already selected
                const isAllSelected =
                  values[arrayName]?.length === allIds.length;

                // Toggle selection: if all are selected, clear the array; otherwise, select all
                setFieldValue(arrayName, isAllSelected ? [] : allIds);
              };

              // const handleCheckboxChangeDes = (e, arrayName) => {
              //   const { value, checked } = e.target;

              //   // Parse the value as a number if it's numeric; otherwise, keep it as is
              //   const parsedValue = isNaN(value) ? value : Number(value);

              //   // Get the current array of selected values from Formik state
              //   const currentArray = values[arrayName] || [];

              //   // Ensure only ps_expense_id and value fields exist
              //   const sanitizedArray = currentArray.map(
              //     ({ ps_expense_id, value }) => ({
              //       ps_expense_id,
              //       value,
              //     })
              //   );

              //   let updatedArray;
              //   if (checked) {
              //     // Add the object with the required fields
              //     const inputVal =
              //       parsedValue === 1 ? values?.debt_to_income_value : "0.00";
              //     const newObject = {
              //       ps_expense_id: parsedValue,
              //       value: inputVal,
              //     };
              //     updatedArray = [...sanitizedArray, newObject];
              //   } else {
              //     // Remove the object by filtering out the ps_expense_id
              //     updatedArray = sanitizedArray.filter(
              //       (item) => item.ps_expense_id !== parsedValue
              //     );
              //   }

              //   // Update the Formik state with the new array of objects
              //   setFieldValue(arrayName, updatedArray);
              // };
              const handleCheckboxChangeDes = (e, arrayName) => {
                const { value, checked } = e.target;

                // Parse the value as a number if it's numeric; otherwise, keep it as is
                const parsedValue = isNaN(value) ? value : Number(value);

                // Get the current array of selected values from Formik state
                const currentArray = values[arrayName] || [];

                let updatedArray;
                if (checked) {
                  // Add the value if it's checked
                  updatedArray = [...currentArray, parsedValue];
                } else {
                  // Remove the value if it's unchecked
                  updatedArray = currentArray.filter(
                    (item) => item !== parsedValue
                  );
                }

                // Update the Formik state with the new array
                setFieldValue(arrayName, updatedArray);
              };

              return (
                <Form>
                  <div className="accordMainStyle">
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>1. Types of Loan and Currency</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.dynamicMain}>
                          <label className={classes.labelName}>Loan Type</label>
                          <div role="group" aria-labelledby={`laonType-label`}>
                            {productSettings?.loan_types?.map((item) => (
                              <label>
                                <input
                                  type="radio"
                                  name="loan_type"
                                  value={item.slug}
                                  checked={values?.loan_type?.includes(
                                    item?.slug
                                  )}
                                  onChange={handleChange}
                                />
                                {item.name}
                              </label>
                            ))}
                          </div>
                          <ErrorMessage
                            name="loan_type"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </div>
                        <div className={classes.dynamicMain}>
                          <label className={classes.labelName}>Currency</label>
                          <div role="group" aria-labelledby={`currency-label`}>
                            {productSettings?.currencies?.map((item) => (
                              <label>
                                <input
                                  type="radio"
                                  name="currency"
                                  value={item.slug}
                                  checked={values?.currency?.includes(
                                    item?.slug
                                  )}
                                  onChange={handleChange}
                                />
                                {item.name}
                              </label>
                            ))}
                          </div>
                          <ErrorMessage
                            name="currency"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </div>

                        {/* <div className={classes.mainRow}>
                          <div className={classes.labelText}></div>
                          <div className={classes.inputListMain}>
                            {showInterest ? (
                              <>
                                <div className={classes.inputList}>
                                  <label htmlFor="">
                                    Interest rate in the fixed period
                                  </label>
                                  <Field
                                    type="text"
                                    name="interest_rate_fixed_period"
                                    value={values?.interest_rate_fixed_period}
                                    onChange={handleChange}
                                    placeholder=""
                                  />
                                </div>
                                <ErrorMessage
                                  name="interest_rate_fixed_period"
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </>
                            ) : null}
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                {showInterest
                                  ? "Interest rate after the fixed period"
                                  : "Interest rate"}
                              </label>
                              <Field
                                type="text"
                                name="interest_rate_after_fixed_period"
                                value={values?.interest_rate_after_fixed_period}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="interest_rate_after_fixed_period"
                              component="div"
                              style={{ color: "red" }}
                            />
                          
                          </div>
                        </div> */}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>2. Interest</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className={`${classes.inputMain} ${classes.inputMainSetting}`}
                        >
                          <label>Interest rates</label>
                          <div className={classes.inputFieldSelect}>
                            <Field
                              as="select"
                              name="ps_interest_rate_id"
                              onChange={(e) => {
                                const interVal = e.target.value;
                                setFieldValue("ps_interest_rate_id", interVal);
                                if (interVal == 1) {
                                  setShowInterest(false); // If float, set false
                                } else {
                                  setShowInterest(true); // If integer, set true
                                }
                              }}
                              className={`${classes.inputField}`}
                            >
                              <option value="">Select</option>
                              {productSettings?.interest_rates?.map((item) => (
                                <option value={item.id}>{item.name}</option>
                              ))}
                            </Field>
                          </div>
                          <ErrorMessage
                            name="ps_interest_rate_id"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </div>
                        <AddInterestRate
                          singleProductSetting={singleProductSetting}
                          setAllInterestRate={setAllInterestRate}
                          allInterestRate={allInterestRate}
                          showInterest={showInterest}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        <Typography>3. Purpose of the loan</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ErrorMessage
                          name="ps_purchase_ids"
                          component="div"
                          style={{ color: "red" }}
                        />
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            Purchase
                            <span
                              className={classes.selectAll}
                              onClick={() =>
                                handleSelectAll(
                                  "ps_purchase_ids",
                                  productSettings?.purchases
                                )
                              }
                            >
                              {values?.ps_purchase_ids?.length ===
                              productSettings?.purchases?.length
                                ? "Deselect All"
                                : "Select All"}
                            </span>
                          </div>
                          <div className={classes.checkList}>
                            {productSettings?.purchases?.map((item) => (
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Field
                                    as={Checkbox}
                                    type="checkbox"
                                    name="ps_purchase_ids" // Same name as Formik state array
                                    value={item.id} // The id of the current item will be the checkbox value
                                    checked={values?.ps_purchase_ids?.includes(
                                      item?.id
                                    )} // Check if the item is in the selected array
                                    onChange={(e) =>
                                      handleCheckboxChangeNew(
                                        e,
                                        "ps_purchase_ids"
                                      )
                                    } // Call the dynamic handler
                                  />
                                }
                                label={item.name} // Display the name of the item
                              />
                            ))}
                          </div>
                        </div>

                        <ErrorMessage
                          name="ps_refinancing_ids"
                          component="div"
                          style={{ color: "red" }}
                        />
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            Refinancing
                            <span
                              className={classes.selectAll}
                              onClick={() =>
                                handleSelectAll(
                                  "ps_refinancing_ids",
                                  productSettings?.refinancing
                                )
                              }
                            >
                              {values?.ps_refinancing_ids?.length ===
                              productSettings?.refinancing?.length
                                ? "Deselect All"
                                : "Select All"}
                            </span>
                          </div>
                          <div className={classes.checkList}>
                            {productSettings?.refinancing?.map((item) => (
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Field
                                    as={Checkbox}
                                    type="checkbox"
                                    name="ps_refinancing_ids" // Same name as Formik state array
                                    value={item.id} // The id of the current item will be the checkbox value
                                    checked={values?.ps_refinancing_ids?.includes(
                                      item?.id
                                    )} // Check if the item is in the selected array
                                    onChange={(e) =>
                                      handleCheckboxChangeNew(
                                        e,
                                        "ps_refinancing_ids"
                                      )
                                    } // Call the dynamic handler
                                  />
                                }
                                label={item.name} // Display the name of the item
                              />
                            ))}
                          </div>
                        </div>

                        <ErrorMessage
                          name="ps_build_recon_ids"
                          component="div"
                          style={{ color: "red" }}
                        />
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            Building and reconstruction
                            <span
                              className={classes.selectAll}
                              onClick={() =>
                                handleSelectAll(
                                  "ps_build_recon_ids",
                                  productSettings?.building_reconstrucions
                                )
                              }
                            >
                              {values?.ps_build_recon_ids?.length ===
                              productSettings?.building_reconstrucions?.length
                                ? "Deselect All"
                                : "Select All"}
                            </span>
                          </div>
                          <div className={classes.checkList}>
                            {productSettings?.building_reconstrucions?.map(
                              (item) => (
                                <FormControlLabel
                                  key={item.id}
                                  control={
                                    <Field
                                      as={Checkbox}
                                      type="checkbox"
                                      name="ps_build_recon_ids" // Same name as Formik state array
                                      value={item.id} // The id of the current item will be the checkbox value
                                      checked={values?.ps_build_recon_ids?.includes(
                                        item?.id
                                      )} // Check if the item is in the selected array
                                      onChange={(e) =>
                                        handleCheckboxChangeNew(
                                          e,
                                          "ps_build_recon_ids"
                                        )
                                      } // Call the dynamic handler
                                    />
                                  }
                                  label={item.name} // Display the name of the item
                                />
                              )
                            )}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                      >
                        <Typography>4. Who can request the loan</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ErrorMessage
                          name="ps_can_req_loan_ids"
                          component="div"
                          style={{ color: "red" }}
                        />
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            <span
                              className={classes.selectAll}
                              onClick={() =>
                                handleSelectAll(
                                  "ps_can_req_loan_ids",
                                  productSettings?.can_req_loans
                                )
                              }
                            >
                              {values?.ps_can_req_loan_ids?.length ===
                              productSettings?.can_req_loans?.length
                                ? "Deselect All"
                                : "Select All"}
                            </span>
                          </div>
                          <div className={classes.checkList}>
                            {productSettings?.can_req_loans?.map((item) => (
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Field
                                    as={Checkbox}
                                    type="checkbox"
                                    name="ps_can_req_loan_ids" // Same name as Formik state array
                                    value={item.id} // The id of the current item will be the checkbox value
                                    checked={values?.ps_can_req_loan_ids?.includes(
                                      item?.id
                                    )} // Check if the item is in the selected array
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      handleCheckboxChangeNew(
                                        e,
                                        "ps_can_req_loan_ids"
                                      );
                                      if (item.id === 2) {
                                        // Check if the value is 2
                                        setBorrowVal(checked); // Set to true if checked, false if unchecked
                                      }
                                      if (checked == false) {
                                        setFieldValue(
                                          "no_of_co_borrowers",
                                          "0"
                                        );
                                      }
                                    }} // Call the dynamic handler
                                  />
                                }
                                label={item.name} // Display the name of the item
                              />
                            ))}
                            {borrowVal && (
                              <div className={classes.inputList}>
                                <label htmlFor="">Max No of Co-Borrowers</label>
                                <Field
                                  type="text"
                                  name="no_of_co_borrowers"
                                  value={values?.no_of_co_borrowers}
                                  onChange={handleChange}
                                  placeholder=""
                                />
                              </div>
                            )}
                            {/* <ErrorMessage
                            name="no_of_co_borrowers"
                            component="div"
                            style={{ color: "red" }}
                          /> */}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5-content"
                        id="panel5-header"
                      >
                        <Typography>5. Accepted income sources</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.incomeMain}>
                          <FieldArray name="income_sources">
                            {({ remove, push }) => {
                              // Ensure at least one row exists
                              if (values?.income_sources?.length === 0) {
                                push({
                                  income_source_id: "",
                                  seniority: "",
                                  percent: "",
                                });
                              }

                              return (
                                <ul>
                                  {values?.income_sources?.map(
                                    (item, index) => (
                                      <li key={index}>
                                        <div className={classes.inputMain}>
                                          <label>Income source</label>
                                          <div
                                            className={classes.inputFieldSelect}
                                          >
                                            <Field
                                              as="select"
                                              name={`income_sources[${index}].income_source_id`}
                                              className={classes.inputField}
                                              onBlur={handleBlur}
                                            >
                                              <option value="">
                                                Select income source
                                              </option>
                                              {masterData?.productSetting?.income_sources?.map(
                                                (source) => (
                                                  <option
                                                    key={source.id}
                                                    value={source.id}
                                                  >
                                                    {source.name}
                                                  </option>
                                                )
                                              )}
                                            </Field>
                                            {touched.income_sources?.[index]
                                              ?.income_source_id &&
                                              errors.income_sources?.[index]
                                                ?.income_source_id && (
                                                <div
                                                  className={classes.errorMsg}
                                                >
                                                  {
                                                    errors.income_sources[index]
                                                      .income_source_id
                                                  }
                                                </div>
                                              )}
                                          </div>
                                        </div>

                                        <div>
                                          <CustomInput
                                            label="Minimal seniority"
                                            type="text"
                                            name={`income_sources[${index}].seniority`}
                                            value={item.seniority}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          {touched.income_sources?.[index]
                                            ?.seniority &&
                                            errors.income_sources?.[index]
                                              ?.seniority && (
                                              <div className={classes.errorMsg}>
                                                {
                                                  errors.income_sources[index]
                                                    .seniority
                                                }
                                              </div>
                                            )}
                                        </div>

                                        <div>
                                          <CustomInput
                                            label="Accepted amount (%)"
                                            type="text"
                                            name={`income_sources[${index}].percent`}
                                            value={item.percent}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          {touched.income_sources?.[index]
                                            ?.percent &&
                                            errors.income_sources?.[index]
                                              ?.percent && (
                                              <div className={classes.errorMsg}>
                                                {
                                                  errors.income_sources[index]
                                                    .percent
                                                }
                                              </div>
                                            )}
                                        </div>

                                        <div className={classes.actionbtn}>
                                          <CloseIcon
                                            onClick={() => remove(index)}
                                          />
                                          {index ===
                                            values.income_sources.length -
                                              1 && (
                                            <AddCircleOutlineIcon
                                              onClick={() =>
                                                push({
                                                  income_source_id: "",
                                                  seniority: "",
                                                  percent: "",
                                                })
                                              }
                                            />
                                          )}
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              );
                            }}
                          </FieldArray>
                        </div>

                        {/* <>
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            4.1. Income from employment
                          </div>
                          <div
                            className={`${classes.checkList} ${classes.flexstart}`}
                          >
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  name="contract_ip"
                                  type="checkbox"
                                  checked={!!values?.contract_ip}
                                  onChange={(e) => {
                                    const checked = e.target.checked;

                                    // Update the main checkbox state
                                    setFieldValue(
                                      "contract_ip",
                                      checked ? 1 : 0
                                    );

                                    // Reset nested state if parent is unchecked
                                    if (!checked) {
                                      setFieldValue(
                                        "contract_ip_seniority_checked",
                                        false
                                      ); // Uncheck child checkbox
                                      setFieldValue(
                                        "contract_ip_seniority",
                                        ""
                                      ); // Clear nested input
                                    }
                                  }}
                                />
                              }
                              label={
                                <div className={classes.checkInputStylefirst}>
                                  <div>
                                    <p>Contract - indefinite period</p>
                                  </div>

                                  {values?.contract_ip == 1 && (
                                    <div className={classes.checkInputStyle}>
                                      <FormControlLabel
                                        control={
                                          <Field
                                            as={Checkbox}
                                            name="contract_ip_seniority_checked"
                                            type="checkbox"
                                            checked={
                                              !!values?.contract_ip_seniority_checked ||
                                              !!values?.contract_ip_seniority
                                            }
                                            onChange={(e) => {
                                              const nestedChecked =
                                                e.target.checked;

                                              // Update the child checkbox state
                                              setFieldValue(
                                                "contract_ip_seniority_checked",
                                                nestedChecked
                                              );

                                              // Clear the input field if the child checkbox is unchecked
                                              if (!nestedChecked) {
                                                setFieldValue(
                                                  "contract_ip_seniority",
                                                  ""
                                                );
                                              }
                                            }}
                                          />
                                        }
                                        label={
                                          <div>
                                            <p>Minimum seniority required</p>
                                          </div>
                                        }
                                      />
                                      {values?.contract_ip_seniority ||
                                      values?.contract_ip_seniority_checked ? (
                                        <Field
                                          type="text"
                                          name="contract_ip_seniority"
                                          value={values?.contract_ip_seniority}
                                          onChange={handleChange}
                                          placeholder="%"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              }
                            />

                            <ErrorMessage
                              name="contract_ip_seniority"
                              component="div"
                              style={{ color: "red" }}
                            />

                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  name="contract_fp"
                                  type="checkbox"
                                  checked={!!values?.contract_fp}
                                  onChange={(e) => {
                                    const checked = e.target.checked;

                                    // Update the main checkbox state
                                    setFieldValue(
                                      "contract_fp",
                                      checked ? 1 : 0
                                    );

                                    // Reset nested state if parent is unchecked
                                    if (!checked) {
                                      setFieldValue(
                                        "contract_fp_seniority_checked",
                                        false
                                      ); // Uncheck child checkbox
                                      setFieldValue(
                                        "contract_fp_seniority",
                                        ""
                                      ); // Clear nested input
                                    }
                                  }}
                                />
                              }
                              label={
                                <div className={classes.checkInputStylefirst}>
                                  <div>
                                    <p>Contract - fixed period</p>
                                  </div>

                                  {values?.contract_fp == 1 && (
                                    <div className={classes.checkInputStyle}>
                                      <FormControlLabel
                                        control={
                                          <Field
                                            as={Checkbox}
                                            name="contract_fp_seniority_checked"
                                            type="checkbox"
                                            checked={
                                              !!values?.contract_fp_seniority_checked ||
                                              !!values?.contract_fp_seniority
                                            }
                                            onChange={(e) => {
                                              const nestedChecked =
                                                e.target.checked;

                                              // Update the child checkbox state
                                              setFieldValue(
                                                "contract_fp_seniority_checked",
                                                nestedChecked
                                              );

                                              // Clear the input field if the child checkbox is unchecked
                                              if (!nestedChecked) {
                                                setFieldValue(
                                                  "contract_fp_seniority",
                                                  ""
                                                );
                                              }
                                            }}
                                          />
                                        }
                                        label={
                                          <div>
                                            <p>Minimum seniority required</p>
                                          </div>
                                        }
                                      />
                                      {values?.contract_fp_seniority ||
                                      values?.contract_fp_seniority_checked ? (
                                        <Field
                                          type="text"
                                          name="contract_fp_seniority"
                                          value={values?.contract_fp_seniority}
                                          onChange={handleChange}
                                          placeholder="%"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              }
                            />
                            <ErrorMessage
                              name="contract_fp_seniority"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        </div>

                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            4.2. Income from entrepreneurship
                          </div>
                          <div
                            className={`${classes.checkList} ${classes.styleCusCheck}`}
                          >
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  name="freelancing_taxable_income_check"
                                  type="checkbox"
                                  checked={
                                    values?.freelancing_taxable_income ||
                                    values?.freelancing_taxable_income_check
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    // Update the `authCheck` value in Formik
                                    setFieldValue(
                                      "freelancing_taxable_income_check",
                                      checked ? 1 : 0
                                    );

                                    // Clear or keep the seniority value based on the checked state
                                    if (!checked) {
                                      setFieldValue(
                                        "freelancing_taxable_income",
                                        ""
                                      ); // Clear if unchecked
                                    }
                                  }}
                                />
                              }
                              label={
                                <div className={classes.checkInputStyle}>
                                  <div>
                                    <p>Freelancing</p>
                                    <p>Taxable income threshold</p>
                                  </div>
                                  <div>
                                    <Field
                                      type="text"
                                      name="freelancing_taxable_income"
                                      value={values.freelancing_taxable_income}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder="%"
                                      // disabled={
                                      //   values?.freelancing_taxable_income_check !==
                                      //   1
                                      // }
                                      // Disable if `contract_ip` is not checked
                                    />
                                    <ErrorMessage
                                      name="freelancing_taxable_income"
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              }
                            />

                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  name="freelancing_real_income_check"
                                  type="checkbox"
                                  checked={
                                    values?.freelancing_real_income ||
                                    values?.freelancing_real_income_check
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    // Update the `authCheck` value in Formik
                                    setFieldValue(
                                      "freelancing_real_income_check",
                                      checked ? 1 : 0
                                    );

                                    // Clear or keep the seniority value based on the checked state
                                    if (!checked) {
                                      setFieldValue(
                                        "freelancing_real_income",
                                        ""
                                      ); // Clear if unchecked
                                    }
                                  }}
                                />
                              }
                              label={
                                <div className={classes.checkInputStyle}>
                                  <div>
                                    <p>Freelancing</p>
                                    <p>Real income taxation system</p>
                                  </div>
                                  <div>
                                    <Field
                                      type="text"
                                      name="freelancing_real_income"
                                      value={values.freelancing_real_income}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      placeholder="%"
                                      disabled={
                                        values?.freelancing_real_income_check !==
                                        1
                                      } // Disable if `contract_ip` is not checked
                                    />
                                  </div>
                                </div>
                              }
                            />
                            <ErrorMessage
                              name="freelancing_real_income"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  name="dividends_check"
                                  type="checkbox"
                                  checked={
                                    values?.dividends || values?.dividends_check
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    // Update the `authCheck` value in Formik
                                    setFieldValue(
                                      "dividends_check",
                                      checked ? 1 : 0
                                    );

                                    // Clear or keep the seniority value based on the checked state
                                    if (!checked) {
                                      setFieldValue("dividends", ""); // Clear if unchecked
                                    }
                                  }}
                                />
                              }
                              label={
                                <div className={classes.checkInputStyle}>
                                  <div>
                                    <p>Dividends</p>
                                    <p>
                                      The percentage of income taken into
                                      account
                                    </p>
                                  </div>
                                  <div>
                                    <Field
                                      type="text"
                                      name="dividends"
                                      value={values.dividends}
                                      onChange={handleChange}
                                      placeholder="%"
                                      disabled={values?.dividends_check !== 1} // Disable if `contract_ip` is not checked
                                    />
                                  </div>
                                </div>
                              }
                            />
                            <ErrorMessage
                              name="dividends"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  name="srl_check"
                                  type="checkbox"
                                  checked={
                                    values?.srl || values?.srl_check
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    // Update the `authCheck` value in Formik
                                    setFieldValue("srl_check", checked ? 1 : 0);

                                    // Clear or keep the seniority value based on the checked state
                                    if (!checked) {
                                      setFieldValue("srl", ""); // Clear if unchecked
                                    }
                                  }}
                                />
                              }
                              label={
                                <div className={classes.checkInputStyle}>
                                  <div>
                                    <p>SRL</p>
                                    <p>
                                      The percentage of income taken into
                                      account
                                    </p>
                                  </div>
                                  <div>
                                    <Field
                                      type="text"
                                      name="srl"
                                      value={values.srl}
                                      onChange={handleChange}
                                      placeholder="%"
                                      disabled={values?.srl_check !== 1} // Disable if `contract_ip` is not checked
                                    />
                                  </div>
                                </div>
                              }
                            />
                            <ErrorMessage
                              name="srl"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  name="srl_micro_check"
                                  type="checkbox"
                                  checked={
                                    values?.srl_micro || values?.srl_micro_check
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    // Update the `authCheck` value in Formik
                                    setFieldValue(
                                      "srl_micro_check",
                                      checked ? 1 : 0
                                    );

                                    // Clear or keep the seniority value based on the checked state
                                    if (!checked) {
                                      setFieldValue("srl_micro", ""); // Clear if unchecked
                                    }
                                  }}
                                />
                              }
                              label={
                                <div className={classes.checkInputStyle}>
                                  <div>
                                    <p>SRL Micro</p>
                                    <p>
                                      The percentage of income taken into
                                      account
                                    </p>
                                  </div>
                                  <div>
                                    <Field
                                      type="text"
                                      name="srl_micro"
                                      value={values.srl_micro}
                                      onChange={handleChange}
                                      placeholder="%"
                                      disabled={values?.srl_micro_check !== 1} // Disable if `contract_ip` is not checked
                                    />
                                  </div>
                                </div>
                              }
                            />
                            <ErrorMessage
                              name="srl_micro"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="ps_income_rental_ids"
                          component="div"
                          style={{ color: "red" }}
                        />
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            4.3. Income from rental
                          </div>
                          <div className={classes.checkList}>
                            {productSettings?.income_rentals?.map((item) => (
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Field
                                    as={Checkbox}
                                    type="checkbox"
                                    name="ps_income_rental_ids" // Same name as Formik state array
                                    value={item.id} // The id of the current item will be the checkbox value
                                    checked={values?.ps_income_rental_ids?.includes(
                                      item?.id
                                    )} // Check if the item is in the selected array
                                    onChange={(e) =>
                                      handleCheckboxChangeNew(
                                        e,
                                        "ps_income_rental_ids"
                                      )
                                    } // Call the dynamic handler
                                  />
                                }
                                label={item.name} // Display the name of the item
                              />
                            ))}
                          </div>
                        </div>
                        <ErrorMessage
                          name="ps_income_abroad_ids"
                          component="div"
                          style={{ color: "red" }}
                        />
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            4.4. Income from abroad
                          </div>
                          <div className={classes.checkList}>
                            {productSettings?.income_abroads?.map((item) => (
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Field
                                    as={Checkbox}
                                    type="checkbox"
                                    name="ps_income_abroad_ids" // Same name as Formik state array
                                    value={item.id} // The id of the current item will be the checkbox value
                                    checked={values?.ps_income_abroad_ids?.includes(
                                      item?.id
                                    )} // Check if the item is in the selected array
                                    onChange={(e) =>
                                      handleCheckboxChangeNew(
                                        e,
                                        "ps_income_abroad_ids"
                                      )
                                    } // Call the dynamic handler
                                  />
                                }
                                label={item.name} // Display the name of the item
                              />
                            ))}
                          </div>
                        </div>
                        </> */}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6-content"
                        id="panel6-header"
                      >
                        <Typography>6. Expenses</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.mainRow}>
                          {/* <div className={classes.labelText}></div> */}
                          <div
                            className={`${classes.checkList} ${classes.checknested}`}
                          >
                            <div className={classes.checkrow}>
                              <ErrorMessage
                                name="is_expense_debt_to_income"
                                component="div"
                                style={{ color: "red" }}
                              />
                              <FormControlLabel
                                control={
                                  <Field
                                    as={Checkbox}
                                    name="is_expense_debt_to_income"
                                    type="checkbox"
                                    // value={item.id}
                                    // checked={values?.debt_to_income_checked?.some(
                                    //   (expense) =>
                                    //     expense.ps_expense_id === item.id
                                    // )}
                                    onChange={handleChange}
                                  />
                                }
                                label={
                                  <p>
                                    Debt-to-income ratio from approved but
                                    unused credit cards
                                  </p>
                                }
                              />
                              <Field
                                type="text"
                                name="expense_debt_to_income_ratio"
                                value={values?.expense_debt_to_income_ratio}
                                onChange={handleChange}
                                placeholder="%"
                              />
                              <ErrorMessage
                                name="expense_debt_to_income_ratio"
                                component="div"
                                style={{ color: "red" }}
                              />
                            </div>

                            <h4>Expense Types</h4>
                            <span
                              className={classes.selectAll}
                              onClick={() =>
                                handleSelectAll(
                                  "ps_expenses",
                                  productSettings?.expenses
                                )
                              }
                            >
                              {values?.ps_expenses?.length ===
                              productSettings?.expenses?.length
                                ? "Deselect All"
                                : "Select All"}
                            </span>
                            <ErrorMessage
                              name="ps_expenses"
                              component="div"
                              style={{ color: "red" }}
                            />
                            {productSettings?.expenses?.map((item) => (
                              // <FormControlLabel
                              //   key={item.id}
                              //   control={
                              //     <Field
                              //       as={Checkbox}
                              //       name="ps_expenses"
                              //       type="checkbox"
                              //       value={item.id}
                              //       checked={values?.ps_expenses?.some(
                              //         (expense) =>
                              //           expense.ps_expense_id === item.id
                              //       )}
                              //       onChange={(e) =>
                              //         handleCheckboxChangeDes(e, "ps_expenses")
                              //       }
                              //     />
                              //   }
                              //   label={<p>{item.name}</p>}
                              // />
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Field
                                    as={Checkbox}
                                    type="checkbox"
                                    name="ps_expenses" // Same name as Formik state array
                                    value={item.id} // The id of the current item will be the checkbox value
                                    checked={values?.ps_expenses?.includes(
                                      item?.id
                                    )}
                                    onChange={(e) =>
                                      handleCheckboxChangeNew(e, "ps_expenses")
                                    } // Call the dynamic handler
                                  />
                                }
                                label={item.name} // Display the name of the item
                              />
                              // if (item.id === 1) {
                              //   return (
                              //     <div
                              //       key={item.id}
                              //       className={`${classes.checkInputStylefirst}`}
                              //     >
                              //       {/* Parent Checkbox */}
                              //       <FormControlLabel
                              //         control={
                              //           <Field
                              //             as={Checkbox}
                              //             name="debt_to_income_ip"
                              //             type="checkbox"
                              //             value={item.id}
                              //             checked={!!values?.debt_to_income_ip}
                              //             onChange={(e) => {
                              //               const isChecked = e.target.checked;
                              //               setFieldValue(
                              //                 "debt_to_income_ip",
                              //                 isChecked ? 1 : 0
                              //               );
                              //               handleCheckboxChangeDes(
                              //                 e,
                              //                 "ps_expenses"
                              //               );

                              //               // Reset child checkbox and input if parent is unchecked
                              //               if (!isChecked) {
                              //                 setFieldValue(
                              //                   "debt_to_income_checked",
                              //                   false
                              //                 );
                              //                 setFieldValue(
                              //                   "debt_to_income_seniority",
                              //                   ""
                              //                 );
                              //               }
                              //             }}
                              //           />
                              //         }
                              //         label={
                              //           <div
                              //             className={`${classes.checkInputStylefirst}`}
                              //           >
                              //             <div>
                              //               <p>{item.name}</p>
                              //             </div>

                              //             {values?.debt_to_income_ip == 1 && (
                              //               <div
                              //                 className={
                              //                   classes.checkInputStyle
                              //                 }
                              //               >
                              //                 {/* Child Checkbox */}
                              //                 <FormControlLabel
                              //                   control={
                              //                     <Field
                              //                       as={Checkbox}
                              //                       name="debt_to_income_checked"
                              //                       type="checkbox"
                              //                       checked={
                              //                         !!values?.debt_to_income_checked ||
                              //                         !!values?.debt_to_income_seniority
                              //                       }
                              //                       onChange={(e) => {
                              //                         const isNestedChecked =
                              //                           e.target.checked;

                              //                         setFieldValue(
                              //                           "debt_to_income_checked",
                              //                           isNestedChecked
                              //                         );

                              //                         // Reset the input field if child checkbox is unchecked
                              //                         if (!isNestedChecked) {
                              //                           setFieldValue(
                              //                             "debt_to_income_seniority",
                              //                             ""
                              //                           );
                              //                         }
                              //                       }}
                              //                     />
                              //                   }
                              //                   label={
                              //                     <p>{item.description}</p>
                              //                   }
                              //                 />

                              //                 {/* Input Field */}

                              //                 {values?.debt_to_income_seniority ||
                              //                 values?.debt_to_income_checked ? (
                              //                   <Field
                              //                     type="text"
                              //                     name="debt_to_income_seniority"
                              //                     value={
                              //                       values?.debt_to_income_seniority
                              //                     }
                              //                     onChange={(e) => {
                              //                       const newValue =
                              //                         e.target.value;

                              //                       setFieldValue(
                              //                         "debt_to_income_seniority",
                              //                         newValue
                              //                       );

                              //                       // Update the `ps_expenses` array with the new value
                              //                       const updatedExpenses =
                              //                         values?.ps_expenses.map(
                              //                           (expense) =>
                              //                             expense.ps_expense_id ===
                              //                             item.id
                              //                               ? {
                              //                                   ...expense,
                              //                                   value: newValue,
                              //                                 }
                              //                               : expense
                              //                         );
                              //                       setFieldValue(
                              //                         "ps_expenses",
                              //                         updatedExpenses
                              //                       );
                              //                     }}
                              //                     placeholder="%"
                              //                   />
                              //                 ) : (
                              //                   ""
                              //                 )}
                              //               </div>
                              //             )}
                              //           </div>
                              //         }
                              //       />
                              //     </div>
                              //   );
                              // } else {
                              //   // Single Checkbox
                              //   return (
                              //     <FormControlLabel
                              //       key={item.id}
                              //       control={
                              //         <Field
                              //           as={Checkbox}
                              //           name="ps_expenses"
                              //           type="checkbox"
                              //           value={item.id}
                              //           checked={values?.ps_expenses?.some(
                              //             (expense) =>
                              //               expense.ps_expense_id === item.id
                              //           )}
                              //           onChange={(e) =>
                              //             handleCheckboxChangeDes(
                              //               e,
                              //               "ps_expenses"
                              //             )
                              //           }
                              //         />
                              //       }
                              //       label={<p>{item.name}</p>}
                              //     />
                              //   );
                              // }
                            ))}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel7-content"
                        id="panel7-header"
                      >
                        <Typography>7. Warranties / Collaterals</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ErrorMessage
                          name="ps_warranties_collaterals_ids"
                          component="div"
                          style={{ color: "red" }}
                        />
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            <span
                              className={classes.selectAll}
                              onClick={() =>
                                handleSelectAll(
                                  "ps_warranties_collaterals_ids",
                                  productSettings?.warranties_collaterals
                                )
                              }
                            >
                              {values?.ps_warranties_collaterals_ids?.length ===
                              productSettings?.warranties_collaterals?.length
                                ? "Deselect All"
                                : "Select All"}
                            </span>
                          </div>
                          <div className={classes.checkList}>
                            {productSettings?.warranties_collaterals?.map(
                              (item) => (
                                <FormControlLabel
                                  key={item.id}
                                  control={
                                    <Field
                                      as={Checkbox}
                                      type="checkbox"
                                      name="ps_warranties_collaterals_ids" // Same name as Formik state array
                                      value={item.id} // The id of the current item will be the checkbox value
                                      checked={values?.ps_warranties_collaterals_ids?.includes(
                                        item?.id
                                      )} // Check if the item is in the selected array
                                      onChange={(e) =>
                                        handleCheckboxChangeNew(
                                          e,
                                          "ps_warranties_collaterals_ids"
                                        )
                                      } // Call the dynamic handler
                                    />
                                  }
                                  label={item.name} // Display the name of the item
                                />
                              )
                            )}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8-content"
                        id="panel8-header"
                      >
                        <Typography>8. Foreigners</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.incomeMain}>
                          <FieldArray name="foreigners_countries">
                            {({ remove, push }) => {
                              // Ensure at least one row exists
                              if (values?.foreigners_countries?.length === 0) {
                                push({
                                  country_code: "",
                                  value: "",
                                });
                              }

                              return (
                                <ul>
                                  {values?.foreigners_countries?.map(
                                    (item, index) => (
                                      <li key={index}>
                                        <div className={classes.inputMain}>
                                          <label>Country</label>
                                          <div
                                            className={classes.inputFieldSelect}
                                          >
                                            <Field
                                              as="select"
                                              name={`foreigners_countries[${index}].country_code`}
                                              className={classes.inputField}
                                              onBlur={handleBlur}
                                            >
                                              <option value="">
                                                Select Country
                                              </option>
                                              {masterData?.countries?.map(
                                                (source) => (
                                                  <option
                                                    key={source.id}
                                                    value={source.id}
                                                  >
                                                    {source.name}
                                                  </option>
                                                )
                                              )}
                                            </Field>
                                            {touched.foreigners_countries?.[
                                              index
                                            ]?.country_code &&
                                              errors.foreigners_countries?.[
                                                index
                                              ]?.country_code && (
                                                <div
                                                  className={classes.errorMsg}
                                                >
                                                  {
                                                    errors.foreigners_countries[
                                                      index
                                                    ].country_code
                                                  }
                                                </div>
                                              )}
                                          </div>
                                        </div>

                                        <div>
                                          <CustomInput
                                            label="Value"
                                            type="text"
                                            name={`foreigners_countries[${index}].value`}
                                            value={item.value}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          {touched.foreigners_countries?.[index]
                                            ?.value &&
                                            errors.foreigners_countries?.[index]
                                              ?.value && (
                                              <div className={classes.errorMsg}>
                                                {
                                                  errors.foreigners_countries[
                                                    index
                                                  ].value
                                                }
                                              </div>
                                            )}
                                        </div>

                                        <div className={classes.actionbtn}>
                                          <CloseIcon
                                            onClick={() => remove(index)}
                                          />
                                          {index ===
                                            values.foreigners_countries.length -
                                              1 && (
                                            <AddCircleOutlineIcon
                                              onClick={() =>
                                                push({
                                                  country_code: "",
                                                  value: "",
                                                })
                                              }
                                            />
                                          )}
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              );
                            }}
                          </FieldArray>
                        </div>
                        {/* <Addcountry
                          setFieldValue={setFieldValue}
                          singleProductSetting={singleProductSetting}
                          setSelectedCountries={setSelectedCountries}
                          selectedCountries={selectedCountries}
                          masterData={masterData}
                          values={values}
                        /> */}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel9-content"
                        id="panel9-header"
                      >
                        <Typography>9. Loan parameters</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}></div>
                          <div className={classes.inputListMain}>
                            <div className={classes.inputList}>
                              <label htmlFor="">Minimum loan amount</label>
                              <Field
                                type="text"
                                name="min_loan_amount"
                                value={values?.min_loan_amount}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="min_loan_amount"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Maximum loan amount</label>
                              <Field
                                type="text"
                                name="max_loan_amount"
                                value={values?.max_loan_amount}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="max_loan_amount"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Minimum income</label>
                              <Field
                                type="text"
                                name="min_income_amount"
                                value={values?.min_income_amount}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="min_income_amount"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Minimum age</label>
                              <Field
                                type="text"
                                name="min_age"
                                value={values?.min_age}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="min_age"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Maximum age</label>
                              <Field
                                type="text"
                                name="max_age"
                                value={values?.max_age}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="max_age"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Maximum age Male</label>
                              <Field
                                type="text"
                                name="max_age_male"
                                value={values?.max_age_male}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="max_age_male"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Maximum age Female</label>
                              <Field
                                type="text"
                                name="max_age_female"
                                value={values?.max_age_female}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="max_age_female"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Maximum LTV</label>
                              <Field
                                type="text"
                                name="max_ltv"
                                value={values?.max_ltv}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="max_ltv"
                              component="div"
                              style={{ color: "red" }}
                            />

                            <div className={classes.inputList}>
                              <label htmlFor="">DTI (%)</label>
                              <Field
                                type="text"
                                name="dti"
                                value={values?.dti}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="dti"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Max DTI (%)</label>
                              <Field
                                type="text"
                                name="max_dti"
                                value={values?.max_dti}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="max_dti"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">DTSI (%)</label>
                              <Field
                                type="text"
                                name="dtsi"
                                value={values?.dtsi}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="dtsi"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Minimum duration (mount)</label>
                              <Field
                                type="text"
                                name="min_duration_mount"
                                value={values?.min_duration_mount}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="min_duration_mount"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Maximum duration (mount)</label>
                              <Field
                                type="text"
                                name="max_duration_mount"
                                value={values?.max_duration_mount}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="max_duration_mount"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Early repayment</label>
                              <FormControlLabel
                                control={
                                  <Field name="early_repayment">
                                    {({ field, form }) => (
                                      <Checkbox
                                        {...field}
                                        checked={field.value === 1}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          form.setFieldValue(
                                            "early_repayment",
                                            isChecked ? 1 : 0
                                          );
                                        }}
                                      />
                                    )}
                                  </Field>
                                }
                                label="Yes"
                              />
                            </div>
                            <ErrorMessage
                              name="early_repayment"
                              component="div"
                              style={{ color: "red" }}
                            />
                            {/* <div className={classes.inputList}>
                              <label htmlFor="">
                                A Grade Energy Efficiency Level
                              </label>

                              <FormControlLabel
                                control={
                                  <Field name="grade_energy_efficiency_level">
                                    {({ field, form }) => (
                                      <Checkbox
                                        {...field}
                                        checked={field.value === 1}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          form.setFieldValue(
                                            "grade_energy_efficiency_level",
                                            isChecked ? 1 : 0
                                          );
                                        }}
                                      />
                                    )}
                                  </Field>
                                }
                                label=""
                              />
                            </div>
                            <ErrorMessage
                              name="grade_energy_efficiency_level"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                Sallary Transfer required
                              </label>

                              <FormControlLabel
                                control={
                                  <Field name="salary_transfer_required">
                                    {({ field, form }) => (
                                      <Checkbox
                                        {...field}
                                        checked={field.value === 1}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          form.setFieldValue(
                                            "salary_transfer_required",
                                            isChecked ? 1 : 0
                                          );
                                        }}
                                      />
                                    )}
                                  </Field>
                                }
                                label=""
                              />
                            </div>
                            <ErrorMessage
                              name="salary_transfer_required"
                              component="div"
                              style={{ color: "red" }}
                            /> */}
                          </div>
                        </div>
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            8.1 One Time Fees
                          </div>
                          <div className={classes.inputListMain}>
                            <div className={classes.inputList}>
                              <label htmlFor="">AEGRM tax</label>
                              <Field
                                type="text"
                                name="aegrm_tax"
                                value={values?.aegrm_tax}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="aegrm_tax"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Assessment fee</label>
                              <Field
                                type="text"
                                name="assessment_fee"
                                value={values?.assessment_fee}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="assessment_fee"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Property evaluation fee</label>
                              <Field
                                type="text"
                                name="property_evaluation_fee"
                                value={values?.property_evaluation_fee}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="property_evaluation_fee"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        </div>
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            8.2 Monthly Fees
                          </div>
                          <div className={classes.inputListMain}>
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                Admin Fee for the Credit Account
                              </label>
                              <Field
                                type="text"
                                name="admin_fee_for_credit_account"
                                value={values?.admin_fee_for_credit_account}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="admin_fee_for_credit_account"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                Wire transfer payment commision
                              </label>
                              <Field
                                type="text"
                                name="wire_transfer_payment_commission"
                                value={values?.wire_transfer_payment_commission}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="wire_transfer_payment_commission"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel10-content"
                        id="panel10-header"
                      >
                        <Typography>10. Bank Margins over IRCC</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}></div>
                          <div
                            className={`${classes.inputListMain} ${classes.marg}`}
                          >
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                margin for Initial interest rate
                              </label>
                              <Field
                                type="text"
                                name="margin_initial_interest_rate"
                                value={values?.margin_initial_interest_rate}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="margin_initial_interest_rate"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                margin after fix interest rate
                              </label>
                              <Field
                                type="text"
                                name="margin_after_fix_interest_rate"
                                value={values?.margin_after_fix_interest_rate}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="margin_after_fix_interest_rate"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion> */}
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel10-content"
                        id="panel10-header"
                      >
                        <Typography>10. Property insurance</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}></div>
                          <div
                            className={`${classes.inputListMain} ${classes.check}`}
                          >
                            <div className={classes.inputList}>
                              <label className={classes.lab}>
                                <input
                                  type="radio"
                                  name="property_insurance"
                                  value="property_value"
                                  checked={
                                    values.property_insurance ==
                                    "property_value"
                                  }
                                  onChange={handleChange}
                                />
                                insurance for the value of the property
                              </label>
                            </div>
                            <div className={classes.inputList}>
                              <label className={classes.lab}>
                                <input
                                  type="radio"
                                  name="property_insurance"
                                  value="loan_value"
                                  checked={
                                    values.property_insurance == "loan_value"
                                  }
                                  onChange={handleChange}
                                />
                                insurance for the loan value
                              </label>
                            </div>
                            <ErrorMessage
                              name="property_insurance"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                Coverage of the insured value
                              </label>
                              <Field
                                type="text"
                                name="property_insurance_cover_val"
                                value={values?.property_insurance_cover_val}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="property_insurance_cover_val"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel11-content"
                        id="panel11-header"
                      >
                        <Typography>11. Life insurance</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}></div>
                          <div
                            className={`${classes.inputListMain} ${classes.check}`}
                          >
                            <div className={classes.inputList}>
                              <FormControlLabel
                                control={
                                  <Field name="mandatory_life_insurance">
                                    {({ field, form }) => (
                                      <Checkbox
                                        {...field}
                                        checked={field.value === 1}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          form.setFieldValue(
                                            "mandatory_life_insurance",
                                            isChecked ? 1 : 0
                                          );
                                        }}
                                      />
                                    )}
                                  </Field>
                                }
                                label="Mandatory Life insurnace"
                              />
                              <ErrorMessage
                                name="mandatory_life_insurance"
                                component="div"
                                style={{ color: "red" }}
                              />
                            </div>
                            <div className={classes.inputList}>
                              <FormControlLabel
                                control={
                                  <Field name="is_life_insurance_included">
                                    {({ field, form }) => (
                                      <Checkbox
                                        {...field}
                                        checked={field.value === 1}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          form.setFieldValue(
                                            "is_life_insurance_included",
                                            isChecked ? 1 : 0
                                          );
                                        }}
                                      />
                                    )}
                                  </Field>
                                }
                                label="Life Insurance is included in the monthly installment"
                              />
                              <ErrorMessage
                                name="is_life_insurance_included"
                                component="div"
                                style={{ color: "red" }}
                              />
                            </div>
                            <div className={classes.inputList}>
                              <label htmlFor="">Life Insurance costs</label>
                              <Field
                                type="text"
                                name="life_insurance_included_value"
                                value={values?.life_insurance_included_value}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="life_insurance_included_value"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel12-content"
                        id="panel12-header"
                      >
                        <Typography>12. Loan drawdown</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.mainRow}>
                          <div className={classes.labelText}>
                            Drawdown options
                            <span
                              className={classes.selectAll}
                              onClick={() =>
                                handleSelectAll(
                                  "ps_drawdown_option_ids",
                                  productSettings?.drawdown_options
                                )
                              }
                            >
                              {values?.ps_drawdown_option_ids?.length ===
                              productSettings?.drawdown_options?.length
                                ? "Deselect All"
                                : "Select All"}
                            </span>
                          </div>
                          <div className={classes.checkList}>
                            <ErrorMessage
                              name="ps_drawdown_option_ids"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputListcheck}>
                              {productSettings?.drawdown_options?.map(
                                (item) => (
                                  <FormControlLabel
                                    key={item.id}
                                    control={
                                      <Field
                                        as={Checkbox}
                                        type="checkbox"
                                        name="ps_drawdown_option_ids" // Same name as Formik state array
                                        value={item.id} // The id of the current item will be the checkbox value
                                        checked={values?.ps_drawdown_option_ids?.includes(
                                          item?.id
                                        )} // Check if the item is in the selected array
                                        onChange={(e) =>
                                          handleCheckboxChangeNew(
                                            e,
                                            "ps_drawdown_option_ids"
                                          )
                                        } // Call the dynamic handler
                                      />
                                    }
                                    label={item.name} // Display the name of the item
                                  />
                                )
                              )}
                            </div>
                            <ErrorMessage
                              name="max_drawdown_period"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                Max drawdown period (months)
                              </label>
                              <Field
                                type="text"
                                name="max_drawdown_period"
                                value={values?.max_drawdown_period}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="max_drawdown_period_refinancing"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                Max drawdown period for refinancing (months)
                              </label>
                              <Field
                                type="text"
                                name="max_drawdown_period_refinancing"
                                value={values?.max_drawdown_period_refinancing}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="fee_incomplete_drawdown"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                Fee for incomplete drawdown
                              </label>
                              <Field
                                type="text"
                                name="fee_incomplete_drawdown"
                                value={values?.fee_incomplete_drawdown}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="fee_drawdown"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">Fee for drawdown</label>
                              <Field
                                type="text"
                                name="fee_drawdown"
                                value={values?.fee_drawdown}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                            <ErrorMessage
                              name="drawdown_extension_period"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className={classes.inputList}>
                              <label htmlFor="">
                                Extension of drawdown period
                              </label>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Field name="drawdown_extension_period">
                                      {({ field, form }) => (
                                        <Checkbox
                                          {...field}
                                          checked={field.value === 1}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            form.setFieldValue(
                                              "drawdown_extension_period",
                                              isChecked ? 1 : 0
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  }
                                  label="yes"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className={classes.addProductBtn}>
                    <Button
                      onClick={handleCancel}
                      outlineGrey
                      customWidth="170px"
                    >
                      Close
                    </Button>
                    <Button type="submit" blue customWidth="170px">
                      {loading ? <Loader /> : "Save"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default AddProductSetting;
