import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/partials/partial-listing.module.scss";
import style from "./addloan.module.scss";
import {
  getAllFamilyMember,
  deleteFamilyMember,
  createFamilyMember,
  updateFamilyMember,
  getSingleFamilyMember,
} from "../../../api/misc";
import DataTable from "../../../components/shared/customTable";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import Button from "../../../components/shared/customBtn";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../assets/images/edit_square.svg";
import DeleteModal from "../../../components/shared/deleteModal";
import FormatDateTime from "../../../components/shared/FormatMain/formatDate";
import BasicModal from "../../../components/shared/basicmodal";
import CustomInput from "../../../components/shared/customInput";
import Loader from "../../../components/shared/loader";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SavingsIcon from "@mui/icons-material/Savings";

const AddFamilyMember = ({ editExistingclient, handleCancel }) => {
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [loadingCreate, setLoadingCreate] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [count, setCount] = useState(0);
  const [editFamily, setEditFamily] = useState();
  const [openUploadFamily, setOpenuploadFamily] = useState(false);
  const [indepent, setindepent] = useState();
  console.log("indepent", indepent);
  useEffect(() => {
    if (editFamily) {
      setindepent(editFamily?.is_dependent);
    }
  }, [editFamily]);
  let FamilySchema;
  if (indepent == 0) {
    FamilySchema = Yup.object().shape({
      full_name: Yup.string().required("Please enter full name"),
      family_relationship_id: Yup.string().required(
        "Please select relationship"
      ),
      education_level_id: Yup.string().required(
        "Please select education level"
      ),
      date_of_birth: Yup.string().required("Please select date of birth"),
      gender: Yup.string().required("Please select gender"),
      is_dependent: Yup.number().required(
        "Please select financially dependent"
      ),
      family_incomes: Yup.array().of(
        Yup.object().shape({
          income_source_id: Yup.string()
            .required("Income source is required")
            .nullable(false), // Ensures it's not null
          income: Yup.string().required("Income is required").nullable(false), // Ensures it's not null
        })
      ),
    });
  } else {
    FamilySchema = Yup.object().shape({
      full_name: Yup.string().required("Please enter full name"),
      family_relationship_id: Yup.string().required(
        "Please select relationship"
      ),
      education_level_id: Yup.string().required(
        "Please select education level"
      ),
      date_of_birth: Yup.string().required("Please select date of birth"),
      gender: Yup.string().required("Please select gender"),
      is_dependent: Yup.number().required(
        "Please select financially dependent"
      ),
    });
  }
  const handleFamilyModalClose = () => {
    setOpenuploadFamily(false);
    setEditFamily(null);
    setindepent(null);
  };

  const {
    response: getAllFamilyData,
    loading: getFamilyLoading,
    error: getFamilyError,
  } = useGetAxios(getAllFamilyMember(editExistingclient?.id), false, count);

  const handlEdit = async (row) => {
    setOpenuploadFamily(true);
    try {
      // Replace with your API call, here is a mock example
      const res = await getSingleFamilyMember(row.id, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setEditFamily(res?.data?.data);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
  };
  console.log("editfamily", editFamily);
  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteFamilyMember(deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handleAddNewFamily = () => {
    setOpenuploadFamily(true);
  };

  const columns = [
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 1,
      resizable: false,
    },
    {
      field: "family_relationship.name",
      headerName: "Relationship",
      flex: 1,
      resizable: false,
      renderCell: (params) => params.row.family_relationship.name,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      resizable: false,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handlEdit(params.row)}
          >
            <img src={editSquare} alt="editIcon" />
          </span>

          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
            <CloseIcon />
          </span>
        </>
      ),
    },
  ];
  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />

      <div className={classes.addRow}>
        <Button
          customClass={classes.filterBtn}
          blue
          custborderRadius
          customWidth="153px"
          onClick={handleAddNewFamily}
        >
          Add New
        </Button>
        <div className={classes.addRowInner}></div>
      </div>
      <DataTable
        rows={getAllFamilyData?.data?.data}
        columns={columns}
        loading={getFamilyLoading}
        paginationModel={false}
      />
      <div className={style.addUserBtn}>
        <Button onClick={handleCancel} outlineGrey customWidth="170px">
          Close
        </Button>
      </div>
      {deleteId && (
        <DeleteModal
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="Member"
          isLoading={loadingDelete}
        />
      )}

      <BasicModal
        isOpen={openUploadFamily}
        closeModal={handleFamilyModalClose}
        customWidth="994px"
      >
        <div className={classes.defaultForm}>
          <div className={classes.modalHead}>
            <h3>{editFamily ? "Edit Family Member" : "Add Family Member"}</h3>
            <hr />
          </div>

          <Formik
            initialValues={{
              full_name: editFamily ? editFamily.full_name : "",
              date_of_birth: editFamily ? editFamily.date_of_birth : "",
              family_relationship_id: editFamily
                ? editFamily.family_relationship_id
                : "",
              gender: editFamily ? editFamily.gender : "",
              education_level_id: editFamily
                ? editFamily.education_level_id
                : "",
              is_dependent: editFamily ? editFamily.is_dependent : "",
              family_incomes: editFamily
                ? editFamily.incomes
                : [{ income_source_id: "", income: "", deleted: false }],
            }}
            validationSchema={FamilySchema}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
              setLoadingCreate(true);
              const famData = {
                ...values,
                client_id: editExistingclient?.id,
                family_incomes:
                  values.is_dependent == "1" ? [] : values.family_incomes,
              };
              const editfamData = {
                ...values,
                client_id: editExistingclient?.id,
                gender: values.gender,
                family_incomes:
                  values.is_dependent == "1"
                    ? [] // If is_dependent is "1", clear the family_incomes array
                    : values.family_incomes?.map((income) => ({
                        id: income.id,
                        income_source_id: income.income_source_id,
                        income: income.income,
                        deleted: income.deleted ? income.deleted : false, // Set deleted to false
                      })),
              };
              console.log("editfamData", editfamData);
              try {
                let res;
                editFamily
                  ? (res = await updateFamilyMember(
                      editFamily?.id,
                      editfamData,
                      {
                        headers: {
                          lang: lang,
                          Authorization: `Bearer ${tokenAD}`,
                        },
                      }
                    ))
                  : (res = await createFamilyMember(famData, {
                      headers: {
                        lang: lang,
                        Authorization: `Bearer ${tokenAD}`,
                      },
                    }));
                // Handle success response
                if (res?.status === 200) {
                  setLoadingCreate(false);
                  toast.success(res?.data?.message);

                  setTimeout(() => {
                    setOpenuploadFamily(false);
                    setCount(count + 1);
                    resetForm({ values: "" });
                    setEditFamily(null); // Reset form fields
                    setindepent(null);
                  }, 1000);
                } else {
                  throw new Error(res?.data?.message || "An error occurred");
                }
              } catch (error) {
                setLoading(false);
                toast.error(
                  error.response.data.message ||
                    "An error occurred during the process."
                );
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formMain}>
                  <Grid container spacing={2} xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={4} lg={4}>
                      <CustomInput
                        label="Full Name"
                        type="text"
                        name="full_name"
                        value={values.full_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter full name"
                        touched={touched.full_name}
                        errors={errors.full_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <CustomInput
                        label="Birth Date"
                        type="date"
                        name="date_of_birth"
                        value={values.date_of_birth}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        touched={touched.date_of_birth}
                        errors={errors.date_of_birth}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className={style.dynamicMain}>
                        <label className={style.labelName}>Gender</label>
                        <div role="group" aria-labelledby={`gender`}>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              checked={values?.gender == "male"}
                              onChange={handleChange}
                            />
                            M
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              checked={values?.gender == "female"}
                              onChange={handleChange}
                            />
                            F
                          </label>
                        </div>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className={`${style.inputMain}`}>
                        <label>Relationship</label>
                        <div className={style.inputFieldSelect}>
                          <Field
                            as="select"
                            name="family_relationship_id"
                            onBlur={handleBlur}
                            touched={touched.family_relationship_id}
                            errors={errors.family_relationship_id}
                            onChange={handleChange}
                            className={`${style.inputField}`}
                          >
                            <option value="">Select relationship</option>

                            {masterData?.familyRelationships?.map((item) => (
                              <option value={`${item?.id}`}>
                                {item?.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={style.errorMsg}>
                          <ErrorMessage name="family_relationship_id" />
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <div className={`${style.inputMain}`}>
                        <label>Education Level</label>
                        <div className={style.inputFieldSelect}>
                          <Field
                            as="select"
                            name="education_level_id"
                            onBlur={handleBlur}
                            touched={touched.education_level_id}
                            errors={errors.education_level_id}
                            onChange={handleChange}
                            placeholder="Select document type"
                            className={`${style.inputField}`}
                          >
                            <option value="">Select education level</option>

                            {masterData?.educationLevels?.map((item) => (
                              <option value={`${item?.id}`}>
                                {item?.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={style.errorMsg}>
                          <ErrorMessage name="education_level_id" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className={style.dynamicMain}>
                        <label className={style.labelName}>
                          Financially dependent
                        </label>
                        <div role="group" aria-labelledby={`laonType-label`}>
                          <label>
                            <input
                              type="radio"
                              name="is_dependent"
                              value="1"
                              checked={values.is_dependent === 1} // Check if is_dependent is 1
                              onChange={() => {
                                setFieldValue("is_dependent", 1);
                                setindepent(1);
                              }} // Set is_dependent to 1
                            />
                            Yes
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="is_dependent"
                              value="0"
                              checked={values.is_dependent === 0} // Check if is_dependent is 0
                              onChange={() => {
                                setFieldValue("is_dependent", 0);
                                setindepent(0);
                              }} // Set is_dependent to 0
                            />
                            No
                          </label>
                        </div>
                        <div className={style.errorMsg}>
                          <ErrorMessage name="is_dependent" component="div" />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  {values?.is_dependent === 0 && (
                    <div className={style.incomeMain}>
                      <h3>
                        <SavingsIcon /> Income
                      </h3>

                      <FieldArray
                        name="family_incomes"
                        render={(arrayHelpers) => {
                          // Ensure at least one empty row exists
                          if (values.family_incomes.length === 0) {
                            arrayHelpers.push({
                              id: 0,
                              income_source_id: "",
                              income: "",
                              deleted: false,
                            });
                          }

                          return (
                            <ul>
                              {values.family_incomes
                                ?.filter((item) => !item.deleted)
                                ?.map((income, index) => (
                                  <li key={index}>
                                    <h5>{index + 1}.</h5>
                                    <div className={`${style.inputMain}`}>
                                      <label>Income source</label>
                                      <div className={style.inputFieldSelect}>
                                        <Field
                                          as="select"
                                          name={`family_incomes.${index}.income_source_id`}
                                          onBlur={handleBlur}
                                          className={`${style.inputField}`}
                                        >
                                          <option value="">
                                            Select income source
                                          </option>
                                          {masterData?.incomeSources?.map(
                                            (item) => (
                                              <option
                                                key={item?.id}
                                                value={item?.id}
                                              >
                                                {item?.name}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        <div className={style.errorMsg}>
                                          <ErrorMessage
                                            name={`family_incomes.${index}.income_source_id`}
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <CustomInput
                                        label="Income (lei)"
                                        type="text"
                                        name={`family_incomes.${index}.income`}
                                        value={income.income}
                                        onChange={handleChange}
                                      />
                                      <div className={style.errorMsg}>
                                        <ErrorMessage
                                          name={`family_incomes.${index}.income`}
                                          component="div"
                                        />
                                      </div>
                                    </div>

                                    <div className={style.actionbtn}>
                                      {values.family_incomes.length > 1 &&
                                      editFamily ? (
                                        <CloseIcon
                                          onClick={() =>
                                            arrayHelpers.replace(index, {
                                              ...income,
                                              deleted: true,
                                            })
                                          }
                                        />
                                      ) : (
                                        <CloseIcon
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        />
                                      )}
                                      <AddCircleOutlineIcon
                                        onClick={() =>
                                          arrayHelpers.push({
                                            id: 0,
                                            income_source_id: "",
                                            income: "",
                                            deleted: false,
                                          })
                                        }
                                      />
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className={style.addUserBtn}>
                  <Button
                    onClick={handleFamilyModalClose}
                    outlineGrey
                    customWidth="170px"
                  >
                    Close
                  </Button>
                  <Button type="submit" blue customWidth="170px">
                    {loadingCreate ? <Loader /> : <>Add</>}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </BasicModal>
    </div>
  );
};

export default AddFamilyMember;
