import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/partials/partial-listing.module.scss";
import style from "./loan.module.scss";
import { getAllLoans, deleteLoan, ExportLoans } from "../../api/misc";
import DataTablePro from "../../components/shared/customTable/tableGridPro";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import Button from "../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../assets/images/edit_square.svg";
import DeleteModal from "../../components/shared/deleteModal";
import FormatDateOnly from "./../../components/shared/FormatMain/formatDateOnly";
import FormatText from "../../components/shared/FormatMain/formattext";
import CustomFilter from "../../components/shared/customFilter";
import useGetAxios from "../../api/hooks/useGetAxios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../components/shared/loader";

const Users = ({ setLoanState, setEditExistingclient }) => {
  const { t } = useTranslation();
  localStorage.setItem("breadcrumbName", t("loan.heading"));
  // get master data start
  const masterData = useSelector((state) => state?.masterData?.masterData);
  // get master data end

  const userCompleteData = JSON.parse(localStorage.getItem("userData"));
  // start all permissions
  // const hasViewLoanPermission =
  //   userCompleteData?.data?.permissions?.includes("view loans");
  // const hasCreateLoanPermission =
  //   userCompleteData?.data?.permissions?.includes("create loans");
  // const hasUpdateLoanPermission =
  //   userCompleteData?.data?.permissions?.includes("update loans");
  // const hasDeleteLoanPermission =
  //   userCompleteData?.data?.permissions?.includes("delete loans");
  const checkLoanPermission = (viewItemPermissions) => {
    if (
      Array.isArray(viewItemPermissions) &&
      Array.isArray(userCompleteData?.data?.permissions)
    ) {
      return viewItemPermissions.some((permission) =>
        userCompleteData?.data?.permissions?.includes(permission)
      );
    }
    return false; // Return false if either is invalid or no match is found
  };

  const hasViewLoanPermission = checkLoanPermission([
    "view owned",
    "view structure",
    "view all",
  ]);
  const hasCreateLoanPermission = checkLoanPermission(["create loans"]);
  const hasUpdateLoanPermission = checkLoanPermission([
    "update owned",
    "update structure",
    "update all",
  ]);
  const hasDeleteLoanPermission = checkLoanPermission([
    "delete owned",
    "delete structure",
    "delete all",
  ]);

  // end all permissions

  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const dashboardSatus = localStorage.getItem("dashboradStatus");
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [totalRows, setTotalRows] = useState(0); // Total number of rows from API
  const [count, setCount] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 20,
  }); // Pagination state
  const [statusValue, setStatusValue] = useState(
    FilterData?.statuses?.value
      ? FilterData?.statuses?.value
      : dashboardSatus
      ? dashboardSatus
      : "0"
  );

  const [categoryValue, setCategoryValue] = useState(
    FilterData?.category?.value ? FilterData?.category?.value : "0"
  );

  const [providerValue, setProviderValue] = useState(
    FilterData?.provider?.value ? FilterData?.provider?.value : "0"
  );
  const [inputValue, setInputValue] = useState(
    FilterData?.searchName?.value ? FilterData?.searchName?.value : ""
  );

  // get all loans
  const {
    response: getAllLoanData,
    loading: getLoanLoading,
    error: getLoanError,
  } = useGetAxios(
    getAllLoans(
      paginationModel.page,
      paginationModel.pageSize,
      statusValue && statusValue != "0" ? statusValue : "",
      categoryValue && categoryValue != "0" ? categoryValue : "",
      providerValue && providerValue != "0" ? providerValue : "",
      inputValue ? inputValue : ""
    ),
    false,
    count
  );
  const handleExport = async () => {
    console.log("handle export");
    // Add your delete logic here
    setLoadingExport(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await ExportLoans(
        paginationModel.pageSize,
        statusValue && statusValue != "0" ? statusValue : "",
        categoryValue && categoryValue != "0" ? categoryValue : "",
        providerValue && providerValue != "0" ? providerValue : "",
        inputValue ? inputValue : "",
        {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        }
      );

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        console.log("res?.data", res?.data?.download_url);
        const fileUrl = res?.data?.download_url;
        setTimeout(() => {
          window.open(fileUrl, "_self");
        }, 500);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingExport(false);
  };

  // if (deleteId) {
  //   const {
  //     response: getLoanDelete,
  //     loading: getLoanLoadingDelete,
  //     error: getproviderErrorDelete,
  //   } = useDeleteAxios(deleteLoan(deleteId));
  // }

  // const handleDelete = async (id) => {
  //   setOpenDelete(true);
  //   setDeleteId(id);
  // };
  // const handleConfirmDelete = async () => {
  //   setOpenDelete(false);
  //   setCount(count + 1);
  // };

  const handleEdit = (row) => {
    // Add your edit logic here
    setLoanState(false);
    setEditExistingclient(row);
  };

  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteLoan(deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handleAddNewLoan = () => {
    // Add your delete logic here
    setLoanState(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      resizable: false,
      flex: 1,
    },
    {
      field: "client_name",
      headerName: "Client",
      flex: 1,
      resizable: true,
      renderCell: (params) => (
        <>
          {params?.row?.client?.first_name} {params?.row?.client?.last_name}
        </>
      ),
    },
    {
      field: "status",
      headerName: t("loan.status"),
      resizable: true,
      flex: 3,
      renderCell: (params) => <>{FormatText(params?.row?.status)}</>,
    },
    {
      field: "amount",
      headerName: t("loan.amount"),
      resizable: false,
      flex: 1,
    },
    {
      field: "product_name",
      headerName: t("loan.product"),
      resizable: false,
      flex: 2,
    },
    {
      field: "provider_name",
      headerName: t("loan.provider"),
      resizable: false,
      flex: 1,
    },

    {
      field: "created_at",
      headerName: t("loan.applicationDate"),
      resizable: false,
      flex: 2,
      renderCell: (params) => (
        <>
          {params?.row?.created_at
            ? FormatDateOnly(params?.row?.created_at)
            : ""}
        </>
      ),
    },
    {
      field: "updated_at",
      headerName: t("loan.lastUpdate"),
      resizable: false,
      flex: 2,
      renderCell: (params) => (
        <>
          {params?.row?.created_at
            ? FormatDateOnly(params?.row?.updated_at)
            : ""}
        </>
      ),
    },

    {
      ...(hasUpdateLoanPermission || hasDeleteLoanPermission
        ? {
            field: "actions",
            headerName: t("loan.action"),
            width: 150,
            renderCell: (params) => (
              <>
                {hasUpdateLoanPermission && (
                  <span
                    className={classes.iconStyleEdit}
                    onClick={() => handleEdit(params.row)}
                  >
                    <img src={editSquare} alt="editIcon" />
                  </span>
                )}
                {hasDeleteLoanPermission && (
                  <span
                    className={classes.iconStyleDel}
                    onClick={() => handleDelete(params.row.id)}
                  >
                    <DeleteIcon />
                  </span>
                )}
              </>
            ),
          }
        : null),
    },
  ];

  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };

  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };

  return (
    <div className={`${classes.mainListingPage} ${classes.backgroundBase}`}>
      <ToastContainer />
      {hasViewLoanPermission ? (
        <>
          <CustomFilter
            setLoading={setLoading}
            statusValue={statusValue}
            setStatusValue={setStatusValue}
            statusOptions={masterData?.applicationStatuses}
            statusInitialValue="0"
            categoryValue={categoryValue}
            setCategoryValue={setCategoryValue}
            categoryOptions={masterData?.applicationCategories}
            categoryInitialValue="0"
            providerValue={providerValue}
            setProviderValue={setProviderValue}
            providerOptions={masterData?.providers}
            providerInitialValue="0"
            inputValue={inputValue}
            setInputValue={setInputValue}
            searchName
          />

          <div className={classes.addRow}>
            <div>
              {hasCreateLoanPermission && (
                <Button
                  customClass={classes.filterBtn}
                  blue
                  custborderRadius
                  customWidth="153px"
                  onClick={handleAddNewLoan}
                >
                  {t("loan.addBtnText")}
                </Button>
              )}
            </div>
            <div className={classes.addRowInner}>
              <Button
                customClass={classes.filterBtn}
                outlineGrey
                custborderRadius
                onClick={handleExport}
              >
                {t("loan.exportBtnText")} {loadingExport && <Loader />}
              </Button>
              <div className={classes.paginationControls}>
                <FormControl variant="standard" sx={{ minWidth: 60 }}>
                  <InputLabel>{t("loan.rows")}</InputLabel>
                  <Select
                    value={paginationModel.pageSize}
                    onChange={handlePageSizeChange}
                  >
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <DataTablePro
            rows={getAllLoanData?.data?.data}
            columns={columns}
            loading={getLoanLoading}
            totalRows={getAllLoanData?.data?.total}
            paginationModel={paginationModel}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            pinColumnLeft={["id", "client_name", "status"]}
            pinColumnRight={["actions"]}
          />
          {deleteId && (
            <DeleteModal
              isOpen={openDelete}
              onClose={handleClose}
              handleConfirmDelete={handleConfirmDelete}
              name="Loan"
              isLoading={loadingDelete}
            />
          )}
        </>
      ) : (
        <p>You have no permission to view the loan applications</p>
      )}
    </div>
  );
};

export default Users;
