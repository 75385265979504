// components/DataTablePro.js
import React from "react";
// import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Loader from "../loader";
// import CustomNoRowsOverlay from "./noRecord";

const DataTablePro = ({
  rows,
  columns,
  loading,
  totalRows,
  paginationModel,
  onPageChange,
  sortModel,
  setSortModel,
  pinColumnLeft,
  pinColumnRight,
}) => {
  const totalPages = Math.ceil(totalRows / paginationModel?.pageSize);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Paper sx={{ minHeight: "400px", width: "100%", boxShadow: "none" }}>
          <DataGridPro
            rows={rows}
            columns={columns.map((col) => ({
              ...col,
              ...(col?.field == "checkbox"
                ? { minWidth: 50 }
                : col?.field == "id"
                ? { minWidth: 100 }
                : col?.field == "applications_count"
                ? { minWidth: 70 }
                : col?.field == "actions"
                ? { minWidth: 100, width: 100, pinned: "right" }
                : { minWidth: 150 }), // Apply minWidth only if field is NOT checkbox or id
            }))}
            rowCount={totalRows}
            paginationMode="server"
            hideFooter // Hide default pagination
            disableColumnMenu={true}
            sortModel={sortModel && sortModel} // Set the default sort model
            onSortModelChange={
              sortModel && ((newModel) => setSortModel(newModel))
            }
            sortingOrder={["asc", "desc"]}
            initialState={{
              pinnedColumns: { left: pinColumnLeft, right: pinColumnRight },
            }}
            sx={{
              border: 0,
              width: "100%",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#efefef",
              },
            }}
          />

          {paginationModel && (
            <Pagination
              count={totalPages} // Total number of pages
              page={paginationModel.page} // Current page
              onChange={onPageChange} // Handle page change
              siblingCount={2} // Number of pages to show around the current page
              boundaryCount={1} // Number of pages to show at the start/end
              showFirstButton // Show "First" button
              showLastButton // Show "Last" button
              sx={{ mt: 2, display: "flex", justifyContent: "center" }}
            />
          )}
        </Paper>
      )}
    </>
  );
};

export default DataTablePro;
