import React, { useState } from "react";
import Ircc from "./getAllIrcc";
import AddEditIrcc from "./AddEditIrcc";
const MainAccessLevel = () => {
  const [irccState, setIrccState] = useState(true);
  const [editIrcc, setEditIrcc] = useState();
  const handleCancel = () => {
    setIrccState(true);
    setEditIrcc(null);
  };
  return (
    <>
      {irccState ? (
        <Ircc setIrccState={setIrccState} setEditIrcc={setEditIrcc} />
      ) : (
        <AddEditIrcc
          editIrcc={editIrcc}
          handleCancel={handleCancel}
          setEditIrcc={setEditIrcc}
          setIrccState={setIrccState}
        />
      )}
    </>
  );
};

export default MainAccessLevel;
