import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLayout from "./components/adminLayout";
import NoPage from "./pages/nopage";
import SignGDPR from "./pages/signGDPR";
import ProtectedRoute from "./components/protectedRoute";
import AuthRedirectRoute from "./components/protectedRoute/authRedirectRoute";
import Unauthorized from "./components/unauthorized";
import "./index.css";
// auth links
import Login from "./pages/Auth/Login";
import TwoFactorAuth from "./pages/Auth/Login/twoFactorAuth";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import VerifyAccount from "./pages/Auth/VerifyAccount";

// pages links
import Home from "./pages/home";

// settings Pages
import SettingsLayout from "./pages/settings";
import GeneralSettings from "./pages/settings/generalSettings";
import Appearance from "./pages/settings/appearance";
import Privacy from "./pages/settings/privacy";
import Notifications from "./pages/settings/notifications";
import Providers from "./pages/settings/providers";
import Products from "./pages/settings/products";
import Users from "./pages/settings/users";
import Permissions from "./pages/settings/permissions";
import AccessLevel from "./pages/settings/accessLevel";
import CareerLevels from "./pages/settings/careerLevels";
import AppSettings from "./pages/settings/appSettings";
import Loans from "./pages/loans";
import Client from "./pages/clients";
import CreditComparer from "./pages/loans/creditComparer";
import Commission from "./pages/clients/getAllClientCommission";
import AgentCommission from "./pages/clients/getAllAgentCommission";
import Reconciliation from "./pages/clients/getAllBankReconciliation";
import PaymentSlips from "./pages/clients/getAllPaymentSlip";

import IncomeSource from "./pages/settings/incomeSource";
import Branches from "./pages/settings/branches";
import Profile from "./pages/profile";
import Expenses from "./pages/settings/expenses";
import Ircc from "./pages/settings/ircc";
import AllNotifications from "./components/header/getAllNotifications";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* Auth links  */}
        <Route
          path="/sign-in"
          element={
            // <AuthRedirectRoute>
            <Login />
            // </AuthRedirectRoute>
          }
        />

        <Route path="/twoFactorAuth" element={<TwoFactorAuth />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-account" element={<VerifyAccount />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/signGDPR/:id" element={<SignGDPR />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route path="*" element={<NoPage />} />
          <Route
            index
            element={
              <ProtectedRoute>
                <NoPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/overview/summary"
            element={
              <ProtectedRoute requiredPermissions={["view summary"]}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/loans/applications"
            element={
              <ProtectedRoute
                requiredPermissions={["manage loans", "applications"]}
              >
                <Loans />
              </ProtectedRoute>
            }
          />
          <Route
            path="/loans/credit-compare"
            element={
              <ProtectedRoute requiredPermissions={["view credit compare"]}>
                <CreditComparer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/my-commissions"
            element={
              <ProtectedRoute requiredPermissions={["my commissions"]}>
                <Commission />
              </ProtectedRoute>
            }
          />
          <Route
            path="/financials/agent-commissions"
            element={
              <ProtectedRoute requiredPermissions={["agent commissions"]}>
                <AgentCommission />
              </ProtectedRoute>
            }
          />
          <Route
            path="/financials/reconciliation"
            element={
              <ProtectedRoute requiredPermissions={["reconciliation"]}>
                <Reconciliation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/financials/payment-slips"
            element={
              <ProtectedRoute requiredPermissions={["payment slips"]}>
                <PaymentSlips />
              </ProtectedRoute>
            }
          />

          <Route
            path="/allNotifications"
            element={
              <ProtectedRoute>
                <AllNotifications />
              </ProtectedRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoute requiredPermissions={["view users"]}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <ProtectedRoute requiredPermissions={["view clients"]}>
                <Client />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <ProtectedRoute requiredPermissions={["messages"]}>
                <Home />
              </ProtectedRoute>
            }
          />
          {/* setting start  */}
          <Route
            path="/"
            element={
              <ProtectedRoute requiredPermissions={["settings"]}>
                <SettingsLayout />
              </ProtectedRoute>
            }
          >
            <Route
              path="/settings/general-settings"
              element={
                <ProtectedRoute requiredPermissions={["view general-settings"]}>
                  <GeneralSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/application-settings"
              element={
                <ProtectedRoute
                  requiredPermissions={["view application settings"]}
                >
                  <AppSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/appearance"
              element={
                <ProtectedRoute requiredPermissions={["view appearance"]}>
                  <Appearance />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/privacy-and-safety"
              element={
                <ProtectedRoute
                  requiredPermissions={["view privacy-and-safety"]}
                >
                  <Privacy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/notifications"
              element={
                <ProtectedRoute requiredPermissions={["view notifications"]}>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/providers"
              element={
                <ProtectedRoute requiredPermissions={["view providers"]}>
                  <Providers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/products"
              element={
                <ProtectedRoute requiredPermissions={["view products"]}>
                  <Products />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/users"
              element={
                <ProtectedRoute requiredPermissions={["view users"]}>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/permissions"
              element={
                <ProtectedRoute requiredPermissions={["view permissions"]}>
                  <Permissions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/career-levels"
              element={
                <ProtectedRoute requiredPermissions={["view career levels"]}>
                  <CareerLevels />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/access-levels"
              element={
                <ProtectedRoute requiredPermissions={["view access levels"]}>
                  <AccessLevel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/income-sources"
              element={
                <ProtectedRoute requiredPermissions={["view income sources"]}>
                  <IncomeSource />
                </ProtectedRoute>
              }
            />

            <Route
              path="/settings/expenses"
              element={
                <ProtectedRoute requiredPermissions={["view expenses"]}>
                  <Expenses />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/bnr-ircc"
              element={
                <ProtectedRoute requiredPermissions={["view bnr"]}>
                  <Ircc />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/branches"
              element={
                <ProtectedRoute requiredPermissions={["view branches"]}>
                  <Branches />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* setting end  */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
