import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./addloan.module.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import { addLoanProduct, getProductByProvider } from "../../../api/misc";
import { useSelector } from "react-redux";

const ProductSchema = Yup.object().shape({
  provider_id: Yup.string().required("Please select provider"),
  product_id: Yup.string().required("Please select product"),
  application_category_id: Yup.string().required(
    "Please select application category"
  ),
  amount: Yup.string().required("Please enter amount"),
  currency: Yup.string().required("Please select currency"),
  application_date: Yup.string().required("Please select date"),
});

const AddProduct = ({
  clientData,
  handleCancel,
  setLoanState,
  setClientData,
  editExistingclient,
  setEditExistingclient,
}) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  // const [checked, setChecked] = useState(clientData?.is_company ? true : false); // Checkbox state
  const [providers, setProviders] = useState([]);
  const [productOption, setProduct] = useState([]);
  const [applicationCatigory, setApplicationCatigory] = useState([]);
  const [loadingProductCategories, setLoadingProductCategories] =
    useState(false);

  const fetchProductCategories = async (selectedProviderId) => {
    if (selectedProviderId) {
      setLoadingProductCategories(true);
      try {
        const response = await getProductByProvider(selectedProviderId, {
          headers: {
            lang: lang,
            Authorization: `Bearer ${tokenAD}`,
          },
        });
        setProduct(response.data.data.productsByProvider);
        setLoadingProductCategories(false);
      } catch (error) {
        setLoadingProductCategories(false);
      }
    }
  };
  useEffect(() => {
    if (clientData?.provider_id) {
      fetchProductCategories(clientData?.provider_id); // Fetch product categories for the default provider_id
    }
  }, [clientData?.provider_id]);
  // for edit
  useEffect(() => {
    if (editExistingclient?.provider_id) {
      fetchProductCategories(editExistingclient?.provider_id); // Fetch product categories for the default provider_id
    }
  }, [editExistingclient?.provider_id]);
  console.log("clientData", clientData);
  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={
            editExistingclient
              ? {
                  provider_id: editExistingclient?.provider_id,
                  product_id: editExistingclient?.product_id,
                  application_category_id:
                    editExistingclient?.application_category_id,
                  amount: editExistingclient?.amount,
                  currency: editExistingclient?.currency,
                  application_date: editExistingclient?.application_date,
                }
              : {
                  provider_id: "",
                  product_id: "",
                  application_category_id: "",
                  amount: "",
                  currency: "",
                  application_date: "",
                }
          }
          validationSchema={ProductSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            console.log("valuesProduct", values);
            // const sendData = {
            //   product_name: values?.product_name,
            //   provider_id: values?.provider_id,
            //   product_id: values?.product_id,
            //   short_description: values?.short_description,
            // };

            try {
              let res;
              res = await addLoanProduct(editExistingclient?.id, values, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });

              // Handle success response
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);

                setTimeout(() => {
                  setEditExistingclient(res?.data?.data);
                  // setProductState(true); // Update provider state
                  // resetForm({ values: "" }); // Reset form fields
                  // setClientData(null);
                  // setClientData(res?.data?.data?.product);
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2} xs={12} md={12} lg={6}>
                  <Grid item xs={12} md={12} lg={12}>
                    <div className={`${classes.inputMain}`}>
                      <label>Provider</label>
                      <div className={classes.inputFieldSelect}>
                        <Field
                          as="select"
                          name="provider_id"
                          onBlur={handleBlur}
                          touched={touched.provider_id}
                          errors={errors.provider_id}
                          onChange={async (event) => {
                            const selectedProviders = event.target.value;
                            setFieldValue("provider_id", selectedProviders);
                            setFieldValue("product_id", ""); // Reset product_id when provider_id changes
                            fetchProductCategories(selectedProviders);
                          }}
                          placeholder="Provider"
                          className={`${classes.inputField}`}
                        >
                          <option value="">Select Provider</option>
                          {masterData?.providers?.map((provider) => (
                            <option value={`${provider?.id}`}>
                              {provider?.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className={classes.errorMsg}>
                        <ErrorMessage name="provider_id" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    {loadingProductCategories ? (
                      <p>Loading product...</p>
                    ) : (
                      <div className={`${classes.inputMain}`}>
                        <label>Product</label>
                        <div className={classes.inputFieldSelect}>
                          <Field
                            as="select"
                            name="product_id"
                            onBlur={handleBlur}
                            touched={touched.product_id}
                            errors={errors.product_id}
                            onChange={handleChange}
                            disabled={
                              !values.provider_id || productOption?.length === 0
                            }
                            placeholder="Select Product"
                            className={`${classes.inputField}`}
                          >
                            <option value="">Select Product</option>

                            {productOption?.map((product) => (
                              <option value={product?.id}>
                                {product?.product_name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="product_id" />
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <br></br>

                <Grid container spacing={2} xs={12} md={6} lg={6}>
                  <Grid item xs={12} md={3} lg={6}>
                    <CustomInput
                      label="Amount"
                      type="text"
                      name="amount"
                      value={values.amount}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter amount"
                      touched={touched.amount}
                      errors={errors.amount}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <CustomInput
                      label="Currency"
                      type="select"
                      name="currency"
                      value={values.currency}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Select Currency"
                      touched={touched.currency}
                      errors={errors.currency}
                      options={["EUR", "RO", "PK", "USD"]}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <div className={`${classes.inputMain}`}>
                      <label>Application Category</label>
                      <div className={classes.inputFieldSelect}>
                        <Field
                          as="select"
                          name="application_category_id"
                          onBlur={handleBlur}
                          touched={touched.application_category_id}
                          errors={errors.application_category_id}
                          onChange={handleChange}
                          placeholder="Select Application Category"
                          className={`${classes.inputField}`}
                        >
                          <option value="">Select Application Category</option>

                          {masterData?.applicationCategories?.map((cati) => (
                            <option value={cati?.id}>
                              {cati?.category_name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className={classes.errorMsg}>
                        <ErrorMessage name="application_category_id" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Application date"
                      type="date"
                      name="application_date"
                      value={values.application_date}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter short description"
                      touched={touched.application_date}
                      errors={errors.application_date}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addUserBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                {editExistingclient?.status_slug ===
                "indicative-offer-refused-process-closed" ? (
                  ""
                ) : (
                  <Button type="submit" blue customWidth="170px">
                    {loading ? <Loader /> : <>Save</>}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddProduct;
