import React, { useState, memo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import classes from "./addloan.module.scss";
import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { updateAML } from "../../../api/misc";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ClientSchema = Yup.object().shape({
  risk_factor_id: Yup.string().required("Please select risk factor"),
});

const AddAML = memo(
  ({ handleCancel, editExistingclient, setEditExistingclient }) => {
    const { t } = useTranslation();
    const amlRiskFactor = useSelector(
      (state) => state?.masterData?.masterData?.amlRiskFactors
    );
    const lang = localStorage.getItem("lang");
    const tokenAD = JSON.parse(localStorage.getItem("authToken"));
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(
      editExistingclient?.aml?.is_politicaly_exposed || false
    );

    const handlePolicticallyExposed = (event) =>
      setChecked(event.target.checked);

    return (
      <>
        <ToastContainer />
        <div className={classes.defaultForm}>
          <Formik
            enableReinitialize
            initialValues={{
              risk_factor_id: editExistingclient?.aml?.risk_factor_id || "9",
              is_politicaly_exposed:
                editExistingclient?.aml?.is_politicaly_exposed || false,
              specification_risk:
                editExistingclient?.aml?.specification_risk || "",
            }}
            validationSchema={ClientSchema}
            onSubmit={async (values) => {
              const updateData = {
                risk_factor_id: values.risk_factor_id,
                is_politicaly_exposed: values.is_politicaly_exposed,
                specification_risk: values.is_politicaly_exposed
                  ? values.specification_risk
                  : "",
              };
              setLoading(true);
              try {
                const res = await updateAML(
                  editExistingclient?.id,
                  updateData,
                  {
                    headers: { lang, Authorization: `Bearer ${tokenAD}` },
                  }
                );

                if (res?.status === 200) {
                  setEditExistingclient(res?.data?.data);
                  setTimeout(() => {
                    toast.success(res?.data?.message || "Update successful");
                  }, 500);
                } else {
                  throw new Error(res?.data?.message || "An error occurred");
                }
              } catch (error) {
                toast.error(
                  error.response?.data?.message ||
                    "An error occurred during the process."
                );
              } finally {
                setLoading(false);
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formMain}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <div className={classes.inputMain}>
                        <label>{t("loan.riskFactor")}*</label>
                        <Field
                          as="select"
                          name="risk_factor_id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={classes.inputField}
                        >
                          <option value="">Select Risk Factor</option>
                          {amlRiskFactor?.map((riskFactor) => (
                            <option key={riskFactor?.id} value={riskFactor?.id}>
                              {riskFactor?.riskfactor}
                            </option>
                          ))}
                        </Field>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="risk_factor_id" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            name="is_politicaly_exposed"
                            checked={values.is_politicaly_exposed}
                            onChange={(e) => {
                              setFieldValue(
                                "is_politicaly_exposed",
                                e.target.checked
                              );
                              handlePolicticallyExposed(e);
                            }}
                          />
                        }
                        label={t("loan.policyExposePerson")}
                      />
                    </Grid>
                    {checked && (
                      <Grid item xs={12} md={12} lg={12}>
                        <CustomInput
                          label={t("loan.specificationRisk")}
                          type="textarea"
                          name="specification_risk"
                          value={values.specification_risk}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Specification of risk"
                          rows={4}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
                <div className={classes.addUserBtn}>
                  <Button
                    onClick={handleCancel}
                    outlineGrey
                    customWidth="170px"
                  >
                    {t("loan.close")}
                  </Button>
                  {editExistingclient?.status_slug ===
                  "indicative-offer-refused-process-closed" ? (
                    ""
                  ) : (
                    <Button type="submit" blue customWidth="170px">
                      {loading ? <Loader /> : t("loan.save")}
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }
);

export default AddAML;
