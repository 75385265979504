import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./Login.module.scss";
import { Link } from "react-router-dom";
import { userLogin } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import loginBanner from "../../../assets/images/loginBanner2.png";
import logo from "../../../assets/images/logo.png";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { useTranslation } from "react-i18next";
import CustomLanguage from "../../../components/shared/customLanguage";
import Loader from "../../../components/shared/loader";
import axios from "axios";
import { creteRecordGeoLocation } from "../../../api/misc";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    ),
  // password: Yup.string()
  //   .required("Please enter your password")
  //   .min(8, "Password must be at least 6 characters long"),
});
const Login = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classes.mainLogin}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{ language: lang, email: "", password: "" }}
          validationSchema={loginSchema}
          onSubmit={async (values) => {
            setLoading(true);
            localStorage.setItem("userRole", "admin");
            const lang = localStorage.getItem("lang");

            try {
              const res = await userLogin(values, {
                headers: {
                  lang: lang ? lang : "en",
                },
              });

              if (res?.status == 200) {
                if (res?.data?.data?.requires_2fa) {
                  localStorage.setItem("userData", JSON.stringify(res?.data));
                  localStorage.setItem("lang", lang ? lang : "en");
                  navigate("/twoFactorAuth");
                } else {
                  toast.success(res?.data?.message);
                  dispatch({
                    type: LOGIN,
                    payload: res?.data,
                  });

                  localStorage.setItem(
                    "authToken",
                    JSON.stringify(res?.data?.data?.token)
                  );
                  localStorage.setItem("userData", JSON.stringify(res?.data));
                  localStorage.setItem("lang", lang ? lang : "en");
                  const routeGo =
                    (res?.data?.data?.menu[0]?.collapsable &&
                      res?.data?.data?.menu[0]?.dropdown) ||
                    (!res?.data?.data?.menu[0]?.collapsable &&
                      res?.data?.data?.menu[0]?.dropdown)
                      ? res?.data?.data?.menu[0]?.items[0]?.link
                      : res?.data?.data?.menu[0]?.link;
                  setTimeout(async () => {
                    setLoading(false);
                    if (res?.data?.data?.requires_2fa) {
                      navigate("/twoFactorAuth");
                    } else {
                      navigate(routeGo ? `/${routeGo}` : "/help");
                    }

                    if (res?.data.data.geo_location) {
                      const agentData = await axios.get(
                        "https://ipinfo.io/json?token=bf12de50cc381f"
                      );
                      await creteRecordGeoLocation(
                        {
                          user_agent: agentData.data,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${res?.data?.data?.token}`,
                            lang: "en",
                          },
                        }
                      );
                    }
                  }, 1000);
                }
              }
            } catch (error) {
              setLoading(false);
              const showErr = error?.response?.data?.message;
              toast.error(showErr);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <img src={logo} alt="logo" />
                <h1>{t("loginPage.login")}</h1>
                {/* <p>{t("loginPage.description")}</p> */}
              </div>
              <CustomLanguage defValue={lang} />
              <CustomInput
                label={t("loginPage.email")}
                type="text"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t("loginPage.enterEmail")}
                touched={touched.email}
                errors={errors.email}
              />
              <div className={classes.rememberForgot}>
                <Link to="/forgot-password">
                  <span className={classes.forgotStyle}>
                    {t("loginPage.forgotPass")}?
                  </span>
                </Link>
              </div>
              <CustomInput
                label={t("loginPage.password")}
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t("loginPage.enterPass")}
                touched={touched.password}
                errors={errors.password}
                eyeIcon
                setPasswordChange={setShowPassword}
                passwordChange={showPassword}
              />

              <Button type="submit" blue disabled={!(isValid && dirty)}>
                {loading ? <Loader /> : <>{t("loginPage.loginBtn")}</>}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rightBanner}>
        <img src={loginBanner} alt="login banner" height="100%" />
      </div>
    </div>
  );
};

export default Login;
