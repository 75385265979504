import React, { useState, useEffect } from "react";
import classes from "./addUsers.module.scss";
import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import { Formik, Form, Field } from "formik";
import { Checkbox, FormControlLabel, Grid, Switch } from "@mui/material";
import Button from "../../../../components/shared/customBtn";
import {
  getAllPermissions,
  updatePermissions,
  getUserSingle,
  updateTwoFactor,
  updateIpFilter,
} from "../../../../api/misc";
import useGetAxios from "../../../../api/hooks/useGetAxios";
import BasicModal from "../../../../components/shared/basicmodal";
import CustomInput from "../../../../components/shared/customInput";
import * as Yup from "yup";

const DocumentSchema = Yup.object().shape({
  document_type: Yup.string().required("Please enter document type"),
  document_name: Yup.string().required("Please enter document name"),
  issuing_date: Yup.string().required("Please select issuing date"),
  expiring_date: Yup.string().required("Please select expiring date"),
  document: Yup.string().required("Please select document"),
});
const AddSecurity = ({ editUser, handleCancel, setEditUser }) => {
  const lang = localStorage.getItem("lang");
  const ipInfo = localStorage.getItem("ipInfo");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [facLoading, setFacLoading] = useState(false);
  const [filLoading, setFilLoading] = useState(false);
  const [openUploadDocument, setOpenuploadDocument] = useState(false);
  const [twoFacData, setTwoFacData] = useState();
  const [filStatus, setFilStatus] = useState(
    editUser?.ip_whitelist == "active" ? true : false
  );

  const {
    response: getAllPermissionData,
    loading: getPermissionLoading,
    error: getPermissionError,
  } = useGetAxios(getAllPermissions(), false);
  const {
    response: getAllUserPermissionData,
    loading: getUserPermissionLoading,
    error: getUserPermissionError,
  } = useGetAxios(getUserSingle(editUser?.id), false, count);

  const initialPermissions =
    getAllUserPermissionData?.data?.user?.roles?.map((perm) => perm?.id) || [];

  const handleTwoFactor = async () => {
    setFacLoading(true);
    try {
      let res = await updateTwoFactor(editUser?.id, {
        headers: {
          lang: lang,
          Authorization: `Bearer ${tokenAD}`,
        },
      });

      if (res?.status === 200) {
        setFacLoading(false);
        toast.success(res?.data?.message);
        setEditUser({
          ...editUser,
          login_two_factor: res?.data?.data?.login_two_factor,
        });
      } else {
        throw new Error(res?.data?.message || "An error occurred");
      }
    } catch (error) {
      setFacLoading(false);
      toast.error(
        error.response.data.message || "An error occurred during the process."
      );
    }
  };

  const handleBrochureModalClose = () => {
    setOpenuploadDocument(false);
  };
  const handleIpFilter = () => {
    setOpenuploadDocument(true);
  };
  const handleIpFilterToggle = () => {
    setFilStatus(!filStatus);
  };

  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          enableReinitialize // Ensures the form is reinitialized when userPermission is fetched
          initialValues={{
            roles: initialPermissions, // Set slugs in initialValues from userPermission
            ip_whitelist: editUser?.ip_whitelist || "",
            ip_whitelist_list: editUser?.ip_whitelist_list || "",
          }}
          onSubmit={async (values, { resetForm }) => {
            console.log("valuesvaluesvalues", values);
            setLoading(true);
            const permissionData = {
              roles: values.roles,
            };
            const ipData = {
              ip_whitelist: filStatus ? "active" : "inactive",
              ip_whitelist_list: values.ip_whitelist_list,
            };
            try {
              let res = await updatePermissions(editUser?.id, permissionData, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });

              if (res?.status === 200) {
                setLoading(false);
                // toast.success("User has assigned the permissions");
                setCount(count + 1);
                // setTimeout(() => {
                //   resetForm();
                // }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
              // ip call
              let resIp = await updateIpFilter(editUser?.id, ipData, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });

              if (resIp?.status === 200) {
                setLoading(false);
                // toast.success(resIp?.data?.message);
                setEditUser({
                  ...editUser,
                  ip_whitelist: resIp?.data?.data?.ip_whitelist,
                  ip_whitelist_list: resIp?.data?.data?.ip_whitelist_list,
                });
              } else {
                throw new Error(resIp?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }

            // ip filter
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <h2>Access Level </h2>
              <small>Assign Roles to the current User</small>
              <hr />
              {getPermissionLoading ? (
                <Loader />
              ) : (
                getAllPermissionData?.data?.map((item, index) => (
                  <div key={index}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="roles"
                          value={item?.id}
                          type="checkbox"
                          checked={values?.roles?.includes(item?.id)} // Check if slug is selected
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("roles", [
                                ...values.roles,
                                item?.id,
                              ]);
                            } else {
                              setFieldValue(
                                "roles",
                                values.roles.filter((perm) => perm !== item?.id)
                              );
                            }
                          }}
                        />
                      }
                      label={item?.name}
                    />
                  </div>
                ))
              )}
              <h2>Additional Security Settings </h2>
              <hr />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <div className={classes.twoFactorBox}>
                    <h3>Two-Factor Authentication</h3>
                    <p>
                      Two-Factor Authentication adds an extra layer of security
                      to your account, ensuring that only you have the rights to
                      log in.
                    </p>
                    <Button
                      onClick={handleTwoFactor}
                      outlineGrey
                      customWidth="200px"
                      customClass={
                        editUser?.login_two_factor == "active" && classes.black
                      }
                    >
                      {facLoading ? (
                        <Loader />
                      ) : editUser?.login_two_factor == "active" ? (
                        "Disable 2FA"
                      ) : (
                        "Enable 2FA"
                      )}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className={classes.twoFactorBox}>
                    <h3>IP White List</h3>
                    <p>Limit the access to a known set of IP addresses</p>
                    {/* <Button
                      onClick={handleIpFilter}
                      outlineGrey
                      customWidth="170px"
                    >
                      {filLoading ? <Loader /> : "Enable IP Filtering"}
                    </Button> */}
                    <Button
                      onClick={handleIpFilterToggle}
                      outlineGrey
                      customWidth="250px"
                      customClass={filStatus && classes.black}
                    >
                      {filStatus
                        ? "Disable IP Filtering"
                        : "Enable IP Filtering"}
                    </Button>
                    {(filStatus || editUser?.ip_whitelist_list) && (
                      <Grid item xs={12} md={12} lg={12} mt={2}>
                        <CustomInput
                          // label="IP"
                          type="textarea"
                          name="ip_whitelist_list"
                          value={values.ip_whitelist_list}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter IP"
                          touched={touched.ip_whitelist_list}
                          errors={errors.ip_whitelist_list}
                        />
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
              <div className={classes.addUserBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? <Loader /> : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <BasicModal
        isOpen={openUploadDocument}
        closeModal={handleBrochureModalClose}
        customWidth="994px"
      >
        <div className={classes.defaultForm}>
          <div className={classes.modalHead}>
            <h3>Set IP Filtering</h3>
            <hr />
          </div>

          <Formik
            initialValues={{
              ip_whitelist: "",
              ip_whitelist_list: "",
            }}
            // validationSchema={DocumentSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log("values", values);
              setFilLoading(true);
              const ipData = {
                ip_whitelist: values.ip_whitelist,
                ip_whitelist_list: values.ip_whitelist_list,
              };
              console.log("valuesipData", ipData);
              try {
                let res = await updateIpFilter(editUser?.id, ipData, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });

                if (res?.status === 200) {
                  setFilLoading(false);
                  toast.success(res?.data?.message);
                } else {
                  throw new Error(res?.data?.message || "An error occurred");
                }
              } catch (error) {
                setFilLoading(false);
                toast.error(
                  error.response.data.message ||
                    "An error occurred during the process."
                );
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formMain}>
                  <Grid container spacing={2} xs={12} md={6} lg={6}>
                    <Grid item xs={12} md={12} lg={12}>
                      <p>Status</p>
                      {/* <Switch
                        checked={values.ip_whitelist}
                        name="ip_whitelist"
                        label="Status"
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      /> */}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.ip_whitelist}
                            className={classes.dynamicSwitch}
                            onChange={(event) =>
                              setFieldValue(
                                "ip_whitelist",
                                event.target.checked
                              )
                            }
                          />
                        }
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <CustomInput
                        label="IP"
                        type="textarea"
                        name="ip_whitelist_list"
                        value={values.ip_whitelist_list}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter IP"
                        touched={touched.ip_whitelist_list}
                        errors={errors.ip_whitelist_list}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.addUserBtn}>
                  <Button
                    onClick={handleBrochureModalClose}
                    outlineGrey
                    customWidth="170px"
                  >
                    Close
                  </Button>
                  <Button type="submit" blue customWidth="170px">
                    {loadingCreate ? <Loader /> : <>Save</>}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </BasicModal>
    </>
  );
};

export default AddSecurity;
