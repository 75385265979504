import React, { useState, useEffect, forwardRef } from "react";
import classes from "./customLanguage.module.scss";
import { useTranslation } from "react-i18next";
import { Field } from "formik";

const CustomLanguage = (defValue) => {
  const { i18n } = useTranslation();
  const lang = localStorage.getItem("lang");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lang", lng);
  };
  useEffect(() => {
    localStorage.setItem("lang", lang ? lang : "en");
  }, []);
  return (
    <div className={`${classes.inputMainLanguage}`}>
      <div className={classes.inputFieldSelect}>
        <Field
          as="select"
          name="language"
          value={defValue?.defValue}
          onChange={(e) => changeLanguage(e.target.value)}
          placeholder="Select language"
          className={`${classes.inputField}`}
        >
          <option value="">Select Language</option>
          <option value="en">English</option>
          <option value="ro">Romanian</option>
        </Field>
      </div>
    </div>
  );
};

export default CustomLanguage;
