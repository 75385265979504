import React, { useState } from "react";
import Users from "./getAllUser";
import AddUser from "./addUser";
const MainUser = () => {
  const [userState, setUserState] = useState(true);
  const [editUser, setEditUser] = useState();
  const handleCancel = () => {
    setUserState(true);
    setEditUser(null);
  };
  console.log("editUser", editUser);
  return (
    <>
      {userState ? (
        <Users setUserState={setUserState} setEditUser={setEditUser} />
      ) : (
        <AddUser
          editUser={editUser}
          handleCancel={handleCancel}
          setUserState={setUserState}
          setEditUser={setEditUser}
        />
      )}
    </>
  );
};

export default MainUser;
