import React, { useState, useEffect, memo, useCallback } from "react";
import { Formik, Form } from "formik";
import classes from "../creditComparer/comparer.module.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import { GetClientPrescoring } from "../../../api/misc";
import { useSelector } from "react-redux";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import RuleIcon from "@mui/icons-material/Rule";
import DataTablePro from "../../../components/shared/customTable/tableGridPro";
import BasicModal from "../../../components/shared/basicmodal";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { useTranslation } from "react-i18next";

const ComparerSchema = Yup.object().shape({
  age: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
  gender: Yup.string().required("Field is required"),
  down_payment_percentage: Yup.number()
    .required("Field is required")
    .min(15, "Must be at least 15%")
    .max(100, "Cannot exceed 100%"),
  income_employment_indefinite: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
  income_employment_indefinite_start_date:
    Yup.string().required("Field is required"),
  income_employment_fixed_start_date:
    Yup.string().required("Field is required"),
  income_employment_fixed: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
  income_freelancing_taxable: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
  income_freelancing_real: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
  income_dividends: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
  income_srl: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
  income_micro_srl: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
  income_others: Yup.number()
    .required("Field is required")
    .positive("Must be a positive number"),
});
const calculateSum = (fields, values) => {
  return fields.reduce((sum, field) => {
    const value = parseFloat(values[field]) || 0;
    return sum + value;
  }, 0);
};
const PreScoring = ({ editExistingclient, handleCancel }) => {
  const { t } = useTranslation();
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [resultloading, setResultLoading] = useState(false);
  const [resultData, setResultData] = useState();
  const [openViewComparer, setOpenViewComparer] = useState(false);
  const [viewComparerSingle, setViewComparerSingle] = useState();
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const {
    response: getAllGetClientPrescoringData,
    loading: getGetClientPrescoringLoading,
    error: getGetClientPrescoringError,
  } = useGetAxios(GetClientPrescoring(editExistingclient?.id), false);
  const fieldsToSum = [
    "income_employment_indefinite",
    "income_employment_fixed",
    "income_freelancing_taxable",
    "income_freelancing_real",
    "income_dividends",
    "income_srl",
    "income_micro_srl",
    "income_others",
  ];
  const fieldsToSumExpense = [
    "expense_loan_banking",
    "expense_loan_nbfis",
    "expense_credit_card_used",
    "expense_credit_card_unsed",
  ];

  const columns = [
    {
      field: "bank_name",
      headerName: t("loan.bank"),
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const logoUrl = params?.row?.logo;
        return logoUrl ? (
          <img
            src={logoUrl}
            alt={params?.bank_name}
            style={{ margin: "5px 0" }}
          />
        ) : (
          params?.bank_name
        );
      },
    },
    {
      field: "total_income",
      headerName: t("loan.incomeAccepted"),
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;

        return value ? Math.round(value).toLocaleString() : "0";
      },
    },
    {
      field: "existent_dti",
      headerName: t("loan.existingDti"),
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? parseFloat(value).toFixed(2) + "%" : "0.00%";
      },
    },
    {
      field: "unused_credit_card_limit",
      headerName: t("loan.otherUnusedCard"),
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? parseFloat(value).toFixed(2) + "%" : "0.00%";
      },
    },
    {
      field: "remaining_dti",
      headerName: t("loan.dtiLeft"),
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? parseFloat(value).toFixed(2) + "%" : "0.00%";
      },
    },
    {
      field: "max_mortgage_installment",
      headerName: t("loan.maxMortgageRate"),
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value).toLocaleString() : "0";
      },
    },
    {
      field: "max_duration_years",
      headerName: t("loan.maxDurationYears"),
      resizable: false,
      flex: 1,
    },
    {
      field: "max_loan",
      headerName: t("loan.maxCredit"),
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value).toLocaleString() : "0";
      },
    },
    {
      field: "max_property_value",
      headerName: t("loan.maxPropertyVal"),
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const value = params.value;
        return value ? Math.round(value).toLocaleString() : "0";
      },
    },

    // {
    //   field: "actions",
    //   headerName: "Repayment schedule",
    //   width: 150,
    //   renderCell: (params) => (
    //     <span
    //       className={classes.iconStyleEdit}
    //       //   onClick={() => handleEdit(params.row)}
    //     >
    //       view
    //     </span>
    //   ),
    // },
  ];

  const rowsd = getAllGetClientPrescoringData?.data?.result.map(
    (row, index) => ({
      ...row,
      id: row.setting_id || index, // Use existing id or generate one
    })
  );
  return (
    <>
      <ToastContainer />

      {/* <div className={classes.defaultForm}> */}
      {/* <h3 className={classes.head}>
          <PublishedWithChangesIcon /> Quick Loan Comparer
        </h3> */}
      {/* <Formik
          enableReinitialize
          initialValues={{
            age: "",
            gender: "", // male, female
            down_payment_percentage: "",
            income_employment_indefinite: "",
            income_employment_indefinite_start_date: "",
            income_employment_fixed: "",
            income_employment_fixed_start_date: "",
            income_freelancing_taxable: "",
            income_freelancing_real: "",
            income_dividends: "",
            income_srl: "",
            income_micro_srl: "",
            income_others: "",
            expense_loan_banking: "",
            expense_loan_nbfis: "",
            expense_credit_card_used: "",
            expense_credit_card_unsed: "",
          }}
          validationSchema={ComparerSchema}
          onSubmit={async (values, { resetForm }) => {
            console.log("valuessss", values);
            setLoading(true);

            try {
              let res = await CreatePreScoring(values, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);
                setResultData(res?.data.data);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }

              // Handle success response
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            touched,
            errors,
            handleSubmit,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div
                  className={`${classes.formMain} ${classes.prescoringStyle}`}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <CustomInput
                        label="Age"
                        type="text"
                        name="age"
                        value={values.age}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder=""
                        touched={touched.age}
                        errors={errors.age}
                      />

                      <div className={classes.inputMain}>
                        <label>Gender</label>
                        <Field
                          as="select"
                          name="gender"
                          className={classes.inputField}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e); // Formik's default handler
                          }}
                        >
                          <option value="">Select</option>
                          <option value="male">Male</option>
                          <option value="female">female</option>
                        </Field>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="gender" />
                        </div>
                      </div>
                      <CustomInput
                        label="Down Payment (%)"
                        type="text"
                        name="down_payment_percentage"
                        value={values.down_payment_percentage}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder=""
                        touched={touched.down_payment_percentage}
                        errors={errors.down_payment_percentage}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h5>1. Monthly Incomes {`(lei)`}</h5>
                      <div className={classes.oneLine}>
                        <CustomInput
                          label="Employment"
                          type="text"
                          name="income_employment_indefinite"
                          value={values.income_employment_indefinite}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const sum = calculateSum(fieldsToSum, {
                              ...values,
                              income_employment_indefinite: e.target.value,
                            });
                            setTotalIncome(sum);
                          }}
                          // onChange={handleChange}
                          placeholder="infinite period"
                          touched={touched.income_employment_indefinite}
                          errors={errors.income_employment_indefinite}
                        />
                        <CustomInput
                          label="Contract start date"
                          type="date"
                          name="income_employment_indefinite_start_date"
                          value={values.income_employment_indefinite_start_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          touched={
                            touched.income_employment_indefinite_start_date
                          }
                          errors={
                            errors.income_employment_indefinite_start_date
                          }
                        />
                      </div>

                      <div className={classes.oneLine}>
                        <CustomInput
                          // label="Down Payment (%)"
                          type="text"
                          name="income_employment_fixed"
                          value={values.income_employment_fixed}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const sum = calculateSum(fieldsToSum, {
                              ...values,
                              income_employment_fixed: e.target.value,
                            });
                            setTotalIncome(sum);
                          }}
                          // onChange={handleChange}
                          placeholder="fixed period"
                          touched={touched.income_employment_fixed}
                          errors={errors.income_employment_fixed}
                        />
                        <CustomInput
                          // label="Contract start date"
                          type="date"
                          name="income_employment_fixed_start_date"
                          value={values.income_employment_fixed_start_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          touched={touched.income_employment_fixed_start_date}
                          errors={errors.income_employment_fixed_start_date}
                        />
                      </div>

                      <CustomInput
                        label="Freelancing (taxable income threshold)"
                        type="text"
                        name="income_freelancing_taxable"
                        value={values.income_freelancing_taxable}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const sum = calculateSum(fieldsToSum, {
                            ...values,
                            income_freelancing_taxable: e.target.value,
                          });
                          setTotalIncome(sum);
                        }}
                        // onChange={handleChange}
                        placeholder=""
                        touched={touched.income_freelancing_taxable}
                        errors={errors.income_freelancing_taxable}
                      />
                      <CustomInput
                        label="Freelancing (Real income taxation system)"
                        type="text"
                        name="income_freelancing_real"
                        value={values.income_freelancing_real}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const sum = calculateSum(fieldsToSum, {
                            ...values,
                            income_freelancing_real: e.target.value,
                          });
                          setTotalIncome(sum);
                        }}
                        // onChange={handleChange}
                        placeholder=""
                        touched={touched.income_freelancing_real}
                        errors={errors.income_freelancing_real}
                      />
                      <div className={classes.oneLine}>
                        <CustomInput
                          label="Dividends"
                          type="text"
                          name="income_dividends"
                          value={values.income_dividends}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const sum = calculateSum(fieldsToSum, {
                              ...values,
                              income_dividends: e.target.value,
                            });
                            setTotalIncome(sum);
                          }}
                          //   onChange={handleChange}
                          placeholder=""
                          touched={touched.income_dividends}
                          errors={errors.income_dividends}
                        />
                        <CustomInput
                          label="SRL"
                          type="text"
                          name="income_srl"
                          value={values.income_srl}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const sum = calculateSum(fieldsToSum, {
                              ...values,
                              income_srl: e.target.value,
                            });
                            setTotalIncome(sum);
                          }}
                          //   onChange={handleChange}
                          placeholder=""
                          touched={touched.income_srl}
                          errors={errors.income_srl}
                        />
                        <CustomInput
                          label="Micro SRL"
                          type="text"
                          name="income_micro_srl"
                          value={values.income_micro_srl}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const sum = calculateSum(fieldsToSum, {
                              ...values,
                              income_micro_srl: e.target.value,
                            });
                            setTotalIncome(sum);
                          }}
                          //   onChange={handleChange}
                          placeholder=""
                          touched={touched.income_micro_srl}
                          errors={errors.income_micro_srl}
                        />
                      </div>
                      <CustomInput
                        label="other"
                        type="text"
                        name="income_others"
                        value={values.income_others}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const sum = calculateSum(fieldsToSum, {
                            ...values,
                            income_others: e.target.value,
                          });
                          setTotalIncome(sum);
                        }}
                        // onChange={handleChange}
                        placeholder=""
                        touched={touched.income_others}
                        errors={errors.income_others}
                      />
                      <h3>
                        TOTAL INCOMES: <span>{totalIncome}</span>
                      </h3>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <h5>2. Monthly expenses for other loans {`(lei)`}</h5>
                      <CustomInput
                        label="Personal loans (banking)"
                        type="text"
                        name="expense_loan_banking"
                        value={values.expense_loan_banking}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const sum = calculateSum(fieldsToSumExpense, {
                            ...values,
                            expense_loan_banking: e.target.value,
                          });
                          setTotalExpense(sum);
                        }}
                        // onChange={handleChange}
                        placeholder=""
                        touched={touched.expense_loan_banking}
                        errors={errors.expense_loan_banking}
                      />
                      <CustomInput
                        label="Personal loans (NBFIs)"
                        type="text"
                        name="expense_loan_nbfis"
                        value={values.expense_loan_nbfis}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const sum = calculateSum(fieldsToSumExpense, {
                            ...values,
                            expense_loan_nbfis: e.target.value,
                          });
                          setTotalExpense(sum);
                        }}
                        // onChange={handleChange}
                        placeholder=""
                        touched={touched.expense_loan_nbfis}
                        errors={errors.expense_loan_nbfis}
                      />
                      <CustomInput
                        label="Credit cards - used credit"
                        type="text"
                        name="expense_credit_card_used"
                        value={values.expense_credit_card_used}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const sum = calculateSum(fieldsToSumExpense, {
                            ...values,
                            expense_credit_card_used: e.target.value,
                          });
                          setTotalExpense(sum);
                        }}
                        // onChange={handleChange}
                        placeholder=""
                        touched={touched.expense_credit_card_used}
                        errors={errors.expense_credit_card_used}
                      />
                      <CustomInput
                        label="Credit cards - unused credit"
                        type="text"
                        name="expense_credit_card_unsed"
                        value={values.expense_credit_card_unsed}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const sum = calculateSum(fieldsToSumExpense, {
                            ...values,
                            expense_credit_card_unsed: e.target.value,
                          });
                          setTotalExpense(sum);
                        }}
                        // onChange={handleChange}
                        placeholder=""
                        touched={touched.expense_credit_card_unsed}
                        errors={errors.expense_credit_card_unsed}
                      />
                      <h3>
                        TOTAL EXPENSES: <span>{totalExpense}</span>
                      </h3>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className={classes.addUserBtn}>
                        <Button type="submit" blue customWidth="170px">
                          {loading ? <Loader /> : <>Analyse</>}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            );
          }}
        </Formik> */}
      {/* </div> */}
      {getGetClientPrescoringLoading ? (
        <Loader />
      ) : (
        <div className={classes.defaultFormTwo}>
          <h3 className={classes.head}>{t("loan.preScoreheading")}</h3>
          <div className={classes.reduceHeight}>
            <DataTablePro
              rows={rowsd}
              columns={columns}
              paginationModel={false}
              pinColumnLeft={["bank_name"]}
            />
          </div>
        </div>
      )}
      <div className={classes.addUserBtn}>
        <Button onClick={handleCancel} outlineGrey customWidth="170px">
          {t("loan.close")}
        </Button>
      </div>
      {/* <BasicModal
        isOpen={openViewComparer}
        closeModal={handleViewComparerModalClose}
        customWidth="994px"
      >
        <DataTable
          rows={rowsOverall}
          columns={columnsOverall}
          paginationModel={false}
        />
      </BasicModal> */}
    </>
  );
};

export default PreScoring;
