import React, { useState, useEffect } from "react";
import classes from "./careerLevels.module.scss";
import ListTree from "../../../components/shared/treeList";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { AllCareerLevels } from "../../../api/misc";
import Button from "../../../components/shared/customBtn";
import DeleteModal from "../../../components/shared/deleteModal";
import { DeleteCareerLevel } from "../../../api/misc";
import { ToastContainer, toast } from "react-toastify";

const ListCareerLevel = ({ setCareerLevelState, setEditCareerLevel }) => {
  localStorage.setItem("breadcrumbName", "Settings | Career Levels ");
  const [count, setCount] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    response: getAllCareerLevelData,
    loading: getCareerLevelLoading,
    error: getCareerLevelError,
  } = useGetAxios(AllCareerLevels(), false, count);

  const [allCareerLevelData, setAllCareerLevelData] = useState(
    getAllCareerLevelData?.data
  );

  useEffect(() => {
    setAllCareerLevelData(getAllCareerLevelData?.data);
  }, [getAllCareerLevelData]);

  // const handleEdit = (row) => {
  //   // Add your edit logic here
  //   setProviderState(false);
  //   setEditProvider(row);
  // };

  // const handleDelete = async (id) => {
  //   setOpenDelete(true);
  //   setDeleteId(id);
  // };

  // console.log(allCareerLevelData);
  const handleEdit = (row) => {
    // console.log("Editing:", item);
    // Implement edit functionality
    setCareerLevelState(false);
    setEditCareerLevel(row);
  };

  const handleDelete = async (row) => {
    setOpenDelete(true);
    setDeleteId(row.id);
  };

  const handleAddNewCareerLevel = () => {
    // Add your delete logic here
    setCareerLevelState(false);
  };

  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await DeleteCareerLevel(deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };

  return (
    <>
      <ToastContainer />
      <div className={classes.mainUser}>
        <h1>CareerLevels</h1>
        <Button
          customClass={classes.filterBtn}
          blue
          custborderRadius
          customWidth="153px"
          onClick={handleAddNewCareerLevel}
        >
          Add New
        </Button>
        <ListTree
          data={allCareerLevelData}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
        {deleteId && (
          <DeleteModal
            isOpen={openDelete}
            onClose={handleClose}
            handleConfirmDelete={handleConfirmDelete}
            name="Career Level"
            isLoading={loadingDelete}
          />
        )}
      </div>
    </>
  );
};

export default ListCareerLevel;
