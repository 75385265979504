import axios from "axios";
// http://192.168.1.15:8080/24Credit_BE/api

var base_url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: base_url,
  // baseURL: "https://services.24credit.ro/dev/api",
  // baseURL: "http://24credit.local.com/api",
});
// https://services.24credit.ro/dev/api
