import React from "react";
import classes from "./addProviders.module.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./addProvidersBuiltin.scss";
import AddAccessLevelMain from "./addAccessLevelMain";
import AccessLevelHistory from "./accessLevelHistory";
// import AddCareer from "./careerLevel";

const AccessLevelTabs = ({
  editAccessLevel,
  handleCancel,
  setEditAccessLevel,
}) => {
  localStorage.setItem(
    "breadcrumbName",
    editAccessLevel ? "Edit Access levels" : "Add Access levels"
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  console.log("editAccessLevelmaintab", editAccessLevel);
  return (
    <>
      {editAccessLevel ? <h2>#{editAccessLevel.name}</h2> : null}
      <div className={classes.MainProvider}>
        <div className={classes.ProviderTabContainer}>
          <Box className={`${classes.tabsHead} ProviderTabBuiltin`}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Setting" {...a11yProps(0)} />
              <Tab
                label="History"
                {...a11yProps(1)}
                disabled={editAccessLevel ? false : true}
              />
            </Tabs>
          </Box>
        </div>
        <div className={classes.ProfileTabs}>
          <CustomTabPanel value={value} index={0}>
            <AddAccessLevelMain
              handleCancel={handleCancel}
              editAccessLevel={editAccessLevel}
              setEditAccessLevel={setEditAccessLevel}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AccessLevelHistory
              handleCancel={handleCancel}
              editAccessLevel={editAccessLevel}
            />
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default AccessLevelTabs;
