import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../header";
import Sidebar from "../sidebar";
import classes from "./adminLayout.module.scss";
import "./adminBuiltin.scss";
import {
  getAllCounties,
  getStatuses,
  getCatgories,
  getProviders,
  getAllCareer,
  getUserStatus,
  getProductStatus,
  getProviderStatus,
  getAllProviderType,
  getAllUsersName,
  getAllBranches,
  getAllMasterData,
} from "../../api/misc";
import { useDispatch } from "react-redux";
import useGetAxios from "../../api/hooks/useGetAxios";
import axios from "axios";

const AdminLayout = () => {
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const { response: masterData } = useGetAxios(
    getAllMasterData(),
    false,
    0,
    dispatch,
    "MASTERDATA"
  );

  useEffect(() => {
    const getIpInfo = async () => {
      try {
        // Replace with your API call, here is a mock example
        const agentData = await axios.get(
          "https://ipinfo.io/json?token=bf12de50cc381f",
          {
            headers: {
              Authorization: `Bearer ${tokenAD}`,
              lang: lang,
            },
          }
        );

        if (agentData?.status == 200) {
          console.log("ipppppppppppp", agentData?.data?.ip);
          localStorage.setItem("ipInfo", agentData?.data?.ip);
        }
      } catch (error) {
        const showErr = error?.response?.data?.message;
        console.log(showErr);
      }
    };
    getIpInfo();
  }, []);

  // navbar start
  const [isNavbar, setIsNavbar] = useState(false);
  const [closeNavbar2, setCloseNavbar2] = useState(false);
  const handleArrow = () => {
    setCloseNavbar2(false);
    setIsNavbar(!isNavbar);
  };

  return (
    <>
      <div className={classes.mainLayoutSection}>
        <div
          className={`${classes.mainHeader} ${
            isNavbar && classes.removeMarginHead
          }`}
        >
          <Header handleArrow={handleArrow} />
        </div>
        <div className={classes.mainSidebarContent}>
          <div
            className={`${classes.mainSidebar} ${
              isNavbar && classes.openSideBar
            } ${closeNavbar2 && classes.closeSideBar} ${
              isNavbar && "hideMenuItem"
            }`}
          >
            <Sidebar
              setCloseNavbar2={setCloseNavbar2}
              isNavbar={isNavbar}
              setIsNavbar={setIsNavbar}
              handleArrow={handleArrow}
            />
          </div>
          <div
            className={`${classes.mainContent} ${
              isNavbar && classes.removeMargin
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
