import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./ircc.module.scss";
// import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import { AddIrcc, UpdateIrcc } from "../../../api/misc";
import { useSelector } from "react-redux";

const IrccSchema = Yup.object().shape({
  start_date: Yup.string().required("Field is required"),
  value: Yup.string().required("Field is required"),
});

const AddEditIrcc = ({ editIrcc, handleCancel, setEditIrcc, setIrccState }) => {
  localStorage.setItem(
    "breadcrumbName",
    editIrcc ? "Edit BNR IRCC" : "Add BNR IRCC"
  );
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);

  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={
            editIrcc
              ? {
                  start_date: editIrcc?.start_date,
                  value: editIrcc?.value,
                }
              : {
                  start_date: "",
                  value: "",
                }
          }
          validationSchema={IrccSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);

            try {
              let res;

              // Check if editing or creating a new user
              if (editIrcc) {
                res = await UpdateIrcc(editIrcc?.id, values, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              } else {
                res = await AddIrcc(values, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              }

              // Handle success response
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);

                setTimeout(() => {
                  // setUserState(true); // Update user state
                  resetForm({ values: "" }); // Reset form fields
                  setIrccState(true);
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                {/* <h3>{editIrcc?.value && editIrcc?.value}</h3> */}
                <Grid container spacing={2} xs={12} md={6} lg={6}>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Start Date"
                      type="date"
                      name="start_date"
                      value={values?.start_date}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter name"
                      touched={touched.start_date}
                      errors={errors.start_date}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Value(%)"
                      type="text"
                      name="value"
                      value={values?.value}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter name"
                      touched={touched.value}
                      errors={errors.value}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addUserBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? <Loader /> : <>{editIrcc ? "Save" : "Add"}</>}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddEditIrcc;
