import React, { useState } from "react";
import Provider from "./getAllProvider";
import AddProvider from "./addProvider";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { useDispatch } from "react-redux";
import { getAllMasterData } from "../../../api/misc";
const MainProvider = () => {
  const dispatch = useDispatch();
  const [providerState, setProviderState] = useState(true);
  const [editProvider, setEditProvider] = useState();
  const handleCancel = () => {
    setProviderState(true);
    setEditProvider(null);
  };
  const [countProvider, setCountProvider] = useState(0);
  const { response: masterDataProvider } = useGetAxios(
    getAllMasterData(),
    false,
    countProvider,
    dispatch,
    "MASTERDATA"
  );
  return (
    <>
      {providerState ? (
        <Provider
          setProviderState={setProviderState}
          setEditProvider={setEditProvider}
          setCountProvider={setCountProvider}
          countProvider={countProvider}
        />
      ) : (
        <AddProvider
          editProvider={editProvider}
          handleCancel={handleCancel}
          setProviderState={setProviderState}
          setEditProvider={setEditProvider}
          setCountProvider={setCountProvider}
          countProvider={countProvider}
        />
      )}
    </>
  );
};

export default MainProvider;
