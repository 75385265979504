import React, { useState } from "react";
import Loans from "./getAllClients";
import AddLoan from "./addClient";
const MainClient = () => {
  const [loanState, setLoanState] = useState(true);
  const [clientData, setClientData] = useState();
  const [editExistingclient, setEditExistingclient] = useState();
  const handleCancel = () => {
    setLoanState(true);
    setClientData(null);
    setEditExistingclient(null);
  };
  console.log("editExistingclient", editExistingclient);
  return (
    <>
      {loanState ? (
        <Loans
          setLoanState={setLoanState}
          setClientData={setClientData}
          setEditExistingclient={setEditExistingclient}
        />
      ) : (
        <AddLoan
          clientData={clientData}
          handleCancel={handleCancel}
          setLoanState={setLoanState}
          setClientData={setClientData}
          setEditExistingclient={setEditExistingclient}
          editExistingclient={editExistingclient}
        />
      )}
    </>
  );
};

export default MainClient;
