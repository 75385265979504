import React, { useState, useEffect } from "react";
import classes from "./careerLevels.module.scss";
import ListTree from "../../../components/shared/treeList";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { AllCareerLevels } from "../../../api/misc";
import Button from "../../../components/shared/customBtn";
import ListCareerLevel from "./getAllCareerLevel";
import AddEditCareerLevel from "./AddEditCareerLevel";

const CareerLevels = () => {
  const [careerLevelState, setCareerLevelState] = useState(true);
  const [editCareerLevel, setEditCareerLevel] = useState();

  const handleCancel = () => {
    setCareerLevelState(true);
    setEditCareerLevel(null);
  };
  // const [count, setCount] = useState(0);

  // const {
  //   response: getAllCareerLevelData,
  //   loading: getCareerLevelLoading,
  //   error: getCareerLevelError,
  // } = useGetAxios(
  //   AllCareerLevels(),
  //   false,
  //   count
  // );

  // const [allCareerLevelData, setAllCareerLevelData] = useState(getAllCareerLevelData?.data);

  // useEffect(() => {
  //   setAllCareerLevelData(getAllCareerLevelData?.data);
  // }, [getAllCareerLevelData]);

  // // console.log(allCareerLevelData);
  // const handleEdit = (item) => {
  //   console.log("Editing:", item);
  //   // Implement edit functionality
  // };

  // const handleDelete = (item) => {
  //   console.log("Deleting:", item);
  //   // Implement delete functionality
  // };

  // const handleAdd = () => {
  //   // Add your delete logic here
  //   setCareerLevelState(false);
  // };

  return (
    <>
      {careerLevelState ? (
        <ListCareerLevel
          setCareerLevelState={setCareerLevelState}
          setEditCareerLevel={setEditCareerLevel}
        />
      ) : (
        // <div>Add</div>
        <AddEditCareerLevel
          editCareerLevel={editCareerLevel}
          handleCancel={handleCancel}
          setCareerLevelState={setCareerLevelState}
          setEditCareerLevel={setEditCareerLevel}
        />
      )}
      {/* <div className={classes.mainUser}>
        <h1>CareerLevels</h1>
        <Button
          customClass={classes.filterBtn}
          blue
          custborderRadius
          customWidth="153px"
          onClick={handleAdd}
        >
          Add New
        </Button>
        <ListTree data={allCareerLevelData} onEdit={handleEdit} onDelete={handleDelete} />
      </div> */}
    </>
  );
};

export default CareerLevels;
