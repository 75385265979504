import React, { useState, useEffect, useRef } from "react";
import classes from "../../../assets/styles/partials/partial-listing.module.scss";
import style from "./addloan.module.scss";
import { getAllLoanGDPR, generateLoanGDPR } from "../../../api/misc";
import DataTable from "../../../components/shared/customTable";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "../../../components/shared/customBtn";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../../assets/images/edit_square.svg";
import DeleteModal from "../../../components/shared/deleteModal";
import FormatDateTime from "../../../components/shared/FormatMain/formatDate";
import BasicModal from "../../../components/shared/basicmodal";
import CustomInput from "../../../components/shared/customInput";
import Loader from "../../../components/shared/loader";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { useTranslation } from "react-i18next";
import CachedIcon from "@mui/icons-material/Cached";

const AddGDPR = ({
  clientData,
  handleCancel,
  editExistingclient,
  setAllstatusVal,
  setCountStatus,
  countStatus,
}) => {
  const { t } = useTranslation();
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [count, setCount] = useState(0);
  const [brochuresLink, setBrochuresLink] = useState();
  const [openPreviewBrochuer, setOpenPreviewBrochuer] = useState(false);
  const [getStatus, setgetStatus] = useState(null);

  const handlePreviewModalClose = () => {
    setOpenPreviewBrochuer(false);
  };
  const {
    response: getAllGDPRData,
    loading: getGDPRLoading,
    error: getGDPRError,
  } = useGetAxios(
    getAllLoanGDPR(
      editExistingclient ? editExistingclient?.id : clientData?.id
    ),
    false,
    count
  );

  const handlePreview = (row) => {
    setOpenPreviewBrochuer(true);
    setBrochuresLink(row?.file_path);
    setgetStatus(row);
  };

  const handleRefresh = () => {
    setCount(count + 1);
    setCountStatus(countStatus + 1);
  };
  const handleAddNewProduct = async () => {
    // setOpenuploadBrochuer(true);
    setLoading(true); // Show loading
    try {
      // Replace with your API call, here is a mock example
      const response = await generateLoanGDPR(
        editExistingclient ? editExistingclient?.id : clientData?.id,
        {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        }
      );
      if (response?.status === 200) {
        setCount(count + 1);
        setCountStatus(countStatus + 1);
        setAllstatusVal("gdpr-sent");
      } else {
        throw new Error(response?.data?.message || "An error occurred");
      }
      // setRows(response.data.data);
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
      setCount(count + 1);
    }
    setLoading(false); // Hide loading
  };

  const columns = [
    {
      field: "client_name",
      headerName: t("loan.clientName"),
      resizable: false,
      flex: 1,
    },
    {
      field: "gdpr_date",
      headerName: t("loan.gdprDate"),
      flex: 1,
      resizable: false,
      renderCell: (params) => (
        <>
          {params?.row?.created_at
            ? FormatDateTime(params?.row?.created_at)
            : ""}
        </>
      ),
    },
    {
      field: "status",
      headerName: t("loan.status"),
      resizable: false,
      flex: 1,
    },

    {
      field: "actions",
      headerName: t("loan.action"),
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handlePreview(params.row)}
          >
            Preview
          </span>
          {/* |
          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
    
            Delete
          </span> */}
        </>
      ),
    },
  ];

  return (
    <div className={classes.mainListingPage}>
      <ToastContainer />

      <div className={classes.addRow}>
        {editExistingclient?.status_slug ===
        "indicative-offer-refused-process-closed" ? (
          ""
        ) : (
          <Button
            customClass={classes.filterBtn}
            blue
            custborderRadius
            customWidth="300px"
            onClick={handleAddNewProduct}
          >
            {loading ? <Loader /> : t("loan.gdprBTN")}
          </Button>
        )}
        <div className={classes.addRowInner}>
          {" "}
          <div className={style.refreshBtn} onClick={handleRefresh}>
            <CachedIcon /> Referesh
          </div>
        </div>
      </div>
      <DataTable
        rows={getAllGDPRData?.data}
        columns={columns}
        loading={getGDPRLoading}
        paginationModel={false}
      />
      <div className={style.addUserBtn}>
        <Button onClick={handleCancel} outlineGrey customWidth="170px">
          {t("loan.close")}
        </Button>
      </div>

      <BasicModal
        isOpen={openPreviewBrochuer}
        closeModal={handlePreviewModalClose}
        customWidth="994px"
        customClass="removePadi"
      >
        {getStatus?.status === "signed" && (
          <p style={{ padding: "20px 40px" }}>
            The document has been accepted and signed on:{" "}
            {getStatus?.signed_date}
            <br />
            Country: {getStatus?.user_agent?.country}, City:{" "}
            {getStatus?.user_agent?.city}, Latitude:{" "}
            {getStatus?.user_agent?.latitude}, Longitude:{" "}
            {getStatus?.user_agent?.longitude} <br />
            IP: {getStatus?.user_agent?.ip}, <br />
            User Agent: {getStatus?.user_agent?.userAgent}
          </p>
        )}
        <iframe
          src={`${brochuresLink}#navpanes=0&scrollbar=0?timestamp=${Date.now()}`}
          width="100%"
          height="1000px"
          style={{ border: "none" }}
          title="PDF Viewer"
        />
      </BasicModal>
    </div>
  );
};

export default AddGDPR;
