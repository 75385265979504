import axios from "../utilities/index";
// for client
export const getAllNotification = (headers) => {
  return axios.get(`Notifications/GetAll`, headers);
};
export const updateNotification = (id, headers) => {
  return axios.put(`Notifications/UpdateStatus/${id}`, {}, headers);
};

export const getAllNotificationDynamic = (
  page,
  pageSize,
  notificaitonStatus,
  notificaitonType
) => {
  let getUrl = `/Notifications/GetAll?page=${page}&per_page=${pageSize}`;

  // Add status to the URL if it's not empty
  if (notificaitonStatus) {
    getUrl += `&status=${notificaitonStatus}`;
  }
  // Add career to the URL if it's not empty
  if (notificaitonType) {
    getUrl += `&type=${notificaitonType}`;
  }

  return getUrl;
};

export const getAllClientCommission = (
  page,
  pageSize,
  paymentStatus,
  month,
  searchName
) => {
  let getUrl = `/Reports/GetAllCommissions?page=${page}&per_page=${pageSize}`;

  // Add status to the URL if it's not empty
  if (paymentStatus) {
    getUrl += `&status=${paymentStatus}`;
  }
  // Add career to the URL if it's not empty
  if (month) {
    getUrl += `&month=${month}`;
  }

  // Add searchName to the URL if it's not empty
  if (searchName) {
    getUrl += `&name=${searchName}`;
  }
  return getUrl;
};
export const getAllPaymentSlips = (
  page,
  pageSize,
  month,
  bank,
  paymentStatus,
  deliveryStatus,
  searchName
) => {
  let getUrl = `/Financials/GetAllPaymentSlips?page=${page}&per_page=${pageSize}`;

  // Add career to the URL if it's not empty
  if (month) {
    getUrl += `&month=${month}`;
  }
  // Add career to the URL if it's not empty
  if (bank) {
    getUrl += `&provider_id=${bank}`;
  }
  // Add status to the URL if it's not empty
  if (paymentStatus) {
    getUrl += `&payment_status=${paymentStatus}`;
  }
  if (deliveryStatus) {
    getUrl += `&delivery_status=${deliveryStatus}`;
  }
  // Add searchName to the URL if it's not empty
  if (searchName) {
    getUrl += `&name=${searchName}`;
  }
  return getUrl;
};

export const deleteSlip = (id, headers) => {
  return axios.delete(`/Financials/DeletePaymentSlip/${id}`, headers);
};
export const updateDeliveryStatus = (id, type, data, headers) => {
  return axios.put(
    `/Financials/UpdatePaymentSlip/${id}/${type}`,
    data,
    headers
  );
};
export const getSingleSlip = (id, headers) => {
  return axios.get(`/Financials/GetPaymentSlip/${id}`, headers);
};
export const ExportCommission = (paymentStatus, month, headers) => {
  return axios.get(
    `/Reports/ExportCommissions?status=${paymentStatus}&month=${month}`,
    headers
  );
};
export const getAllAgentCommission = (
  comType,
  page,
  pageSize,
  paymentStatus,
  month,
  bank,
  searchName
) => {
  let getUrl = `/Financials/GetAllCommissions/${comType}?page=${page}&per_page=${pageSize}`;

  // Add status to the URL if it's not empty
  if (paymentStatus) {
    getUrl += `&status=${paymentStatus}`;
  }
  // Add career to the URL if it's not empty
  if (month) {
    getUrl += `&month=${month}`;
  }
  // Add career to the URL if it's not empty
  if (bank) {
    getUrl += `&provider_id=${bank}`;
  }

  // Add searchName to the URL if it's not empty
  if (searchName) {
    getUrl += `&client_name=${searchName}`;
  }
  return getUrl;
};

export const ExportAgentCommission = (paymentStatus, month, headers) => {
  return axios.get(
    `/Financials/ExportCommissions?status=${paymentStatus}&month=${month}`,
    headers
  );
};
export const updateCommissionStatus = (data, headers) => {
  return axios.post(`/Financials/UpdateCommissionsStatus`, data, headers);
};
export const generatePaymentSlip = (data, headers) => {
  return axios.post(`/Financials/GeneratePaymentSlip`, data, headers);
};
// for client
export const getAllClients = (
  page,
  pageSize,
  countyName,
  cityName,
  searchName,
  headers
) => {
  let getUrl = `/GetAllClients?page=${page}&per_page=${pageSize}`;

  // Add status to the URL if it's not empty
  if (countyName) {
    getUrl += `&county=${countyName}`;
  }
  // Add career to the URL if it's not empty
  if (cityName) {
    getUrl += `&city=${cityName}`;
  }

  // Add searchName to the URL if it's not empty
  if (searchName) {
    getUrl += `&name=${searchName}`;
  }
  return getUrl;
};
export const deleteClient = (id, headers) => {
  return axios.delete(`/DeleteClient/${id}`, headers);
};
// for loan
export const getAllLoans = (
  page,
  pageSize,
  currentStatus,
  categoryName,
  providerName,
  searchName,
  headers
) => {
  let getUsersUrl = `/GetAllLoans?page=${page}&per_page=${pageSize}`;

  // Add status to the URL if it's not empty
  if (currentStatus) {
    getUsersUrl += `&status=${currentStatus}`;
  }
  // Add career to the URL if it's not empty
  if (categoryName) {
    getUsersUrl += `&category=${categoryName}`;
  }
  if (providerName) {
    getUsersUrl += `&provider=${providerName}`;
  }

  // Add searchName to the URL if it's not empty
  if (searchName) {
    getUsersUrl += `&client=${searchName}`;
  }
  return getUsersUrl;
};
export const ExportLoans = (
  pageSize,
  currentStatus,
  categoryName,
  providerName,
  searchName,
  headers
) => {
  return axios.get(
    `/ExportAllLoans?per_page=${pageSize}&status=${currentStatus}&category=${categoryName}&provider=${providerName}&client=${searchName}`,
    headers
  );
};
export const ExportClients = (pageSize, county, city, searchName, headers) => {
  return axios.get(
    `/ExportAllClients?per_page=${pageSize}&county=${county}&city=${city}&name=${searchName}`,
    headers
  );
};
export const createLoan = (data, headers) => {
  return axios.post(`/Loan/Create`, data, headers);
};
export const updateMainStatus = (id, data, headers) => {
  return axios.put(`/Loan/UpdateStatus/${id}`, data, headers);
};
export const createClient = (data, headers) => {
  return axios.post(`/CreateClient`, data, headers);
};
export const updateClient = (id, data, headers) => {
  return axios.put(`/UpdateClient/${id}`, data, headers);
};
export const getClientByPersonalId = (id, headers) => {
  return axios.get(`/GetClientByPersonalId/${id}`, headers);
};
export const deleteLoan = (id, headers) => {
  return axios.delete(`/Loan/Delete/${id}`, headers);
};
export const getAppStatuses = (headers) => {
  return axios.get(`/GetMasterData/applicationStatuses`, headers);
};
export const getStatuses = () => {
  return `/GetMasterData/applicationStatuses`;
};
export const getCatgories = () => {
  return `/GetMasterData/applicationCategories`;
};
export const getProviders = () => {
  return `/GetMasterData/providers`;
};
export const addLoanProduct = (id, data, headers) => {
  return axios.put(`/Loan/Product/Update/${id}`, data, headers);
};
export const getLoanDocument = (appId, data, headers) => {
  return axios.get(`/Loan/ListDocuments/${appId}`, data, headers);
};
export const uploadLoanDocument = (data, headers) => {
  return axios.post(`/Loan/UploadDocument`, data, headers);
};
export const getAllOffer = (appId) => {
  return `/Loan/GetAllOffers/${appId}`;
};
export const getAllAppsSetting = (headers) => {
  return axios.get(`/GetMasterData/appSettings`, headers);
};
export const createOffer = (appId, data, headers) => {
  return axios.post(`/Loan/CreateOffer/${appId}`, data, headers);
};
export const updateOfferStatus = (appId, offerId, data, headers) => {
  return axios.put(
    `/Loan/UpdateOfferStatus/${appId}/${offerId}`,
    data,
    headers
  );
};
export const deleteOffer = (appId, offerId, headers) => {
  return axios.delete(`/Loan/DeleteOffer/${appId}/${offerId}`, headers);
};

export const updateAppSetting = (data, headers) => {
  return axios.put(`/UpdateAppSettings`, data, headers);
};
// AML
export const updateAML = (appId, data, headers) => {
  return axios.put(`/Loan/UpdateAML/${appId}`, data, headers);
};

// comments
export const getAllComment = (appId, commentType) => {
  return `/Loan/GetAllComments/${appId}/${commentType}`;
};
export const createComment = (appId, data, headers) => {
  return axios.post(`/Loan/CreateComment/${appId}`, data, headers);
};
export const updateComment = (appId, commentId, data, headers) => {
  return axios.put(`/Loan/UpdateComment/${appId}/${commentId}`, data, headers);
};
export const deleteComment = (appId, commentId, headers) => {
  return axios.delete(`/Loan/DeleteComment/${appId}/${commentId}`, headers);
};
// gdpr
export const getAllLoanGDPR = (id) => {
  return `/Loan/GetAllGDPRS/${id}`;
};
export const generateLoanGDPR = (id, headers) => {
  return axios.get(`/Loan/GenerateGDPR/${id}`, headers);
};

export const getAllGDPR = (id) => {
  return `/Client/GDPRS/${id}`;
};

export const generateGDPR = (id, headers) => {
  return axios.get(`/Client/GenerateGDPR/${id}`, headers);
};
export const signGDPR = (id, data) => {
  return axios.post(`/Loan/SignGDPR/${id}`, data);
};
export const signGDPRbyID = (id) => {
  return axios.get(`/Loan/GetGDPRById/${id}`);
};
export const GetClientFinancial = (id) => {
  return `/Client/GetClientFinancials/${id}`;
};
export const GetClientPrescoring = (id) => {
  return `/Loan/GetClientPreScoring/${id}`;
};
export const CreateClientFinancial = (id, data, headers) => {
  return axios.put(`/Client/UpdateClientFinancials/${id}`, data, headers);
};

// export const editSingleUser = (id, data, headers) => {
//   return axios.put(`/UpdateUser/${id}`, data, headers);
// };
// export const deleteUser = (id, headers) => {
//   return axios.delete(`/DeleteUser/${id}`, headers);
// };
// export const getCardDetail = (id) => {
//   return axios.get(`/cards/card/${id}`);
// };
export const getUserProfileInfo = () => {
  return `/GetProfileData`;
};
export const updateUserProfile = (data, headers) => {
  return axios.post(`/UpdateProfileData`, data, headers);
};
export const creteRecordGeoLocation = (data, headers) => {
  return axios.post(`/RecordGeoLocation`, data, headers);
};
// users
export const getAllUsers = (
  page,
  pageSize,
  careerId,
  currentStatus,
  searchName
) => {
  let getUsersUrl = `/AllUsers?page=${page}&per_page=${pageSize}`;

  // Add career to the URL if it's not empty
  if (careerId) {
    getUsersUrl += `&career=${careerId}`;
  }

  // Add status to the URL if it's not empty
  if (currentStatus) {
    getUsersUrl += `&status=${currentStatus}`;
  }

  // Add searchName to the URL if it's not empty
  if (searchName) {
    getUsersUrl += `&name=${searchName}`;
  }
  return getUsersUrl;
};
export const createUser = (data, headers) => {
  return axios.post(`/CreateUser`, data, headers);
};
export const editSingleUser = (id, data, headers) => {
  return axios.put(`/UpdateUser/${id}`, data, headers);
};
export const deleteUser = (id, headers) => {
  return axios.delete(`/DeleteUser/${id}`, headers);
};
export const resendUser = (id, headers) => {
  return axios.get(`/WelcomeEmail/${id}`, headers);
};
export const getCardDetail = (id) => {
  return axios.get(`/cards/card/${id}`);
};

/**
 * Products routes
 */
export const getAllProducts = (
  page,
  pageSize,
  providerId,
  currentStatus,
  searchName
) => {
  let getProductsUrl = `/AllProducts?page=${page}&per_page=${pageSize}`;

  // Add career to the URL if it's not empty
  if (providerId) {
    getProductsUrl += `&provider=${providerId}`;
  }

  // Add status to the URL if it's not empty
  if (currentStatus) {
    getProductsUrl += `&status=${currentStatus}`;
  }

  // Add searchName to the URL if it's not empty
  if (searchName) {
    getProductsUrl += `&product_name=${searchName}`;
  }
  return getProductsUrl;
};
export const createProduct = (data, headers) => {
  return axios.post(`/CreateProduct`, data, headers);
};
export const editSingleProduct = (id, data, headers) => {
  return axios.put(`/UpdateProduct/${id}`, data, headers);
};
export const deleteProduct = (id, headers) => {
  return axios.delete(`/DeleteProduct/${id}`, headers);
};
export const cloneProduct = (id, headers) => {
  return axios.put(`/CloneProduct/${id}`, {}, headers);
};

// get provders routes
export const getAllProviders = (
  page,
  pageSize,
  providerTypeId,
  currentStatus,
  searchName
) => {
  let getProvidersUrl = `/GetAllProviders?page=${page}&per_page=${pageSize}`;

  // Add career to the URL if it's not empty
  if (providerTypeId) {
    getProvidersUrl += `&type=${providerTypeId}`;
  }

  // Add status to the URL if it's not empty
  if (currentStatus) {
    getProvidersUrl += `&status=${currentStatus}`;
  }

  // Add searchName to the URL if it's not empty
  if (searchName) {
    getProvidersUrl += `&name=${searchName}`;
  }
  return getProvidersUrl;
};
export const createProvider = (data, headers) => {
  return axios.post(`/CreateProvider`, data, headers);
};
export const editSingleProvider = (id, data, headers) => {
  return axios.put(`/UpdateProvider/${id}`, data, headers);
};
export const deleteProvider = (id, headers) => {
  return axios.delete(`/DeleteProvider/${id}`, headers);
};
export const createProviderSetting = (id, data, headers) => {
  return axios.put(`/Provider/Setting/${id}`, data, headers);
};
// commission

export const getAllCommission = (id) => {
  return `/Provider/GetAllProviderCommissions/${id}`;
};
export const createCommission = (data, headers) => {
  return axios.post(`/Provider/CreateCommission`, data, headers);
};
export const editSingleCommission = (id, data, headers) => {
  return axios.put(`/Provider/UpdateCommission/${id}`, data, headers);
};
export const deleteCommission = (id, headers) => {
  return axios.delete(`/Provider/DeleteCommission/${id}`, headers);
};
// get provders routes
export const getAllAccessLevel = (
  page,
  pageSize,
  currentStatus,
  searchName
) => {
  let getAccessLevelUrl = `/Roles/GetAllRoles?page=${page}&per_page=${pageSize}`;

  // Add status to the URL if it's not empty
  if (currentStatus) {
    getAccessLevelUrl += `&status=${currentStatus}`;
  }

  // Add searchName to the URL if it's not empty
  if (searchName) {
    getAccessLevelUrl += `&name=${searchName}`;
  }
  return getAccessLevelUrl;
};
export const createAccessLevel = (data, headers) => {
  return axios.post(`/Roles/CreateRole`, data, headers);
};
export const editSingleAccessLevel = (id, data, headers) => {
  return axios.put(`/Roles/UpdateRole/${id}`, data, headers);
};
export const deleteAccessLevel = (id, headers) => {
  return axios.delete(`/DeleteAccessLevel/${id}`, headers);
};
export const getAllModuleAccess = () => {
  return `/Roles/GetAllModules`;
};
export const getSingleModuleAccess = (id) => {
  return `/Roles/GetRole/${id}`;
};
export const getAllIncomeSource = () => {
  return `/IncomeSource/GetAll`;
};
export const AddIncomeSource = (data, headers) => {
  return axios.post(`/IncomeSource/Create`, data, headers);
};
export const UpdateIncomeSource = (id, data, headers) => {
  return axios.put(`/IncomeSource/Update/${id}`, data, headers);
};
export const deleteIncomeSource = (id, headers) => {
  return axios.delete(`/IncomeSource/Delete/${id}`, headers);
};
// branches
export const getAllBranchesData = () => {
  return `/Branch/GetAll`;
};
export const AddBranches = (data, headers) => {
  return axios.post(`/Branch/Create`, data, headers);
};
export const UpdateBranches = (id, data, headers) => {
  return axios.put(`/Branch/Update/${id}`, data, headers);
};
export const deleteBranches = (id, headers) => {
  return axios.delete(`/Branch/Delete/${id}`, headers);
};

// Expense
export const getAllExpensesData = () => {
  return `/Expense/GetAll`;
};
export const AddExpenses = (data, headers) => {
  return axios.post(`/Expense/Create`, data, headers);
};
export const UpdateExpenses = (id, data, headers) => {
  return axios.put(`/Expense/Update/${id}`, data, headers);
};
export const deleteExpenses = (id, headers) => {
  return axios.delete(`/Expense/Delete/${id}`, headers);
};
// BNR Ircc
export const getAllIrccData = () => {
  return `/BNR/GetAll`;
};
export const AddIrcc = (data, headers) => {
  return axios.post(`/BNR/Create`, data, headers);
};
export const UpdateIrcc = (id, data, headers) => {
  return axios.put(`/BNR/Update/${id}`, data, headers);
};
export const deleteIrcc = (id, headers) => {
  return axios.delete(`/BNR/Delete/${id}`, headers);
};

// product brochures

export const getAllBrochures = (product_id) => {
  return `/Product/GetAllBrochures/${product_id}`;
};
export const createProductDocumentList = (product_id, data, headers) => {
  return axios.post(`/Product/CreateBrochure/${product_id}`, data, headers);
};
export const deleteProductDocumentList = (product_id, brochure_id, headers) => {
  return axios.delete(
    `/Product/DeleteBrochure/${product_id}/${brochure_id}`,
    headers
  );
};
export const getAllContract = (product_id) => {
  return `/Loan/GetAllContracts/${product_id}`;
};
export const createLoanContractList = (data, headers) => {
  return axios.post(`/Loan/UploadContract`, data, headers);
};
// product UserDocument

export const getAllUserDocument = (user_id) => {
  return `/User/GetAllDocuments/${user_id}`;
};
export const createUserDocumentList = (user_id, data, headers) => {
  return axios.post(`/User/CreateDocument/${user_id}`, data, headers);
};
export const getPreviewDoc = (doc_id, headers) => {
  return axios.get(`/User/ViewDocument/${doc_id}`, headers);
};
export const deleteUserDocumentList = (user_id, document_id, headers) => {
  return axios.delete(
    `/User/DeleteDocument/${user_id}/${document_id}`,
    headers
  );
};

export const getAllUserHistory = (mod, id) => {
  return `/History/${mod}/${id}`;
};
// family member

export const getAllFamilyMember = (client_id) => {
  return `/Client/GetAllMembers/${client_id}`;
};
export const getSingleFamilyMember = (member_id, headers) => {
  return axios.get(`/Client/GetMember/${member_id}`, headers);
};
export const createFamilyMember = (data, headers) => {
  return axios.post(`/Client/CreateMember`, data, headers);
};
export const deleteFamilyMember = (member_id, headers) => {
  return axios.delete(`/Client/DeleteMember/${member_id}`, headers);
};
export const updateFamilyMember = (member_id, data, headers) => {
  return axios.put(`/Client/UpdateMember/${member_id}`, data, headers);
};
export const getAllProductDocumentList = (product_id) => {
  return `/GetProductDocumentList/${product_id}`;
};

export const getSingleUserDocumentList = (product_id) => {
  return `/Product/GetAllDocuments/${product_id}`;
};
export const updateDocumentList = (product_id, data, headers) => {
  return axios.put(`/Product/UpdateDocuments/${product_id}`, data, headers);
};
export const getAllMasterData = () => {
  return `/GetMasterData`;
};
export const getAllDashboard = () => {
  return `/GetDashboard`;
};

export const updateDashbordLayout = (data, headers) => {
  return axios.put(`/UpdateDashboard`, data, headers);
};
export const getAllStatus = (id) => {
  return `/GetMasterData/applicationStatuses/${id}`;
};

export const getProductSettingsList = (product_id) => {
  return `/GetProductSetting/${product_id}`;
};
export const updateProductSetting = (product_id, data, headers) => {
  return axios.put(`/UpdateProductSetting/${product_id}`, data, headers);
};

// get couties and cities
export const getAllCounties = () => {
  return `/GetMasterData/counties`;
};
export const getAllCities = (countyCode, headers) => {
  return axios.get(`/GetMasterData/citiesByCounty/${countyCode}`, headers);
};
export const getAllCareer = () => {
  return `/GetMasterData/careerLevels`;
};
export const getAllProviderType = () => {
  return `/GetMasterData/providerTypes`;
};
export const getAllUsersName = () => {
  return `/GetMasterData/users`;
};
export const getUserStatus = () => {
  return `/GetMasterData/userStatuses`;
};
export const getProductStatus = () => {
  return `/GetMasterData/productStatuses`;
};
export const getProviderStatus = () => {
  return `/GetMasterData/providerStatuses`;
};

export const getAllBranches = () => {
  return `/GetMasterData/branches`;
};
export const getAllMasterProviders = (headers) => {
  return axios.get(`/GetMasterData/providers`, headers);
};
export const getProductCategoriesByProvider = (providerId, headers) => {
  return axios.get(
    `/GetMasterData/productCategoriesByProvider/${providerId}`,
    headers
  );
};
export const getProductByProvider = (providerId, headers) => {
  return axios.get(`/GetMasterData/productsByProvider/${providerId}`, headers);
};
export const getApplicationCategory = (headers) => {
  return axios.get(`/GetMasterData/applicationCategories`, headers);
};
export const addCareerLevel = (id, data, headers) => {
  return axios.put(`/User/UpdateCareerLevel/${id}`, data, headers);
};

export const getAllPermissions = () => {
  return `/Roles/GetAllRoles`;
};
export const updatePermissions = (id, data, headers) => {
  return axios.put(`/User/AsignRoles/${id}`, data, headers);
};
export const updateTwoFactor = (id, headers) => {
  return axios.put(`/User/Enable2FA/${id}`, {}, headers);
};
export const updateIpFilter = (id, data, headers) => {
  return axios.put(`/User/IPWhiteList/${id}`, data, headers);
};

export const getSingleUserPermissions = (id) => {
  return `/Roles/GetRole/${id}`;
};
export const getUserSingle = (id) => {
  return `/GetUser/${id}`;
};
/**
 * Career Level Crud
 */
export const AllCareerLevels = () => {
  return `/GetAllCareerLevels`;
};

export const CreateCreditCompare = (data, headers) => {
  return axios.post(`/Loan/CreateCreditCompare`, data, headers);
};
export const GetCreditCompareSingle = (type, comId, setId, headers) => {
  return axios.get(
    `/Loan/GetCreditCompareDetail/${type}/${comId}/${setId}`,
    headers
  );
};
export const GetCreditComparePre = (type, comId) => {
  return `/Loan/GetCreditCompare/${type}/${comId}`;
};
export const getIndicativeOffer = (type, comId, bankId, headers) => {
  return axios.get(
    `/Loan/SharePdfCreditCompare/${type}/${comId}?psInterestTypeIds=${bankId}`,
    headers
  );
};
export const AddCareerLevel = (data, headers) => {
  return axios.post(`/CreateCareerLevel`, data, headers);
};

export const UpdateCareerLevel = (id, data, headers) => {
  return axios.put(`/UpdateCareerLevel/${id}`, data, headers);
};

export const DeleteCareerLevel = (id, headers) => {
  return axios.delete(`/DeleteCareerLevel/${id}`, headers);
};
export const CreatePreScoring = (data, headers) => {
  return axios.post(`/Client/PreScoring`, data, headers);
};
