import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/partials/partial-listing.module.scss";
import style from "./comment.module.scss";
import {
  getAllComment,
  createComment,
  updateComment,
  deleteComment,
} from "../../../api/misc";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Button from "../customBtn";
import { toast } from "react-toastify";
import DeleteModal from "../deleteModal";
import FormatDateTime from "../FormatMain/formatDate";
import BasicModal from "../basicmodal";
import CustomInput from "../customInput";
import Loader from "../loader";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import useGetAxios from "../../../api/hooks/useGetAxios";
import ChatIcon from "@mui/icons-material/Chat";

const CommentSchema = Yup.object().shape({
  comment: Yup.string().required("Please enter comment"),
});

const AddComments = ({ clientData, editExistingclient, commentType }) => {
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const [loadingCommentCreate, setLoadingCommentCreate] = useState(false);
  const [deleteCommentId, setCommentDeleteId] = useState(null);
  const [openCommentDelete, setOpenCommentDelete] = useState(false);
  const [loadingCommentDelete, setLoadingCommentDelete] = useState(false);
  const [openUploadComment, setOpenuploadComment] = useState(false);
  const [editComment, setEditComment] = useState(null);

  const { response: getAllCommentData, loading: getCommentLoading } =
    useGetAxios(
      getAllComment(
        editExistingclient ? editExistingclient?.id : clientData?.id,
        commentType
      ),
      false
    );

  const [allCommentData, setAllCommentData] = useState(getAllCommentData?.data);

  useEffect(() => {
    setAllCommentData(getAllCommentData?.data);
  }, [getAllCommentData]);

  const handleAddNewComment = () => {
    setOpenuploadComment(true);
  };

  const handleEdit = (data) => {
    setOpenuploadComment(true);
    setEditComment(data);
  };

  const handleCommentModalClose = () => {
    setOpenuploadComment(false);
    setEditComment(null);
  };

  const handleDelete = async (id) => {
    setOpenCommentDelete(true);
    setCommentDeleteId(id);
  };

  const handleConfirmDelete = async () => {
    setLoadingCommentDelete(true);
    try {
      const res = await deleteComment(editExistingclient?.id, deleteCommentId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });
      if (res?.status === 200) {
        const updatedRows = allCommentData?.filter(
          (row) => row.id !== deleteCommentId
        );
        setAllCommentData(updatedRows);
        setOpenCommentDelete(false);
        toast.success(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
    setLoadingCommentDelete(false);
  };

  const handleClose = () => {
    setOpenCommentDelete(false);
    setCommentDeleteId(null);
    setLoadingCommentDelete(false);
  };

  return (
    <div className={style.mainCommentContainer}>
      <div className={style.mainCommentPage}>
        <div className={style.commentHeader}>
          <div className={style.heading}>
            <h3>
              <ChatIcon />
              Comments
            </h3>
          </div>
          <div className={style.addCommentBtn}>
            {editExistingclient?.status_slug ===
            "indicative-offer-refused-process-closed" ? (
              ""
            ) : (
              <Button
                customClass={style.filterBtn}
                black
                custborderRadius
                customWidth="300px"
                onClick={handleAddNewComment}
              >
                Add Comment
              </Button>
            )}
          </div>
        </div>
        {getCommentLoading ? (
          <Loader />
        ) : (
          allCommentData?.map((item, index) => (
            <div className={style.mainComment} key={index}>
              <div className={style.senderName}>
                <h4>When and Who</h4>
                <p>{FormatDateTime(item?.updated_at)}</p>
                <h6>{item?.username}</h6>
              </div>
              <div className={style.commentText}>
                <h4>What</h4>
                <p>{item?.comment}</p>
                <div className={style.actionBtn}>
                  <span
                    className={style.iconEdit}
                    onClick={() => handleEdit(item)}
                  >
                    <ModeEditIcon />
                  </span>
                  <span
                    className={style.iconDel}
                    onClick={() => handleDelete(item?.id)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {deleteCommentId && (
        <DeleteModal
          isOpen={openCommentDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="Comment"
          isLoading={loadingCommentDelete}
        />
      )}
      <BasicModal
        isOpen={openUploadComment}
        closeModal={handleCommentModalClose}
        customWidth="994px"
      >
        <div className={classes.defaultForm}>
          <div className={classes.modalHead}>
            <h3>{editComment ? "Edit Comment" : "Add Comment"}</h3>
            <hr />
          </div>
          <Formik
            initialValues={{
              comment: editComment ? editComment.comment : "",
            }}
            validationSchema={CommentSchema}
            onSubmit={async (values, { resetForm }) => {
              setLoadingCommentCreate(true);
              const formData = editComment
                ? { comment: values.comment }
                : { comment_type: commentType, comment: values.comment };

              try {
                let res;
                if (editComment) {
                  res = await updateComment(
                    editExistingclient?.id,
                    editComment?.id,
                    formData,
                    {
                      headers: {
                        lang: lang,
                        Authorization: `Bearer ${tokenAD}`,
                      },
                    }
                  );
                } else {
                  res = await createComment(editExistingclient?.id, formData, {
                    headers: {
                      lang: lang,
                      Authorization: `Bearer ${tokenAD}`,
                    },
                  });
                }
                if (res?.status === 200) {
                  if (editComment) {
                    setAllCommentData((prevData) =>
                      prevData.map((item) =>
                        item.id === editComment.id
                          ? {
                              ...item,
                              comment: values.comment,
                              updated_at: res?.data?.data?.updated_at,
                              username: res?.data?.data?.username,
                            }
                          : item
                      )
                    );
                  } else {
                    setAllCommentData((prevArray) => [
                      res.data.data,
                      ...prevArray,
                    ]);
                  }
                  toast.success(res?.data?.message);
                  resetForm();
                  handleCommentModalClose();
                } else {
                  throw new Error("Failed to save comment");
                }
              } catch (error) {
                toast.error(
                  error?.response?.data?.message ||
                    "An error occurred during the process."
                );
              }
              setLoadingCommentCreate(false);
            }}
          >
            {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formMain}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CustomInput
                        label="Write Comment"
                        type="textarea"
                        name="comment"
                        rows="5"
                        value={values.comment}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter comment"
                        errors={errors.comment}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={style.addGenericBtn}>
                  <Button
                    onClick={handleCommentModalClose}
                    outlineGrey
                    customWidth="170px"
                  >
                    Close
                  </Button>
                  <Button type="submit" blue customWidth="170px">
                    {loadingCommentCreate ? <Loader /> : <>Save Comment</>}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </BasicModal>
    </div>
  );
};

export default AddComments;
