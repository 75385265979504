import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./addProviders.module.scss";
import "./addProvidersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../../components/shared/customInput";
import Button from "../../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import {
  getAllModuleAccess,
  getSingleModuleAccess,
} from "../../../../api/misc";
import { useSelector } from "react-redux";
import CheckboxForm from "./checkboxAccessLevel";
import useGetAxios from "../../../../api/hooks/useGetAxios";

const ProviderSchema = Yup.object().shape({
  name: Yup.string().required("Please enter provider name"),
  provider_type_id: Yup.string().required("Please select provider type"),
});

const AddAccessLevelMain = ({
  editAccessLevel,
  handleCancel,
  setEditAccessLevel,
}) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const {
    response: getAllModuleData,
    loading: getAccesLoading,
    error: getAccesError,
  } = useGetAxios(getAllModuleAccess(), false);
  const {
    response: getSingleModuleData,
    loading: getSingleAccessLoading,
    error: getSingleAccessError,
  } = useGetAxios(
    editAccessLevel?.id ? getSingleModuleAccess(editAccessLevel.id) : null,
    false
  );

  // const checkboxData = [
  //   {
  //     slug: "overview",
  //     name: "Overview",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Overview",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Overview",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Overview",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Overview",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "messages",
  //     name: "Messages",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Messages",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Messages",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Messages",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Messages",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "loans",
  //     name: "Loans",
  //     permissions: [
  //       {
  //         slug: "view owned",
  //         name: "View just Owned data",
  //       },
  //       {
  //         slug: "view structure",
  //         name: "View just Managed Structure Data",
  //       },
  //       {
  //         slug: "view all",
  //         name: "View just All The Data",
  //       },
  //       {
  //         slug: "create",
  //         name: "Allow to Add",
  //       },
  //       {
  //         slug: "update owned",
  //         name: "Allow to Edit just Owned Data",
  //       },
  //       {
  //         slug: "update structure",
  //         name: "Allow to Edit just Managed Structure Data",
  //       },
  //       {
  //         slug: "update all",
  //         name: "Allow to Edit All the  Data",
  //       },
  //       {
  //         slug: "delete owned",
  //         name: "Allow to Delete just Owned Data",
  //       },
  //       {
  //         slug: "delete structure",
  //         name: "Allow to Delete just Managed Structure Data",
  //       },
  //       {
  //         slug: "delete all",
  //         name: "Allow to Delete All the Data",
  //       },
  //       {
  //         slug: "export",
  //         name: "Allow to Export",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "clients",
  //     name: "Clients",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Clients",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Clients",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Clients",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Clients",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "financials",
  //     name: "Financials",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Financials",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Financials",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Financials",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Financials",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "reports",
  //     name: "Reports",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Reports",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Reports",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Reports",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Reports",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "appSettings",
  //     name: "Application Settings",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Application Settings",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Application Settings",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Application Settings",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Application Settings",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "providers",
  //     name: "Providers",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Providers",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Providers",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Providers",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Providers",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "products",
  //     name: "Products",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Products",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Products",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Products",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Products",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "users",
  //     name: "User Management",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View User Management",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create User Management",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update User Management",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete User Management",
  //       },
  //       {
  //         slug: "block",
  //         name: "Block User Management",
  //       },
  //       {
  //         slug: "unblock",
  //         name: "Unblock User Management",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "accessLevels",
  //     name: "Access Levels",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Access Levels",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Access Levels",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Access Levels",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Access Levels",
  //       },
  //     ],
  //   },
  //   {
  //     slug: "careerLevels",
  //     name: "Career Levels",
  //     permissions: [
  //       {
  //         slug: "view",
  //         name: "View Career Levels",
  //       },
  //       {
  //         slug: "create",
  //         name: "Create Career Levels",
  //       },
  //       {
  //         slug: "update",
  //         name: "Update Career Levels",
  //       },
  //       {
  //         slug: "delete",
  //         name: "Delete Career Levels",
  //       },
  //     ],
  //   },
  // ];
  // const permission = [
  //   {
  //     slug: "clients",
  //     permissions: ["view", "create"],
  //   },
  //   {
  //     slug: "loans",
  //     permissions: ["view structure"],
  //   },
  // ];
  return (
    <>
      <div className={classes.defaultForm}>
        {getAccesLoading ? (
          <Loader />
        ) : (
          <CheckboxForm
            checkboxData={getAllModuleData?.data}
            editAccessLevel={getSingleModuleData?.data}
            handleCancel={handleCancel}
            setEditAccessLevel={setEditAccessLevel}
          />
        )}

        {/* <Formik
          initialValues={
            editAccessLevel
              ? {
                  role_name: editAccessLevel?.name,
                  permissions: [],
                }
              : {
                  role_name: "",
                  permissions: [],
                }
          }
          validationSchema={ProviderSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            const sendData = {
              role_name: values?.name,
              permissions: values?.provider_type_id,
            };

            // try {
            //   let res;
            //   // Check if editing or creating a new provider
            //   if (editAccessLevel) {
            //     res = await editSingleProvider(editAccessLevel?.id, sendData, {
            //       headers: {
            //         lang: lang,
            //         Authorization: `Bearer ${tokenAD}`,
            //       },
            //     });
            //   } else {
            //     res = await createProvider(values, {
            //       headers: {
            //         lang: lang,
            //         Authorization: `Bearer ${tokenAD}`,
            //       },
            //     });
            //   }

            //   // Handle success response
            //   if (res?.status === 200) {
            //     console.log("res.dataapi", res.data);
            //     setLoading(false);
            //     toast.success(res?.data?.message);
            //     setEditAccessLevel(res.data.data);
            //   } else {
            //     throw new Error(res?.data?.message || "An error occurred");
            //   }
            // } catch (error) {
            //   setLoading(false);
            //   toast.error(
            //     error.response.data.message ||
            //       "An error occurred during the process."
            //   );
            // }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="Access Level Name"
                      type="text"
                      name="role_name"
                      value={values.role_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter access level name"
                      touched={touched.role_name}
                      errors={errors.role_name}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addProviderBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>{editAccessLevel ? "Save" : "Add Provider"}</>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik> */}
      </div>
    </>
  );
};

export default AddAccessLevelMain;
