import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/partials/partial-listing.module.scss";
import style from "./client.module.scss";
import { getAllClients, deleteClient, ExportClients } from "../../api/misc";
import DataTable from "../../components/shared/customTable";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import Button from "../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import editSquare from "../../assets/images/edit_square.svg";
import DeleteModal from "../../components/shared/deleteModal";
import FormatDateTimeOnly from "../../components/shared/FormatMain/formatDateOnly";
import FormatText from "../../components/shared/FormatMain/formattext";
import CustomFilter from "../../components/shared/customFilter";
import useGetAxios from "../../api/hooks/useGetAxios";
import { useSelector } from "react-redux";
import AddCardIcon from "@mui/icons-material/AddCard";
import { Link } from "react-router-dom";
import Loader from "../../components/shared/loader";

const Users = ({ setLoanState, setEditExistingclient }) => {
  localStorage.setItem("breadcrumbName", "Clients");
  // get master data start
  const masterData = useSelector((state) => state?.masterData?.masterData);
  // get master data end

  const userCompleteData = JSON.parse(localStorage.getItem("userData"));
  // start all permissions
  const hasViewLoanPermission =
    userCompleteData?.data?.permissions?.includes("view clients");
  const hasCreateLoanPermission =
    userCompleteData?.data?.permissions?.includes("create clients");
  const hasUpdateLoanPermission =
    userCompleteData?.data?.permissions?.includes("update clients");
  const hasDeleteLoanPermission =
    userCompleteData?.data?.permissions?.includes("delete clients");
  // end all permissions

  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const lang = localStorage.getItem("lang");
  const FilterData = JSON.parse(localStorage.getItem("FilterData"));
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [totalRows, setTotalRows] = useState(0); // Total number of rows from API
  const [count, setCount] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 20,
  }); // Pagination state
  const [countyValue, setcountyValue] = useState(
    FilterData?.county?.value ? FilterData?.county?.value : "0"
  );

  const [cityValue, setCityValue] = useState(
    FilterData?.city?.value ? FilterData?.city?.value : "0"
  );

  const [inputValue, setInputValue] = useState(
    FilterData?.searchName?.value ? FilterData?.searchName?.value : ""
  );

  // get all loans
  const {
    response: getAllLoanData,
    loading: getLoanLoading,
    error: getLoanError,
  } = useGetAxios(
    getAllClients(
      paginationModel.page,
      paginationModel.pageSize,
      countyValue && countyValue != "0" ? countyValue : "",
      cityValue && cityValue != "0" ? cityValue : "",
      inputValue ? inputValue : ""
    ),
    false,
    count
  );

  // if (deleteId) {
  //   const {
  //     response: getLoanDelete,
  //     loading: getLoanLoadingDelete,
  //     error: getproviderErrorDelete,
  //   } = useDeleteAxios(deleteLoan(deleteId));
  // }

  // const handleDelete = async (id) => {
  //   setOpenDelete(true);
  //   setDeleteId(id);
  // };
  // const handleConfirmDelete = async () => {
  //   setOpenDelete(false);
  //   setCount(count + 1);
  // };

  const handleAddApplication = (data) => {
    localStorage.setItem("createApplication", JSON.stringify(data));
  };
  const handleEdit = (row) => {
    // Add your edit logic here
    setLoanState(false);
    setEditExistingclient(row);
  };

  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = async () => {
    setLoadingDelete(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await deleteClient(deleteId, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        setOpenDelete(false);
        toast.success(res?.data?.message);
        setCount(count + 1);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingDelete(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handleAddNewLoan = () => {
    // Add your delete logic here
    setLoanState(false);
  };
  const handleExport = async () => {
    console.log("handle export");
    // Add your delete logic here
    setLoadingExport(true);
    // Add your delete logic here

    try {
      // Replace with your API call, here is a mock example
      const res = await ExportClients(
        paginationModel.pageSize,
        countyValue && countyValue != "0" ? countyValue : "",
        cityValue && cityValue != "0" ? cityValue : "",
        inputValue ? inputValue : "",
        {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        }
      );

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        console.log("res?.data", res?.data?.download_url);
        const fileUrl = res?.data?.download_url;
        setTimeout(() => {
          window.open(fileUrl, "_self");
        }, 500);
      }
    } catch (error) {
      const showErr = error?.response?.data?.message;
      toast.error(showErr);
    }
    setLoadingExport(false);
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 70, resizable: false, flex: 1 },
    {
      field: "first_name",
      headerName: "Client",
      flex: 3,
      resizable: false,
      renderCell: (params) => (
        <>
          {params?.row?.first_name} {params?.row?.last_name}
        </>
      ),
    },
    {
      field: "updated_at",
      headerName: "Created on",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.updated_at
            ? FormatDateTimeOnly(params?.row?.updated_at)
            : ""}
        </>
      ),
    },
    {
      field: "managed_by",
      headerName: "Managed by",
      resizable: false,
      flex: 1,
      renderCell: (params) => <>{params?.row?.managed_by?.name}</>,
    },
    {
      field: "county",
      headerName: "County",
      resizable: false,
      flex: 1,
      renderCell: (params) => <>{params?.row?.address?.county_row?.county}</>,
    },
    {
      field: "city",
      headerName: "City",
      resizable: false,
      flex: 1,
      renderCell: (params) => <>{params?.row?.address?.city_row?.city}</>,
    },
    {
      field: "applications_count",
      headerName: "No Loans",
      resizable: false,
      flex: 1,
    },
    {
      ...(hasUpdateLoanPermission || hasDeleteLoanPermission
        ? {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params) => (
              <>
                {hasUpdateLoanPermission && (
                  <span
                    className={classes.iconStyleEdit}
                    onClick={() => handleEdit(params.row)}
                  >
                    <img src={editSquare} alt="editIcon" />
                  </span>
                )}
                {hasDeleteLoanPermission && (
                  <span
                    className={classes.iconStyleDel}
                    onClick={() => handleDelete(params.row.id)}
                  >
                    <DeleteIcon />
                  </span>
                )}
                <span
                  className={classes.iconStyleDel}
                  title="Create Loan Application"
                  onClick={() => handleAddApplication(params.row)}
                >
                  <Link to="/loans/applications">
                    <AddCardIcon />
                  </Link>
                </span>
              </>
            ),
          }
        : null),
    },
  ];

  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };

  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };

  return (
    <div className={`${classes.mainListingPage} ${classes.backgroundBase}`}>
      <ToastContainer />
      {hasViewLoanPermission ? (
        <>
          <CustomFilter
            setLoading={setLoading}
            countyValue={countyValue}
            setCountyValue={setcountyValue}
            countyOptions={masterData?.counties}
            countyInitialValue="0"
            cityValue={cityValue}
            setCityValue={setCityValue}
            cityInitialValue="0"
            inputValue={inputValue}
            setInputValue={setInputValue}
            searchName
          />

          <div className={classes.addRow}>
            <div>
              {hasCreateLoanPermission && (
                <Button
                  customClass={classes.filterBtn}
                  blue
                  custborderRadius
                  customWidth="153px"
                  onClick={handleAddNewLoan}
                >
                  Add New
                </Button>
              )}
            </div>
            <div className={classes.addRowInner}>
              <Button
                customClass={classes.filterBtn}
                outlineGrey
                custborderRadius
                onClick={handleExport}
              >
                Export data {loadingExport && <Loader />}
              </Button>
              <div className={classes.paginationControls}>
                <FormControl variant="standard" sx={{ minWidth: 60 }}>
                  <InputLabel>Rows</InputLabel>
                  <Select
                    value={paginationModel.pageSize}
                    onChange={handlePageSizeChange}
                  >
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <DataTable
            rows={getAllLoanData?.data.data}
            columns={columns}
            loading={getLoanLoading}
            totalRows={getAllLoanData?.data?.total}
            paginationModel={paginationModel}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
          {deleteId && (
            <DeleteModal
              isOpen={openDelete}
              onClose={handleClose}
              handleConfirmDelete={handleConfirmDelete}
              name="Client"
              isLoading={loadingDelete}
            />
          )}
        </>
      ) : (
        <p>You have no permission to view the clients</p>
      )}
    </div>
  );
};

export default Users;
