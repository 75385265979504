import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./addEditCareerLevels.module.scss";
// import "./addUsersBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/shared/loader";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import { AddCareerLevel, UpdateCareerLevel } from "../../../api/misc";
import { useSelector } from "react-redux";

const CareerLevelSchema = Yup.object().shape({
  level_name: Yup.string().required("Please enter career level"),
  level_parent_id: Yup.string().required("Please select nested under"),
  status: Yup.string().required("Please select"),
});

const AddEditCareerLevel = ({
  editCareerLevel,
  handleCancel,
  setCareerLevelState,
  setEditCareerLevel,
}) => {
  localStorage.setItem(
    "breadcrumbName",
    editCareerLevel ? "Edit Career Level" : "Add Career Level"
  );
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);

  //   const [checked, setChecked] = useState(editUser?.is_company ? true : false); // Checkbox state
  //   const [cities, setCities] = useState([]);
  //   const [loadingCities, setLoadingCities] = useState(false);

  // Handle change event
  //   const handleChangeCompany = (event) => {
  //     setChecked(event.target.checked); // Toggle the checked state
  //   };

  //   useEffect(() => {
  //     if (editUser?.county_code) {
  //     //   fetchCities(editUser?.county_code); // Fetch cities for the default county_code
  //     }
  //   }, [editUser?.county_code]);

  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={
            editCareerLevel
              ? {
                  level_name: editCareerLevel?.level_name,
                  level_parent_id: editCareerLevel?.parent_id,
                  status: editCareerLevel?.status,
                  commission: editCareerLevel?.commission,
                }
              : {
                  level_name: "",
                  level_parent_id: "",
                  status: "",
                  commission: "",
                }
          }
          validationSchema={CareerLevelSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            const sendData = {
              level_name: values?.level_name,
              level_parent_id: values?.level_parent_id,
              status: values?.status,
              commission: values?.commission,
            };

            try {
              let res;

              // Check if editing or creating a new user
              if (editCareerLevel) {
                res = await UpdateCareerLevel(editCareerLevel?.id, sendData, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              } else {
                res = await AddCareerLevel(values, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              }

              // Handle success response
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);

                setTimeout(() => {
                  // setUserState(true); // Update user state
                  // resetForm({ values: "" }); // Reset form fields
                  setEditCareerLevel(res?.data?.data?.user);
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              console.log("err", error);
              console.log("err-msg ", error.response.data.message);
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2} xs={12} md={6} lg={6}>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Current Career Level*"
                      type="text"
                      name="level_name"
                      value={values?.level_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter first name"
                      touched={touched.level_name}
                      errors={errors.level_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <div className={`${classes.inputMain}`}>
                      <label>Nested Under:*</label>
                      <div className={classes.inputFieldSelect}>
                        <Field
                          as="select"
                          name="level_parent_id"
                          onBlur={handleBlur}
                          touched={touched.level_parent_id}
                          errors={errors.level_parent_id}
                          onChange={async (event) => {
                            const selectedLevelParentId = event.target.value;
                            setFieldValue(
                              "level_parent_id",
                              selectedLevelParentId
                            );
                          }}
                          placeholder="Select Nested Under"
                          className={`${classes.inputField}`}
                        >
                          <option value="">Select Nested Under</option>

                          {masterData?.careerLevels?.map((careerLevel) => (
                            <option value={`${careerLevel?.id}`}>
                              {careerLevel?.level_name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className={classes.errorMsg}>
                        <ErrorMessage name="level_parent_id" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <div className={`${classes.inputMain}`}>
                      <label>Status*</label>
                      <div className={classes.inputFieldSelect}>
                        <Field
                          as="select"
                          name="status"
                          onBlur={handleBlur}
                          touched={touched.status}
                          errors={errors.status}
                          onChange={async (event) => {
                            const selectedStatus = event.target.value;
                            setFieldValue("status", selectedStatus);
                          }}
                          placeholder="Select Status"
                          className={`${classes.inputField}`}
                        >
                          <option value="">Select Status</option>

                          {masterData?.generalStatus?.map((generalStatus) => (
                            <option value={`${generalStatus?.slug}`}>
                              {generalStatus?.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className={classes.errorMsg}>
                        <ErrorMessage name="status" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Commission (%)"
                      type="text"
                      name="commission"
                      value={values?.commission}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="%"
                      touched={touched.commission}
                      errors={errors.commission}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addUserBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>{editCareerLevel ? "Save" : "Add Career"}</>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddEditCareerLevel;
