import React, { useState, useEffect } from "react";
import classes from "./addProducts.module.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import { Formik, Form, Field } from "formik";
import { Checkbox, FormControlLabel, styled } from "@mui/material";
import Button from "../../../../components/shared/customBtn";
import {
  getAllProductDocumentList,
  updateDocumentList,
  getSingleUserDocumentList,
} from "../../../../api/misc";
import useGetAxios from "../../../../api/hooks/useGetAxios";
import BasicModal from "../../../../components/shared/basicmodal";
import Visibility from "@mui/icons-material/Visibility";

const AddClientDocuments = ({ editProduct, handleCancel }) => {
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  const [documentLink, setDocumentLink] = useState();
  const [openPreviewDocument, setOpenPreviewDocument] = useState(false);

  const {
    response: getAllProductDocumentData,
    loading: getProductDocumentLoading,
    error: getProductDocumentError,
  } = useGetAxios(getAllProductDocumentList(editProduct?.id), false);
  const {
    response: getAllUserDocumentData,
    loading: getUserDocumentLoading,
    error: getUserDocumentError,
  } = useGetAxios(getSingleUserDocumentList(editProduct?.id), false);

  const initialDocumentsList =
    getAllUserDocumentData?.data?.map(
      (perm) => perm?.provider_type_document_id
    ) || [];

  const handlePreview = (path) => {
    setOpenPreviewDocument(true);
    setDocumentLink(path);
  };
  const handlePreviewModalClose = () => {
    setOpenPreviewDocument(false);
  };

  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          enableReinitialize // Ensures the form is reinitialized when userDocumentsList is fetched
          initialValues={{
            provider_type_document_ids: initialDocumentsList, // Set slugs in initialValues from userDocumentsList
          }}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            try {
              let res = await updateDocumentList(editProduct?.id, values, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });

              if (res?.status === 200) {
                setLoading(false);
                toast.success("Successfully provided");

                // setTimeout(() => {
                //   resetForm();
                // }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <h2>List of documents to be provided by the client: </h2>
              <hr />
              {getAllProductDocumentData?.data?.list?.map((item, index) => (
                <div key={index}>
                  <FormControlLabel
                    control={
                      <Field
                        as={Checkbox}
                        name="provider_type_document_ids"
                        value={item?.id}
                        type="checkbox"
                        checked={values?.provider_type_document_ids?.includes(
                          item?.id
                        )} // Check if slug is selected
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue("provider_type_document_ids", [
                              ...values.provider_type_document_ids,
                              item?.id,
                            ]);
                          } else {
                            setFieldValue(
                              "provider_type_document_ids",
                              values.provider_type_document_ids.filter(
                                (perm) => perm !== item?.id
                              )
                            );
                          }
                        }}
                      />
                    }
                    // label={item?.document_name}
                    label={
                      <span>
                        {item?.document_name} <span></span>
                        {item?.flag == 1 && (
                          <Visibility
                            onClick={() => handlePreview(item?.url)}
                          />
                        )}
                      </span>
                    }
                  />
                </div>
              ))}
              <div className={classes.addProductBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? <Loader /> : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <BasicModal
          isOpen={openPreviewDocument}
          closeModal={handlePreviewModalClose}
          customWidth="1400px"
          customClass="removePadi"
        >
          <iframe
            src={`${documentLink}#navpanes=0&scrollbar=0?timestamp=${Date.now()}`}
            width="100%"
            height="1000px"
            style={{ border: "none" }}
            title="PDF Viewer"
          />
        </BasicModal>
      </div>
    </>
  );
};

export default AddClientDocuments;
