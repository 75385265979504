import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import classes from "./dashboard.module.scss";
import "./dashboardBuiltin.scss";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PersonIcon from "@mui/icons-material/Person";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import useGetAxios from "../../api/hooks/useGetAxios";
import {
  getAllDashboard,
  getAllLoans,
  updateDashbordLayout,
} from "../../api/misc";
import FormatDateOnly from "../../components/shared/FormatMain/formatDateOnly";
import FormatText from "../../components/shared/FormatMain/formattext";
import DataTable from "../../components/shared/customTable";
import editSquare from "../../assets/images/edit_square.svg";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import Loader from "../../components/shared/loader";
import { Formik, Form, Field } from "formik";
import { Checkbox, FormControlLabel } from "@mui/material";
import Button from "../../components/shared/customBtn";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  localStorage.setItem("breadcrumbName", "Welcome back");
  const paginationPage = JSON.parse(localStorage.getItem("paginationPage"));
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [paginationModel, setPaginationModel] = useState({
    page: paginationPage ? paginationPage?.page : 1,
    pageSize: 5,
  }); // Pagination state
  const {
    response: getAllDashboardData,
    loading: getDashboardLoading,
    error: getDashboardError,
  } = useGetAxios(getAllDashboard(), false, count);

  const {
    response: getAllLoanData,
    loading: getLoanLoading,
    error: getLoanError,
  } = useGetAxios(
    getAllLoans(paginationModel.page, paginationModel.pageSize),
    false
  );
  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
    const paginationPage = { page: "" };
    paginationPage.page = value;

    // Step 3: Save the updated filterData back to localStorage
    localStorage.setItem("paginationPage", JSON.stringify(paginationPage));
  };
  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };
  const handleGetStatus = (data) => {
    localStorage.setItem("dashboradStatus", data);
  };
  const handleDashboardEditApp = (data) => {
    console.log("data", data);
    localStorage.setItem("dashboradEditData", JSON.stringify(data));
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 70, resizable: false, flex: 1 },
    {
      field: "id",
      headerName: "ID",
      resizable: false,
      flex: 1,
    },
    {
      field: "client_name",
      headerName: "Client",
      flex: 2,
      resizable: false,
      renderCell: (params) => (
        <>
          {params?.row?.client?.first_name} {params?.row?.client?.last_name}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      resizable: false,
      flex: 3,
      renderCell: (params) => <>{FormatText(params?.row?.status)}</>,
    },
    {
      field: "product_name",
      headerName: t("loan.product"),
      resizable: false,
      flex: 1,
    },
    {
      field: "provider_name",
      headerName: t("loan.provider"),
      resizable: false,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("loan.applicationDate"),
      resizable: false,
      flex: 2,
      renderCell: (params) => (
        <>
          {params?.row?.created_at
            ? FormatDateOnly(params?.row?.created_at)
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      headerName: t("loan.action"),
      width: 150,
      renderCell: (params) => (
        <div onClick={() => handleDashboardEditApp(params?.row)}>
          <Link to="/loans/applications" className={classes.iconStyleEdit}>
            <img src={editSquare} alt="editIcon" />
          </Link>
        </div>
      ),
    },
  ];
  const [openbox, setOpenBox] = useState(false);
  const handleOpenList = () => {
    console.log("first");
    setOpenBox(!openbox);
  };
  const initialPermissions =
    getAllDashboardData?.data
      ?.filter((perm) => perm?.active) // Filter only active permissions
      ?.map((perm) => perm?.slug) || [];

  console.log("getAllDashboardData?.data", getAllDashboardData?.data);
  return (
    <div>
      <ToastContainer />
      <div className={classes.mainDashboard}>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <div
              className={classes.menuBox}
              style={{ backgroundColor: "#D8D9DB" }}
            >
              <div className={classes.fixBox} onClick={handleOpenList}>
                <div className={classes.menuRow}>
                  <div className={classes.menuIcon}>
                    {" "}
                    <FormatAlignCenterIcon />
                  </div>
                  <div className={classes.menuText}>
                    <h2>{t("dashboard.configure")}</h2>
                    <p>{t("dashboard.yourDashboard")}</p>
                    <ChevronRightIcon />
                  </div>
                </div>
                <h4 style={{ textAlign: "left" }}>
                  {t("dashboard.configureText")}
                </h4>
              </div>
              {openbox && (
                <div className={classes.dropOpen}>
                  <p>Show on Dashboard:</p>
                  <Formik
                    enableReinitialize // Ensures the form is reinitialized when userPermission is fetched
                    initialValues={{
                      settings: initialPermissions, // Set slugs in initialValues from userPermission
                    }}
                    onSubmit={async (values, { resetForm }) => {
                      console.log("valuesvaluesvalues", values);
                      setLoading(true);
                      try {
                        let res = await updateDashbordLayout(values, {
                          headers: {
                            lang: lang,
                            Authorization: `Bearer ${tokenAD}`,
                          },
                        });

                        if (res?.status === 200) {
                          setLoading(false);
                          setOpenBox(false);
                          toast.success(res?.data?.message);
                          setCount(count + 1);
                        } else {
                          throw new Error(
                            res?.data?.message || "An error occurred"
                          );
                        }
                      } catch (error) {
                        setLoading(false);
                        toast.error(
                          error.response.data.message ||
                            "An error occurred during the process."
                        );
                      }
                    }}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className={classes.listcheck}>
                          {getAllDashboardData?.data?.map((item, index) => (
                            <div key={index}>
                              <FormControlLabel
                                control={
                                  <Field
                                    as={Checkbox}
                                    name="settings"
                                    value={item?.slug}
                                    type="checkbox"
                                    checked={values?.settings?.includes(
                                      item?.slug
                                    )} // Check if slug is selected
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue("settings", [
                                          ...values.settings,
                                          item?.slug,
                                        ]);
                                      } else {
                                        setFieldValue(
                                          "settings",
                                          values.settings.filter(
                                            (perm) => perm !== item?.slug
                                          )
                                        );
                                      }
                                    }}
                                  />
                                }
                                label={item?.name}
                              />
                            </div>
                          ))}
                        </div>
                        <div className={classes.addUserBtn}>
                          <Button type="submit" blue customWidth="170px">
                            {loading ? <Loader /> : "Apply"}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </Grid>
          {getAllDashboardData?.data?.map((item) =>
            item?.slug == "sales" && item?.active == true ? (
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <div className={`${classes.menuBox} ${classes.blaclBG}`}>
                  <Link to="/loans/applications">
                    <div className={classes.menuRow}>
                      <div className={classes.menuIcon}>
                        {" "}
                        <LocalMallIcon />
                      </div>
                      <div className={classes.menuText}>
                        <h2>{t("dashboard.totalSale")}</h2>
                        <p>
                          {item?.count} {t("dashboard.loans")}
                        </p>
                        <ChevronRightIcon />
                      </div>
                    </div>
                    <h3>LEI {item?.price} </h3>
                  </Link>
                </div>
              </Grid>
            ) : item?.slug == "in-progress-applications" &&
              item?.active == true ? (
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <div className={classes.menuBox}>
                  <Link to="/loans/applications">
                    <div className={classes.menuRow}>
                      <div className={classes.menuIcon}>
                        {" "}
                        <PersonIcon />
                      </div>
                      <div className={classes.menuText}>
                        <h2>Work in progress</h2>
                        <p>Open applications</p>
                        <ChevronRightIcon />
                      </div>
                    </div>
                    <h3>{item?.count} </h3>
                  </Link>
                </div>
              </Grid>
            ) : item?.type == "application_status" &&
              item?.active == true &&
              item?.slug == "indicative-offer-refused-process-closed" ? (
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <div
                  className={classes.menuBox}
                  onClick={() => handleGetStatus(item?.slug)}
                >
                  <Link to="/loans/applications">
                    <div className={classes.menuRow}>
                      <div className={classes.menuIcon}>
                        <SettingsBackupRestoreIcon />
                      </div>
                      <div className={classes.menuText}>
                        <h2>{t("dashboard.indicativeOfferRefused")}</h2>
                        <p> {t("dashboard.processClosed")}</p>
                        <ChevronRightIcon />
                      </div>
                    </div>
                    <h3>{item?.count} </h3>
                  </Link>
                </div>
              </Grid>
            ) : item?.type == "application_status" &&
              item?.active == true &&
              item?.slug == "client-registered" ? (
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <div
                  className={classes.menuBox}
                  onClick={() => handleGetStatus(item?.slug)}
                >
                  <Link to="/loans/applications">
                    <div className={classes.menuRow}>
                      <div className={classes.menuIcon}>
                        <SettingsBackupRestoreIcon />
                      </div>
                      <div className={classes.menuText}>
                        <h2>{t("dashboard.clientRegister")}</h2>
                        <p> {t("dashboard.loanAppOpen")}</p>
                        <ChevronRightIcon />
                      </div>
                    </div>
                    <h3>{item?.count} </h3>
                  </Link>
                </div>
              </Grid>
            ) : item?.type == "application_status" && item?.active == true ? (
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <div
                  className={classes.menuBox}
                  onClick={() => handleGetStatus(item?.slug)}
                >
                  <Link to="/loans/applications">
                    <div className={classes.menuRow}>
                      <div className={classes.menuIcon}>
                        <SettingsBackupRestoreIcon />
                      </div>
                      <div className={classes.menuText}>
                        <h2>Waiting for</h2>
                        <p>{item?.name}</p>
                        <ChevronRightIcon />
                      </div>
                    </div>
                    <h3>{item?.count} </h3>
                  </Link>
                </div>
              </Grid>
            ) : item?.slug == "clients" && item?.active == true ? (
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <div className={classes.menuBox}>
                  <Link to="/loans/applications">
                    <div className={classes.menuRow}>
                      <div className={classes.menuIcon}>
                        <SettingsBackupRestoreIcon />
                      </div>
                      <div className={classes.menuText}>
                        <h2>{t("dashboard.totalClient")}</h2>
                        {/* <p>{item?.name}</p> */}
                        <ChevronRightIcon />
                      </div>
                    </div>
                    <h3>{item?.count} </h3>
                  </Link>
                </div>
              </Grid>
            ) : null
          )}

          {/* {getAllDashboardData?.data?.applications?.by_status?.map((item) => (
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <div
                className={classes.menuBox}
                onClick={() => handleGetStatus(item?.slug)}
              >
                <Link to="/loans/applications">
                  <div className={classes.menuRow}>
                    <div className={classes.menuIcon}>
                      {" "}
                      <SettingsBackupRestoreIcon />
                    </div>
                    <div className={classes.menuText}>
                      <h2>Waiting for</h2>
                      <p>{item?.name_en}</p>
                      <ChevronRightIcon />
                    </div>
                  </div>
                  <h3>{item?.applications_count} </h3>
                </Link>
              </div>
            </Grid>
          ))} */}
        </Grid>
      </div>
      {getAllDashboardData?.data?.map((item) =>
        item?.slug == "loan-applications" && item?.active == true ? (
          <div className={classes.boxStyle}>
            <h3>{t("dashboard.appNeedAttention")}</h3>
            <DataTable
              rows={getAllLoanData?.data?.data}
              columns={columns}
              loading={getLoanLoading}
              totalRows={getAllLoanData?.data?.total}
              paginationModel={paginationModel}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        ) : null
      )}
    </div>
  );
};

export default Home;
