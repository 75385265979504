import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./addProducts.module.scss";
import "./addProductsBuiltin.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/shared/loader";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import CustomInput from "../../../../components/shared/customInput";
import Button from "../../../../components/shared/customBtn";
import { Field, ErrorMessage } from "formik";
import {
  createProduct,
  editSingleProduct,
  getProductCategoriesByProvider,
} from "../../../../api/misc";
import { useSelector } from "react-redux";

const ProductSchema = Yup.object().shape({
  product_name: Yup.string().required("Please enter product name"),
  provider_id: Yup.string().required("Please select provider"),
  product_category_id: Yup.string().required("Please select product category"),
  short_description: Yup.string().required("Please select short description"),
});

const AddProduct = ({
  editProduct,
  handleCancel,
  setProductState,
  setEditProduct,
  setCountProvider,
  countProvider,
}) => {
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const [loading, setLoading] = useState(false);
  // const [checked, setChecked] = useState(editProduct?.is_company ? true : false); // Checkbox state
  const [productCategories, setProductCategories] = useState([]);
  const [loadingProductCategories, setLoadingProductCategories] =
    useState(false);

  const fetchProductCategories = async (selectedProviderId) => {
    if (selectedProviderId) {
      setLoadingProductCategories(true);
      try {
        const response = await getProductCategoriesByProvider(
          selectedProviderId,
          {
            headers: {
              lang: lang,
              Authorization: `Bearer ${tokenAD}`,
            },
          }
        );
        setProductCategories(response.data.data.productCategoriesByProvider);
        setLoadingProductCategories(false);
      } catch (error) {
        console.error("Error fetching product categories:", error);
        setLoadingProductCategories(false);
      }
    }
  };
  useEffect(() => {
    if (editProduct?.provider_id) {
      fetchProductCategories(editProduct?.provider_id); // Fetch product categories for the default provider_id
    }
  }, [editProduct?.provider_id]);
  return (
    <>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={
            editProduct
              ? {
                  product_name: editProduct?.product_name,
                  provider_id: editProduct?.provider_id,
                  product_category_id: editProduct?.product_category_id,
                  short_description: editProduct?.short_description,
                }
              : {
                  product_name: "",
                  product_category_id: "",
                  provider_id: "",
                  short_description: "",
                }
          }
          validationSchema={ProductSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            const sendData = {
              product_name: values?.product_name,
              provider_id: values?.provider_id,
              product_category_id: values?.product_category_id,
              short_description: values?.short_description,
            };

            try {
              let res;
              // Check if editing or creating a new provider
              if (editProduct) {
                res = await editSingleProduct(editProduct?.id, sendData, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              } else {
                res = await createProduct(values, {
                  headers: {
                    lang: lang,
                    Authorization: `Bearer ${tokenAD}`,
                  },
                });
              }

              // Handle success response
              if (res?.status === 200) {
                setLoading(false);
                toast.success(res?.data?.message);

                setTimeout(() => {
                  // setProductState(true); // Update provider state
                  // resetForm({ values: "" }); // Reset form fields
                  // setEditProduct(null);
                  setEditProduct(res?.data?.data?.product);
                  setCountProvider(countProvider + 1);
                }, 1500);
              } else {
                throw new Error(res?.data?.message || "An error occurred");
              }
            } catch (error) {
              setLoading(false);
              toast.error(
                error.response.data.message ||
                  "An error occurred during the process."
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formMain}>
                <Grid container spacing={2} xs={12} md={12} lg={12}>
                  <Grid item xs={12} md={6} lg={6}>
                    <CustomInput
                      label="Product Name"
                      type="text"
                      name="product_name"
                      value={values.product_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter product name"
                      touched={touched.product_name}
                      errors={errors.product_name}
                    />
                  </Grid>
                </Grid>
                <br></br>

                <Grid container spacing={2} xs={12} md={12} lg={12}>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={`${classes.inputMain}`}>
                      <label>Provider</label>
                      <div className={classes.inputFieldSelect}>
                        <Field
                          as="select"
                          name="provider_id"
                          onBlur={handleBlur}
                          touched={touched.provider_id}
                          errors={errors.provider_id}
                          onChange={async (event) => {
                            const selectedProviders = event.target.value;
                            setFieldValue("provider_id", selectedProviders);
                            setFieldValue("product_category_id", ""); // Reset product_category_id when provider_id changes
                            fetchProductCategories(selectedProviders);
                          }}
                          placeholder="Provider"
                          className={`${classes.inputField}`}
                        >
                          <option value="">Select Provider</option>
                          {masterData?.providers?.map((provider) => (
                            <option value={`${provider.id}`}>
                              {provider.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className={classes.errorMsg}>
                        <ErrorMessage name="provider_id" />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <br></br>

                <Grid container spacing={2} xs={12} md={12} lg={12}>
                  <Grid item xs={12} md={6} lg={6}>
                    {loadingProductCategories ? (
                      <p>Loading product categories...</p>
                    ) : (
                      <div className={`${classes.inputMain}`}>
                        <label>Product Category</label>
                        <div className={classes.inputFieldSelect}>
                          <Field
                            as="select"
                            name="product_category_id"
                            onBlur={handleBlur}
                            touched={touched.product_category_id}
                            errors={errors.product_category_id}
                            onChange={handleChange}
                            disabled={
                              !values.provider_id ||
                              productCategories.length === 0
                            }
                            placeholder="Select Product Category"
                            className={`${classes.inputField}`}
                          >
                            <option value="">Select Product Category</option>

                            {productCategories.map((product_category) => (
                              <option value={product_category.id}>
                                {product_category.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.errorMsg}>
                          <ErrorMessage name="product_category_id" />
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <br></br>

                <Grid container spacing={2} xs={12} md={12} lg={12}>
                  <Grid item xs={12} md={12} lg={12}>
                    <CustomInput
                      label="Short Description"
                      type="text"
                      name="short_description"
                      value={values.short_description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter short description"
                      touched={touched.short_description}
                      errors={errors.short_description}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.addProductBtn}>
                <Button onClick={handleCancel} outlineGrey customWidth="170px">
                  Close
                </Button>
                <Button type="submit" blue customWidth="170px">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>{editProduct ? "Save" : "Add Product"}</>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddProduct;
