import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loader from "../../../../components/shared/loader";
import classes from "./addProviders.module.scss";
import Button from "../../../../components/shared/customBtn";
import { createAccessLevel, editSingleAccessLevel } from "../../../../api/misc";
import { ToastContainer, toast } from "react-toastify";

// Sample checkbox data
// const checkboxData = [
//   {
//     slug: "overview",
//     name: "Overview",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Overview",
//       },
//       {
//         slug: "create",
//         name: "Create Overview",
//       },
//       {
//         slug: "update",
//         name: "Update Overview",
//       },
//       {
//         slug: "delete",
//         name: "Delete Overview",
//       },
//     ],
//   },
//   {
//     slug: "messages",
//     name: "Messages",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Messages",
//       },
//       {
//         slug: "create",
//         name: "Create Messages",
//       },
//       {
//         slug: "update",
//         name: "Update Messages",
//       },
//       {
//         slug: "delete",
//         name: "Delete Messages",
//       },
//     ],
//   },
//   {
//     slug: "loans",
//     name: "Loans",
//     permissions: [
//       {
//         slug: "view owned",
//         name: "View just Owned data",
//       },
//       {
//         slug: "view structure",
//         name: "View just Managed Structure Data",
//       },
//       {
//         slug: "view all",
//         name: "View just All The Data",
//       },
//       {
//         slug: "create",
//         name: "Allow to Add",
//       },
//       {
//         slug: "update owned",
//         name: "Allow to Edit just Owned Data",
//       },
//       {
//         slug: "update structure",
//         name: "Allow to Edit just Managed Structure Data",
//       },
//       {
//         slug: "update all",
//         name: "Allow to Edit All the  Data",
//       },
//       {
//         slug: "delete owned",
//         name: "Allow to Delete just Owned Data",
//       },
//       {
//         slug: "delete structure",
//         name: "Allow to Delete just Managed Structure Data",
//       },
//       {
//         slug: "delete all",
//         name: "Allow to Delete All the Data",
//       },
//       {
//         slug: "export",
//         name: "Allow to Export",
//       },
//     ],
//   },
//   {
//     slug: "clients",
//     name: "Clients",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Clients",
//       },
//       {
//         slug: "create",
//         name: "Create Clients",
//       },
//       {
//         slug: "update",
//         name: "Update Clients",
//       },
//       {
//         slug: "delete",
//         name: "Delete Clients",
//       },
//     ],
//   },
//   {
//     slug: "financials",
//     name: "Financials",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Financials",
//       },
//       {
//         slug: "create",
//         name: "Create Financials",
//       },
//       {
//         slug: "update",
//         name: "Update Financials",
//       },
//       {
//         slug: "delete",
//         name: "Delete Financials",
//       },
//     ],
//   },
//   {
//     slug: "reports",
//     name: "Reports",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Reports",
//       },
//       {
//         slug: "create",
//         name: "Create Reports",
//       },
//       {
//         slug: "update",
//         name: "Update Reports",
//       },
//       {
//         slug: "delete",
//         name: "Delete Reports",
//       },
//     ],
//   },
//   {
//     slug: "appSettings",
//     name: "Application Settings",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Application Settings",
//       },
//       {
//         slug: "create",
//         name: "Create Application Settings",
//       },
//       {
//         slug: "update",
//         name: "Update Application Settings",
//       },
//       {
//         slug: "delete",
//         name: "Delete Application Settings",
//       },
//     ],
//   },
//   {
//     slug: "providers",
//     name: "Providers",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Providers",
//       },
//       {
//         slug: "create",
//         name: "Create Providers",
//       },
//       {
//         slug: "update",
//         name: "Update Providers",
//       },
//       {
//         slug: "delete",
//         name: "Delete Providers",
//       },
//     ],
//   },
//   {
//     slug: "products",
//     name: "Products",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Products",
//       },
//       {
//         slug: "create",
//         name: "Create Products",
//       },
//       {
//         slug: "update",
//         name: "Update Products",
//       },
//       {
//         slug: "delete",
//         name: "Delete Products",
//       },
//     ],
//   },
//   {
//     slug: "users",
//     name: "User Management",
//     permissions: [
//       {
//         slug: "view",
//         name: "View User Management",
//       },
//       {
//         slug: "create",
//         name: "Create User Management",
//       },
//       {
//         slug: "update",
//         name: "Update User Management",
//       },
//       {
//         slug: "delete",
//         name: "Delete User Management",
//       },
//       {
//         slug: "block",
//         name: "Block User Management",
//       },
//       {
//         slug: "unblock",
//         name: "Unblock User Management",
//       },
//     ],
//   },
//   {
//     slug: "accessLevels",
//     name: "Access Levels",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Access Levels",
//       },
//       {
//         slug: "create",
//         name: "Create Access Levels",
//       },
//       {
//         slug: "update",
//         name: "Update Access Levels",
//       },
//       {
//         slug: "delete",
//         name: "Delete Access Levels",
//       },
//     ],
//   },
//   {
//     slug: "careerLevels",
//     name: "Career Levels",
//     permissions: [
//       {
//         slug: "view",
//         name: "View Career Levels",
//       },
//       {
//         slug: "create",
//         name: "Create Career Levels",
//       },
//       {
//         slug: "update",
//         name: "Update Career Levels",
//       },
//       {
//         slug: "delete",
//         name: "Delete Career Levels",
//       },
//     ],
//   },
// ];

// Sample data received for editing
// const apiRoleData = {
//   role_name: "New Role 1",
//   permissions: [
//     {
//       slug: "clients",
//       permissions: ["view", "create"],
//     },
//     {
//       slug: "loans",
//       permissions: ["view owned", "create", "update owned"],
//     },
//   ],
// };

const CheckboxForm = ({
  checkboxData,
  editAccessLevel,
  handleCancel,
  setEditAccessLevel,
}) => {
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));

  const transformToOutput = (values) => {
    const permissions = Object.keys(values)
      .filter((key) => key !== "role_name")
      .map((key) => ({
        slug: key,
        permissions: Object.keys(values[key].children).filter(
          (childKey) => values[key].children[childKey]
        ),
      }))
      .filter((item) => item.permissions.length > 0);

    return {
      role_name: values.role_name,
      permissions,
    };
  };
  const buildInitialValues = (data, apiData) => {
    const initialValues = {
      role_name: apiData?.name || "",
    };

    data?.forEach((item) => {
      const apiItem = apiData?.allowed_permissions?.find(
        (perm) => perm === item.slug
      );
      initialValues[item.slug] = {
        parent: !!apiItem, // Parent checkbox checked if any child permission is present
        children: item.permissions.reduce((acc, perm) => {
          acc[perm.slug] =
            apiData?.allowed_permissions.includes(perm.slug) || false;
          return acc;
        }, {}),
      };
    });

    return initialValues;
  };
  const initialValues = buildInitialValues(checkboxData, editAccessLevel);

  const handleParentChange = (
    setFieldValue,
    parentSlug,
    isChecked,
    children
  ) => {
    setFieldValue(`${parentSlug}.parent`, isChecked);
    children.forEach((child) => {
      setFieldValue(`${parentSlug}.children.${child.slug}`, isChecked);
    });
  };

  const handleChildChange = (
    setFieldValue,
    parentSlug,
    childSlug,
    isChecked,
    allChildren,
    values
  ) => {
    setFieldValue(`${parentSlug}.children.${childSlug}`, isChecked);

    // Check if any child is selected, then check the parent
    const isAnyChildChecked = Object.keys(values[parentSlug].children).some(
      (childKey) =>
        values[parentSlug].children[childKey] || childKey === childSlug
    );

    setFieldValue(`${parentSlug}.parent`, isAnyChildChecked);
  };
  console.log("editAccessLevel", editAccessLevel);
  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object({
          role_name: Yup.string().required("Role Name is required"),
        })}
        onSubmit={async (values) => {
          const sendData = transformToOutput(values);

          console.log("Output:", sendData);

          setLoading(true);

          try {
            let res;
            // Check if editing or creating a new provider
            if (editAccessLevel) {
              res = await editSingleAccessLevel(editAccessLevel?.id, sendData, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });
            } else {
              res = await createAccessLevel(sendData, {
                headers: {
                  lang: lang,
                  Authorization: `Bearer ${tokenAD}`,
                },
              });
            }

            // Handle success response
            if (res?.status === 200) {
              console.log("res.dataapi", res.data);
              setLoading(false);
              toast.success(res?.data?.message);
              setEditAccessLevel(res.data.data);
            } else {
              throw new Error(res?.data?.message || "An error occurred");
            }
          } catch (error) {
            setLoading(false);
            toast.error(
              error.response.data.message ||
                "An error occurred during the process."
            );
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* Role Name Input */}
            <div className={classes.inputMain}>
              <label htmlFor="role_name">Role Name </label>
              <Field
                name="role_name"
                type="text"
                className={classes.inputField}
                placeholder="Enter Role Name"
              />
            </div>
            <p>
              <b>Has Acces to:</b>
            </p>
            <hr />

            {/* Checkbox Permissions */}
            {checkboxData?.map((item) => (
              <div key={item.slug}>
                {/* Parent Checkbox */}
                <label className={classes.mainCheck}>
                  <Field
                    type="checkbox"
                    name={`${item.slug}.parent`}
                    checked={values[item.slug].parent}
                    onChange={(e) =>
                      handleParentChange(
                        setFieldValue,
                        item.slug,
                        e.target.checked,
                        item.permissions
                      )
                    }
                  />
                  {item.name}
                </label>
                <div
                  style={{ marginLeft: "20px" }}
                  className={classes.mainCheckInner}
                >
                  {/* Child Checkboxes */}
                  {item.permissions.map((perm) => (
                    <label key={perm.slug}>
                      <Field
                        type="checkbox"
                        name={`${item.slug}.children.${perm.slug}`}
                        checked={values[item.slug].children[perm.slug]}
                        onChange={(e) =>
                          handleChildChange(
                            setFieldValue,
                            item.slug,
                            perm.slug,
                            e.target.checked,
                            item.permissions,
                            values
                          )
                        }
                      />
                      {perm.name}
                    </label>
                  ))}
                </div>
              </div>
            ))}
            <div className={classes.addProviderBtn}>
              <Button onClick={handleCancel} outlineGrey customWidth="170px">
                Close
              </Button>
              <Button type="submit" blue customWidth="170px">
                {loading ? (
                  <Loader />
                ) : (
                  <>{editAccessLevel ? "Save" : "Submit"}</>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CheckboxForm;
