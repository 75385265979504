import React, { useCallback, useState } from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import classes from "./mainStatus.module.scss";
import { toast } from "react-toastify";
import useGetAxios from "../../../api/hooks/useGetAxios";
import { getAllStatus } from "../../../api/misc";

const MainStatus = ({
  allstatusVal,
  setAllstatusVal,
  editExistingclient,
  setEditExistingclient,
  apiCall,
  countStatus,
}) => {
  const lang = localStorage.getItem("lang");
  const tokenAD = JSON.parse(localStorage.getItem("authToken"));
  const masterData = useSelector((state) => state?.masterData?.masterData);
  const [count, setCount] = useState(0);

  const {
    response: getAllStatusData,
    loading: getStatusLoading,
    error: getStatusError,
  } = useGetAxios(
    getAllStatus(editExistingclient?.id),
    false,
    count || countStatus
  );
  console.log("getAllStatusData", getAllStatusData?.data?.applicationStatuses);
  const handleAllStatusChange = useCallback(
    async (event) => {
      setAllstatusVal(event.target.value);
      if (editExistingclient?.id) {
        const updatestat = {
          status: event.target.value || allstatusVal,
        };
        const resStatReq = await apiCall(editExistingclient?.id, updatestat, {
          headers: {
            lang: lang,
            Authorization: `Bearer ${tokenAD}`,
          },
        });
        if (resStatReq?.status === 200) {
          setCount(count + 1);
          toast.success(resStatReq?.data?.message);
          setEditExistingclient(resStatReq?.data?.data);
        } else {
          throw new Error(resStatReq?.data?.message || "An error occurred");
        }
      }
    },
    [allstatusVal]
  );
  return (
    <div className={classes.wholeStatus}>
      <FormControl variant="standard" sx={{ minWidth: 150 }}>
        <InputLabel>Status</InputLabel>
        <Select value={allstatusVal} onChange={handleAllStatusChange}>
          <MenuItem value="0" disabled>
            Select Status
          </MenuItem>
          {getAllStatusData?.data?.applicationStatuses?.map((item) => (
            <MenuItem value={item?.slug}>{item?.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MainStatus;
