import React from "react";

const FormatDateTime = (dateString) => {
  const date = new Date(dateString);

  // Format options
  const options = {
    year: "numeric",
    month: "long", // e.g., October
    day: "numeric", // e.g., 18
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // 12-hour format with AM/PM
  };

  // Return formatted date
  return date.toLocaleString("en-US", options);
};

export default FormatDateTime;
