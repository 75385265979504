const FormatDateOnly = (dateString) => {
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  // Format as D/M/Y
  return `${day}/${month}/${year}`;
};

export default FormatDateOnly;
