export const getCroppedImg = (imageSrc, crop, width, height) => {
  const image = new Image();
  image.src = imageSrc;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );

      try {
        const base64Image = canvas.toDataURL("image/jpeg");
        resolve(base64Image); // Return Base64 string
      } catch (error) {
        reject(error);
      }
    };

    image.onerror = (error) => reject(error);
  });
};
